import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Descriptions,
  Row,
  Statistic,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { getFileFromPath } from 'services/requests/files.request';
import { setLoading } from 'services/store/actions/view';
import { HistoryColor } from 'services/store/enums/historyColor.enum';
import { IFile } from 'services/store/types/files/Files';
import { IOrderPayment, IOrder } from 'services/store/types/orders/Orders';
import OrderApprovePaymentModal from './Modals/ApproveOrderPayment.modal';

const { Text } = Typography;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow-x: auto;
`;

interface IProps {
  order: IOrder;
  handleUpdateOrder: (
    updateData: Partial<IOrder>,
    historyUpdate: string[],
    historyColor: HistoryColor
  ) => Promise<any>;
}

const OrderPayments: React.FC<IProps> = ({ order, handleUpdateOrder }) => {
  const dispatch = useDispatch();
  const [paymentToApprove, setPaymentToApprove] = useState<IOrderPayment>();
  const [toPaid, setToPaid] = useState<number>(order.price);
  const [approvedPayments, setApprovedPayments] = useState<number>(0);

  useEffect(() => {
    const approvedPaymentsValue = order.payments
      .filter((p) => p.isPaid)
      .reduce((acc, p) => {
        const sum = acc + p.value;
        return sum;
      }, 0);

    Promise.resolve().then(() => {
      setToPaid(order.price - approvedPaymentsValue);
      setApprovedPayments(approvedPaymentsValue);
    });
  }, [order]);

  const getPaymentFile = async (path: string) => {
    dispatch(setLoading(true));
    await getFileFromPath(path);
    dispatch(setLoading(false));
  };

  return (
    <>
      <Row>
        <Col md={{ span: 2, offset: 20 }} span={24}>
          <Statistic
            title="Zapłacono"
            value={approvedPayments}
            valueStyle={{ color: 'green' }}
            suffix="zł"
          />
        </Col>
        <Col md={{ span: 2 }} span={24}>
          <Statistic
            title="Do zapłaty pozostało"
            value={toPaid}
            valueStyle={{ color: !toPaid ? 'green' : 'red' }}
            suffix="zł"
          />
        </Col>
      </Row>
      <Wrapper>
        {order.payments.map((payment: IOrderPayment, index: number) => (
          <PaymentItem
            key={payment._id}
            payment={payment}
            index={index}
            setPaymentToApprove={setPaymentToApprove}
            getPaymentFile={getPaymentFile}
          />
        ))}
      </Wrapper>

      {/* MODALS */}
      {paymentToApprove && (
        <OrderApprovePaymentModal
          order={order}
          payment={paymentToApprove}
          closeModal={() => setPaymentToApprove(undefined)}
          handleUpdateOrder={handleUpdateOrder}
        />
      )}
    </>
  );
};

export default OrderPayments;

interface IPaymentItemProps {
  payment: IOrderPayment;
  index: number;
  setPaymentToApprove: (payment: IOrderPayment) => void;
  getPaymentFile: (path: string) => void;
}
const PaymentItem: React.FC<IPaymentItemProps> = ({
  payment,
  setPaymentToApprove,
  getPaymentFile,
}) => {
  return (
    <Card style={{ margin: 10, width: 200 }} bodyStyle={{ padding: 10 }}>
      <Descriptions title={payment.name} column={1}>
        <Descriptions.Item label="Termin">
          <Text strong>{dayjs(payment.term).format('DD.MM.YYYY')}</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Wartość">
          <Text strong>{payment.value} zł</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Typ">
          <Text strong>{payment.type}</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Zapłacono?">
          <Text strong type={payment.isPaid ? 'success' : 'danger'}>
            {payment.isPaid ? 'Tak' : 'Nie'}
          </Text>
        </Descriptions.Item>
        <Descriptions.Item label="Potwierdzenie">
          {payment.file ? (
            <Button
              size="small"
              onClick={() =>
                payment.file && getPaymentFile((payment.file as IFile).dir)
              }
            >
              Pobierz
            </Button>
          ) : (
            '-'
          )}
        </Descriptions.Item>
      </Descriptions>
      <Button
        type="primary"
        size="small"
        block
        disabled={payment.isPaid}
        onClick={() => setPaymentToApprove(payment)}
      >
        Zatwierdź płatność
      </Button>
    </Card>
  );
};
