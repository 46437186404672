import React, { useState } from 'react';
import { Button, message, Modal, Typography } from 'antd';
import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import orderIdImage from 'assets/images/orderId.png';
import { AntInput } from 'components/Formik-AntdFields/CreateAntdField';
import { paintedElementIdValidation } from 'scenes/ProjectPage/utils/validation';
import { HistoryColor } from 'services/store/enums/historyColor.enum';
import { IProject } from 'services/store/types/projects/Projects';

interface IProps {
  project: IProject;
  closeModal: () => void;
  handleUpdateProject: (
    updateData: Partial<IProject>,
    historyUpdate: string[],
    historyColor: HistoryColor
  ) => Promise<any>;
}

const AddProjectPaintedElementsOrderModal: React.FC<IProps> = ({
  project,
  closeModal,
  handleUpdateProject,
}) => {
  const onOk = async (values: { id: string }) => {
    if (project.paintedElementsOrders.includes(values.id)) {
      message.info('To zamówienie istnieje już w projekcie');
      return;
    }
    const historyUpdate = [`Dodano zamówienie na elementy lakierowane.`];
    const newPaintedElementsOrders = [
      ...project.paintedElementsOrders,
      values.id,
    ];

    const orderCheck = await axios.get(
      `https://www.hurt.zamowfronty.pl/api/orders/${values.id}`
    );
    if (orderCheck.status === 404) {
      message.error('Brak zamówienia z podanym ID');
      return;
    } else if (orderCheck.status !== 200) {
      message.error(
        'Wystąpił problem z pobraniem zamówienia. Upewnij się że ID jest poprawne'
      );
      return;
    }

    await handleUpdateProject(
      { paintedElementsOrders: newPaintedElementsOrders },
      historyUpdate,
      HistoryColor.SUCCESS
    );
    closeModal();
  };

  return (
    <Modal
      title="Zamówienie na elementy lakierowane"
      destroyOnClose
      visible
      onCancel={closeModal}
      maskClosable={false}
      footer={[
        <Button key="back" onClick={closeModal}>
          Anuluj
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          form="ChangeDataForm"
        >
          Zatwierdź
        </Button>,
      ]}
    >
      <Formik
        validationSchema={paintedElementIdValidation}
        onSubmit={(values) => onOk(values)}
        initialValues={{ id: '' }}
      >
        {(props) => {
          return (
            <Form noValidate id="ChangeDataForm" onSubmit={props.handleSubmit}>
              <Field
                component={AntInput}
                name="id"
                type="text"
                placeholder="ID zamówienia"
                validate={props.errors.id}
                submitCount={props.submitCount}
                hasFeedback
              />
              <div style={{ marginTop: 20, textAlign: 'center' }}>
                <Typography.Text>
                  ID zamówienia jest ciągiem 24 znaków zawartym w linku
                </Typography.Text>
                <img src={orderIdImage} alt="orderId" />
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AddProjectPaintedElementsOrderModal;
