import React, { ReactElement } from 'react';
import styled from 'styled-components';

const Span = styled.span<{ disabled: boolean; danger: boolean }>`
  color: ${({ theme, disabled, danger }) =>
    danger ? '#ad1818' : disabled ? 'gray' : theme.primary};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  &:hover {
    text-decoration: ${({ disabled }) => (disabled ? 'none' : 'underline')};
  }
`;

interface PropsT {
  text: string | ReactElement;
  onClick: () => void;
  style?: any;
  disabled?: boolean;
  danger?: boolean;
}

const TextButton: React.FC<PropsT> = ({
  text,
  onClick = () => {},
  style,
  disabled = false,
  danger = false,
}) => (
  <Span
    onClick={() => !disabled && onClick()}
    style={style}
    disabled={disabled}
    danger={danger}
  >
    {text}
  </Span>
);

export default TextButton;
