import axios from 'axios';

const setAuthToken = (): void => {
  if (localStorage.token)
    axios.defaults.headers.common.Authorization =
      'Bearer ' + localStorage.token;
  else delete axios.defaults.headers.common.Authorization;
};

export default setAuthToken;
