import { TUsersActions, USERS_LOAD_SUCCESS } from '../types/users/actions';
import { IUsers } from '../types/users/Users';

const initialState: IUsers = {
  users: [],
};

const usersReducer = (state = initialState, action: TUsersActions): IUsers => {
  switch (action.type) {
    case USERS_LOAD_SUCCESS: {
      return { ...state, users: action.users };
    }
    default:
      return state;
  }
};

export { usersReducer };
