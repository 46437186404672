import React, { useState } from 'react';
import { Descriptions, message, Modal, Space } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import humanizeDuration from 'humanize-duration';
import { isDate } from 'lodash';
import { useDispatch } from 'react-redux';
import AntDatePicker from 'components/DatePicker/DatePicker';
import UserPositionTag from 'components/Tags/UserPositionTag/UserPositionTag';
import { workTimesApi } from 'services/api/workTimes.api';
import { addItemToProjectHistory } from 'services/requests/projects.request';
// import { updateWorkTimeFinishDate } from 'services/requests/workTIme.request';
import { setLoading } from 'services/store/actions/view';
import { HistoryColor } from 'services/store/enums/historyColor.enum';
import { IWorkTime } from 'services/store/types/statistics/Statistics';
import { getUserName } from 'services/utils/string';

// FIXME REFACTOR & useMutation. There is similar modal, checkout in users statistics module

interface IProps {
  workTimeItem: IWorkTime;
  closeModal: () => void;
  getWorkTimes?: () => Promise<any>;
}

const UpdateWorkTimeItemModal: React.FC<IProps> = ({
  workTimeItem,
  closeModal,
  getWorkTimes,
}) => {
  const dispatch = useDispatch();
  const [date, setDate] = useState<Date>(workTimeItem.finish);

  const durationTimestamp = new Date(workTimeItem.duration).getTime();
  const durationText = humanizeDuration(durationTimestamp, {
    units: ['h', 'm'],
    round: true,
    language: 'pl',
  });

  const updateWorkTimeItem = async () => {
    if (date && isDate(date) && workTimeItem._id) {
      const isDateEarlierThanStart =
        dayjs(date).diff(dayjs(workTimeItem.start)) < 0;
      if (isDateEarlierThanStart)
        return message.error(
          "Data zakończenia musi być 'po' dacie rozpoczęcia"
        );
      dispatch(setLoading(true));
      await workTimesApi.updateWorkTime(workTimeItem._id, { finish: date });
      if (getWorkTimes) {
        await getWorkTimes();
      }
      const historyActions = [
        'Zmieniono datę zakończenia procesu',
        `Dla użytkownika: ${getUserName(workTimeItem.user)}, na stanowisku: ${
          workTimeItem.position
        }`,
        `z ${dayjs(workTimeItem.finish).format('DD.MM.YYYY, HH:mm')} na ${dayjs(
          date
        ).format('DD.MM.YYYY, HH:mm')}`,
      ];
      await addItemToProjectHistory(
        workTimeItem.project as string,
        historyActions,
        HistoryColor.DANGER
      );
      dispatch(setLoading(false));
      closeModal();
    }
  };
  return (
    <Modal
      title="Zmiana daty zakończenia procesu"
      destroyOnClose
      visible
      onCancel={closeModal}
      maskClosable={false}
      onOk={updateWorkTimeItem}
    >
      <Space direction="vertical" size="middle">
        <Descriptions column={1} size="small">
          <Descriptions.Item label="Użytkownik">
            <strong>{getUserName(workTimeItem.user)}</strong>
          </Descriptions.Item>
          <Descriptions.Item label="Stanowisko">
            <UserPositionTag position={workTimeItem.position} />
          </Descriptions.Item>
          <Descriptions.Item label="Rozpoczęcie procesu">
            <strong>
              {dayjs(workTimeItem.start).format('DD.MM.YYYY, HH:mm')}
            </strong>
          </Descriptions.Item>
          <Descriptions.Item label="Aktualny czas trwania">
            <strong>{durationText}</strong>
          </Descriptions.Item>
        </Descriptions>
        <div>
          <strong>Data zakończenia</strong>
          <AntDatePicker
            showTime
            value={dayjs(date)}
            showSecond={false}
            disabledDate={(d) => d.diff(dayjs(workTimeItem.start)) < 0}
            onChange={(d) => (d ? setDate(d.toDate()) : null)}
            style={{ width: '100%' }}
            format="DD.MM.YYYY"
          />
        </div>
      </Space>
    </Modal>
  );
};

export default UpdateWorkTimeItemModal;
