import React from 'react';
import { Descriptions, Skeleton } from 'antd';
import { IAddress } from 'services/store/types/address/Address';
import { ICompanyAddress } from 'services/store/types/settings/Settings';

interface IProps {
  address?: IAddress | ICompanyAddress;
  title?: string;
}

const Address: React.FC<IProps> = ({ address, title = 'Adres' }) => {
  if (address)
    return (
      <Descriptions title={title} column={1}>
        <Descriptions.Item label="Miejscowość">
          <strong>{address.city}</strong>
        </Descriptions.Item>
        <Descriptions.Item label="Kod pocztowy">
          <strong>{address.postcode}</strong>
        </Descriptions.Item>
        <Descriptions.Item label="Adres">
          <strong>{address.address}</strong>
        </Descriptions.Item>
        <Descriptions.Item label="Opis">
          <strong>{address.description}</strong>
        </Descriptions.Item>
      </Descriptions>
    );
  return (
    <Skeleton title={{ width: 100 }} paragraph={{ rows: 4, width: 100 }} />
  );
};

export default Address;
