import UserPositionTag from 'components/Tags/UserPositionTag/UserPositionTag';
import { Positions } from 'services/store/enums/users.enum';
import { IUser } from 'services/store/types/users/Users';
import { getUserName } from 'services/utils/string';

export const renderProductionNodeCell = (data: {
  position: Positions;
  user: IUser;
}) => {
  return (
    <div>
      <div>
        <UserPositionTag position={data.position} />
      </div>
      <div>{getUserName(data.user)}</div>
    </div>
  );
};
