import React from 'react';
import { Tag } from 'antd';
import BlinkingDot from 'components/Spinner/BlinkingDot';
import { ProjectStatus } from 'services/store/enums/project.enum';

interface IProps {
  status: ProjectStatus;
  inProduction?: boolean;
}

const ProjectStatusTag: React.FC<IProps> = ({
  status,
  inProduction = false,
}) => {
  if (status === ProjectStatus.PENDING)
    return (
      <Tag color="gold">
        {status.toUpperCase()} {inProduction && <BlinkingDot />}
      </Tag>
    );
  if (status === ProjectStatus.PROJECT)
    return (
      <Tag color="orange">
        {status.toUpperCase()} {inProduction && <BlinkingDot />}
      </Tag>
    );
  if (status === ProjectStatus.TECHNOLOGIST)
    return (
      <Tag color="lime">
        {status.toUpperCase()} {inProduction && <BlinkingDot />}
      </Tag>
    );
  if (status === ProjectStatus.CARPENTER)
    return (
      <Tag color="cyan">
        {status.toUpperCase()} {inProduction && <BlinkingDot />}
      </Tag>
    );
  if (status === ProjectStatus.STORAGE)
    return (
      <Tag color="blue">
        {status.toUpperCase()} {inProduction && <BlinkingDot />}
      </Tag>
    );
  if (status === ProjectStatus.MONTAGE)
    return (
      <Tag color="geekblue">
        {status.toUpperCase()} {inProduction && <BlinkingDot />}
      </Tag>
    );
  if (status === ProjectStatus.FINISHED)
    return (
      <Tag color="green">
        {status.toUpperCase()} {inProduction && <BlinkingDot />}
      </Tag>
    );
  if (status === ProjectStatus.CANCELED)
    return (
      <Tag color="red">
        {status.toUpperCase()} {inProduction && <BlinkingDot />}
      </Tag>
    );
  return null;
};

export default ProjectStatusTag;
