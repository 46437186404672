import { useCallback, useEffect, useState } from 'react';
import { AppstoreOutlined, HomeOutlined } from '@ant-design/icons';
import { Button, PageHeader, Space, Input, Row, Col, message } from 'antd';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import AntDivider from 'components/Divider/Divider';
import { H1, H2, H4 } from 'components/Header';
import AccessoryModal from 'components/Modals/AccessoryModal/Accessory.modal';
import CreateCustomerModal from 'components/Modals/CustomerCreateModal/CreateCustomerModal';
import { useModal } from 'hook/useModals';
import { checkIsOrdersStatusUpdateIsNeededAndUpdate } from 'services/requests/orders.request';
import { TAppState } from 'services/store';
import { getAllAccessories } from 'services/store/actions/accessories';
import { getAllUsers } from 'services/store/actions/users';
import { setLoading } from 'services/store/actions/view';
import { Positions } from 'services/store/enums/users.enum';
import { IAccessory } from 'services/store/types/accessories/Accessories';
import { IOrder } from 'services/store/types/orders/Orders';
import {
  IProject,
  IProjectAccessory,
} from 'services/store/types/projects/Projects';
import { IUser } from 'services/store/types/users/Users';
import setAuthToken from 'services/utils/setAuthToken';
import PageTemplate from 'templates/PageTemplate';
import ProjectAccessoriesList from './components/ProjectAccessoriesList';
import ProjectOrderData from './components/ProjectOrderData';
import ProjectUserData from './components/ProjectUserData';
import ProjectUserSelect from './components/ProjectUserSelect';
import { initProjectData } from './data/initProject.data';
import { newProjectValidation } from './utils/validation';
import { ErrorModal } from '../../components/Modals/ErrorModal/ErrorModal';
import PickAccessoriesModal from '../../components/Modals/PickAccessoriesModal/PickAccessoriesModal';

const ProjectCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { orderId }: { orderId: string } = useParams();
  const user = useSelector((state: TAppState) => state.auth.user);
  const users = useSelector((state: TAppState) => state.users.users);
  const accessories = useSelector(
    (state: TAppState) => state.accessories.accessories
  );
  const { modal, showModal, closeModal } = useModal({
    addAddress: false,
    addCustomer: false,
    addAccessory: false,
    pickAccessories: false,
  });
  const [order, setOrder] = useState<IOrder>();
  const [project, setProject] = useState<IProject>(initProjectData);

  const getOrder = useCallback(async () => {
    dispatch(setLoading(true));
    try {
      setAuthToken();
      const res = await axios.get(`/api/orders/${orderId}`);

      setOrder(res.data);
    } catch (error: any) {
      console.log(error, error.response);
      message.error('Błąd');
    }
    dispatch(setLoading(false));
  }, [orderId]);

  useEffect(() => {
    if (user) {
      setProject((prev: any) => ({ ...prev, createdBy: user }));
      dispatch(getAllUsers());
      dispatch(getAllAccessories());
    }
  }, [user]);

  useEffect(() => {
    if (orderId) {
      getOrder();
    }
  }, [getOrder]);

  useEffect(() => {
    if (order) {
      setProject((prev: any) => ({
        ...prev,
        number: `${order.number}/`,
      }));
    }
  }, [order]);

  useEffect(() => {
    if (accessories) {
      const suggested = accessories
        .filter((acc: any) => acc.isSuggested)
        .map((acc: any) => ({ accessory: acc, qty: 1 }));

      setProject((prev: IProject) => ({
        ...prev,
        accessories: suggested,
      }));
    }
  }, [accessories]);

  // NOTE Designer, Technologist
  const handleSetUser = (
    userType: 'designer' | 'technologist',
    _id?: string
  ) => {
    if (users) {
      const employee = users.find((u: any) => u._id === _id);
      setProject((prev: IProject) => ({ ...prev, [userType]: employee }));
    }
  };

  // NOTE Accessories
  const handleSetAccessories = (pickedAccessories: IProjectAccessory[]) => {
    const newAccessories = pickedAccessories.map((acc) => {
      const existing = project.accessories.find(
        (a: any) => a.accessory._id === (acc.accessory as IAccessory)._id
      );
      if (existing) return existing;
      return acc;
    });

    setProject((prev: IProject) => ({
      ...prev,
      accessories: newAccessories,
    }));
  };

  const handleProjectAccessoryQty = (index: number, qty: number) => {
    const projectAccessories = project.accessories.map((acc: any, i: any) =>
      i === index ? { ...acc, qty } : acc
    );
    setProject((prev: IProject) => ({
      ...prev,
      accessories: projectAccessories,
    }));
  };

  const handleDeleteProjectAccessory = (index: number) => {
    const projectAccessories = project.accessories.filter(
      (acc: any, i: any) => i !== index
    );
    setProject((prev: IProject) => ({
      ...prev,
      accessories: projectAccessories,
    }));
  };

  // NOTE Project
  const handleCreateProject = async () => {
    if (order && order._id) {
      // validation
      const errors = newProjectValidation(project);
      if (errors.length) return ErrorModal(errors);

      // creatingProject
      dispatch(setLoading(true));
      try {
        const projectAccessories: any[] = project.accessories.map(
          (projectAccessory: any) => ({
            accessory: (projectAccessory.accessory as IAccessory)._id,
            qty: projectAccessory.qty,
          })
        );

        const res = await axios.post(`/api/projects`, {
          ...project,
          order: order._id,
          accessories: projectAccessories,
        });
        message.success('Utworzono projekt');

        await checkIsOrdersStatusUpdateIsNeededAndUpdate(order._id);

        history.push(`/projects/${res.data}`);
        dispatch(setLoading(false));
      } catch (error) {
        console.log(error);
        message.error('Błąd');
        dispatch(setLoading(false));
      }
    }
  };
  console.log(project);
  return (
    <PageTemplate>
      <PageHeader title={<H1>Nowy projekt</H1>} />
      {/* NOTE Order */}
      <H2>Dane zamówienia</H2>
      <ProjectOrderData order={order} />
      <AntDivider />

      {/* NOTE Created by */}
      <H2>Utworzony przez</H2>
      <ProjectUserData user={project.createdBy} />
      <AntDivider />

      {/* NOTE Designer */}
      <H2>Projektant</H2>
      <ProjectUserSelect
        value={project.designer?._id}
        handleUpdate={(id?: string) => handleSetUser('designer', id)}
        placeholder="Projektant"
        users={users?.filter((u: IUser) =>
          u.positions.includes(Positions.DESIGNER)
        )}
      />
      <ProjectUserData user={project.designer} />
      <AntDivider />

      {/* NOTE Technologist */}
      <H2>Technolog</H2>
      <ProjectUserSelect
        value={project.technologist?._id}
        handleUpdate={(id?: string) => handleSetUser('technologist', id)}
        placeholder="Technolog"
        users={users?.filter((u: any) => u.position === Positions.TECHNOLOGIST)}
      />
      <ProjectUserData user={project.technologist} />
      <AntDivider />

      {/* NOTE Data */}
      <H2>Dane do projektu</H2>
      <div>
        <Space>
          <H4>Nazwa projektu:</H4>
          <Input
            size="large"
            value={project.name}
            onChange={(e) =>
              setProject((prev: any) => ({
                ...prev,
                name: e.target.value,
                number: `${order?.number}/${e.target.value}`,
              }))
            }
            placeholder="Nazwa projektu"
          />
        </Space>
      </div>
      <br />
      <div>
        <Space>
          <H4>Numer projektu:</H4>
          <Input
            size="large"
            value={project.number}
            onChange={(e) =>
              setProject((prev: any) => ({ ...prev, number: e.target.value }))
            }
            placeholder="Numer projektu"
          />
        </Space>
      </div>
      <AntDivider />

      {/* NOTE Accessories */}
      <Space size="large" align="start">
        <H2>Akcesoria</H2>
        <Button type="primary" onClick={() => showModal('pickAccessories')}>
          <AppstoreOutlined />
          Wybierz akcesoria
        </Button>
        <Button onClick={() => showModal('addAccessory')}>
          <HomeOutlined />
          Dodaj akcesoria
        </Button>
      </Space>
      <ProjectAccessoriesList
        accessories={project.accessories}
        handleQty={handleProjectAccessoryQty}
        handleDelete={handleDeleteProjectAccessory}
      />
      <AntDivider />

      {/* NOTE Utwórz  */}
      <Row style={{ marginTop: 50, marginBottom: 20 }}>
        <Col offset={21}>
          <Button size="large" type="primary" onClick={handleCreateProject}>
            Utwórz projekt
          </Button>
        </Col>
      </Row>
      {/* NOTE MODALS */}
      {modal.pickAccessories && accessories && (
        <PickAccessoriesModal
          accessories={accessories}
          closeModal={closeModal}
          onSubmit={handleSetAccessories}
          picked={project.accessories.map((acc: any) => acc.accessory._id)}
        />
      )}
      {modal.addAccessory && <AccessoryModal closeModal={closeModal} />}
      {/* {modal.addCustomer && <CreateCustomerModal closeModal={closeModal} />} */}
    </PageTemplate>
  );
};

export default ProjectCreate;
