import React, { useState } from 'react';
import { Input, Modal, Radio, Row, Select } from 'antd';
import { Positions } from 'services/store/enums/users.enum';
import { IWorkAction } from 'services/store/types/settings/Settings';
import { IUser } from 'services/store/types/users/Users';

const { Option } = Select;

interface IProps {
  startProduction: (position: Positions, workAction?: string) => Promise<any>;
  workActions: IWorkAction[];
  user: IUser | null;
  closeModal: () => void;
}

const StartProductionModal: React.FC<IProps> = ({
  startProduction,
  workActions,
  user,
  closeModal,
}) => {
  const [type, setType] = useState<string>(
    workActions.length ? 'select' : 'type-text'
  );
  const [workAction, setWorkAction] = useState<string>('');

  const handleRadio = (e: any) => setType(e.target.value);

  const handleOk = async () => {
    if (user?.activePosition) {
      await startProduction(user.activePosition, workAction || undefined);
      closeModal();
    }
  };

  return (
    <Modal
      title="Rozpoczynanie procesu"
      destroyOnClose
      visible
      onCancel={closeModal}
      maskClosable={false}
      onOk={handleOk}
      okButtonProps={{ disabled: !user?.activePosition }}
    >
      <Radio.Group
        onChange={handleRadio}
        value={type}
        style={{ width: '100%', marginBottom: 10 }}
      >
        <Row justify="space-between">
          <Radio value={'select'} disabled={!workActions.length}>
            Wybierz z listy
          </Radio>
          <Radio value={'type-text'}>Wpisz samodzielnie</Radio>
        </Row>
      </Radio.Group>

      {type === 'select' ? (
        <Select
          style={{ width: '100%' }}
          value={workAction || null}
          onChange={(value) => setWorkAction(value)}
          placeholder="Wykonywana akcja"
          disabled={!workActions.length || type !== 'select'}
        >
          {workActions.map((action) => (
            <Option
              key={action._id}
              value={action.action}
              title={action.description}
            >
              {action.action}
            </Option>
          ))}
        </Select>
      ) : (
        <Input
          value={workAction}
          onChange={(e) => setWorkAction(e.target.value)}
          placeholder="Wykonywana akcja"
          max={50}
        />
      )}
    </Modal>
  );
};

export default StartProductionModal;
