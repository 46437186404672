import { useCallback, useEffect, useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { PageHeader, Modal, message } from 'antd';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import CustomerData from 'components/CustomerData/CustomerData';
import AntDivider from 'components/Divider/Divider';
import { H1, H2 } from 'components/Header';
import AddressModal from 'components/Modals/AddressModal/AddressModal';
import OrdersList from 'components/ordersList/OrdersList';
import { useModal } from 'hook/useModals';
import { TAppState } from 'services/store';
import { setLoading } from 'services/store/actions/view';
import { IAddress } from 'services/store/types/address/Address';
import { ICustomer } from 'services/store/types/customers/Customers';
import { IOrder } from 'services/store/types/orders/Orders';
import setAuthToken from 'services/utils/setAuthToken';
import { getUserName } from 'services/utils/string';
import PageTemplate from 'templates/PageTemplate';
import ChangeCustomerDataModal from './components/ChangeCustomerDataModal';
import CustomerActions from './components/CustomerActions';

const { confirm } = Modal;

const CustomerPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state: TAppState) => state.auth.user);
  const { id }: { id: string } = useParams();
  const [customer, setCustomer] = useState<ICustomer>();
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [isOdersLoading, setIsOrdersLoading] = useState<boolean>(false);
  const { modal, showModal, closeModal } = useModal({
    changeData: false,
    changeAddress: false,
  });

  const getOrders = useCallback(async () => {
    try {
      setIsOrdersLoading(true);
      setAuthToken();
      const res = await axios.get(`/api/orders`, {
        params: {
          customer: id,
        },
      });
      setOrders(res.data);
    } catch (error) {
      console.log(error);
      message.error('Błąd');
    } finally {
      setIsOrdersLoading(false);
    }
  }, []);

  useEffect(() => {
    dispatch(setLoading(true));
    setAuthToken();
    axios.get(`/api/customers/${id}`).then((res) => {
      setCustomer(res.data);
    });
    dispatch(setLoading(false));
    getOrders();
  }, []);

  const changeCustomerAddress = async (address: IAddress) => {
    try {
      const res = await axios.patch(`/api/customers/${id}`, {
        address,
      });
      setCustomer(res.data);
      message.success('Adres klienta zaktualizowany');
      closeModal();
    } catch (error: any) {
      message.error('Błąd');
      console.log(error.response);
    }
  };

  const addCustomerToBlackList = async () => {
    if (customer) {
      dispatch(setLoading(true));
      try {
        const res = await axios.patch(`/api/customers/${id}`, {
          isOnBlackList: !customer.isOnBlackList,
        });
        setCustomer(res.data);
        if (!customer.isOnBlackList)
          message.warning('Dodano klienta do czarnej listy');
        else if (customer.isOnBlackList)
          message.success('Usunięto klienta z czarnej listy');
      } catch (error) {
        message.error('Błąd');
      }
      dispatch(setLoading(false));
    }
  };

  const deleteCustomer = async () => {
    dispatch(setLoading(true));
    try {
      await axios.delete(`/api/customers/${id}`);
      message.success('Klient usunięty');
      history.goBack();
    } catch (error) {
      message.error('Błąd');
    }
    dispatch(setLoading(false));
  };

  const confirmDeleteCustomer = () =>
    confirm({
      title: 'Czy na pewno chcesz usunąć tego klienta?',
      content:
        'Usunięte zostaną również wszystkie projekty wraz z plikami, przypisane do tego klienta.',
      icon: <ExclamationCircleOutlined />,
      okText: 'Tak',
      okType: 'danger',
      cancelText: 'Nie',
      onOk() {
        deleteCustomer();
      },
      onCancel() {},
    });

  return (
    <PageTemplate>
      {customer && (
        <>
          <PageHeader
            title={
              <H1>
                {getUserName(customer)}{' '}
                {customer.isOnBlackList && (
                  <ExclamationCircleOutlined
                    style={{ color: 'red' }}
                    title="Klient jest na czarnej liście"
                  />
                )}
              </H1>
            }
            subTitle={customer.company}
            extra={
              <CustomerActions
                customer={customer}
                actions={{
                  showModal,
                  addCustomerToBlackList,
                  confirmDeleteCustomer,
                }}
              />
            }
          />
          <CustomerData customer={customer} showAddress />
          <AntDivider />
          <H2>Zamówienia</H2>
          <OrdersList orders={orders} isLoading={isOdersLoading} user={user} />
        </>
      )}

      {/* MODAL */}
      {modal.changeAddress && customer && (
        <AddressModal
          address={customer.address}
          onSubmit={changeCustomerAddress}
          closeModal={closeModal}
        />
      )}
      {modal.changeData && customer && (
        <ChangeCustomerDataModal
          customer={customer}
          setCustomer={setCustomer}
          closeModal={closeModal}
        />
      )}
    </PageTemplate>
  );
};

export default CustomerPage;
