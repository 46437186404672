import React, { useEffect, useState } from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { Image, InputNumber, Table } from 'antd';
import { DeleteIcon } from 'components/Icons';
import { IAccessory } from 'services/store/types/accessories/Accessories';
import { IFile } from 'services/store/types/files/Files';
import { IProjectAccessory } from 'services/store/types/projects/Projects';
import { cutString } from 'services/utils/string';

const columns: any[] = [
  {
    dataIndex: 'lp',
    title: 'Lp.',
    align: 'center' as const,
  },
  {
    title: 'Zdjęcie',
    dataIndex: 'image',
    align: 'center' as const,
    render: (image: IFile) =>
      image ? (
        <Image
          alt="img"
          width={40}
          height={40}
          src={`${image.dir}`}
          preview={{ src: `${image.dir}` }}
        />
      ) : (
        '-'
      ),
  },
  {
    title: 'Nazwa',
    dataIndex: 'name',
    align: 'center' as const,
    defaultSortOrder: 'ascend' as const,
    sorter: (a: any, b: any) => a.name.localeCompare(b.name),
  },
  {
    title: 'Symbol',
    dataIndex: 'symbol',
    align: 'center' as const,
    sorter: (a: any, b: any) => (a.symbol || '').localeCompare(b.symbol || ''),
  },
  {
    title: 'Description',
    dataIndex: 'description',
    align: 'center' as const,
    render: (description: string) => cutString(description || '', 20),
  },
  {
    title: 'Cena',
    dataIndex: 'price',
    align: 'center' as const,
  },
  {
    title: 'Sugerowany',
    dataIndex: 'isSuggested',
    align: 'center' as const,
    render: (isSuggested: boolean) => (isSuggested ? <CheckOutlined /> : null),
  },
  {
    title: 'Ilość',
    dataIndex: 'qty',
    align: 'center' as const,
  },
  {
    title: 'Usuń',
    dataIndex: 'delete',
    align: 'center' as const,
  },
];

interface IProps {
  accessories: IProjectAccessory[];
  handleQty: (index: number, value: number) => void;
  handleDelete: (index: number) => void;
}

const ProjectAccessoriesList: React.FC<IProps> = ({
  accessories,
  handleQty,
  handleDelete,
}) => {
  const [list, setList] = useState<any[]>([]);

  useEffect(() => {
    const newAccessories = accessories.map(
      (accessory: IProjectAccessory, i: number) => ({
        ...(accessory.accessory as IAccessory),
        qty: (
          <InputNumber
            value={accessory.qty}
            onChange={(value) => value && handleQty(i, value)}
            min={1}
          />
        ),
        delete: <DeleteIcon onClick={() => handleDelete(i)} title="Usuń" />,
        key: (accessory.accessory as IAccessory)._id,
      })
    );
    setList(newAccessories);
  }, [accessories]);

  return (
    <Table
      size="small"
      columns={columns}
      pagination={false}
      dataSource={list}
    />
  );
};

export default ProjectAccessoriesList;
