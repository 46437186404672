import { IOrderPayment } from 'services/store/types/orders/Orders';

export const validateOrderPayments = (
  payments: IOrderPayment[],
  price: number
) => {
  const errors: string[] = [];

  if (payments.some((payment) => !payment.name))
    errors.push('Nazwij wszystkie transze');
  if (payments.some((payment) => !payment.term))
    errors.push('Wybierz terminy płatności');
  if (payments.some((payment) => !payment.value))
    errors.push('Podaj wartości transz');

  const paymentsValue = payments.reduce((acc, payment) => {
    acc = acc + (payment.value || 0);
    return acc;
  }, 0);
  if (paymentsValue !== price)
    errors.push('Suma transz różni się od ceny projektu');
  return errors;
};
