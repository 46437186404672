export enum ProjectProductionEditView {
  DAYS = 'days',
  DAYS_PARTS = 'days-parts',
}

export enum HomePageKey {
  ORDERS = 'orders',
  PROJECTS = 'projects',
}
export enum ProjectsKey {
  IN_PRODUCTION = 'inProduction',
  PENDING = 'pending',
  FINISHED = 'finished',
}
export enum OrdersKey {
  IN_PRODUCTION = 'inProduction',
  PENDING = 'pending',
  FINISHED = 'finished',
}
export enum ProjectPageKey {
  DASHBOARD = 'dashboard',
  CUSTOMER = 'customer',
  PAINTED_ELEMENTS = 'paintedElements',
  ACCESSORIES = 'accessories',
  FILES = 'files',
  IMAGES = 'images',
  HISTORY = 'history',
  STATISTICS = 'statiworkTimestics',
  EQUIPMENT = 'equipment',
}
export enum OrderPageKey {
  DASHBOARD = 'dashboard',
  CUSTOMERS = 'customer',
  PAYMENTS = 'payments',
  COSTS = 'costs',
  DOCUMENTS = 'documents',
  STATISTICS = 'statistics',
  HISTORY = 'history',
}
