import React from 'react';
import { Modal } from 'antd';
import { Field, Form, Formik } from 'formik';
import { AntInput } from 'components/Formik-AntdFields/CreateAntdField';
import { companyContactValidation } from 'scenes/Settings/utils/validate';
import { ISettings, IWebPage } from 'services/store/types/settings/Settings';

const initValues = {
  name: '',
  webPage: '',
};

interface IProps {
  closeModal: () => void;
  updateSettings: (data: Partial<ISettings>) => Promise<any>;
  webPages: IWebPage[];
}

const AddCompanyWebpageModal: React.FC<IProps> = ({
  closeModal,
  webPages,
  updateSettings,
}) => {
  const handleSubmit = async (data: IWebPage) => {
    await updateSettings({ webPages: [...webPages, data] });
    closeModal();
  };

  return (
    <Modal
      title="Kontakt"
      destroyOnClose
      visible
      onCancel={closeModal}
      maskClosable={false}
      okButtonProps={{
        form: 'companyWebPageEditId',
        htmlType: 'submit',
        disabled: false,
      }}
    >
      <Formik
        validationSchema={companyContactValidation}
        onSubmit={handleSubmit}
        initialValues={initValues}
      >
        {(props) => {
          return (
            <Form
              noValidate
              id="companyWebPageEditId"
              onSubmit={props.handleSubmit}
            >
              <Field
                component={AntInput}
                name="name"
                type="text"
                placeholder="Nazwa"
                validate={props.errors.name}
                submitCount={props.submitCount}
                hasFeedback
              />
              <Field
                component={AntInput}
                name="webPage"
                type="text"
                placeholder="Adres strony"
                validate={props.errors.webPage}
                submitCount={props.submitCount}
                hasFeedback
              />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AddCompanyWebpageModal;
