import { message } from 'antd';
import axios from 'axios';
import { HistoryColor } from 'services/store/enums/historyColor.enum';
import { IOrder } from 'services/store/types/orders/Orders';
import setAuthToken from 'services/utils/setAuthToken';

export const updateOrder = async (
  id: string,
  updateData: Partial<IOrder>,
  historyUpdate: string[],
  historyColor: HistoryColor
) => {
  try {
    setAuthToken();
    const res = await axios.patch(`/api/orders/${id}`, {
      updateData,
      historyUpdate,
      historyColor,
    });

    message.success('Zamówienie zaktualizowane');
    return res.data;
  } catch (error: any) {
    console.log(error, error.response);
    message.error('Błąd');
  }
};

export const checkIsOrdersStatusUpdateIsNeededAndUpdate = async (
  id: string
) => {
  try {
    setAuthToken();
    const res = await axios.patch(`/api/orders/${id}/status`, {});

    if (res.data === true) message.success('Status zamówienia zaktualizowany');
    return res.data;
  } catch (error: any) {
    console.log(error, error.response);
    message.error('Błąd');
  }
};
