import React from 'react';
import { Tag } from 'antd';
import { Positions } from 'services/store/enums/users.enum';

interface IProps {
  position?: Positions;
}

const UserPositionTag: React.FC<IProps> = ({ position }) => {
  if (position === Positions.DESIGNER)
    return <Tag color="orange">{position.toUpperCase()}</Tag>;
  if (position === Positions.TECHNOLOGIST)
    return <Tag color="lime">{position.toUpperCase()}</Tag>;
  if (position === Positions.CARPENTER)
    return <Tag color="cyan">{position.toUpperCase()}</Tag>;
  if (position === Positions.INSTALLER)
    return <Tag color="geekblue">{position.toUpperCase()}</Tag>;
  if (position === Positions.STOREKEEPER)
    return <Tag color="blue">{position.toUpperCase()}</Tag>;
  return null;
};

export default UserPositionTag;
