import React, { useEffect, useState } from 'react';
import { DeleteOutlined, DollarCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import update from 'immutability-helper';
import styled from 'styled-components';
import AntDatePicker from 'components/DatePicker/DatePicker';
import { ErrorModal } from 'components/Modals/ErrorModal/ErrorModal';
import { validateOrderPayments } from 'scenes/OrderPage/utils/validate';
import { HistoryColor } from 'services/store/enums/historyColor.enum';
import { OderPaymentType } from 'services/store/enums/orders.enum';
import { IOrder, IOrderPayment } from 'services/store/types/orders/Orders';

const { Text } = Typography;
const { Option } = Select;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow-x: scroll;
  & > div {
    margin: 0 5px;
  }
`;

const initPayment: IOrderPayment = {
  name: 'Transza płatności',
  term: new Date(),
  isPaid: false,
  value: 0,
  type: OderPaymentType.NOT_CONFIRMED,
};

interface IProps {
  order: IOrder;
  closeModal: () => void;
  handleUpdateOrder: (
    updateData: Partial<IOrder>,
    historyUpdate: string[],
    historyColor: HistoryColor
  ) => Promise<any>;
}

const ChangeOrderPaymentsPartsModal: React.FC<IProps> = ({
  order,
  closeModal,
  handleUpdateOrder,
}) => {
  const [newPayments, setNewPayments] = useState<IOrderPayment[]>(
    order.payments
  );

  useEffect(() => {
    if (!newPayments.length) handleAddPayment();
  }, []);

  const handleAddPayment = () =>
    setNewPayments([
      ...newPayments,
      { ...initPayment, name: `Transza platnosci ${newPayments.length + 1}` },
    ]);

  const handleRemovePayment = (index: number) => {
    if (newPayments.length === 1) return;
    if (newPayments[index].isPaid) return;
    setNewPayments(newPayments.filter((p, i) => i !== index));
  };

  const handlePaymentField = (
    index: number,
    field: keyof IOrderPayment,
    value: any
  ) => {
    const payments = update(newPayments, {
      [index]: {
        [field]: { $set: value },
      },
    });
    setNewPayments(payments);
  };

  const onOk = async () => {
    const errors = validateOrderPayments(newPayments, order.price);
    if (errors.length) return ErrorModal(errors);

    const historyUpdate = [
      `Zaktualizowano transze płatności:`,
      `${order.payments.length} na ${newPayments.length} części`,
    ];
    await handleUpdateOrder(
      { payments: newPayments },
      historyUpdate,
      HistoryColor.WARNING
    );
    closeModal();
  };

  return (
    <Modal
      title="Zmiana transz płatności"
      destroyOnClose
      visible
      width={800}
      onCancel={closeModal}
      maskClosable={false}
      onOk={onOk}
    >
      <Button
        type="primary"
        onClick={handleAddPayment}
        style={{ marginBottom: 5 }}
      >
        <DollarCircleOutlined /> Dodaj transzę
      </Button>

      <Wrapper>
        {newPayments.map((payment, i) => (
          <PaymentItem
            key={payment._id || i}
            payment={payment}
            handlePaymentField={handlePaymentField}
            handleRemovePayment={handleRemovePayment}
            index={i}
          />
        ))}
      </Wrapper>
    </Modal>
  );
};

export default ChangeOrderPaymentsPartsModal;

interface IOrderPaymentItemProps {
  payment: IOrderPayment;
  index: number;
  handlePaymentField: (
    index: number,
    field: keyof IOrderPayment,
    value: any
  ) => void;
  handleRemovePayment: (index: number) => void;
}

const PaymentItem: React.FC<IOrderPaymentItemProps> = ({
  payment,
  index,
  handlePaymentField,
  handleRemovePayment,
}) => {
  return (
    <div>
      <Card bodyStyle={{ padding: 10, width: 180 }}>
        <strong>Nazwa: </strong>
        <Input
          disabled={payment.isPaid}
          style={{ width: '100%' }}
          placeholder="Nazwa"
          value={payment.name}
          onChange={(e) => handlePaymentField(index, 'name', e.target.value)}
        />
        <strong>Termin: </strong>
        <AntDatePicker
          disabled={payment.isPaid}
          style={{ width: '100%' }}
          value={dayjs(payment.term)}
          onChange={(date: any) =>
            handlePaymentField(index, 'term', new Date(date))
          }
          format="DD.MM.YYYY"
        />
        <strong>Wartość: </strong>
        <InputNumber
          disabled={payment.isPaid}
          style={{ display: 'block', width: '100%' }}
          value={payment.value}
          onChange={(value) => handlePaymentField(index, 'value', value)}
          min={0}
        />
        <strong>Typ: </strong>
        <Select
          disabled={payment.isPaid}
          style={{ display: 'block', width: '100%' }}
          value={payment.type}
          onChange={(value) => handlePaymentField(index, 'type', value)}
        >
          <Option value={OderPaymentType.CONFIRMED}>
            {OderPaymentType.CONFIRMED}
          </Option>
          <Option value={OderPaymentType.NOT_CONFIRMED}>
            {OderPaymentType.NOT_CONFIRMED}
          </Option>
        </Select>

        {payment.isPaid ? (
          <Text type="success">Zapłacono</Text>
        ) : (
          <Row>
            <Col offset={22}>
              <DeleteOutlined
                style={{ color: 'red', cursor: 'pointer', marginTop: 10 }}
                onClick={() => handleRemovePayment(index)}
                title="Usuń"
              />
            </Col>
          </Row>
        )}
      </Card>
    </div>
  );
};
