import * as yup from 'yup';

export const userDataChangeValidation = yup.object({
  email: yup
    .string()
    .email('Format adresu email jest nieprawidłowy')
    .required('Email jest wymagany'),
  phone: yup
    .string()
    .matches(/^\d{9}$/, 'Nr telefonu musi składać się z 9 cyfr')
    .nullable(),
  earnings: yup.number(),
  firstName: yup
    .string()
    .matches(/^[a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ]+$/, 'Wyłącznie litery')
    .required('Imię jest wymagane'),
  lastName: yup
    .string()
    .required('Nazwisko jest wymagane')
    .matches(/^[a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ-]+$/, 'Wyłącznie litery'),
});
