import dayjs from 'dayjs';
import {
  getCellColor,
  getProductionNodeCellColor,
} from 'components/ProductionSchedule/utils';
import { Positions } from 'services/store/enums/users.enum';
import { IProject } from 'services/store/types/projects/Projects';
import { IUser } from 'services/store/types/users/Users';
import { renderDayCell } from './renderDisplayCells';
import { renderDateTitle } from '../../components/renderDateTitle';
import { renderProductionNodeCell } from '../../components/renderProductionNodeCell';

export const getDisplayDayViewTableHeaders = (
  dates: Date[],
  project?: IProject,
  userId?: string,
  position?: Positions
) => {
  const nodesItem = {
    title: 'Gniazda produkcyjne',
    width: 150,
    dataIndex: 'node',
    render: (data: { position: Positions; user: IUser }) => {
      return renderProductionNodeCell(data);
    },
    onCell: (data: any) => {
      const isCellDisabled = isDisabled(
        [data.node.user],
        data.node.position,
        userId,
        position
      );
      return {
        style: {
          backgroundColor: getProductionNodeCellColor(data.node.position),
          opacity: isCellDisabled ? 0.3 : 1,
        },
      };
    },
    key: 'node',
    fixed: 'left',
    align: 'center' as const,
  };

  const datesColumns = dates.map((date) => {
    return {
      title: renderDateTitle(date),
      render: (projectsNumbers: string[]) => {
        return renderDayCell(projectsNumbers);
      },
      onCell: (data: any) => {
        const key = dayjs(date).format('DD.MM.YYYY');
        const isProjectInDay = !!(
          project && data[key].includes(project.number)
        );
        const isCellDisabled = isDisabled(
          [data.node.user],
          data.node.position,
          userId,
          position
        );
        return {
          style: {
            backgroundColor: isProjectInDay
              ? 'green'
              : getCellColor(date, data.node.position, false),
            opacity: isCellDisabled ? 0.3 : 1,
          },
        };
      },
      dataIndex: dayjs(date).format('DD.MM.YYYY'),
      key: date,
      width: 120,
      align: 'center' as const,
    };
  });

  return [nodesItem, ...datesColumns];
};

const isDisabled = (
  users: IUser[],
  nodePosition: Positions,
  userId?: string,
  position?: Positions
) => {
  if (!userId || !position) return false;
  return (
    !users.map((user) => user?._id).includes(userId) ||
    nodePosition !== position
  );
};
