import React from 'react';
import { Empty, Timeline } from 'antd';
import dayjs from 'dayjs';
import { IHistory } from 'services/store/interfaces/history.interface';
import { getUserName } from 'services/utils/string';

interface IProps {
  history?: IHistory[];
}

const ProjectHistory: React.FC<IProps> = ({ history }) => {
  if (history)
    return (
      <Timeline mode="left">
        {history.map((story: IHistory) => (
          <Timeline.Item
            key={story._id}
            label={
              <>
                {story.user ? getUserName(story.user) : 'BRAK DANYCH'}
                {' - '}
                <strong>{dayjs(story.date).format('DD.MM.YYYY, HH:mm')}</strong>
              </>
            }
            color={story.color}
          >
            {story.actions.map((action) => (
              <div key={action}>{action}</div>
            ))}
          </Timeline.Item>
        ))}
      </Timeline>
    );
  return <Empty />;
};

export default ProjectHistory;
