import { useCallback, useEffect, useState } from 'react';
import {
  CalendarOutlined,
  MessageOutlined,
  RedoOutlined,
} from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import {
  Button,
  Col,
  Input,
  message,
  PageHeader,
  Row,
  Select,
  Space,
  Tabs,
} from 'antd';
import axios from 'axios';
import { debounce } from 'lodash';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import AntDivider from 'components/Divider/Divider';
import { H1, H2 } from 'components/Header';
import WorkingOnModal from 'components/Modals/WorkingOnModal/WorkingOn.modal';
import ProductionScheduleView from 'components/ProductionSchedule/Display/ProductionScheduleDisplay';
import { useModal } from 'hook/useModals';
import { TAppState } from 'services/store';
import {
  getAllProductionNodes,
  setUserCurrentNode,
} from 'services/store/actions/productionNodes';
import { getAllUsers } from 'services/store/actions/users';
import {
  setHomePageKey,
  setProjectProductionEditView,
} from 'services/store/actions/view';
import { OrderStatus } from 'services/store/enums/orders.enum';
import { ProjectStatus } from 'services/store/enums/project.enum';
import { Positions } from 'services/store/enums/users.enum';
import {
  HomePageKey,
  ProjectProductionEditView,
} from 'services/store/enums/view.enum';
import { IAlert } from 'services/store/interfaces/alert.interface';
import { ICustomer } from 'services/store/types/customers/Customers';
import { IMessage } from 'services/store/types/messages/Messages';
import { IOrder } from 'services/store/types/orders/Orders';
import { IProject } from 'services/store/types/projects/Projects';
import { ISchedule } from 'services/store/types/schedule/Schedule';
import { IStatistics } from 'services/store/types/statistics/Statistics';
import setAuthToken from 'services/utils/setAuthToken';
import { getUserName } from 'services/utils/string';
import { isUserHasPermission } from 'services/utils/users';
import PageTemplate from 'templates/PageTemplate';
import DashboardAlerts from './components/DashboardAlerts/DashboardAlerts';
import DashboardCurrentUsersActivity from './components/DashboardCurrentUsersActivity/DashboardCurrentUsersActivity';
import DashboardOrders from './components/DashboardOrders';
import DashboardProjects from './components/DashboardProjects';
import Messages from './components/messages/Messages';
import NewMessageModal from './components/Modals/NewMessageModal';
import StartProcessModal from './components/Modals/StartProcess.modal';
import AddScheduleItemModal from '../../components/Modals/ScheduleModal/AddScheduleItem.modal';
import ScheduleList from '../../components/Schedule/ScheduleList/ScheduleList';

const { TabPane } = Tabs;
const { Option } = Select;

const initModals = {
  newMessage: false,
  newScheduleItem: false,
  startProcess: false,
  workingOn: false,
};

const debounceControledInput = debounce(
  (value, dispatch) => dispatch(value),
  700
);

const DashboardPage = () => {
  const dispatch = useDispatch();
  const { modal, showModal, closeModal } = useModal(initModals);
  const [loading, setLoading] = useState({
    orders: false,
    projects: false,
    messages: false,
    schedule: false,
    alerts: false,
    users: false,
    statistics: false,
  });

  const tabKey = useSelector((state: TAppState) => state.view.homePageKey);
  const user = useSelector((state: TAppState) => state.auth.user);
  const users = useSelector((state: TAppState) => state.users.users);
  const view = useSelector(
    (state: TAppState) => state.view.projectProductionEditView
  );
  const nodes = useSelector((state: TAppState) => state.productionNodes.nodes);

  const [projects, setProjects] = useState<IProject[]>([]);
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [schedule, setSchedule] = useState<ISchedule[]>([]);
  const [projectsAlerts, setProjectsAlerts] = useState<IAlert[]>([]);
  const [ordersAlerts, setOrdersAlerts] = useState<IAlert[]>([]);
  const [customers, setCustomers] = useState<ICustomer[]>([]);

  const [customer, setCustomer] = useState(undefined);
  const [name, setName] = useState('');
  const [nameValue, setNameValue] = useState('');

  // --------------------------------------------------------------------------
  // NOTE Get data
  // --------------------------------------------------------------------------
  const getProjects = useCallback(async () => {
    try {
      setLoadingFor('projects', true);
      setAuthToken();
      const res = await axios.get(`/api/projects`, {
        params: {
          status: [
            ProjectStatus.PENDING,
            ProjectStatus.PROJECT,
            ProjectStatus.TECHNOLOGIST,
            ProjectStatus.CARPENTER,
            ProjectStatus.STORAGE,
            ProjectStatus.MONTAGE,
          ],
          customer,
          name,
          unpaged: true,
        },
      });
      const uniqueCustomers = res.data.content
        .map((project: IProject) => {
          return project.order?.customer;
        })
        .reduce((acc: ICustomer[], curr: ICustomer) => {
          if (!curr?._id) return acc;
          const isExists = acc.find((cust) => cust._id === curr._id);
          if (!isExists) return [...acc, curr];
          return acc;
        }, [])
        .sort((a: ICustomer, b: ICustomer) =>
          a.lastName.localeCompare(b.lastName)
        );
      if (!customers.length) setCustomers(uniqueCustomers);
      setProjects(res.data.content);
    } catch (error) {
      console.log(error);
      message.error('Błąd');
    } finally {
      setLoadingFor('projects', false);
    }
  }, [name, customer]);

  const getOrders = useCallback(async () => {
    try {
      setLoadingFor('orders', true);
      setAuthToken();
      const res = await axios.get(`/api/orders`, {
        params: {
          status: [OrderStatus.PENDING, OrderStatus.IN_PROGRESS].join(', '),
        },
      });
      setOrders(res.data);
    } catch (error) {
      console.log(error);
      message.error('Błąd');
    } finally {
      setLoadingFor('orders', false);
    }
  }, []);

  const getMessages = useCallback(async () => {
    try {
      setLoadingFor('messages', true);
      setAuthToken();
      const res = await axios.get(`/api/messages/self`);
      setMessages(res.data);
    } catch (error) {
      console.log(error);
      message.error('Błąd');
    } finally {
      setLoadingFor('messages', false);
    }
  }, []);

  const getSchedule = useCallback(async () => {
    try {
      setLoadingFor('schedule', true);
      setAuthToken();
      if (
        isUserHasPermission(user, [Positions.DESIGNER, Positions.TECHNOLOGIST])
      ) {
        const res = await axios.get(`/api/schedule`);
        setSchedule(res.data);
      } else {
        const res = await axios.get(`/api/schedule/self`);
        setSchedule(res.data);
      }
    } catch (error) {
      console.log(error);
      message.error('Błąd');
    } finally {
      setLoadingFor('schedule', false);
    }
  }, []);

  const getAlerts = useCallback(async () => {
    try {
      setLoadingFor('alerts', true);
      setAuthToken();
      const projectsAlertsRes = await axios.get(
        `/api/projects/multiple/in-progress/alerts`
      );
      const ordersAlertsRes = await axios.get(
        `/api/orders/multiple/in-progress/alerts`
      );
      setProjectsAlerts(projectsAlertsRes.data);
      setOrdersAlerts(ordersAlertsRes.data);
    } catch (error) {
      console.log(error);
      message.error('Błąd');
    } finally {
      setLoadingFor('alerts', false);
    }
  }, []);

  const getUsers = useCallback(async () => {
    setLoadingFor('users', true);
    dispatch(getAllUsers(() => setLoadingFor('users', false)));
  }, []);

  // --------------------------------------------------------------------------
  //NOTE Lifiecycle
  // --------------------------------------------------------------------------
  useEffect(() => {
    if (user) {
      getProjects();
    }
  }, [getProjects, user]);

  useEffect(() => {
    if (user) {
      getOrders();
      getMessages();
      if (
        isUserHasPermission(user, [Positions.DESIGNER, Positions.TECHNOLOGIST])
      ) {
        getAlerts();
      }
    }
  }, [user]);

  useEffect(() => {
    if (projects && user) {
      if (!schedule.length) getSchedule();
      if (!nodes.length) dispatch(getAllProductionNodes());
      if (
        isUserHasPermission(user, [Positions.DESIGNER, Positions.TECHNOLOGIST])
      )
        if (!users.length) getUsers();
    }
  }, [projects, user]);

  useEffect(() => {
    if (user?.activePosition && nodes.length) {
      dispatch(setUserCurrentNode(user._id, user.activePosition));
    }
  }, [user?.activePosition, nodes]);

  // --------------------------------------------------------------------------
  // NOTE HANDLERS
  // --------------------------------------------------------------------------
  const markMessageReaded = async (id: string) => {
    try {
      setLoadingFor('messages', true);
      await axios.patch(`/api/messages/${id}/mark-readed`);
      await getMessages();
      message.info('Wiadomość odczytana');
    } catch (error) {
      console.log(error);
      message.error('Błąd');
    } finally {
      setLoadingFor('messages', false);
    }
  };
  const removeMessage = async (id: string) => {
    try {
      setLoadingFor('messages', true);
      await axios.delete(`/api/messages/${id}`);
      await getMessages();
      message.success('Wiadomość usunięta');
    } catch (error) {
      console.log(error);
      message.error('Błąd');
    } finally {
      setLoadingFor('messages', false);
    }
  };

  const changeView = (key: ProjectProductionEditView) => {
    dispatch(setProjectProductionEditView(key));
  };

  const setLoadingFor = (key: string, isLoading: boolean) =>
    setLoading((prev: any) => ({ ...prev, [key]: isLoading }));

  const handleName = (value: string) => {
    setNameValue(value);
    return debounceControledInput(value, setName);
  };
  // FIXME REFACOTR - uwzględnić filtrowanie w projektach i zamówieniach
  return (
    <PageTemplate>
      <PageHeader
        title={<H1>Dashboard</H1>}
        extra={[
          <Button
            key={1}
            onClick={() =>
              showModal(user?.workingOn ? 'workingOn' : 'startProcess')
            }
            disabled={!user}
            type="primary"
          >
            {user?.workingOn ? 'Zakończ proces' : 'Rozpocznij proces'}
          </Button>,
        ]}
      />
      <Row gutter={18}>
        {/* NOTE PROJEKTY */}
        <Col md={16} span={24}>
          <Row justify="end">
            <Space
              direction={isMobile ? 'vertical' : 'horizontal'}
              style={isMobile ? { display: 'flex' } : {}}
            >
              <Select
                value={customer}
                onChange={setCustomer}
                placeholder="Klient"
                disabled={!customers.length}
                allowClear
                style={{ width: '100%', minWidth: 150 }}
              >
                {customers?.map((c) => (
                  <Option key={c._id} value={c._id}>
                    {getUserName(c, 'l-f')}
                  </Option>
                ))}
              </Select>
              <Input
                value={nameValue}
                onChange={(e) => handleName(e.target.value)}
                placeholder="Nazwa"
                allowClear
              />
            </Space>
          </Row>
          <Tabs
            activeKey={tabKey}
            onChange={(key: string) =>
              dispatch(setHomePageKey(key as HomePageKey))
            }
            size="small"
          >
            {isUserHasPermission(user, [
              Positions.TECHNOLOGIST,
              Positions.DESIGNER,
            ]) && (
              <TabPane tab={<H2>Zamówienia</H2>} key={HomePageKey.ORDERS}>
                <DashboardOrders
                  orders={orders}
                  loading={loading.orders}
                  user={user}
                />
              </TabPane>
            )}
            <TabPane tab={<H2>Projekty</H2>} key={HomePageKey.PROJECTS}>
              <DashboardProjects
                projects={projects}
                loading={loading.projects}
                user={user}
              />
            </TabPane>
          </Tabs>

          <AntDivider marginTop={50} />
          {/* NOTE HARMONOGRAM */}
          <>
            <Row justify="space-between">
              <H2 style={{ margin: 0 }}>Harmonogram</H2>
              {/* FIXME Przenieść ten select do własnego komponentu */}
              <Select
                key={1}
                placeholder="Widok"
                value={view}
                onChange={(e) => changeView(e)}
                style={{ width: 100 }}
              >
                <Option value={ProjectProductionEditView.DAYS}>Dzień</Option>
                <Option value={ProjectProductionEditView.DAYS_PARTS}>
                  Dzień / 4
                </Option>
              </Select>
            </Row>
            <ProductionScheduleView
              userId={user?._id}
              position={user?.activePosition}
            />
          </>
        </Col>
        <Col span={1} />
        <Col md={7} span={24}>
          {/* NOTE AKTUALNA AKTYWNOŚĆ UŻYTKOWNIKÓW */}
          {isUserHasPermission(user, [
            Positions.DESIGNER,
            Positions.TECHNOLOGIST,
          ]) && (
            <>
              <Row justify="space-between">
                <H2 style={{ margin: 0 }}>Aktualna aktywność</H2>
                <Button onClick={getUsers}>
                  <RedoOutlined />
                </Button>
              </Row>
              <DashboardCurrentUsersActivity
                users={users}
                isLoading={loading.users}
                refetchActivity={getUsers}
              />
              <AntDivider marginTop={50} />
            </>
          )}
          {/* NOTE ALERTY */}
          {isUserHasPermission(user, [
            Positions.DESIGNER,
            Positions.TECHNOLOGIST,
          ]) && (
            <>
              <Row justify="space-between">
                <H2 style={{ margin: 0 }}>Alerty</H2>
                <Button onClick={getAlerts}>
                  <RedoOutlined />
                </Button>
              </Row>
              <DashboardAlerts
                projectsAlerts={projectsAlerts}
                ordersAlerts={ordersAlerts}
                isLoading={loading.alerts}
              />
              <AntDivider marginTop={50} />
            </>
          )}
          {/* NOTE WIADOMOŚCI */}
          <Row justify="space-between">
            <H2 style={{ margin: 0 }}>Wiadomości</H2>
            <Space>
              <Button type="primary" onClick={() => showModal('newMessage')}>
                <MessageOutlined />
                Nowa
              </Button>
              <Button onClick={getMessages}>
                <RedoOutlined />
              </Button>
            </Space>
          </Row>
          <Messages
            messages={messages}
            isLoading={loading.messages}
            markReaded={markMessageReaded}
            removeMessage={removeMessage}
            refetchMessages={getMessages}
          />
          <AntDivider marginTop={50} />
          {/* NOTE TERMINARZ */}
          <Row justify="space-between">
            <H2 style={{ margin: 0 }}>Terminarz</H2>
            <Space>
              <Button
                type="primary"
                onClick={() => showModal('newScheduleItem')}
              >
                <CalendarOutlined />
                Dodaj
              </Button>
              <Button onClick={getSchedule}>
                <RedoOutlined />
              </Button>
            </Space>
          </Row>
          <ScheduleList
            getSchedule={getSchedule}
            schedule={schedule}
            isLoading={loading.schedule}
          />
        </Col>
      </Row>

      {/*NOTE MODALS */}
      {modal.newMessage && (
        <NewMessageModal closeModal={closeModal} getMessages={getMessages} />
      )}
      {modal.startProcess && user && (
        <StartProcessModal closeModal={closeModal} user={user} />
      )}
      {modal.newScheduleItem && (
        <AddScheduleItemModal
          closeModal={closeModal}
          getSchedule={getSchedule}
          projects={projects}
        />
      )}
      {user && modal.workingOn && (
        <WorkingOnModal user={user} closeModal={closeModal} />
      )}
      <div style={{ height: 50 }} />
    </PageTemplate>
  );
};

export default DashboardPage;
