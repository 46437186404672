import React from 'react';
import { Alert } from 'antd';
import { IAlert } from 'services/store/interfaces/alert.interface';

interface IProps {
  alerts: IAlert[];
}

const AlertsList: React.FC<IProps> = ({ alerts }) => {
  return (
    <div>
      {alerts.length
        ? alerts.map((alert) => (
            <div key={alert.message} title={alert.title}>
              <Alert
                type={alert.type}
                message={alert.message}
                showIcon
                style={{ marginBottom: 5 }}
              />
            </div>
          ))
        : 'Brak powiadomień'}
    </div>
  );
};

export default AlertsList;
