import React, { useEffect, useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Table } from 'antd';
import { ICategory } from 'services/store/types/settings/Settings';
import { cutString } from 'services/utils/string';

const columns: any[] = [
  {
    dataIndex: 'lp',
    title: 'Lp.',
    align: 'center' as const,
  },
  {
    title: 'Typ',
    dataIndex: 'type',
    align: 'center' as const,
    sorter: (a: ICategory, b: ICategory) => a.type.localeCompare(b.type),
  },
  {
    title: 'Nazwa',
    dataIndex: 'name',
    align: 'center' as const,
    defaultSortOrder: 'ascend' as const,
    sorter: (a: ICategory, b: ICategory) => a.name.localeCompare(b.name),
  },
  {
    title: 'Opis',
    dataIndex: 'description',
    align: 'center' as const,
    render: (description: string) => cutString(description || '', 20),
  },
  {
    title: 'Edytuj',
    dataIndex: 'edit',
    align: 'center' as const,
  },
  {
    title: 'Usuń',
    dataIndex: 'delete',
    align: 'center' as const,
  },
];

interface IProps {
  categories: ICategory[];
  removeCategory: (id: string) => Promise<any>;
  setToEdit?: (categoryToEdit: ICategory) => void;
  maxHeight?: number;
}

const CategoriesList: React.FC<IProps> = ({
  categories,
  removeCategory,
  setToEdit,
  maxHeight,
}) => {
  const [list, setList] = useState<any[]>([]);

  useEffect(() => {
    const newCatgeories = categories.map((category: ICategory, i: number) => ({
      ...category,
      lp: i + 1,
      key: category._id,
      edit: (
        <Button size="small" onClick={() => setToEdit && setToEdit(category)}>
          Edytuj
        </Button>
      ),
      delete: (
        <Popconfirm
          key={1}
          title="Na pewno chcesz usunąć tą kategorię i wszystkie jej elementy?"
          onConfirm={() => category._id && removeCategory(category._id)}
          okText="Tak"
          cancelText="Nie"
        >
          <Button danger size="small">
            Usuń
          </Button>
        </Popconfirm>
      ),
    }));
    setList(newCatgeories);
  }, [categories]);

  return (
    <Table
      columns={columns}
      size="small"
      pagination={false}
      dataSource={list}
      scroll={{ x: 1000, y: maxHeight }}
    />
  );
};

export default CategoriesList;
