import dayjs from 'dayjs';
import { getUniqueProjectsNumbersFromActions } from 'components/ProductionSchedule/utils';
import { IPositionNode } from 'services/store/types/productionNodes/ProductionNodes';

export const getDisplayDayViewData = (nodes: IPositionNode[]) => {
  const newList = nodes.map((node) => {
    const item: any = {
      key: node.nodeId,
      node: { position: node.position, user: node.user },
    };
    node.schedule.forEach((scheduleItem) => {
      const dataIndex = dayjs(scheduleItem.date).format('DD.MM.YYYY');
      item[dataIndex] = getUniqueProjectsNumbersFromActions(
        scheduleItem.actions
      );
    });
    return item;
  });
  return newList;
};
