import React, { useEffect, useState } from 'react';
import { List, Comment, Button, Typography } from 'antd';
import dayjs from 'dayjs';
import { IComment } from 'services/store/interfaces/comment.interface';
import { IUser } from 'services/store/types/users/Users';
import { getUserName } from 'services/utils/string';

const { Text } = Typography;

interface IProps {
  comments: IComment[];
  showAddCommentModal: () => void;
  handleRemoveComment: (comment: string) => void;
}

const CommentsList: React.FC<IProps> = ({
  comments,
  showAddCommentModal,
  handleRemoveComment,
}) => {
  const [list, setList] = useState<any[]>([]);

  useEffect(() => {
    if (comments) {
      const commentsList = comments.map((comment) => {
        return {
          author: (
            <span>
              {comment.user
                ? getUserName(comment.user as IUser)
                : 'Użytkownik usunął swoje konto'}
            </span>
          ),
          content: <p>{comment.comment}</p>,
          datetime: dayjs(comment.date).format('DD.MM.YYYY'),
        };
      });
      setList(commentsList);
    }
  }, [comments]);

  return (
    <div>
      {list.length > 0 ? (
        <CommentList
          comments={list}
          handleRemoveComment={handleRemoveComment}
        />
      ) : (
        <div style={{ marginBottom: 30 }}>
          <Text disabled>Brak komentarzy...</Text>
        </div>
      )}
      <Button type="primary" onClick={() => showAddCommentModal()}>
        Dodaj komentarz
      </Button>
    </div>
  );
};

export default CommentsList;

interface ICommentsListProps {
  comments: any[];
  handleRemoveComment: (comment: string) => void;
}

const CommentList: React.FC<ICommentsListProps> = ({
  comments,
  handleRemoveComment,
}) => {
  return (
    <List
      dataSource={comments}
      itemLayout="horizontal"
      renderItem={(props) => (
        <Comment
          {...props}
          actions={[
            <span
              key="comment-list-reply-to-0"
              onClick={() => handleRemoveComment(props.content.props.children)}
            >
              Usuń
            </span>,
          ]}
        />
      )}
    />
  );
};
