import React from 'react';
import { Col, Descriptions, Row, Statistic } from 'antd';
import dayjs from 'dayjs';
import Address from 'components/Address/Address';
import { Positions } from 'services/store/enums/users.enum';
import { IOrder } from 'services/store/types/orders/Orders';
import { IUser } from 'services/store/types/users/Users';
import { getUserName } from 'services/utils/string';
import { isUserHasPermission } from 'services/utils/users';

interface IProps {
  order: IOrder;
  projectsCount: number;
  user: IUser | null;
  isAdmin: boolean;
}

const OrderHeader: React.FC<IProps> = ({
  order,
  projectsCount,
  user,
  isAdmin,
}) => {
  return (
    <Row>
      {/* NOTE ORDER DATA */}
      <Col md={6} span={24}>
        <Descriptions title="Dane zamówienia" column={1}>
          <Descriptions.Item label="Numer">
            <strong>{order.number}</strong>
          </Descriptions.Item>
          <Descriptions.Item label="Nazwa">
            <strong>{order.name}</strong>
          </Descriptions.Item>
          <Descriptions.Item label="Utworzony przez">
            <strong>{order.createdBy && getUserName(order.createdBy)}</strong>
          </Descriptions.Item>
        </Descriptions>
      </Col>

      {/* NOTE DATES */}
      <Col md={6} span={24}>
        <Descriptions title="Daty" column={1}>
          <Descriptions.Item label="Data utworzenia">
            <strong>{dayjs(order.createdAt).format('DD.MM.YYYY')}</strong>
          </Descriptions.Item>
          <Descriptions.Item label="Data zakończenia">
            <strong>
              {order.finishedAt && dayjs(order.finishedAt).format('DD.MM.YYYY')}
            </strong>
          </Descriptions.Item>
        </Descriptions>
      </Col>

      {/* NOTE ADDRESS */}
      <Col md={6} span={24}>
        <Address address={order.address} title="Adres montażu" />
      </Col>

      <Col md={4} span={24} />
      <Col md={2} span={24}>
        {user &&
          isUserHasPermission(user, [
            Positions.DESIGNER,
            Positions.TECHNOLOGIST,
          ]) && (
            <>
              {isAdmin && (
                <Statistic title="Cena" value={order.price} suffix="zł" />
              )}
              <Statistic title="Liczba projektów" value={projectsCount} />
            </>
          )}
      </Col>
    </Row>
  );
};

export default OrderHeader;
