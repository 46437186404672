import React from 'react';
import { Modal } from 'antd';
import { Field, Form, Formik } from 'formik';
import { AntInput } from 'components/Formik-AntdFields/CreateAntdField';
import { companyAdressValidation } from 'scenes/Settings/utils/validate';
import {
  ICompanyAddress,
  ISettings,
} from 'services/store/types/settings/Settings';

const initValues = {
  name: '',
  city: '',
  address: '',
  postcode: '',
  description: '',
};

interface IProps {
  closeModal: () => void;
  updateSettings: (data: Partial<ISettings>) => Promise<any>;
  address: ICompanyAddress[];
}

const AddCompanyAddressModal: React.FC<IProps> = ({
  closeModal,
  address,
  updateSettings,
}) => {
  const handleSubmit = async (data: ICompanyAddress) => {
    await updateSettings({ address: [...address, data] });
    closeModal();
  };

  return (
    <Modal
      title="Adres"
      destroyOnClose
      visible
      onCancel={closeModal}
      maskClosable={false}
      okButtonProps={{
        form: 'companyAdresEditId',
        htmlType: 'submit',
        disabled: false,
      }}
    >
      <Formik
        validationSchema={companyAdressValidation}
        onSubmit={handleSubmit}
        initialValues={initValues}
      >
        {(props) => {
          return (
            <Form
              noValidate
              id="companyAdresEditId"
              onSubmit={props.handleSubmit}
            >
              <Field
                component={AntInput}
                name="name"
                type="text"
                placeholder="Nazwa"
                validate={props.errors.name}
                submitCount={props.submitCount}
                hasFeedback
              />
              <Field
                component={AntInput}
                name="city"
                type="text"
                placeholder="Miejscowość"
                validate={props.errors.city}
                submitCount={props.submitCount}
                hasFeedback
              />
              <Field
                component={AntInput}
                name="address"
                type="text"
                placeholder="Adres"
                validate={props.errors.address}
                submitCount={props.submitCount}
                hasFeedback
              />
              <Field
                component={AntInput}
                name="postcode"
                type="text"
                placeholder="Kod pocztowy"
                validate={props.errors.postcode}
                submitCount={props.submitCount}
                hasFeedback
              />
              <Field
                component={AntInput}
                name="description"
                type="text"
                placeholder="Opis"
                validate={props.errors.description}
                submitCount={props.submitCount}
                hasFeedback
              />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AddCompanyAddressModal;
