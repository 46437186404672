import {
  CATEGORIES_LOAD_SUCCESS,
  SETTINGS_CLEAR,
  SETTINGS_LOAD_SUCCESS,
  TSettingsActions,
  WORK_ACTIONS_LOAD_SUCCESS,
} from '../types/settings/actions';
import { ISettingsReducer } from '../types/settings/Settings';

const initialState: ISettingsReducer = {
  type: 'global',
  companyData: {
    name: '',
    nip: '',
    regon: '',
    bankAccount: '',
  },
  webPages: [],
  contact: [],
  address: [],

  categories: [],
  workActions: [],
};

const settingsReducer = (
  state = initialState,
  action: TSettingsActions
): ISettingsReducer => {
  switch (action.type) {
    case SETTINGS_LOAD_SUCCESS: {
      return { ...state, ...action.settings };
    }
    case CATEGORIES_LOAD_SUCCESS: {
      return { ...state, categories: [...action.categories] };
    }
    case WORK_ACTIONS_LOAD_SUCCESS: {
      return { ...state, workActions: [...action.workActions] };
    }
    case SETTINGS_CLEAR: {
      return { ...initialState };
    }
    default:
      return state;
  }
};

export { settingsReducer };
