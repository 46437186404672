import {
  DeleteOutlined,
  DollarCircleFilled,
  DollarCircleOutlined,
  DownloadOutlined,
  EllipsisOutlined,
  FieldNumberOutlined,
  FileAddFilled,
  FileTextOutlined,
  HomeOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import { isMobile } from 'react-device-detect';
import { OrderStatus } from 'services/store/enums/orders.enum';
import { Positions } from 'services/store/enums/users.enum';
import { IOrder } from 'services/store/types/orders/Orders';
import { IUser } from 'services/store/types/users/Users';
import { isUserHasPermission } from 'services/utils/users';

const { SubMenu } = Menu;

interface IActions {
  showModal: (key: any) => void;
  handleRemoveOrder: () => void;
  goToProjectCreate: () => void;
}
interface IProps {
  order: IOrder;
  user: IUser;
  actions: IActions;
  isAdmin: boolean;
}

const OrderActions: React.FC<IProps> = ({ order, user, actions, isAdmin }) => {
  if (!isUserHasPermission(user, [Positions.DESIGNER, Positions.TECHNOLOGIST]))
    return <></>;
  return (
    <>
      <Button key={0} type="primary" onClick={actions.goToProjectCreate}>
        <PlusOutlined /> Dodaj projekt
      </Button>
      {!isMobile && (
        <>
          <Button key={1} onClick={() => actions.showModal('changeName')}>
            <FileTextOutlined /> Zmień nazwę
          </Button>
          <Button key={2} onClick={() => actions.showModal('changeNumber')}>
            <FieldNumberOutlined /> Zmień numer
          </Button>
          <Button key={3} onClick={() => actions.showModal('changeAddress')}>
            <HomeOutlined /> Zmień adres
          </Button>
        </>
      )}
      <Dropdown
        key="more"
        overlay={dropDownMenu(order, user, actions, isAdmin)}
        trigger={['click']}
      >
        <Button type="primary">
          <EllipsisOutlined /> Menu
        </Button>
      </Dropdown>
    </>
  );
};

const dropDownMenu = (
  order: IOrder,
  user: IUser,
  actions: IActions,
  isAdmin: boolean
) => (
  <Menu>
    {isMobile && (
      <SubMenu title="Zamówienie" key={1}>
        <Menu.Item key={2} onClick={() => actions.showModal('changeName')}>
          <FileTextOutlined /> Zmień nazwę
        </Menu.Item>
        <Menu.Item key={3} onClick={() => actions.showModal('changeNumber')}>
          <FieldNumberOutlined /> Zmień numer
        </Menu.Item>
        <Menu.Item key={4} onClick={() => actions.showModal('changeAddress')}>
          <HomeOutlined /> Zmień adres
        </Menu.Item>
      </SubMenu>
    )}
    {isAdmin && (
      <>
        <SubMenu title="Płatności" key={6}>
          <Menu.Item
            key={7}
            onClick={() => actions.showModal('editPrice')}
            disabled={[OrderStatus.FINISHED].includes(order.status)}
          >
            <DollarCircleFilled />
            Zmień cenę
          </Menu.Item>
          <Menu.Item
            key={8}
            onClick={() => actions.showModal('editPayments')}
            disabled={[OrderStatus.FINISHED].includes(order.status)}
          >
            <DollarCircleOutlined />
            Edytuj transze
          </Menu.Item>
        </SubMenu>
        <SubMenu title="Koszty" key={9}>
          <Menu.Item
            key={10}
            onClick={() => actions.showModal('addCost')}
            disabled={[OrderStatus.FINISHED].includes(order.status)}
          >
            <DollarCircleFilled />
            Dodaj koszt
          </Menu.Item>
        </SubMenu>
      </>
    )}
    <SubMenu title="Dokumenty" key={11}>
      <Menu.Item
        key={12}
        disabled={[OrderStatus.FINISHED].includes(order.status)}
        onClick={() => actions.showModal('addDocument')}
      >
        <FileAddFilled />
        Dodaj plik
      </Menu.Item>
      <Menu.Item
        key={13} // disabled={[OrderStatus.FINISHED].includes(order.status)}
        disabled={true}
        onClick={() => actions.showModal('generateDocuments')}
      >
        <DownloadOutlined />
        Generuj dokument
      </Menu.Item>
    </SubMenu>
    <Menu.Divider />

    <Menu.Item key={14} danger onClick={actions.handleRemoveOrder}>
      <DeleteOutlined /> Usuń
    </Menu.Item>
  </Menu>
);

export default OrderActions;
