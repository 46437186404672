import { useCallback, useEffect, useState } from 'react';
import { FolderAddOutlined } from '@ant-design/icons';
import {
  Button,
  Input,
  message,
  PageHeader,
  Row,
  Select,
  Space,
  Tabs,
} from 'antd';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { H1 } from 'components/Header';
import { IProjectSearchQuery } from 'interfaces';
import { IProjectSearchParams } from 'services/api/projects.api';
import { TAppState } from 'services/store';
import { setProjectsKey } from 'services/store/actions/view';
import { ProjectStatus } from 'services/store/enums/project.enum';
import { Positions } from 'services/store/enums/users.enum';
import { ProjectsKey } from 'services/store/enums/view.enum';
import setAuthToken from 'services/utils/setAuthToken';
import { isUserHasPermission } from 'services/utils/users';
import PageTemplate from 'templates/PageTemplate';
import ProjectsList from '../../components/ProjectsList/ProjectsList';

const { TabPane } = Tabs;

// TODO Dodać paginację i filtry

const ProjectsPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const tabKey = useSelector((state: TAppState) => state.view.projectsKey);
  const user = useSelector((state: TAppState) => state.auth.user);

  const [isLoading, setIsLoading] = useState(false);
  const [projects, setProjects] = useState([]);

  const getProjects = useCallback(async (query: IProjectSearchParams) => {
    setIsLoading(true);
    try {
      setAuthToken();
      const res = await axios.get(`/api/projects`, { params: query });

      setProjects(res.data.content);
    } catch (error) {
      console.log(error);
      message.error('Błąd');
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (tabKey === 'inProduction') {
      getProjects({
        status: [
          ProjectStatus.PROJECT,
          ProjectStatus.TECHNOLOGIST,
          ProjectStatus.CARPENTER,
          ProjectStatus.STORAGE,
          ProjectStatus.MONTAGE,
        ],
        unpaged: true,
      });
    } else if (tabKey === 'pending') {
      getProjects({ status: [ProjectStatus.PENDING], unpaged: true });
    } else if (tabKey === 'finished') {
      getProjects({
        status: [ProjectStatus.FINISHED, ProjectStatus.CANCELED],
        unpaged: true,
      });
    }
  }, [tabKey, getProjects]);

  const redirectToProjectCreate = () => history.push(`/projects/create`);
  const handleTabKey = (key: ProjectsKey) => {
    setProjects((prev) => []);
    dispatch(setProjectsKey(key));
  };

  return (
    <PageTemplate>
      <PageHeader
        title={<H1>Projekty</H1>}
        extra={
          isUserHasPermission(user, [
            Positions.DESIGNER,
            Positions.TECHNOLOGIST,
          ]) && [
            <Button key={1} onClick={redirectToProjectCreate}>
              <FolderAddOutlined />
              Dodaj
            </Button>,
          ]
        }
      />
      <Row justify="end">
        <Space>
          <Select placeholder="Klient" style={{ width: 200 }}></Select>
          <Input placeholder="Numer" style={{ width: 200 }} />
        </Space>
      </Row>

      <Tabs
        defaultActiveKey={tabKey}
        onChange={(key) => handleTabKey(key as ProjectsKey)}
        size="large"
        style={{ marginBottom: 32 }}
      >
        <TabPane tab="W produkcji" key="inProduction">
          <ProjectsList projects={projects} isLoading={isLoading} />
        </TabPane>
        <TabPane tab="Oczekujące" key="pending">
          <ProjectsList projects={projects} isLoading={isLoading} />
        </TabPane>
        <TabPane tab="Zakończone" key="finished">
          <ProjectsList projects={projects} isLoading={isLoading} />
        </TabPane>
      </Tabs>
    </PageTemplate>
  );
};

export default ProjectsPage;
