import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  
  *, *::before, *::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  body {
    margin: 0;
    padding: 0;
  }

.ant-divider-horizontal.ant-divider-with-text-left::before,
.ant-divider-horizontal.ant-divider-with-text-left::after,
.ant-divider-horizontal.ant-divider-with-text-right::before,
.ant-divider-horizontal.ant-divider-with-text-right::after,
.ant-divider
{
   border-top: .1px solid lightskyblue;
   .anticon{
       color:red !important;
   }
}
.table-row-primary{
    background-color:#f0faff
}
.table-row-picked{
    background-color:#73a52e;
    &:hover{
      background-color:#73a52e !important;
    }
}
.table-row-clicable{
    cursor: pointer ;
}
.table-cell-hover-scale{
  transition: all 0.3s;
  &:hover{
      z-index: 999 !important;
      transform:scale(1.1) ;
    }
}
.table-cell-hover-green{
    &:hover{
      background-color:#4cb04d !important;
    }
}
.table-cell-hover-red{
    &:hover{
      background-color: #f54747 !important;
    }
}
.table-row-highlight{
  background-color: #e9ffe0;
  
  .ant-table-cell-fix-left {
      background-color: #e9ffe0;
      
  }

  .message-to-left{
    width:100vw
  }
}
`;

export default GlobalStyle;
