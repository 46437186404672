import { ProjectStatus } from 'services/store/enums/project.enum';
import { IProject } from 'services/store/types/projects/Projects';

export const initProjectData: IProject = {
  order: undefined,
  createdBy: undefined,
  createdAt: new Date(),
  name: '',
  number: '',
  status: [ProjectStatus.PENDING],
  comments: [],
  accessories: [],
  equipment: [],
  inProduction: [],
  workingOn: [],
  history: [],
  images: [],
  files: [],
  paintedElementsOrders: [],
  productionDays: {
    project: 1,
    technologist: 1,
    carpenter: 1,
    montage: 1,
  },
};
