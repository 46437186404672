import dayjs from 'dayjs';
import { IPositionNode } from 'services/store/types/productionNodes/ProductionNodes';

export const getDisplayDayPartViewData = (nodes: IPositionNode[]) => {
  const newList = nodes.map((node) => {
    const item: any = {
      key: node.nodeId,
      node: { position: node.position, user: node.user },
    };

    node.schedule.forEach((scheduleItem) => {
      scheduleItem.actions.forEach((action, index) => {
        const dataIndex = `${dayjs(scheduleItem.date).format(
          'DD.MM.YYYY'
        )}[${index}]`;
        item[dataIndex] = action.project?.number;
      });
    });
    return item;
  });
  return newList;
};
