import { ICustomer } from 'services/store/types/customers/Customers';
import { IProject } from 'services/store/types/projects/Projects';
import { IUser } from 'services/store/types/users/Users';

export const getUserName = <T extends { firstName: string; lastName: string }>(
  user: T | undefined,
  direction: 'f-l' | 'l-f' = 'f-l'
) => {
  if (!user) return 'user removed';
  if (direction === 'f-l')
    return `${user.firstName || ''} ${user.lastName || ''}`;
  return `${user.lastName || ''} ${user.firstName || ''}`;
};

export const getUserInitials = (user: IUser) => {
  return `${user.firstName[0]}${user.lastName[0]}`;
};

export const capitalizeFirstLetter = (s: string) =>
  s.charAt(0).toUpperCase() + s.slice(1);

export const cutString = (string: string, maxLength: number) => {
  if (string.length > maxLength) return `${string.slice(0, maxLength)}...`;
  return string;
};

export const getProjectName = (project: Partial<IProject>) =>
  `${getUserName(project.order?.customer as ICustomer)} - ${project.name}`;
