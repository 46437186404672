import React, { ReactElement } from 'react';
import { Badge } from 'antd';
import { ScheduleType } from 'services/store/enums/schedules.enum';

interface IProps {
  type: ScheduleType;
  content?: ReactElement;
}

const ScheduleBadges: React.FC<IProps> = ({ type, content = '' }) => {
  if (type === ScheduleType.MEASURE)
    return <Badge color="orange" text={content} />;
  if (type === ScheduleType.MONTAGE)
    return <Badge color="green" text={content} />;
  if (type === ScheduleType.VISIT)
    return <Badge color="purple" text={content} />;
  if (type === ScheduleType.OTHER) return <Badge color="red" text={content} />;
  return null;
};

export default ScheduleBadges;
