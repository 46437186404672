import Axios from 'axios';
import { Dispatch } from 'redux';
import { USERS_LOAD_SUCCESS, ISetUsers } from '../types/users/actions';
import { IUser } from '../types/users/Users';

export const setUsers = (users: IUser[]): ISetUsers => ({
  type: USERS_LOAD_SUCCESS,
  users,
});

/**--------------------
 * THUNK
 ----------------------*/

export const getAllUsers =
  (onEnd?: () => void) => async (dispatch: Dispatch) => {
    try {
      const res = await Axios.get('/api/users/');

      dispatch(setUsers(res.data));
      if (onEnd) onEnd();
    } catch (error) {
      dispatch(setUsers([]));
    }
  };
