import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import withContext from 'hoc/withPermission';

interface PropsT {
  component: React.FC<any>;
  permission: string;
  permissions: string[];
}

const PrivateRoute = ({
  component: Component,
  permission,
  permissions,
  ...props
}: PropsT) => {
  return (
    <Route
      {...props}
      render={(restProps) =>
        permissions.includes(permission) ? (
          <Component {...restProps} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default withContext(PrivateRoute);
