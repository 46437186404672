import React from 'react';
import { List } from 'antd';
import styled from 'styled-components';
import { IMessage } from 'services/store/types/messages/Messages';
import { IUser } from 'services/store/types/users/Users';
import MessagesListItem from './MessagesListItem';

const MessagesWrapper = styled.div`
  max-height: 300px;
  overflow-y: scroll;
`;

interface IProps {
  messages: IMessage[];
  markReaded: (id: string) => void;
  removeMessage: (id: string) => void;
  user: null | IUser;
}

const MessagesList: React.FC<IProps> = ({
  messages,
  markReaded,
  removeMessage,
  user,
}) => {
  const isMessageReaded = (message: IMessage) => {
    if (user?._id === (message.recipient as IUser)._id) {
      if ((message.recipient as IUser)._id !== message.isReaded) return false;
    }
    return true;
  };

  return (
    <MessagesWrapper>
      <List
        itemLayout="horizontal"
        dataSource={messages}
        renderItem={(message) => (
          <MessagesListItem
            message={message}
            markReaded={markReaded}
            removeMessage={removeMessage}
            isMessageReaded={isMessageReaded}
          />
        )}
      />
    </MessagesWrapper>
  );
};

export default MessagesList;
