import React, { useEffect, useState } from 'react';
import { Table, Typography } from 'antd';
import humanizeDuration from 'humanize-duration';
import { useHistory } from 'react-router';
import { IOrderWorkTimeStatistics } from 'services/store/types/statistics/Statistics';
import { summarizeOrderWorkTimesStatisticsDuration } from 'services/utils/statistics.utils';

const { Text } = Typography;

const columns: any[] = [
  {
    dataIndex: 'lp',
    title: 'Lp.',
    align: 'center' as const,
    render: (e: any, r: any, i: number) => i + 1,
    width: 50,
  },
  {
    title: 'Imię',
    dataIndex: 'firstName',
    align: 'center' as const,
  },
  {
    title: 'Nazwisko',
    dataIndex: 'lastName',
    align: 'center' as const,
  },
  {
    title: 'Stawka [zł/h]',
    dataIndex: 'earnings',
    align: 'center' as const,
  },
  {
    title: 'Czas pracy',
    dataIndex: 'durationInMs',
    align: 'center' as const,
    render: (durationInMs: number) => {
      const durationText = humanizeDuration(durationInMs, {
        units: ['h', 'm'],
        round: true,
        language: 'pl',
      });
      return <Text>{durationText}</Text>;
    },
  },
  {
    title: 'Koszt [zł]',
    dataIndex: 'employeeCost',
    align: 'center' as const,
  },
];

interface IProps {
  workTimes: IOrderWorkTimeStatistics[];
  isLoading?: boolean;
  maxHeight?: number;
}

const OrderUsersWorkTimesList: React.FC<IProps> = ({
  workTimes,
  isLoading = false,
  maxHeight,
}) => {
  const history = useHistory();
  const [list, setList] = useState<any[]>([]);

  useEffect(() => {
    if (workTimes) {
      const newList = workTimes.map(
        (workTime: IOrderWorkTimeStatistics, i: number) => ({
          ...workTime,

          key: workTime.userId,
        })
      );
      setList(newList);
    }
  }, [workTimes]);

  const handleRedirect = (id: string) => {
    history.push(`/users/${id}`);
  };

  return (
    <div>
      <Table
        columns={columns}
        pagination={false}
        dataSource={list}
        loading={isLoading}
        rowClassName={(r: any) => `table-row-clicable`}
        scroll={{ x: 1000, y: maxHeight }}
        size="small"
        onRow={(record) => {
          return {
            onClick: () => {
              handleRedirect(record.userId);
            },
          };
        }}
        summary={(data) => {
          if (!workTimes) return null;
          const summarizedDurationInMs =
            summarizeOrderWorkTimesStatisticsDuration(workTimes);
          const durationText = humanizeDuration(summarizedDurationInMs, {
            units: ['h', 'm'],
            round: true,
            language: 'pl',
          });

          return (
            <Table.Summary.Row style={{ backgroundColor: 'lightgray' }}>
              <Table.Summary.Cell index={1} colSpan={4}>
                <Text strong>Suma</Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2} align="center">
                <Typography.Text strong>{durationText}</Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3} colSpan={2} align="center" />
            </Table.Summary.Row>
          );
        }}
      />
    </div>
  );
};

export default OrderUsersWorkTimesList;
