import React from 'react';
import { CalendarOutlined } from '@ant-design/icons';
import { Card, Row } from 'antd';
import ProjectStatusTag from 'components/Tags/ProjectStatusTag/ProjectStatusTag';
import { ICustomer } from 'services/store/types/customers/Customers';
import { IProject } from 'services/store/types/projects/Projects';
import { getUserName } from 'services/utils/string';

interface IProps {
  project: IProject;
  setActiveProject: (project: IProject) => void;
  isActive: boolean;
}

const ProjectCard: React.FC<IProps> = ({
  project,
  setActiveProject,
  isActive,
}) => {
  return (
    <Card
      title={
        <Row justify="space-between">
          <div>{project.number}</div>
          {project.isPlaned && (
            <CalendarOutlined title="Projekt został już zaplanowany" />
          )}
        </Row>
      }
      onClick={() => setActiveProject(project)}
      style={isActive ? { backgroundColor: '#cfe3cc' } : {}}
    >
      <p>
        Klient:{' '}
        <strong>{getUserName(project.order?.customer as ICustomer)}</strong>
      </p>
      <div>
        {project.status.map((status) => (
          <ProjectStatusTag status={status} key={status} />
        ))}
      </div>
    </Card>
  );
};

export default ProjectCard;
