import React, { useCallback, useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Button, message, Row } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import humanizeDuration from 'humanize-duration';
import { cloneDeep, kebabCase } from 'lodash';
import { useDispatch } from 'react-redux';
import { H4 } from 'components/Header';
import WorkTimeSummaryModal from 'components/Modals/WorkTimeSummaryModal/WorkTimeSummary.modal';
import { useModal } from 'hook/useModals';
import { workTimesApi } from 'services/api/workTimes.api';
import { setLoading } from 'services/store/actions/view';
import { IProject } from 'services/store/types/projects/Projects';
import setAuthToken from 'services/utils/setAuthToken';
import {
  ISortedByUserWorkingTime,
  sortWorkingTimesByUsers,
  summarizeWorkTimesDuration,
} from 'services/utils/statistics.utils';
import UserWorkingTimeTable from './UserWorkingTimeTable';

interface IProps {
  project: IProject;
}

const ProjectStatistics: React.FC<IProps> = ({ project }) => {
  const dispatch = useDispatch();
  const [workingTimes, setWorkingTimes] = useState<ISortedByUserWorkingTime[]>(
    []
  );
  const [summarizedDurationInMs, setSummarizedDurationInMs] = useState(0);
  const { closeModal, showModal, modal } = useModal({
    getStatisticsSummaryFile: false,
  });

  const getWorkTime = useCallback(async () => {
    dispatch(setLoading(true));
    try {
      setAuthToken();
      setWorkingTimes([]);
      const res = await axios.get(`/api/work-time/project/${project._id}`);
      const sortedResult = sortWorkingTimesByUsers(res.data);

      const summarizedDuration = summarizeWorkTimesDuration(res.data);

      setSummarizedDurationInMs(summarizedDuration);

      setWorkingTimes(() => cloneDeep(sortedResult));
    } catch (error: any) {
      console.log(error, error.response);
      message.error('Błąd');
    } finally {
      dispatch(setLoading(false));
    }
  }, [project]);

  useEffect(() => {
    getWorkTime();
  }, [getWorkTime]);

  const { mutateAsync: getStatisticsFile, isLoading: isFileDownloading } =
    useMutation({
      mutationKey: ['download-stats-file'],
      mutationFn: () => {
        return workTimesApi.getStatisticsFile({
          dateFrom: dayjs(project.createdAt).startOf('day').toISOString(),
          dateTo: dayjs().endOf('day').toISOString(),
          project: project._id,
        });
      },
      onSuccess: async () => {},
      onError: () => {
        message.error('Błąd!');
      },
    });

  return (
    <div>
      <Row justify={'end'}>
        <Button
          onClick={() => showModal('getStatisticsSummaryFile')}
          disabled={!workingTimes.length}
        >
          Pobierz podsumowanie
        </Button>
        <Button
          onClick={() => getStatisticsFile()}
          disabled={!workingTimes.length}
          loading={isFileDownloading}
        >
          Pobierz
        </Button>
      </Row>
      <H4>
        Statystyki pracowników:{' '}
        {summarizedDurationInMs
          ? humanizeDuration(summarizedDurationInMs, {
              units: ['h', 'm'],
              round: true,
              language: 'pl',
            })
          : ''}
      </H4>
      {workingTimes.map((userWorkingTime, i) => {
        return (
          <UserWorkingTimeTable
            workingTimes={userWorkingTime}
            maxHeight={300}
            key={userWorkingTime.user?._id || i}
            getWorkTimes={getWorkTime}
          />
        );
      })}

      {modal.getStatisticsSummaryFile && (
        <WorkTimeSummaryModal
          closeModal={closeModal}
          projectId={project._id}
          initDateFrom={project.createdAt}
          initDateTo={project.finishedAt ?? dayjs().toDate()}
          fileName={`projekt_${kebabCase(project.number)}`}
        />
      )}
    </div>
  );
};

export default ProjectStatistics;
