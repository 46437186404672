import { IProject } from 'services/store/types/projects/Projects';

export const newProjectValidation = (project: IProject): string[] => {
  const errors = [];
  if (!project.designer) errors.push('Wybierz projektanta');
  if (!project.technologist) errors.push('Wybierz technologa');
  if (!project.name) errors.push('Podaj nazwę projektu');
  if (!project.number) errors.push('Podaj numer projektu');
  return errors;
};
