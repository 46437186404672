import React from 'react';
import { message, Row, Button } from 'antd';
import { useDispatch } from 'react-redux';
import { removeFileById } from 'services/requests/files.request';
import { setLoading } from 'services/store/actions/view';
import { HistoryColor } from 'services/store/enums/historyColor.enum';
import { IFile } from 'services/store/types/files/Files';
import { IProject, IProjectFile } from 'services/store/types/projects/Projects';
import FilesList from './FilesList';
import { TProjectModalType } from '../ProjectPage';

interface IProps {
  project: IProject;
  handleUpdateProject: (
    updateData: Partial<IProject>,
    historyUpdate: string[],
    historyColor: HistoryColor
  ) => Promise<any>;
  showModal: (modal: TProjectModalType) => void;
}

const ProjectFiles: React.FC<IProps> = ({
  project,
  handleUpdateProject,
  showModal,
}) => {
  const dispatch = useDispatch();

  const handleRemoveFile = async (file: IProjectFile) => {
    dispatch(setLoading(true));

    const removeFileResponse = await removeFileById((file.file as IFile)._id);
    if (removeFileResponse) {
      message.success('Plik usunięty');

      const newFiles = project.files?.filter((fil) => fil._id !== file._id);
      const historyUpdate = [`Usunięto plik:`, file.name];

      await handleUpdateProject(
        { files: newFiles },
        historyUpdate,
        HistoryColor.DANGER
      );
    }
  };

  return (
    <div>
      <Row justify="end">
        <Button type="primary" onClick={() => showModal('addFile')}>
          Dodaj plik
        </Button>
      </Row>
      <FilesList files={project.files} handleRemoveFile={handleRemoveFile} />
    </div>
  );
};

export default ProjectFiles;
