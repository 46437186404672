import React, { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Input,
  Modal,
  Popover,
  Typography,
  Upload,
} from 'antd';
import { RcFile } from 'antd/lib/upload';
import dayjs from 'dayjs';
import AntDatePicker from 'components/DatePicker/DatePicker';
import { initDocumentData } from 'scenes/OrderPage/data/initModals.data';
import { uploadFile } from 'services/requests/files.request';
import { FileDestination } from 'services/store/enums/files.enum';
import { HistoryColor } from 'services/store/enums/historyColor.enum';
import { IOrder, IOrderDocument } from 'services/store/types/orders/Orders';

const { Title } = Typography;

interface IInitDocument extends IOrderDocument {
  files: RcFile[];
}

interface IProps {
  order: IOrder;
  handleUpdateOrder: (
    updateData: Partial<IOrder>,
    historyUpdate: string[],
    historyColor: HistoryColor
  ) => Promise<any>;
  closeModal: () => void;
}

const AddOrderDocumentModal: React.FC<IProps> = ({
  order,
  handleUpdateOrder,
  closeModal,
}) => {
  const [document, setDocument] = useState<IInitDocument>(initDocumentData);

  const onOk = async () => {
    let fileId: string | undefined = undefined;
    if (document.files.length) {
      fileId = await uploadFile(
        document.files[0],
        FileDestination.ORDERS,
        document.name
      );
    }

    const historyUpdate = [`Dodano dokument:`, document.name];
    const documentItem = { ...document, file: fileId };
    const newDocuments = [...order.documents, documentItem];
    await handleUpdateOrder(
      { documents: newDocuments },
      historyUpdate,
      HistoryColor.SUCCESS
    );

    closeModal();
  };

  const handleChange = (field: keyof IInitDocument, value: any) => {
    setDocument({ ...document, [field]: value });
  };

  return (
    <Modal
      title="Dodawanie dokumentu"
      destroyOnClose
      visible
      onCancel={closeModal}
      maskClosable={false}
      onOk={onOk}
      okButtonProps={{
        disabled: !document.files.length || !document.name,
      }}
    >
      <Title level={5}>Nazwa</Title>
      <Input
        value={document.name}
        onChange={(e) => handleChange('name', e.target.value)}
        placeholder="Nazwa"
      />

      <Title level={5}>Opis</Title>
      <Input
        value={document.description}
        onChange={(e) => handleChange('description', e.target.value)}
        placeholder="Opis"
      />

      <Title level={5}>Data</Title>
      <AntDatePicker
        value={dayjs(document.date)}
        onChange={(value) =>
          value && handleChange('date', new Date(value.format()))
        }
        format="DD.MM.YYYY"
      />

      <Title level={5}>Plik</Title>
      <Upload
        onRemove={() => handleChange('files', [])}
        beforeUpload={(file: RcFile) => {
          handleChange('files', [file]);
          return false;
        }}
        fileList={document.files}
        multiple={false}
        accept="image/*"
      >
        <Button icon={<UploadOutlined />}>Dodaj plik</Button>
      </Upload>

      <Popover
        title="Dokument chroniony"
        content="Widoczny tylko dla administratora, projektana i technologa"
      >
        <Checkbox
          style={{ marginTop: 10 }}
          checked={document.protected}
          onChange={(e) => handleChange('protected', e.target.checked)}
        >
          Dokument chroniony
        </Checkbox>
      </Popover>
    </Modal>
  );
};

export default AddOrderDocumentModal;
