import React, { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Layout, message } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import styled from 'styled-components';
// import logo from 'assets/images/logo.svg';
import InfoDrawerButton from 'components/Buttons/AppInfoButton/AppInfoButton';
import WorkingOnModal from 'components/Modals/WorkingOnModal/WorkingOn.modal';
import Navigation from 'components/Navigation';
import Spinner from 'components/Spinner';
import PrivateRoute from 'hoc/PrivateRoute';
import { useModal } from 'hook/useModals';
import interceptor from 'interceptor';
import AccessoriesPage from 'scenes/Accessories/AccessoriesPage';
import AccessoryPage from 'scenes/AccessoryPage/AccessoryPage';
import AccountSettingsPage from 'scenes/AccountSettings/AccountSettingsPage';
import CashRegisterPage from 'scenes/CashRegisterPage/CashRegisterPage';
import CashRegisterPageV2 from 'scenes/CashRegisterPageV2/CashRegisterPageV2';
import CustomerPage from 'scenes/CustomerPage/CustomerPage';
import CustomersPage from 'scenes/Customers/CustomersPage';
import DashboardPage from 'scenes/Dashboard/DashboardPage';
import LoginPage from 'scenes/Login/LoginPage';
import OrderCreate from 'scenes/OrderCreate/OrderCreate';
import OrderPage from 'scenes/OrderPage/OrderPage';
import OrdersPage from 'scenes/OrdersPage/OrdersPage';
import ProductionScheduleEdit from 'scenes/ProductionScheduleEdit/ProductionScheduleEditPage';
import ProjectCreate from 'scenes/ProjectCreate/ProjectCreate';
import ProjectPage from 'scenes/ProjectPage/ProjectPage';
import ProjectsPage from 'scenes/Projects/ProjectsPage';
import SchedulePage from 'scenes/SchedulePage/SchedulePage';
import SettingsPage from 'scenes/Settings/SettingsPage';
import OrdersStatisticsPage from 'scenes/StatisticsPage/Orders/OrdersStatisticsPage';
import UsersStatisticsPage from 'scenes/StatisticsPage/Users/UsersStatisticsPage';
import UserPage from 'scenes/UserPage/UserPage';
import UsersPage from 'scenes/Users/UsersPage';
import { TAppState } from 'services/store';
import { Permissions } from 'services/store/enums/users.enum';
import MainTemplate from 'templates/MainTemplate';
const { Header, Content, Footer } = Layout;

const Logo = styled.img`
  width: 120px;
  height: 30px;
  margin: 18px 28px 16px 0;
  float: left;
  cursor: pointer;
`;

const initModals = { workingOnInfo: false };

function App() {
  const history = useHistory();
  const { showModal, closeModal, modal } = useModal(initModals);
  const user = useSelector((state: TAppState) => state.auth.user);
  const isAuth = useSelector((state: TAppState) => state.auth.isAuth);
  const { isLoading } = useSelector((state: TAppState) => state.view);

  const queryClient = new QueryClient();

  useEffect(() => {
    interceptor(history);
  }, []);

  return (
    <div>
      {isLoading && <Spinner />}
      <QueryClientProvider client={queryClient}>
        <Router>
          <MainTemplate>
            {user?.workingOn && (
              <InfoDrawerButton onClick={() => showModal('workingOnInfo')} />
            )}
            <Layout style={{ minHeight: '100vh' }}>
              <Header>
                <Link to="/">{/* <Logo src={''} /> */}</Link>
                <Navigation />
              </Header>
              <Layout>
                <Content
                  style={{ margin: 10, padding: 10, backgroundColor: 'white' }}
                >
                  <Switch>
                    <Route path="/login" component={LoginPage} />
                    {/* <Route exact path="/" component={HomePage} /> */}

                    <PrivateRoute
                      permissions={[Permissions.ADMIN, Permissions.USER]}
                      exact
                      path="/"
                      component={DashboardPage}
                    />
                    <PrivateRoute
                      permissions={[Permissions.ADMIN, Permissions.USER]}
                      exact
                      path="/blow"
                      component={CashRegisterPage}
                    />
                    <PrivateRoute
                      permissions={[Permissions.ADMIN, Permissions.USER]}
                      exact
                      path="/blow-v2"
                      component={CashRegisterPageV2}
                    />
                    <PrivateRoute
                      permissions={[Permissions.ADMIN]}
                      exact
                      path="/stats/orders"
                      component={OrdersStatisticsPage}
                    />
                    <PrivateRoute
                      permissions={[Permissions.ADMIN]}
                      exact
                      path="/stats/employees"
                      component={UsersStatisticsPage}
                    />
                    <PrivateRoute
                      permissions={[Permissions.ADMIN]}
                      exact
                      path="/users"
                      component={UsersPage}
                    />
                    <PrivateRoute
                      permissions={[Permissions.ADMIN]}
                      exact
                      path="/users/:id"
                      component={UserPage}
                    />
                    <PrivateRoute
                      permissions={[Permissions.ADMIN, Permissions.USER]}
                      exact
                      path="/customers"
                      component={CustomersPage}
                    />
                    <PrivateRoute
                      permissions={[Permissions.ADMIN, Permissions.USER]}
                      exact
                      path="/customers/:id"
                      component={CustomerPage}
                    />
                    <PrivateRoute
                      permissions={[Permissions.ADMIN, Permissions.USER]}
                      exact
                      path="/orders/create"
                      component={OrderCreate}
                    />
                    <PrivateRoute
                      permissions={[Permissions.ADMIN, Permissions.USER]}
                      exact
                      path="/orders"
                      component={OrdersPage}
                    />
                    <PrivateRoute
                      permissions={[Permissions.ADMIN, Permissions.USER]}
                      exact
                      path="/orders/:id"
                      component={OrderPage}
                    />
                    <PrivateRoute
                      permissions={[Permissions.ADMIN, Permissions.USER]}
                      exact
                      path="/projects/create/:orderId"
                      component={ProjectCreate}
                    />
                    <PrivateRoute
                      permissions={[Permissions.ADMIN, Permissions.USER]}
                      exact
                      path="/projects"
                      component={ProjectsPage}
                    />
                    <PrivateRoute
                      permissions={[Permissions.ADMIN, Permissions.USER]}
                      exact
                      path="/projects/:id"
                      component={ProjectPage}
                    />
                    <PrivateRoute
                      permissions={[Permissions.ADMIN, Permissions.USER]}
                      exact
                      path="/production-schedule/edit"
                      component={ProductionScheduleEdit}
                    />
                    <PrivateRoute
                      permissions={[Permissions.ADMIN, Permissions.USER]}
                      exact
                      path="/base/accessories"
                      component={AccessoriesPage}
                    />
                    <PrivateRoute
                      permissions={[Permissions.ADMIN, Permissions.USER]}
                      exact
                      path="/base/accessories/:id"
                      component={AccessoryPage}
                    />
                    <PrivateRoute
                      permissions={[Permissions.ADMIN, Permissions.USER]}
                      exact
                      path="/schedule"
                      component={SchedulePage}
                    />
                    <PrivateRoute
                      permissions={[Permissions.ADMIN, Permissions.USER]}
                      exact
                      path="/settings"
                      component={SettingsPage}
                    />
                    <PrivateRoute
                      permissions={[Permissions.ADMIN, Permissions.USER]}
                      exact
                      path="/account"
                      component={AccountSettingsPage}
                    />
                  </Switch>
                </Content>
                <Footer
                  style={{ textAlign: 'center', padding: '3px 0 10px 0' }}
                >
                  <small>
                    <div>
                      <a href="https://www.mebleblow.pl">Meble Blow 1.0.0</a>
                    </div>
                  </small>
                </Footer>
              </Layout>
            </Layout>
            {user && modal.workingOnInfo && (
              <WorkingOnModal user={user} closeModal={closeModal} />
            )}
          </MainTemplate>
        </Router>

        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </QueryClientProvider>
    </div>
  );
}

export default App;
