import { OrderStatus } from '../enums/orders.enum';
import {
  HomePageKey,
  OrderPageKey,
  OrdersKey,
  ProjectPageKey,
  ProjectProductionEditView,
  ProjectsKey,
} from '../enums/view.enum';
import {
  TViewActions,
  VIEW_SET_ORDERS_KEY,
  VIEW_SET_PROJECTS_KEY,
  VIEW_SET_PROJECT_PAGE_KEY,
  VIEW_SET_ORDER_PAGE_KEY,
  VIEW_SET_SPINNER,
  VIEW_SET_PROJECT_PRODUCTION_EDIT_VIEW,
  VIEW_SET_PROJECT_PRODUCTION_EDIT_CELL_WIDTH,
  VIEW_SET_ORDER_STATISTICS_PAGE_KEY,
  VIEW_SET_HOME_PAGE_KEY,
} from '../types/view/actions';
import { IView } from '../types/view/View';

const initialState: IView = {
  isLoading: false,
  projectsKey: ProjectsKey.IN_PRODUCTION,
  ordersKey: OrdersKey.IN_PRODUCTION,
  projectPageKey: ProjectPageKey.DASHBOARD,
  orderPageKey: OrderPageKey.DASHBOARD,
  projectProductionEditView: ProjectProductionEditView.DAYS,
  ordersStatisticsPageKey: OrderStatus.IN_PROGRESS,
  projectProductionEditCellWidth: 120,
  homePageKey:
    (localStorage.homePageTab as HomePageKey) || HomePageKey.PROJECTS,
};

const viewReducer = (state = initialState, action: TViewActions): IView => {
  switch (action.type) {
    case VIEW_SET_SPINNER: {
      return { ...state, isLoading: action.isLoading };
    }
    case VIEW_SET_HOME_PAGE_KEY: {
      localStorage.setItem('homePageTab', action.homePageKey);
      return { ...state, homePageKey: action.homePageKey };
    }
    case VIEW_SET_PROJECTS_KEY: {
      return { ...state, projectsKey: action.projectsKey };
    }
    case VIEW_SET_ORDERS_KEY: {
      return { ...state, ordersKey: action.ordersKey };
    }
    case VIEW_SET_ORDER_STATISTICS_PAGE_KEY: {
      return {
        ...state,
        ordersStatisticsPageKey: action.ordersStatisticsPageKey,
      };
    }
    case VIEW_SET_PROJECT_PAGE_KEY: {
      return { ...state, projectPageKey: action.projectPageKey };
    }
    case VIEW_SET_ORDER_PAGE_KEY: {
      return { ...state, orderPageKey: action.orderPageKey };
    }
    case VIEW_SET_PROJECT_PRODUCTION_EDIT_VIEW: {
      return {
        ...state,
        projectProductionEditView: action.projectProductionEditView,
      };
    }
    case VIEW_SET_PROJECT_PRODUCTION_EDIT_CELL_WIDTH: {
      return {
        ...state,
        projectProductionEditCellWidth: action.projectProductionEditCellWidth,
      };
    }

    default:
      return state;
  }
};

export { viewReducer };
