import React from 'react';
import { Col, Descriptions, Popconfirm, Row, Space } from 'antd';
import { H4 } from 'components/Header';
import { DeleteIcon } from 'components/Icons';
import {
  ICompanyData,
  ISettings,
} from 'services/store/types/settings/Settings';

interface IProps {
  settings: ISettings;
  updateSettings: (settings: Partial<ISettings>) => Promise<any>;
}

const CompanyData: React.FC<IProps> = ({ settings, updateSettings }) => {
  const removeAddress = async (id: string) => {
    const address = settings.address.filter((addr) => addr._id !== id);
    console.log(address);
    await updateSettings({ address });
  };
  const removeContact = async (id: string) => {
    const contact = settings.contact.filter((con) => con._id !== id);
    await updateSettings({ contact });
  };
  const removeWebPage = async (id: string) => {
    const webPages = settings.webPages.filter((webPage) => webPage._id !== id);
    await updateSettings({ webPages });
  };
  return (
    <Row>
      <Col md={6} span={24}>
        <H4>Dane firmy</H4>
        <Descriptions size="small" column={1}>
          <Descriptions.Item label="Nazwa">
            <strong>{settings.companyData.name}</strong>
          </Descriptions.Item>
          <Descriptions.Item label="NIP">
            <strong>{settings.companyData.nip}</strong>
          </Descriptions.Item>
          <Descriptions.Item label="REGON">
            <strong>{settings.companyData.regon}</strong>
          </Descriptions.Item>
          <Descriptions.Item label="Konto bankowe">
            <strong>{settings.companyData.bankAccount}</strong>
          </Descriptions.Item>
        </Descriptions>
      </Col>
      <Col md={6} span={24}>
        <H4>Adresy</H4>
        {settings.address.map((address) => (
          <Descriptions
            key={address._id}
            title={
              <Space>
                {address.name}
                <Popconfirm
                  title="Na pewno chcesz usunąć ten adres?"
                  onConfirm={() => address._id && removeAddress(address._id)}
                  okText="Tak"
                  cancelText="Nie"
                >
                  <DeleteIcon />
                </Popconfirm>
              </Space>
            }
            size="small"
            column={1}
          >
            <Descriptions.Item label="Miejscowość">
              <strong>{address.city}</strong>
            </Descriptions.Item>
            <Descriptions.Item label="Adres">
              <strong>{address.address}</strong>
            </Descriptions.Item>
            <Descriptions.Item label="Kod pocztowy">
              <strong>{address.postcode}</strong>
            </Descriptions.Item>
            <Descriptions.Item label="Opis">
              <strong>{address.description}</strong>
            </Descriptions.Item>
          </Descriptions>
        ))}
      </Col>
      <Col md={6} span={24}>
        <H4>Kontakt</H4>
        {settings.contact.map((contact) => (
          <Descriptions
            key={contact._id}
            title={
              <Space>
                {contact.name}
                <Popconfirm
                  title="Na pewno chcesz usunąć ten kontakt?"
                  onConfirm={() => contact._id && removeContact(contact._id)}
                  okText="Tak"
                  cancelText="Nie"
                >
                  <DeleteIcon />
                </Popconfirm>
              </Space>
            }
            size="small"
            column={1}
          >
            <Descriptions.Item label="E-mail">
              <strong>{contact.email}</strong>
            </Descriptions.Item>
            <Descriptions.Item label="Telefon">
              <strong>{contact.phone}</strong>
            </Descriptions.Item>
            <Descriptions.Item label="Opis">
              <strong>{contact.description}</strong>
            </Descriptions.Item>
          </Descriptions>
        ))}
      </Col>
      <Col md={6} span={24}>
        <H4>Strony internetowe</H4>
        {settings.webPages.map((webPage) => (
          <Descriptions
            key={webPage._id}
            title={
              <Space>
                {webPage.name}
                <Popconfirm
                  title="Na pewno chcesz usunąć tą stronę?"
                  onConfirm={() => webPage._id && removeWebPage(webPage._id)}
                  okText="Tak"
                  cancelText="Nie"
                >
                  <DeleteIcon />
                </Popconfirm>
              </Space>
            }
            size="small"
            column={1}
          >
            <Descriptions.Item label="Adres">
              <strong>{webPage.webPage}</strong>
            </Descriptions.Item>
          </Descriptions>
        ))}
      </Col>
    </Row>
  );
};

export default CompanyData;
