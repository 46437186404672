import { Modal } from 'antd';

export const ErrorModal = (errors: string[]) => {
  return Modal.error({
    title: 'Uzupełnij dane!',
    okButtonProps: { danger: true },
    content: (
      <ul>
        {errors.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ul>
    ),
  });
};
