import React from 'react';
import { message, Modal } from 'antd';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setLoading } from 'services/store/actions/view';
import { IUser } from 'services/store/types/users/Users';
import setAuthToken from 'services/utils/setAuthToken';
import { getUserName } from 'services/utils/string';

interface IProps {
  closeModal: () => void;
  refetchActivity: () => Promise<any>;
  user: IUser;
}

const StopUserProcessModal: React.FC<IProps> = ({
  closeModal,
  user,
  refetchActivity,
}) => {
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    dispatch(setLoading(true));
    try {
      setAuthToken();

      await axios.patch(`/api/users/${user._id}/stop-working-on`);
      await refetchActivity();

      message.success('Proces zakończony');
    } catch (error: any) {
      console.log(error, error.response);
      message.error('Błąd');
    }
    dispatch(setLoading(false));
    closeModal();
  };

  return (
    <Modal
      title={'Kończenie procesu użytkownika'}
      destroyOnClose
      visible
      onOk={handleSubmit}
      onCancel={closeModal}
      maskClosable={false}
    >
      <div style={{ textAlign: 'center' }}>
        <div>
          <div>
            Czy na pewno chcesz zakończyć akcję za użytkownika:{' '}
            <strong>{getUserName(user)}</strong>?
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default StopUserProcessModal;
