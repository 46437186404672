import React from 'react';
import { Descriptions, Skeleton, Typography } from 'antd';
import { ICustomer } from 'services/store/types/customers/Customers';
import { IUser } from 'services/store/types/users/Users';
import { getUserName } from 'services/utils/string';

const { Text } = Typography;

interface IProps {
  user?: IUser | ICustomer;
  column?: number;
  title?: string;
}

const ProjectUserData: React.FC<IProps> = ({ user, column, title }) => {
  if (!user) return <Skeleton title={false} paragraph={{ rows: 1 }} />;
  return (
    <Descriptions title={title} column={column}>
      <Descriptions.Item label="Imię i nazwisko">
        <strong>{getUserName(user)}</strong>
      </Descriptions.Item>
      <Descriptions.Item label="E-mail">
        <strong>
          <Text copyable>{user.email}</Text>
        </strong>
      </Descriptions.Item>
      <Descriptions.Item label="Nr telefonu">
        <strong>
          <Text copyable>{user.phone}</Text>
        </strong>
      </Descriptions.Item>
    </Descriptions>
  );
};

export default ProjectUserData;
