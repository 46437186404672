export const initProjectModals = {
  addEquipment: false,
  addCost: false,
  addImage: false,
  addFile: false,
  addDocument: false,
  addComment: false,
  addPaintedElementsOrder: false,
  editAccessories: false,
  generateDocuments: false,
  changeStatus: false,
  changeNumber: false,
  changeName: false,
  changeAddress: false,
  changeCreationDate: false,
  changeMontageDate: false,
  changeDesigner: false,
  changeTechnologist: false,
  newScheduleItem: false,
  setProductionDays: false,
  startProduction: false,
};
export const initFileData = {
  name: '',
  description: '',
  date: new Date(),
  files: [],
  protected: false,
};
