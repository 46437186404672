import * as yup from 'yup';
import { IAddress } from 'services/store/types/address/Address';

export const customerDataValidation = yup.object({
  firstName: yup.string(),
  lastName: yup.string(),
  company: yup.string(),
  // pesel: yup
  //   .string()
  //   .trim()
  //   .matches(/^\d{11}$/, 'PESEL składa się z 11 cyfr'),
  nip: yup
    .string()
    .when('company', {
      is: (value: string) => !!value,
      then: yup
        .string()
        .required('NIP jest wymagany')
        .matches(/^\d{10}$/, 'NIP składa się z 10 cyfr'),
      otherwise: yup.string().matches(/^\d{10}$/, 'NIP składa się z 10 cyfr'),
    })
    .nullable(),
  email: yup.string().email('Format adresu email jest nieprawidłowy'),
  phone: yup
    .string()
    .matches(
      /^\(?([0-9]{3})\)?([ .-]?)([0-9]{3})([ .-]?)([0-9]{3})$/,
      'Nr telefonu musi składać się z 9 cyfr'
    ),
});

export const isAddressValid = (address: IAddress) => {
  const errors: any = {};
  if (address.postcode && !/^\d{2}-\d{3}$/.test(address.postcode))
    errors.address = { postcode: 'Kod pocztowy ma nieprawidłowy format' };

  return errors;
};
