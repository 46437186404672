import React from 'react';
import { Select } from 'antd';
import { getUserName } from 'services/utils/string';

const { Option } = Select;

interface IProps {
  value: string | undefined;
  handleUpdate: (id?: string) => void;
  placeholder: string;
  users?: { _id: string; lastName: string; firstName: string }[];
}

const ProjectUserSelect: React.FC<IProps> = ({
  value,
  handleUpdate,
  placeholder,
  users,
}) => {
  return (
    <div>
      <Select
        size="large"
        onClear={() => handleUpdate()}
        placeholder={placeholder}
        allowClear
        value={value}
        onChange={(id) => handleUpdate(id)}
        style={{ width: 200, marginBottom: 30 }}
        disabled={!users}
      >
        {users
          ?.sort((a, b) => `${a.lastName}`.localeCompare(b.lastName))
          .map((item) => {
            if (item._id)
              return (
                <Option key={item._id} value={item._id}>
                  {getUserName(item)}
                </Option>
              );
          })}
      </Select>
    </div>
  );
};

export default ProjectUserSelect;
