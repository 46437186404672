import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Button, Input, message, Modal, Row } from 'antd';
import { useControledDebounce } from 'hook/useControledDebounce';
import { projectsApi } from 'services/api/projects.api';
import { IProject } from 'services/store/types/projects/Projects';
import ProjectsList from '../../ProjectsList/ProjectsList';

interface IProps {
  closeModal: () => void;
  pickProject: (project: IProject) => void;
}

const PickProjectModal: React.FC<IProps> = ({ closeModal, pickProject }) => {
  const { value, debouncedValue, handleDebouncedValue } =
    useControledDebounce();

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['get-projects-to-pick', debouncedValue],
    queryFn: () =>
      projectsApi.getProjects({
        pageNumber: 1,
        itemsPerPage: 5,
        number: debouncedValue,
      }),
    onSuccess: (res) => {},
    onError: (error: any) => {
      console.log(error, error.response);
      message.error('Błąd!');
    },
    enabled: !!debouncedValue,
  });

  const handlePick = (project: IProject) => {
    pickProject(project);
    closeModal();
  };

  return (
    <Modal
      title="Wybierz projekt"
      destroyOnClose
      open
      maskClosable={false}
      width={900}
      footer={
        <Row justify="end">
          <Button type="primary" onClick={closeModal}>
            Zamknij
          </Button>
        </Row>
      }
    >
      <Input
        value={value}
        onChange={(e) => handleDebouncedValue(e.target.value)}
        placeholder="Numer"
        allowClear
      />

      <ProjectsList
        projects={data?.data.content}
        isLoading={isLoading}
        onRowClick={handlePick}
      />
    </Modal>
  );
};

export default PickProjectModal;
