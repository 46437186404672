import React, { useEffect, useState } from 'react';
import { Empty, message, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { TAppState } from 'services/store';
import {
  addProjectToNodeAction,
  removeProjectFromNodeAction,
} from 'services/store/actions/productionNodes';
import { IProject } from 'services/store/types/projects/Projects';
import { getEditDayPartViewData } from './utils/getEditDayPartViewData';
import { getEditDayPartViewTableHeaders } from './utils/getEditDayPartViewTableHeaders';

interface IProps {
  project?: IProject;
  isChanges: boolean;
  setIsChanges: (value: boolean) => void;
  addProjectToChanged: (id: string) => void;
}

const DayPartsView: React.FC<IProps> = ({
  project,
  isChanges,
  setIsChanges,
  addProjectToChanged,
}) => {
  const dispatch = useDispatch();
  const nodes = useSelector((state: TAppState) => state.productionNodes.nodes);
  const dates = useSelector((state: TAppState) => state.productionNodes.dates);
  const cellWidth = useSelector(
    (state: TAppState) => state.view.projectProductionEditCellWidth
  );

  const [columns, setColumns] = useState<any[]>([]);
  const [list, setList] = useState<any>([]);

  // --------------------------------------------------------------------------
  // NOTE COLUMNS
  // --------------------------------------------------------------------------
  useEffect(() => {
    if (dates.length) {
      const columnHeaders = getEditDayPartViewTableHeaders(
        dates,
        addProjectToSchedule,
        removeProjectFromSchedule,
        cellWidth,
        project
      );
      setColumns(columnHeaders);
    }
  }, [dates, project, cellWidth]);

  //   DATA
  useEffect(() => {
    if (nodes.length && columns.length) {
      const newList = getEditDayPartViewData(nodes);
      setList(newList);
    }
  }, [nodes, columns]);

  const addProjectToSchedule = async (
    nodeIndex: number,
    scheduleItemIndex: number,
    actionIndex: number
  ) => {
    if (!project) return message.error('Brak projektu do dodania');
    if (!project._id) return message.error('Brak projektu do dodania');
    if (!isChanges) setIsChanges(true);
    addProjectToChanged(project._id);
    dispatch(
      addProjectToNodeAction(nodeIndex, scheduleItemIndex, actionIndex, project)
    );
  };
  const removeProjectFromSchedule = async (
    nodeIndex: number,
    scheduleItemIndex: number,
    actionIndex: number
  ) => {
    if (!isChanges) setIsChanges(true);
    if (project?._id) addProjectToChanged(project._id);
    dispatch(
      removeProjectFromNodeAction(nodeIndex, scheduleItemIndex, actionIndex)
    );
  };

  if (!columns.length || !list.length) return <Empty />;
  return (
    <Table
      bordered
      columns={columns}
      dataSource={list}
      pagination={false}
      size="small"
      scroll={{ x: 1000, y: '80vh' }}
    />
  );
};

export default DayPartsView;
