import React, { useState } from 'react';
import { Button, Popover } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import ScheduleBadges from 'components/Badges/ScheduleBadges';
import AntCalendar from 'components/Calendar/Calendar';
import ScheduleItemDetailsModal from 'components/Schedule/ScheduleList/ScheduleItemDetailsModal';
import { IProject } from 'services/store/types/projects/Projects';
import { ISchedule } from 'services/store/types/schedule/Schedule';
import { cutString, getProjectName } from 'services/utils/string';

interface IProps {
  schedule: ISchedule[];
  date: Date;
  handleDate: (d: Dayjs) => void;
  getSchedule: () => Promise<any>;
}

const CalendarView: React.FC<IProps> = ({
  schedule,
  date,
  handleDate,
  getSchedule,
}) => {
  const [scheduleDetails, setScheduleDetails] = useState<ISchedule | null>(
    null
  );

  const dateCellRender = (value: any) => {
    const day = dayjs(value);
    const items = schedule.filter((s) => {
      const itemDate = dayjs(s.date);
      if (day.isSame(itemDate, 'date')) return s;
    });

    return items.map((item) => (
      <div key={item._id}>
        <ScheduleBadges
          type={item.type}
          content={
            <Popover
              content={
                <div>
                  <div>
                    {item.name}
                    <div>
                      <Button
                        size="small"
                        type="primary"
                        block
                        onClick={() => setScheduleDetails(item)}
                      >
                        Podejrzyj
                      </Button>
                    </div>
                  </div>
                </div>
              }
              title={
                item.project
                  ? getProjectName(item.project as IProject)
                  : item.name
              }
            >
              {item.name}
            </Popover>
          }
        />
      </div>
    ));
  };

  const monthCellRender = (value: any) => {
    const day = dayjs(value);
    const items = schedule.filter((s) => {
      const itemDate = dayjs(s.date);
      if (day.isSame(itemDate, 'month')) return s;
    });

    return items.map((item) => {
      const projectName = item.project
        ? getProjectName(item.project as IProject)
        : '';
      const itemText = projectName && `${projectName} - ` + item.name;
      return (
        <div key={item._id}>
          <ScheduleBadges
            type={item.type}
            content={<>{cutString(itemText, 80)}</>}
          />
        </div>
      );
    });
  };

  return (
    <div>
      <AntCalendar
        dateCellRender={dateCellRender}
        monthCellRender={monthCellRender}
        onPanelChange={handleDate}
        value={dayjs(date)}
      />

      {/* MODALS */}
      {scheduleDetails && (
        <ScheduleItemDetailsModal
          scheduleItem={scheduleDetails}
          closeModal={() => setScheduleDetails(null)}
          getSchedule={getSchedule}
        />
      )}
    </div>
  );
};

export default CalendarView;
