import React from 'react';
import { Tag } from 'antd';
import { CashRegisterOperationType } from 'services/store/types/cashRegister/CashRegister';

interface IProps {
  operationType: CashRegisterOperationType;
}

const CashRegisterOperationTypeTag: React.FC<IProps> = ({ operationType }) => {
  if (operationType === CashRegisterOperationType.IN)
    return <Tag color="green">Wpłata</Tag>;
  if (operationType === CashRegisterOperationType.OUT)
    return <Tag color="gold">Wypłata</Tag>;
  return null;
};

export default CashRegisterOperationTypeTag;
