import React, { useState } from 'react';
import { Modal, Button, message } from 'antd';
import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import { AntPassword } from 'components/Formik-AntdFields/CreateAntdField';
import { IUser } from 'services/store/types/users/Users';
import { passwordChangeValidation } from '../utils/validation';

interface PropsT {
  closeModal: () => void;
  user: IUser;
}

const ChangePasswordModal: React.FC<PropsT> = ({ closeModal, user }) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (
    values: { password: string; newPassword: string },
    actions: any
  ) => {
    setLoading(true);
    try {
      await axios.patch(`/api/users/${user._id}/change-password`, {
        ...values,
      });

      message.success('Hasło zmienione');
      setLoading(false);
      closeModal();
    } catch (error: any) {
      if (error.response?.data?.error)
        actions.setErrors(error.response.data.error);
      else message.error('Błąd');
      console.log(error, error.response);
      setLoading(false);
    }
  };

  if (user)
    return (
      <Modal
        title="Zmiana hasła"
        destroyOnClose
        visible
        onCancel={closeModal}
        maskClosable={false}
        footer={[
          <Button key="back" onClick={closeModal}>
            Anuluj
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            htmlType="submit"
            form="ChangePasswordForm"
          >
            Zatwierdź
          </Button>,
        ]}
      >
        <div>
          <Formik
            validationSchema={passwordChangeValidation}
            validate={(values) => {
              const errors: any = {};
              if (values.newPassword2 !== values.newPassword)
                errors.newPassword2 = 'Nowe hasła muszą być identyczne';
              return errors;
            }}
            onSubmit={handleSubmit}
            initialValues={{ password: '', newPassword: '', newPassword2: '' }}
          >
            {(props) => {
              return (
                <Form
                  noValidate
                  id="ChangePasswordForm"
                  onSubmit={props.handleSubmit}
                >
                  <Field
                    component={AntPassword}
                    name="password"
                    type="password"
                    placeholder="Aktualne hasło"
                    validate={props.errors.password}
                    submitCount={props.submitCount}
                    hasFeedback
                  />
                  <Field
                    component={AntPassword}
                    name="newPassword"
                    type="password"
                    placeholder="Nowe hasło"
                    validate={props.errors.newPassword}
                    submitCount={props.submitCount}
                    hasFeedback
                  />
                  <Field
                    component={AntPassword}
                    name="newPassword2"
                    type="password"
                    placeholder="Powtórz nowe hasło"
                    validate={props.errors.newPassword2}
                    submitCount={props.submitCount}
                    hasFeedback
                  />
                </Form>
              );
            }}
          </Formik>
        </div>
      </Modal>
    );
  return null;
};

export default ChangePasswordModal;
