import React, { useState } from 'react';
import { Input, message, Modal } from 'antd';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { loadUserData } from 'services/store/actions/auth';
import { setLoading } from 'services/store/actions/view';
import { IUser } from 'services/store/types/users/Users';
import setAuthToken from 'services/utils/setAuthToken';

interface IProps {
  closeModal: () => void;
  user: IUser;
}

const StartProcessModal: React.FC<IProps> = ({ closeModal, user }) => {
  const dispatch = useDispatch();
  const [action, setAction] = useState<string>('');

  const handleOk = async () => {
    if (!user.workingOn?.project) {
      dispatch(setLoading(true));
      try {
        setAuthToken();

        await axios.patch(`/api/users/start-working-on`, {
          position: user.activePosition,
          action,
        });

        dispatch(loadUserData());
        message.success('Proces rozpoczęty');
      } catch (error: any) {
        console.log(error, error.response);
        message.error('Błąd');
      }
      dispatch(setLoading(false));
      closeModal();
    }
  };

  return (
    <Modal
      title="Rozpoczynanie procesu"
      destroyOnClose
      visible
      onCancel={closeModal}
      maskClosable={false}
      onOk={handleOk}
      okButtonProps={{ disabled: !user?.activePosition || !action }}
    >
      <div style={{ textAlign: 'center', marginBottom: 20 }}>
        Rozpoczniesz proces nieprzypisany do żadnego projektu.
      </div>
      <Input
        value={action}
        onChange={(e) => setAction(e.target.value)}
        placeholder="Wykonywana akcja"
        max={50}
      />
    </Modal>
  );
};

export default StartProcessModal;
