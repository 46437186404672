import { OrderStatus } from 'services/store/enums/orders.enum';
import {
  HomePageKey,
  OrderPageKey,
  OrdersKey,
  ProjectPageKey,
  ProjectProductionEditView,
  ProjectsKey,
} from 'services/store/enums/view.enum';

export const VIEW_SET_SPINNER = 'VIEW_SET_SPINNER';
export const VIEW_SET_PROJECTS_KEY = 'VIEW_SET_PROJECTS_KEY';
export const VIEW_SET_HOME_PAGE_KEY = 'VIEW_SET_HOME_PAGE_KEY';
export const VIEW_SET_ORDERS_KEY = 'VIEW_SET_ORDERS_KEY';
export const VIEW_SET_PROJECT_PAGE_KEY = 'VIEW_SET_PROJECT_PAGE_KEY';
export const VIEW_SET_ORDER_PAGE_KEY = 'VIEW_SET_ORDER_PAGE_KEY';
export const VIEW_SET_ORDER_STATISTICS_PAGE_KEY =
  'VIEW_SET_ORDER_STATISTICS_PAGE_KEY';
export const VIEW_SET_PROJECT_PRODUCTION_EDIT_VIEW =
  'VIEW_SET_PROJECT_PRODUCTION_EDIT_VIEW';
export const VIEW_SET_PROJECT_PRODUCTION_EDIT_CELL_WIDTH =
  'VIEW_SET_PROJECT_PRODUCTION_EDIT_CELL_WIDTH';

export interface ISetLoading {
  type: typeof VIEW_SET_SPINNER;
  isLoading: boolean;
}

export interface ISetProjectsKey {
  type: typeof VIEW_SET_PROJECTS_KEY;
  projectsKey: ProjectsKey;
}
export interface ISetHomePageKey {
  type: typeof VIEW_SET_HOME_PAGE_KEY;
  homePageKey: HomePageKey;
}

export interface ISetOrdersKey {
  type: typeof VIEW_SET_ORDERS_KEY;
  ordersKey: OrdersKey;
}
export interface ISetOrdersStatisticsPageKey {
  type: typeof VIEW_SET_ORDER_STATISTICS_PAGE_KEY;
  ordersStatisticsPageKey: OrderStatus;
}

export interface ISetProjectPageKey {
  type: typeof VIEW_SET_PROJECT_PAGE_KEY;
  projectPageKey: ProjectPageKey;
}

export interface ISetOrderPageKey {
  type: typeof VIEW_SET_ORDER_PAGE_KEY;
  orderPageKey: OrderPageKey;
}

export interface ISetProjectProductionEditView {
  type: typeof VIEW_SET_PROJECT_PRODUCTION_EDIT_VIEW;
  projectProductionEditView: ProjectProductionEditView;
}

export interface ISetProjectProductionEditCellWidth {
  type: typeof VIEW_SET_PROJECT_PRODUCTION_EDIT_CELL_WIDTH;
  projectProductionEditCellWidth: number;
}

export type TViewActions =
  | ISetHomePageKey
  | ISetOrdersStatisticsPageKey
  | ISetProjectProductionEditCellWidth
  | ISetProjectProductionEditView
  | ISetOrderPageKey
  | ISetLoading
  | ISetProjectsKey
  | ISetOrdersKey
  | ISetProjectPageKey;
