import { useCallback, useEffect, useState } from 'react';
import { AppstoreAddOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Button, PageHeader, Row, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { H1 } from 'components/Header';
import AccessoryModal from 'components/Modals/AccessoryModal/Accessory.modal';
import CreateCategoryModal from 'components/Modals/CreateCategoryModal/CreateCategory.modal';
import { useModal } from 'hook/useModals';
import { getCategoriesByType } from 'services/requests/settings.request';
import { TAppState } from 'services/store';
import { getAllAccessories } from 'services/store/actions/accessories';
import { setLoading } from 'services/store/actions/view';
import { CategoryType } from 'services/store/enums/categories.enum';
import { Positions } from 'services/store/enums/users.enum';
import { ICategory } from 'services/store/types/settings/Settings';
import { isUserHasPermission } from 'services/utils/users';
import PageTemplate from 'templates/PageTemplate';
import AccessoriesList from './components/AccessoriesList';

const { Option } = Select;

const AccessoriesPage = () => {
  const dispatch = useDispatch();
  const { modal, showModal, closeModal } = useModal({
    createAccessory: false,
    createCategory: false,
  });
  const user = useSelector((state: TAppState) => state.auth.user);
  const accessories = useSelector(
    (state: TAppState) => state.accessories.accessories
  );

  const [categories, setCategories] = useState<ICategory[]>([]);
  const [category, setCategory] = useState<undefined | string>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const getCategories = useCallback(async () => {
    setLoading(true);
    const data = await getCategoriesByType(CategoryType.ACCESSORY);
    setCategories(data);
    setLoading(false);
  }, []);

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getAllAccessories({ category }, () => setIsLoading(false)));
  }, [category]);

  return (
    <PageTemplate>
      <PageHeader
        title={<H1>Akcesoria</H1>}
        extra={
          isUserHasPermission(user, [
            Positions.DESIGNER,
            Positions.TECHNOLOGIST,
          ]) && [
            <Button
              key="1"
              type="primary"
              onClick={() => showModal('createAccessory')}
            >
              <AppstoreAddOutlined />
              Dodaj akcesorium
            </Button>,
            <Button key="2" onClick={() => showModal('createCategory')}>
              <UnorderedListOutlined />
              Dodaj kategorię
            </Button>,
          ]
        }
      />
      <Row justify="end">
        <Select
          value={category}
          onChange={(value: string) => setCategory(value)}
          placeholder="Kategoria"
          allowClear
          style={{ minWidth: 200, marginBottom: 10 }}
        >
          {categories
            .filter((cat: ICategory) => cat.type === CategoryType.ACCESSORY)
            .map((cat: ICategory) => (
              <Option key={cat._id} value={cat._id}>
                {cat.name}
              </Option>
            ))}
        </Select>
      </Row>
      <AccessoriesList accessories={accessories} isLoading={isLoading} />

      {/* MDOALS */}
      {modal.createAccessory && <AccessoryModal closeModal={closeModal} />}
      {modal.createCategory && <CreateCategoryModal closeModal={closeModal} />}
    </PageTemplate>
  );
};

export default AccessoriesPage;
