import React, { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import {
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Space,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import * as yup from 'yup';
import AntDatePicker from 'components/DatePicker/DatePicker';
import PickProjectModal from 'components/Modals/PickProjectModal/PickProject.modal';
import UserPositionTag from 'components/Tags/UserPositionTag/UserPositionTag';
import { IWorkTimeUpdate, workTimesApi } from 'services/api/workTimes.api';
import { IProject } from 'services/store/types/projects/Projects';
import { IWorkTime } from 'services/store/types/statistics/Statistics';
import { getDuration, getDurationText } from 'services/utils/dates.utils';
import { statisticsUtils } from 'services/utils/statistics.utils';
import { getProjectName, getUserName } from 'services/utils/string';

const schema = yup.object({
  start: yup
    .date()
    .typeError('Wartość musi być datą')
    .required('Wartość jest wymagana')
    .max(yup.ref('finish'), `Data jest nieprawidłowa`),
  finish: yup
    .date()
    .typeError('Wartość musi być datą')
    .required('Wartość jest wymagana')
    .min(yup.ref('start'), `Data jest nieprawidłowa`),
});

interface IFormValues
  extends Pick<IWorkTime, 'start' | 'finish' | 'action' | 'cost'> {}

interface IProps {
  workTime: IWorkTime;
  closeModal: () => void;
  refetchStatistics: () => Promise<any>;
}

const UpdateWorkTimeModal: React.FC<IProps> = ({
  workTime,
  closeModal,
  refetchStatistics,
}) => {
  const [pickProjectModal, setPickProjectModal] = useState(false);
  const [pickedProject, setPickedProject] = useState<IProject | undefined>(
    workTime.project ? (workTime.project as IProject) : undefined
  );

  const [earnings, setEarnings] = useState(workTime.user?.earnings || 0);

  const { mutate: updateWorkTime, isLoading } = useMutation({
    mutationKey: ['update-work-time'],
    mutationFn: (values: IFormValues) => {
      const updateData: IWorkTimeUpdate = { ...values };
      if (pickedProject?._id) {
        updateData.project = pickedProject._id;
      }
      return workTimesApi.updateWorkTime(workTime._id, updateData);
    },
    onSuccess: async (res) => {
      await refetchStatistics();
      message.success('Rekord zaktualizowany');
      closeModal();
    },
    onError: (err: any) => {
      console.log(err, err.response);
      message.error('Błąd!');
    },
  });

  const formik = useFormik<IFormValues>({
    initialValues: {
      start: workTime.start,
      finish: workTime.finish,
      action: workTime.action,
      cost: workTime.cost,
    },
    onSubmit: (values) => updateWorkTime(values),
    validationSchema: schema,
  });

  useEffect(() => {
    if (earnings && formik.values.start && formik.values.finish) {
      const durationInMs = getDuration(
        formik.values.start,
        formik.values.finish
      ).getTime();
      const hours = Math.round(((durationInMs / (1000 * 60 * 60)) * 2) / 2);

      const cost = hours * earnings;
      formik.setFieldValue('cost', cost);
    }
  }, [earnings, formik.values.start, formik.values.finish]);

  console.log(workTime.user);
  return (
    <Modal
      title="Aktualizacja rekordu"
      destroyOnClose
      open
      onCancel={closeModal}
      maskClosable={false}
      onOk={() => formik.handleSubmit()}
      okButtonProps={{ loading: isLoading, disabled: !formik.isValid }}
      cancelButtonProps={{ disabled: isLoading }}
    >
      <Space direction="vertical">
        <div>
          {getUserName(workTime.user)} -{' '}
          <UserPositionTag position={workTime.position} />
        </div>
        <Space>
          <Typography.Text>
            Projekt: {pickedProject?.number || '-'}
          </Typography.Text>
          <Button size="small" onClick={() => setPickProjectModal(true)}>
            Zmień
          </Button>
        </Space>
      </Space>
      <Divider />
      <Form labelCol={{ xs: { span: 24 }, sm: { span: 4 } }}>
        <Form.Item
          validateStatus={formik.errors.start && 'error'}
          help={formik.errors.start}
          label="Start"
          required
        >
          <AntDatePicker
            size="middle"
            value={dayjs(formik.values.start)}
            onChange={(date) => formik.setFieldValue('start', date?.toDate())}
            allowClear={false}
            showTime
            showHour
            showMinute
            format="DD.MM.YYYY, HH:mm"
          />
        </Form.Item>
        <Form.Item
          validateStatus={formik.errors.finish && 'error'}
          help={formik.errors.finish}
          label="Koniec"
          required
        >
          <AntDatePicker
            size="middle"
            value={dayjs(formik.values.finish)}
            onChange={(date) => formik.setFieldValue('finish', date?.toDate())}
            allowClear={false}
            showTime
            showHour
            showMinute
            format="DD.MM.YYYY, HH:mm"
          />
        </Form.Item>
        <Form.Item
          validateStatus={formik.errors.action && 'error'}
          help={formik.errors.action}
          label="Akcja"
        >
          <Input
            onChange={(e) => formik.setFieldValue('action', e.target.value)}
            value={formik.values.action}
            placeholder="Akcja"
          />
        </Form.Item>
        <Form.Item label="Stawka">
          <InputNumber onChange={(v) => v && setEarnings(v)} value={earnings} />
        </Form.Item>
        <Space direction="vertical">
          <Typography.Text type="secondary">
            Czas pracy:{' '}
            {statisticsUtils.workTimes.getDuraionText(
              formik.values.start,
              formik.values.finish
            )}
          </Typography.Text>

          <Typography.Text type="secondary">
            Koszt: {formik.values.cost}zł
          </Typography.Text>
        </Space>
      </Form>

      {pickProjectModal && (
        <PickProjectModal
          closeModal={() => setPickProjectModal(false)}
          pickProject={setPickedProject}
        />
      )}
    </Modal>
  );
};

export default UpdateWorkTimeModal;
