import React from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Form, Input, message, Modal, Select, Typography } from 'antd';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import * as yup from 'yup';
import AntDatePicker from 'components/DatePicker/DatePicker';
import UserPositionTag from 'components/Tags/UserPositionTag/UserPositionTag';
import { useControledDebounce } from 'hook/useControledDebounce';
import { projectsApi } from 'services/api/projects.api';
import { IWorkTimeCreate, workTimesApi } from 'services/api/workTimes.api';
import { Positions } from 'services/store/enums/users.enum';
import { IUser } from 'services/store/types/users/Users';
import { _userPositions } from 'services/utils/const';
import { getDuration } from 'services/utils/dates.utils';
import { sortingFuncion } from 'services/utils/sort.utils';
import { statisticsUtils } from 'services/utils/statistics.utils';
import { getUserName } from 'services/utils/string';

const schema = yup.object({
  start: yup
    .date()
    .typeError('Wartość musi być datą')
    .required('Wartość jest wymagana')
    .max(yup.ref('finish'), `Data jest nieprawidłowa`),
  finish: yup
    .date()
    .typeError('Wartość musi być datą')
    .required('Wartość jest wymagana')
    .min(yup.ref('start'), `Data jest nieprawidłowa`),
  action: yup.string().max(250, 'Maksymalna długość to 250 znaków'),
  user: yup.string().required('Wartość jest wymagana'),
  position: yup.string().required('Wartość jest wymagana'),
});

interface IFormValues
  extends Omit<IWorkTimeCreate, 'user' | 'position' | 'duration'> {
  user?: string;
  position?: Positions;
}

interface IProps {
  users: IUser[];
  pickedUser?: string;
  closeModal: () => void;
  refetchStatistics: () => Promise<any>;
}

const CreateWorkTimeModal: React.FC<IProps> = ({
  users,
  pickedUser,
  closeModal,
  refetchStatistics,
}) => {
  const {
    value: projectNumber,
    debouncedValue: debouncedProjectNumber,
    handleDebouncedValue,
  } = useControledDebounce();

  const projects = useQuery({
    queryKey: ['get-projects-numbers', debouncedProjectNumber],
    queryFn: () => {
      if (debouncedProjectNumber)
        return projectsApi.getProjectsNumbers(debouncedProjectNumber);
    },
    onError: () => {
      message.error('Błąd');
    },
    enabled: !!debouncedProjectNumber,
  });

  const { mutate: createWorkTime, isLoading } = useMutation({
    mutationKey: ['create-work-time'],
    mutationFn: (values: IWorkTimeCreate) =>
      workTimesApi.createWorkTime(values),
    onSuccess: async (res) => {
      await refetchStatistics();
      message.success('Rekord utworzony');
      closeModal();
    },
    onError: (err: any) => {
      console.log(err, err.response);
      message.error('Błąd!');
    },
  });

  const formik = useFormik<IFormValues>({
    initialValues: {
      start: dayjs().toDate(),
      finish: dayjs().add(1, 'h').toDate(),
      user: pickedUser || undefined,
      position: undefined,
      project: undefined,
      action: '',
    },
    onSubmit: (values) => {
      const duration = getDuration(values.start, values.finish);
      return createWorkTime({
        ...values,
        user: values.user as string,
        position: values.position as Positions,
        duration,
      });
    },
    validationSchema: schema,
  });

  const getUserPositions = () => {
    if (formik.values.user) {
      const userData = users.find((user) => user._id === formik.values.user);
      if (userData) return userData.positions;
    }
    return [];
  };

  return (
    <Modal
      title="Nowy rekord"
      destroyOnClose
      open
      onCancel={closeModal}
      maskClosable={false}
      onOk={() => formik.handleSubmit()}
      okButtonProps={{ loading: isLoading, disabled: !formik.isValid }}
      cancelButtonProps={{ disabled: isLoading }}
    >
      <Form labelCol={{ xs: { span: 24 }, sm: { span: 5 } }}>
        <Form.Item
          validateStatus={formik.errors.user && 'error'}
          help={formik.errors.user}
          label="Pracownik"
          required
        >
          <Select
            onChange={(value) => formik.setFieldValue('user', value)}
            value={formik.values.user}
            allowClear={true}
            placeholder="Pracownik"
          >
            {users
              .sort(sortingFuncion.users.byFirstName)
              .map((employee: IUser) => (
                <Select.Option key={employee._id} value={employee._id}>
                  {getUserName(employee, 'f-l')}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          validateStatus={formik.errors.position && 'error'}
          help={formik.errors.position}
          label="Stanowisko"
          required
        >
          <Select
            onChange={(value) => formik.setFieldValue('position', value)}
            value={formik.values.position}
            allowClear={true}
            placeholder="Stanowisko"
            disabled={!formik.values.user || !getUserPositions()?.length}
          >
            {getUserPositions().map((position: Positions) => (
              <Select.Option key={position} value={position}>
                {position}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          validateStatus={formik.errors.project && 'error'}
          help={formik.errors.project}
          label="Projekt"
        >
          <Select
            showSearch
            value={formik.values.project}
            placeholder="Wyszukaj..."
            allowClear
            defaultActiveFirstOption={false}
            filterOption={false}
            style={{ maxWidth: 370 }}
            notFoundContent="Wpisz fragment numeru projektu"
            loading={projects.isFetching}
            onSearch={handleDebouncedValue}
            onChange={(value) => formik.setFieldValue('project', value)}
            options={(projects.data?.data || []).map((project) => ({
              value: project._id,
              label: project.number,
            }))}
          />
        </Form.Item>
        <Form.Item
          validateStatus={formik.errors.start && 'error'}
          help={formik.errors.start}
          label="Start"
          required
        >
          <AntDatePicker
            value={dayjs(formik.values.start)}
            onChange={(date) => formik.setFieldValue('start', date?.toDate())}
            allowClear={false}
            showTime
            showHour
            showMinute
            format="DD.MM.YYYY, HH:mm"
          />
        </Form.Item>
        <Form.Item
          validateStatus={formik.errors.finish && 'error'}
          help={formik.errors.finish}
          label="Koniec"
          required
        >
          <AntDatePicker
            value={dayjs(formik.values.finish)}
            onChange={(date) => formik.setFieldValue('finish', date?.toDate())}
            allowClear={false}
            showTime
            showHour
            showMinute
            format="DD.MM.YYYY, HH:mm"
          />
        </Form.Item>
        <Form.Item
          validateStatus={formik.errors.action && 'error'}
          help={formik.errors.action}
          label="Akcja"
        >
          <Input
            onChange={(e) => formik.setFieldValue('action', e.target.value)}
            value={formik.values.action}
            placeholder="Akcja"
          />
        </Form.Item>
        <Typography.Text type="secondary">
          Czas pracy:{' '}
          {statisticsUtils.workTimes.getDuraionText(
            formik.values.start,
            formik.values.finish
          )}
        </Typography.Text>
      </Form>
    </Modal>
  );
};

export default CreateWorkTimeModal;
