import React, { useCallback, useEffect, useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { message, Modal, PageHeader, Tabs } from 'antd';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import AddCommentModal from 'components/Coments/CommentsList/AddComment/AddComment.modal';
import CustomerData from 'components/CustomerData/CustomerData';
import AntDivider from 'components/Divider/Divider';
import { H1 } from 'components/Header';
import AddressModal from 'components/Modals/AddressModal/AddressModal';
import OrderStatusTag from 'components/Tags/OrderStatusTag/OrderStatusTag';
import { useModal } from 'hook/useModals';
import useUser from 'hook/useUser';
import { updateOrder } from 'services/requests/orders.request';
import { TAppState } from 'services/store';
import { clearProductionNodes } from 'services/store/actions/productionNodes';
import { setLoading, setOrderPageKey } from 'services/store/actions/view';
import { HistoryColor } from 'services/store/enums/historyColor.enum';
import { Permissions, Positions } from 'services/store/enums/users.enum';
import { OrderPageKey } from 'services/store/enums/view.enum';
import { IAlert } from 'services/store/interfaces/alert.interface';
import { IComment } from 'services/store/interfaces/comment.interface';
import { IAddress } from 'services/store/types/address/Address';
import { IOrder } from 'services/store/types/orders/Orders';
import { IProject } from 'services/store/types/projects/Projects';
import setAuthToken from 'services/utils/setAuthToken';
import { getUserName } from 'services/utils/string';
import { isUserHasPermission } from 'services/utils/users';
import PageTemplate from 'templates/PageTemplate';
import AddOrderCost from './components/Modals/AddOrderCost.modal';
import AddOrderDocumentModal from './components/Modals/AddOrderDocument.modal';
import ChangeOrderNameModal from './components/Modals/ChangeOrderName.modal';
import ChangeOrderNumberModal from './components/Modals/ChangeOrderNumber.modal';
import ChangePaymentsPartsModal from './components/Modals/ChangeOrderPaymentsParts.modal';
import ChangeOrderPriceModal from './components/Modals/ChangeOrderPrice.modal';
import OrderActions from './components/OrderActions';
import OrderCosts from './components/OrderCosts';
import OrderDashboard from './components/OrderDashboard';
import OrderDocuments from './components/OrderDocuments';
import OrderHeader from './components/OrderHeader';
import OrderHistory from './components/OrderHistory';
import OrderPayments from './components/OrderPayments';
import OrderStatistics from './components/OrderStatistics/OrderStatistics';
import { initOrderPageModals } from './data/initModals.data';

const { TabPane } = Tabs;

const OrderPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id }: { id: string } = useParams();
  const { modal, showModal, closeModal } = useModal(initOrderPageModals);

  const { user, isAdmin } = useUser();
  const tabKey = useSelector((state: TAppState) => state.view.orderPageKey);

  const [order, setOrder] = useState<IOrder>();
  const [projects, setProjects] = useState<IProject[]>([]);
  const [alerts, setAlerts] = useState<IAlert[]>([]);

  // --------------------------------------------------------------------------
  // NOTE Fetch data
  // --------------------------------------------------------------------------
  const getOrder = useCallback(async () => {
    dispatch(setLoading(true));
    try {
      setAuthToken();
      const res = await axios.get(`/api/orders/${id}`);
      setOrder(res.data);
    } catch (error: any) {
      console.log(error, error.response);
      message.error('Błąd');
    }
    dispatch(setLoading(false));
  }, [id]);

  const getOrderAlerts = useCallback(async () => {
    dispatch(setLoading(true));
    try {
      setAuthToken();
      const res = await axios.get(`/api/orders/${id}/alerts`);
      setAlerts(res.data);
    } catch (error: any) {
      console.log(error, error.response);
      message.error('Błąd');
    }
    dispatch(setLoading(false));
  }, [id]);

  const getProjects = useCallback(async () => {
    dispatch(setLoading(true));
    try {
      setAuthToken();
      const res = await axios.get(`/api/projects`, {
        params: { order: id, unpaged: true },
      });
      setProjects(res.data.content);
    } catch (error: any) {
      console.log(error, error.response);
      message.error('Błąd');
    }
    dispatch(setLoading(false));
  }, [id]);

  // --------------------------------------------------------------------------
  // NOTE Lifecycle
  // --------------------------------------------------------------------------
  useEffect(() => {
    getOrder();
    getProjects();
    getOrderAlerts();
  }, [getOrder, getProjects, getOrderAlerts]);

  // --------------------------------------------------------------------------
  // NOTE HANDLERS
  // --------------------------------------------------------------------------
  const handleTabKey = (key: OrderPageKey) => {
    dispatch(setOrderPageKey(key));
  };

  const goToProjectCreate = () => {
    history.push(`/projects/create/${id}`);
  };

  const removeOrder = async () => {
    dispatch(setLoading(true));
    try {
      setAuthToken();
      await axios.delete(`/api/orders/${id}`);

      message.success('Zamówienie i jego projekty zostało usunięte');
      dispatch(clearProductionNodes());
      history.push(`/`);
    } catch (error: any) {
      console.log(error, error.response);
      message.error('Błąd');
    }
    dispatch(setLoading(false));
  };

  const handleRemoveOrder = () =>
    Modal.confirm({
      title: 'Jesteś pewien że chcesz usunąć to zamówienie?',
      icon: <ExclamationCircleOutlined />,
      content:
        'Zostaną usunięte wszystkie dane, pliki i projekty wraz z całą zawartością. Zmiany są nieodwracalne',
      onOk() {
        removeOrder();
      },
      onCancel() {},
    });

  const handleUpdateOrder = async (
    updateData: Partial<IOrder>,
    historyUpdate: string[],
    historyColor: HistoryColor
  ) => {
    dispatch(setLoading(true));
    try {
      const updatedOrder = await updateOrder(
        id,
        updateData,
        historyUpdate,
        historyColor
      );
      setOrder(updatedOrder);
      getOrderAlerts();
    } catch (error: any) {
      console.log(error);
    }
    dispatch(setLoading(false));
  };

  const handleUpdateAddress = async (address: IAddress) => {
    if (order) {
      const historyUpdate = [`Zmieniono adres montażu`];

      await handleUpdateOrder({ address }, historyUpdate, HistoryColor.SUCCESS);
    }
  };

  const handleAddComment = async (comment: IComment) => {
    if (order) {
      const historyUpdate = [`Dodano komentarz`];
      const newComments = [...order.comments, comment];

      await handleUpdateOrder(
        { comments: newComments },
        historyUpdate,
        HistoryColor.WARNING
      );
    }
  };

  const handleRemoveComment = async (comment: string) => {
    if (user?.permission !== Permissions.ADMIN)
      return message.error('Nie możesz tego zrobić');
    if (order) {
      const historyUpdate = [`Usunięto komentarz`];
      const newComments = order.comments.filter(
        (comm) => comm.comment !== comment
      );

      await handleUpdateOrder(
        { comments: newComments },
        historyUpdate,
        HistoryColor.WARNING
      );
    }
  };

  console.log('ORDER: ', order);
  console.log('PROJECTS:', projects);
  if (!order) return null;
  return (
    <PageTemplate>
      <PageHeader
        title={<H1>Zamówienie</H1>}
        subTitle={
          order.customer ? getUserName(order.customer) : 'NO CLIENT DATA'
        }
        tags={<OrderStatusTag status={order.status} />}
        // FIXME Zmienic orderActions z funkcji na komponent
        extra={
          user && (
            <OrderActions
              order={order}
              user={user}
              isAdmin={isAdmin()}
              actions={{
                showModal,
                handleRemoveOrder,
                goToProjectCreate,
              }}
            />
          )
        }
      />
      {/* NOTE ORDER HEADER */}
      <OrderHeader
        order={order}
        user={user}
        projectsCount={projects.length}
        isAdmin={isAdmin()}
      />
      <AntDivider marginTop={50} />

      {/* NOTE ORDER DATA */}
      <Tabs
        defaultActiveKey={tabKey}
        onChange={(key) => handleTabKey(key as OrderPageKey)}
        size="large"
        style={{ marginBottom: 32 }}
      >
        {/* NOTE Dashboard */}
        <TabPane tab="Dashboard" key="dashboard">
          <OrderDashboard
            user={user}
            order={order}
            projects={projects}
            alerts={alerts}
            showModal={showModal}
            getOrderAlerts={getOrderAlerts}
            handleRemoveComment={handleRemoveComment}
          />
        </TabPane>

        {/* NOTE Customer data */}
        <TabPane tab="Dane klienta" key="customer">
          <CustomerData customer={order.customer} />
        </TabPane>

        {/* NOTE Documents */}
        {isUserHasPermission(user, [
          Positions.DESIGNER,
          Positions.TECHNOLOGIST,
        ]) && (
          <TabPane tab="Dokumenty" key="documents">
            <OrderDocuments
              order={order}
              handleUpdateOrder={handleUpdateOrder}
            />
          </TabPane>
        )}

        {/* NOTE Payments */}
        {isAdmin() && (
          <TabPane tab="Płatności" key="payments">
            <OrderPayments
              order={order}
              handleUpdateOrder={handleUpdateOrder}
            />
          </TabPane>
        )}

        {/* NOTE Costs */}
        {isAdmin() && (
          <TabPane tab="Koszty" key="costs">
            <OrderCosts order={order} handleUpdateOrder={handleUpdateOrder} />
          </TabPane>
        )}

        {/* NOTE history */}
        {isUserHasPermission(user, []) && (
          <TabPane tab="Statystyki" key={OrderPageKey.STATISTICS}>
            <OrderStatistics order={order} />
          </TabPane>
        )}

        {/* NOTE history */}
        {isUserHasPermission(user, []) && (
          <TabPane tab="Historia" key={OrderPageKey.HISTORY}>
            <OrderHistory order={order} />
          </TabPane>
        )}
      </Tabs>

      {/* NOTE MODALS */}
      {modal.changeName && (
        <ChangeOrderNameModal
          order={order}
          closeModal={closeModal}
          handleUpdateOrder={handleUpdateOrder}
        />
      )}
      {modal.changeNumber && (
        <ChangeOrderNumberModal
          order={order}
          closeModal={closeModal}
          handleUpdateOrder={handleUpdateOrder}
        />
      )}
      {modal.changeAddress && (
        <AddressModal
          address={order.address}
          title="Zmiana adresu montażu zamówienia"
          closeModal={closeModal}
          onSubmit={handleUpdateAddress}
        />
      )}
      {modal.editPayments && (
        <ChangePaymentsPartsModal
          order={order}
          closeModal={closeModal}
          handleUpdateOrder={handleUpdateOrder}
        />
      )}
      {modal.editPrice && (
        <ChangeOrderPriceModal
          order={order}
          closeModal={closeModal}
          handleUpdateOrder={handleUpdateOrder}
        />
      )}
      {modal.addCost && (
        <AddOrderCost
          order={order}
          closeModal={closeModal}
          handleUpdateOrder={handleUpdateOrder}
        />
      )}
      {modal.addDocument && (
        <AddOrderDocumentModal
          order={order}
          closeModal={closeModal}
          handleUpdateOrder={handleUpdateOrder}
        />
      )}
      {modal.addComment && (
        <AddCommentModal
          closeModal={closeModal}
          handleAddComment={handleAddComment}
        />
      )}
    </PageTemplate>
  );
};

export default OrderPage;
