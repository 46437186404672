import React, { useEffect, useState } from 'react';
import { Select, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { TAppState } from 'services/store';
import { getAllUsers } from 'services/store/actions/users';
import { setLoading } from 'services/store/actions/view';
import { HistoryColor } from 'services/store/enums/historyColor.enum';
import { Positions } from 'services/store/enums/users.enum';
import { IProject } from 'services/store/types/projects/Projects';
import { IUser } from 'services/store/types/users/Users';
import { capitalizeFirstLetter } from 'services/utils/string';

const { Option } = Select;

interface IProps {
  project: IProject;
  closeModal: () => void;
  handleUpdateProject: (
    updateData: Partial<IProject>,
    historyUpdate: string[],
    historyColor: HistoryColor
  ) => Promise<any>;
}

const ChangeProjectTechnologistModal: React.FC<IProps> = ({
  project,
  closeModal,
  handleUpdateProject,
}) => {
  const dispatch = useDispatch();
  const users = useSelector((state: TAppState) => state.users.users);
  const [technologist, setTechnologist] = useState(project.technologist);

  useEffect(() => {
    if (!users.length) {
      dispatch(setLoading(true));
      dispatch(getAllUsers());
      dispatch(setLoading(false));
    }
  }, []);

  const handlesetTechnologist = (_id: string) => {
    const tech = users.find((u) => u._id === _id);
    setTechnologist(tech);
  };

  const onOk = async () => {
    const historyUpdate = [
      `Zmieniono technologa.`,
      `Z ${project.technologist?.firstName} ${project.technologist?.lastName} na ${technologist?.firstName} ${technologist?.lastName}`,
    ];

    await handleUpdateProject(
      { technologist },
      historyUpdate,
      HistoryColor.WARNING
    );
    closeModal();
  };

  return (
    <Modal
      title="Zmiana technologa"
      destroyOnClose
      visible
      onCancel={closeModal}
      maskClosable={false}
      onOk={onOk}
    >
      <Select
        value={technologist?._id}
        onChange={(value) => handlesetTechnologist(value)}
        placeholder="Technolog"
        style={{ width: '100%' }}
      >
        {users
          .filter((u) => u.positions.includes(Positions.TECHNOLOGIST))
          .sort((a, b) => `${a.lastName}`.localeCompare(b.lastName))
          .map((item: IUser) => {
            if (item._id)
              return (
                <Option key={item._id} value={item._id}>
                  {`${capitalizeFirstLetter(item.lastName)}
                  ${capitalizeFirstLetter(item.firstName)}`}
                </Option>
              );
          })}
      </Select>
    </Modal>
  );
};

export default ChangeProjectTechnologistModal;
