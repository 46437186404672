import React from 'react';
import styled from 'styled-components';

const Dot = styled.div`
  @keyframes up-right {
    0% {
      transform: scale(1);
      opacity: 0.25;
    }
    50% {
      transform: scale (1, 5);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0.25;
    }
  }
  &.circle {
    display: inline-block;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    opacity: 0.25;
  }
  &.green {
    background-color: green;
    -webkit-animation: up-right 1s infinite;
    -moz-animation: up-right 1s infinite;
    -o-animation: up-right 1s infinite;
    animation: up-right 1s infinite;
  }
`;

const BlinkingDot = () => {
  return <Dot className="circle green"></Dot>;
};

export default BlinkingDot;
