import * as yup from 'yup';

export const userCreateValidation = yup.object({
  firstName: yup
    .string()
    .matches(/^[a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ]+$/, 'Wyłącznie litery')
    .required('Imię jest wymagane'),
  lastName: yup
    .string()
    .required('Nazwisko jest wymagane')
    .matches(/^[a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ\-]+$/, 'Wyłącznie litery'),
  phone: yup
    .string()
    .length(9, 'Nr telefonu musi składać się z 9 znaków')
    .matches(
      /^\(?([0-9]{3})\)?([ .-]?)([0-9]{3})([ .-]?)([0-9]{3})$/,
      'Tylko cyfry'
    ),
  email: yup
    .string()
    .email('Format adresu email jest nieprawidłowy')
    .required('Email jest wymagany'),
  earnings: yup.number(),
  password: yup
    .string()
    .min(6, 'Hasło musi składać się z min. 6 znaków')
    .required('Hasło jest wymagane'),
  password2: yup.string().required('Hasło jest wymagane'),
  permission: yup.string().required('Uprawnienia są wymagane'),
});
