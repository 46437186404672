import { useCallback, useEffect, useState } from 'react';
import {
  ArrowLeftOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Empty, message, Modal, PageHeader, Tabs } from 'antd';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import AddCommentModal from 'components/Coments/CommentsList/AddComment/AddComment.modal';
import CustomerData from 'components/CustomerData/CustomerData';
import AntDivider from 'components/Divider/Divider';
import { H1, H5 } from 'components/Header';
import AddScheduleItemModal from 'components/Modals/ScheduleModal/AddScheduleItem.modal';
import ProjectStatusTag from 'components/Tags/ProjectStatusTag/ProjectStatusTag';
import UserPositionTag from 'components/Tags/UserPositionTag/UserPositionTag';
import { useModal } from 'hook/useModals';
import { checkIsOrdersStatusUpdateIsNeededAndUpdate } from 'services/requests/orders.request';
import { TAppState } from 'services/store';
import { loadUserData } from 'services/store/actions/auth';
import {
  clearProductionNodes,
  getAllProductionNodes,
} from 'services/store/actions/productionNodes';
import { setLoading, setProjectPageKey } from 'services/store/actions/view';
import { HistoryColor } from 'services/store/enums/historyColor.enum';
import { ProjectStatus } from 'services/store/enums/project.enum';
import { Permissions, Positions } from 'services/store/enums/users.enum';
import { ProjectPageKey } from 'services/store/enums/view.enum';
import { IAlert } from 'services/store/interfaces/alert.interface';
import { IComment } from 'services/store/interfaces/comment.interface';
import { IPaintedElementsOrder } from 'services/store/types/projects/paintedElementsOrder.interface';
import { IProject } from 'services/store/types/projects/Projects';
import { ISchedule } from 'services/store/types/schedule/Schedule';
import { IWorkAction } from 'services/store/types/settings/Settings';
import { IWorkTime } from 'services/store/types/statistics/Statistics';
import { _projectStatuses } from 'services/utils/const';
import {
  getPositionFromProjectStatus,
  getProjectStatusFromActivePosition,
  isProjectOnActivePosition,
  isSomeoneWorkingOnProjectAtPosition,
  sortProjectStatusByProductionProcess,
} from 'services/utils/project.utils';
import setAuthToken from 'services/utils/setAuthToken';
import { getUserName } from 'services/utils/string';
import { isUserHasPermission } from 'services/utils/users';
import PageTemplate from 'templates/PageTemplate';
import AddProjectEquipmentModal from './components/Modals/AddProjectEquipment.modal';
import AddProjectFileModal from './components/Modals/AddProjectFile.modal';
import AddProjectImageModal from './components/Modals/AddProjectImage.modal';
import ProjectAddPaintedElementsOrderModal from './components/Modals/AddProjectPaintedElementsOrder.modal';
import ChangeCreationDateModal from './components/Modals/ChangeCreationDate.modal';
import ChangeProjectDesignerModal from './components/Modals/ChangeProjectDesigner.modal';
import ChangeProjectMontageDateModal from './components/Modals/ChangeProjectMontageDate.modal';
import ProjectChangeNameModal from './components/Modals/ChangeProjectName.modal';
import ChangeProjectNumberModal from './components/Modals/ChangeProjectNumber.modal';
import ChangeProjectStatusModal from './components/Modals/ChangeProjectStatus.modal';
import ChangeProjectTechnologistModal from './components/Modals/ChangeProjectTechnologist.modal';
import ProjectEditAccessoriesModal from './components/Modals/EditProjectAccessories.modal';
import SetProjectProductionDaysModal from './components/Modals/SetProjectProductionDays.modal';
import StartProduction from './components/Modals/StartProduction.modal';
import ProjectAccessories from './components/ProjectAccessories';
import ProjectActions from './components/ProjectActions';
import ProjectDashboard from './components/ProjectDashboard';
import ProjectEquipment from './components/ProjectEquipment';
import ProjectFiles from './components/ProjectFiles';
import ProjectHeader from './components/ProjectHeader';
import ProjectHistory from './components/ProjectHistory';
import ProjectImages from './components/ProjectImages';
import ProjectPaintedElements from './components/ProjectPaintedElements/ProjectPaintedElementsOrders';
import ProjectStatistics from './components/ProjectStatistics/ProjectStatistics';
import { initProjectModals } from './data/initModals.data';

const { TabPane } = Tabs;

// TODO ALERTY Z PRODUKCJI
// TODO Raport wyjazdów do klienta

export type TProjectModalType = keyof typeof initProjectModals;

const ProjectPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id }: { id: string } = useParams();
  const { modal, showModal, closeModal } = useModal(initProjectModals);

  const user = useSelector((state: TAppState) => state.auth.user);
  const projectPageKey = useSelector(
    (state: TAppState) => state.view.projectPageKey
  );
  const nodes = useSelector((state: TAppState) => state.productionNodes.nodes);

  const [project, setProject] = useState<IProject>();
  const [alerts, setAlerts] = useState<IAlert[]>([]);
  const [schedule, setSchedule] = useState<ISchedule[]>([]);
  const [paintedElementsOrders, setPaintedElementsOrders] = useState<
    IPaintedElementsOrder[]
  >([]);
  const [workActions, setWorkActions] = useState<IWorkAction[]>([]);

  const [scheduleLoading, setScheduleLoading] = useState<boolean>(false);

  // --------------------------------------------------------------------------
  // NOTE Fetch data
  // --------------------------------------------------------------------------
  const getProject = useCallback(async () => {
    dispatch(setLoading(true));
    try {
      setAuthToken();
      const res = await axios.get(`/api/projects/${id}`);
      setProject(res.data);

      if (!nodes.length) dispatch(getAllProductionNodes());
    } catch (error: any) {
      console.log(error, error.response);
      message.error('Błąd');
    }
    dispatch(setLoading(false));
  }, [id]);

  const getProjectAlerts = useCallback(async () => {
    dispatch(setLoading(true));
    try {
      setAuthToken();
      const res = await axios.get(`/api/projects/${id}/alerts`);
      setAlerts(res.data);
    } catch (error: any) {
      console.log(error, error.response);
      message.error('Błąd');
    }
    dispatch(setLoading(false));
  }, [id]);

  const getSchedule = useCallback(async () => {
    if (project)
      try {
        setScheduleLoading(true);
        setAuthToken();
        const res = await axios.get(`/api/schedule`);
        setSchedule(res.data);
      } catch (error) {
        console.log(error);
        message.error('Błąd');
      } finally {
        setScheduleLoading(false);
      }
  }, [project]);

  const getPaintedElementsOrders = useCallback(async () => {
    try {
      setAuthToken();
      const res = await axios.get(
        `/api/projects/painted-elements-orders/${id}`
      );
      setPaintedElementsOrders(res.data);
    } catch (error: any) {
      console.log(error, error.response);
      message.error('Błąd');
    }
  }, [project]);

  const getPositionWorkActions = useCallback(async () => {
    if (user?.activePosition) {
      try {
        setAuthToken();
        const res = await axios.get(
          `/api/settings/work-actions/${user.activePosition}`
        );
        setWorkActions(res.data);
      } catch (error: any) {
        console.log(error, error.response);
        message.error('Błąd');
      }
    }
  }, [user]);

  // --------------------------------------------------------------------------
  // NOTE Lifecycle
  // --------------------------------------------------------------------------
  useEffect(() => {
    getProject();
    getProjectAlerts();
  }, [getProject]);

  useEffect(() => {
    if (project) {
      getPaintedElementsOrders();
      getSchedule();
      getPositionWorkActions();
    }
  }, [project]);

  // --------------------------------------------------------------------------
  // NOTE Handlers
  // --------------------------------------------------------------------------
  const handleTabKey = (key: ProjectPageKey) => {
    dispatch(setProjectPageKey(key));
  };

  const goToScheduleEdit = () =>
    history.push(`/production-schedule/edit`, { projectId: id });
  const goToOrder = () => history.push(`/orders/${project?.order?._id}`);

  const handleUpdateProject = async (
    updateData: Partial<IProject>,
    historyUpdate: string[],
    historyColor: HistoryColor
  ) => {
    dispatch(setLoading(true));
    try {
      setAuthToken();
      const res = await axios.patch(`/api/projects/${id}`, {
        updateData,
        historyUpdate,
        historyColor,
      });

      setProject(res.data);
      getProjectAlerts();
      message.success('Projekt zaktualizowany');
    } catch (error: any) {
      console.log(error, error.response);
      message.error('Błąd');
    }
    dispatch(setLoading(false));
  };

  const handleAddComment = async (comment: IComment) => {
    if (project) {
      const historyUpdate = [`Dodano komentarz`];
      const newComments = [...project.comments, comment];

      await handleUpdateProject(
        { comments: newComments },
        historyUpdate,
        HistoryColor.WARNING
      );
    }
  };

  const handleRemoveComment = async (comment: string) => {
    if (user?.permission !== Permissions.ADMIN)
      return message.error('Nie możesz tego zrobić');
    if (project) {
      const historyUpdate = [`Usunięto komentarz`];
      const newComments = project.comments.filter(
        (comm) => comm.comment !== comment
      );

      await handleUpdateProject(
        { comments: newComments },
        historyUpdate,
        HistoryColor.WARNING
      );
    }
  };

  const startProduction = async (position: Positions, workAction?: string) => {
    if ((user?.workingOn as IWorkTime)?.project) {
      return Modal.error({
        title: 'Inny proces w toku!',
        afterClose: () =>
          history.push(
            `/projects/${
              ((user?.workingOn as IWorkTime)?.project as IProject)._id
            }`
          ),
        content: (
          <div style={{ textAlign: 'center' }}>
            <p>
              Zakończ pracę nad projektem na stanowisku{' '}
              <UserPositionTag
                position={(user?.workingOn as IWorkTime)?.position}
              />
            </p>
            <hr />
            <div>
              <small>
                Po zamknięciu tego okna zostaniesz przeniesiony do projektu nad
                którym aktualnie pracujesz. Pamiętaj o ustawieniu odpowiedniego
                stanowiska!
              </small>
            </div>
          </div>
        ),
      });
    }
    if (project) {
      dispatch(setLoading(true));
      try {
        setAuthToken();

        await axios.patch(`/api/users/start-working-on`, {
          position,
          action: workAction || null,
          project: project._id,
        });

        await getProject();
        getProjectAlerts();
        dispatch(loadUserData());
        // message.success('Projekt zaktualizowany');
      } catch (error: any) {
        console.log(error, error.response);
        message.error('Błąd');
      }
      dispatch(setLoading(false));
    }
  };

  const stopProduction = async (position: Positions) => {
    if (project && user) {
      dispatch(setLoading(true));
      try {
        setAuthToken();

        await axios.patch(`/api/users/${user._id}/stop-working-on`);
        await getProject();
        getProjectAlerts();
        dispatch(loadUserData());
        dispatch(getAllProductionNodes());
        // message.success('Projekt zaktualizowany');
      } catch (error: any) {
        console.log(error, error.response);
        message.error('Błąd');
      }
      dispatch(setLoading(false));
    }
  };
  const goToNextStatus = async () => {
    await getProject();
    if (project && user) {
      if (isProjectOnActivePosition(project.status, user.activePosition)) {
        const activePositionProjectStatus = getProjectStatusFromActivePosition(
          user.activePosition
        );

        if (activePositionProjectStatus) {
          const currentStatusIndex = _projectStatuses.findIndex(
            (status) => activePositionProjectStatus === status
          );
          const nextStatusName = _projectStatuses[currentStatusIndex + 1];
          let nextStatus = [...project.status];
          if (
            !isSomeoneWorkingOnProjectAtPosition(project, user.activePosition)
          ) {
            nextStatus = project.status.filter(
              (status) => status !== activePositionProjectStatus
            );
          }

          if (!nextStatus.includes(nextStatusName)) {
            nextStatus = [...nextStatus, nextStatusName];
          }

          nextStatus = sortProjectStatusByProductionProcess(nextStatus);
          const historyUpdate = [
            `Zmieniono status projektu.`,
            `Z ${project.status.join(', ')} na ${nextStatus.join(', ')}`,
          ];

          await handleUpdateProject(
            { status: nextStatus },
            historyUpdate,
            HistoryColor.SUCCESS
          );

          if (project.order?._id) {
            await checkIsOrdersStatusUpdateIsNeededAndUpdate(project.order._id);
          }
        }
      }
    }
  };
  const finishProject = async () => {
    await getProject();
    if (project && user) {
      const historyUpdate = [`Zakończono produkcję projektu`];

      await handleUpdateProject(
        { status: [ProjectStatus.FINISHED], finishedAt: new Date() },
        historyUpdate,
        HistoryColor.SUCCESS
      );

      if (project.order?._id) {
        await checkIsOrdersStatusUpdateIsNeededAndUpdate(project.order._id);
      }
    }
  };
  const removeProject = async () => {
    dispatch(setLoading(true));
    try {
      setAuthToken();
      await axios.delete(`/api/projects/${id}`);

      message.success('Projekt usunięty');
      dispatch(clearProductionNodes());
      history.push(`/orders/${project?.order?._id}`);
    } catch (error: any) {
      console.log(error, error.response);
      message.error('Błąd');
    }
    dispatch(setLoading(false));
  };

  const handleRemoveProject = () =>
    Modal.confirm({
      title: 'Jesteś pewien że chcesz usunąć ten projekt?',
      icon: <ExclamationCircleOutlined />,
      content:
        'Zostaną usunięte wszystkie dane i pliki projektu. Zmiany są nieodwracalne',
      onOk() {
        removeProject();
      },
      onCancel() {},
    });
  const hadnleFinishProject = () =>
    Modal.confirm({
      title: 'Czy chcesz zakończyć produkcję tego projektu?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        finishProject();
      },
      onCancel() {},
    });

  return (
    <PageTemplate>
      {project ? (
        <>
          <PageHeader
            title={<H1>Projekt</H1>}
            subTitle={
              project.order?.customer
                ? getUserName(project.order.customer)
                : 'NO CLIENT DATA'
            }
            tags={project.status.map((status) => (
              <ProjectStatusTag
                key={status}
                status={status}
                inProduction={isSomeoneWorkingOnProjectAtPosition(
                  project,
                  getPositionFromProjectStatus(status)
                )}
              />
            ))}
            // FIXME Zmienic projectActions z funkcji na komponent
            extra={
              user && (
                <ProjectActions
                  project={project}
                  user={user}
                  actions={{
                    showModal,
                    goToScheduleEdit,
                    handleRemoveProject,
                    goToNextStatus,
                    startProduction,
                    stopProduction,
                    finishProject: hadnleFinishProject,
                  }}
                  workActions={workActions}
                />
              )
            }
            backIcon={
              isUserHasPermission(user, [
                Positions.TECHNOLOGIST,
                Positions.DESIGNER,
              ]) && (
                <ArrowLeftOutlined
                  style={{ fontSize: 26, marginBottom: 19 }}
                  title="Przejdź do zamówienia"
                />
              )
            }
            onBack={goToOrder}
          />
          {user &&
            ((user?.workingOn as IWorkTime)?.project as IProject)?._id ===
              project._id && (
              <div style={{ marginBottom: 30, textAlign: 'center' }}>
                <H5>
                  Pracujesz nad tym projektem na stanowisku{' '}
                  <UserPositionTag
                    position={(user?.workingOn as IWorkTime)?.position}
                  />
                </H5>
              </div>
            )}
          {/* NOTE PROJECT HEADER */}
          <ProjectHeader project={project} user={user} />
          <AntDivider marginTop={60} />
          {/* NOTE PROJECT DATA */}
          <Tabs
            defaultActiveKey={projectPageKey}
            onChange={(key) => handleTabKey(key as ProjectPageKey)}
            size="large"
            style={{ marginBottom: 32 }}
          >
            {/* NOTE Dashboard */}
            <TabPane tab="Dashboard" key="dashboard">
              <ProjectDashboard
                user={user}
                project={project}
                showModal={showModal}
                alerts={alerts}
                orders={paintedElementsOrders}
                schedule={schedule}
                getSchedule={getSchedule}
                getProjectAlerts={getProjectAlerts}
                scheduleLoading={scheduleLoading}
                handleRemoveComment={handleRemoveComment}
              />
            </TabPane>

            {/* NOTE Klient */}
            <TabPane tab="Dane klienta" key="customer">
              <CustomerData customer={project.order?.customer} />
            </TabPane>

            {/* NOTE Akcesoria */}
            <TabPane tab="Elementy lakierowane" key="paintedElements">
              <ProjectPaintedElements
                project={project}
                orders={paintedElementsOrders}
                handleUpdateProject={handleUpdateProject}
              />
            </TabPane>

            {/* NOTE Akcesoria */}
            <TabPane tab="Akcesoria" key="accessories">
              <ProjectAccessories accessories={project.accessories} />
            </TabPane>

            {/* NOTE AGD */}
            <TabPane tab="AGD" key="equipment">
              <ProjectEquipment
                project={project}
                handleUpdateProject={handleUpdateProject}
              />
            </TabPane>

            {/* NOTE Pliki */}
            <TabPane tab="Pliki" key="files">
              <ProjectFiles
                project={project}
                handleUpdateProject={handleUpdateProject}
                showModal={showModal}
              />
            </TabPane>

            {/* NOTE Zdjęcia */}
            <TabPane tab="Zdjęcia" key="images">
              <ProjectImages
                project={project}
                handleUpdateProject={handleUpdateProject}
              />
            </TabPane>

            {/* NOTE Statystyki */}
            {isUserHasPermission(user, []) && (
              <TabPane tab="Statystyki" key="statistics">
                <ProjectStatistics project={project} />
              </TabPane>
            )}
            {/* NOTE Historia */}
            {isUserHasPermission(user, []) && (
              <TabPane tab="Historia" key="history">
                <ProjectHistory history={project.history} />
              </TabPane>
            )}
          </Tabs>
          {modal.addComment && (
            <AddCommentModal
              closeModal={closeModal}
              handleAddComment={handleAddComment}
            />
          )}
          {modal.changeStatus && (
            <ChangeProjectStatusModal
              project={project}
              closeModal={closeModal}
              handleUpdateProject={handleUpdateProject}
            />
          )}
          {modal.changeNumber && (
            <ChangeProjectNumberModal
              project={project}
              closeModal={closeModal}
              handleUpdateProject={handleUpdateProject}
            />
          )}
          {modal.changeName && (
            <ProjectChangeNameModal
              project={project}
              closeModal={closeModal}
              handleUpdateProject={handleUpdateProject}
            />
          )}
          {modal.changeCreationDate && (
            <ChangeCreationDateModal
              project={project}
              closeModal={closeModal}
              handleUpdateProject={handleUpdateProject}
            />
          )}
          {modal.changeMontageDate && (
            <ChangeProjectMontageDateModal
              project={project}
              closeModal={closeModal}
              handleUpdateProject={handleUpdateProject}
            />
          )}
          {modal.changeDesigner && (
            <ChangeProjectDesignerModal
              project={project}
              closeModal={closeModal}
              handleUpdateProject={handleUpdateProject}
            />
          )}
          {modal.changeTechnologist && (
            <ChangeProjectTechnologistModal
              project={project}
              closeModal={closeModal}
              handleUpdateProject={handleUpdateProject}
            />
          )}
          {modal.setProductionDays && (
            <SetProjectProductionDaysModal
              project={project}
              closeModal={closeModal}
              handleUpdateProject={handleUpdateProject}
            />
          )}
          {modal.addEquipment && (
            <AddProjectEquipmentModal
              project={project}
              closeModal={closeModal}
              handleUpdateProject={handleUpdateProject}
            />
          )}
          {modal.addImage && (
            <AddProjectImageModal
              project={project}
              closeModal={closeModal}
              handleUpdateProject={handleUpdateProject}
            />
          )}
          {modal.addFile && (
            <AddProjectFileModal
              project={project}
              closeModal={closeModal}
              handleUpdateProject={handleUpdateProject}
            />
          )}
          {modal.editAccessories && (
            <ProjectEditAccessoriesModal
              project={project}
              closeModal={closeModal}
              handleUpdateProject={handleUpdateProject}
            />
          )}
          {modal.addPaintedElementsOrder && (
            <ProjectAddPaintedElementsOrderModal
              project={project}
              closeModal={closeModal}
              handleUpdateProject={handleUpdateProject}
            />
          )}
          {modal.newScheduleItem && (
            <AddScheduleItemModal
              projects={[project]}
              closeModal={closeModal}
              getSchedule={getSchedule}
            />
          )}
          {modal.startProduction && (
            <StartProduction
              startProduction={startProduction}
              workActions={workActions}
              user={user}
              closeModal={closeModal}
            />
          )}
        </>
      ) : (
        <Empty />
      )}
    </PageTemplate>
  );
};

export default ProjectPage;
