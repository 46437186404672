export enum CashRegisterOperationType {
  IN = 'in',
  OUT = 'out',
}
export interface ICashRegister {
  value: number;
  lastPayOut: Date;
  authorizedUsers: string[];
}
export interface ICashRegisterOperation {
  _id: string;
  user: string;
  value: number;
  cashRegisterValue: number;
  operationType: CashRegisterOperationType;
  description: string;
  additionalInfo?: string;
  settled: boolean;
  createdAt: Date;
}
export interface ICashRegisterOperationDescription {
  _id: string;
  forType: CashRegisterOperationType;
  value: string;
  createdAt: Date;
}
export interface ICashRegisterReport {
  _id: string;
  path: string;
  name: string;
  createdAt: Date;
}
// TODO zamienić reduxa na query
