import * as yup from 'yup';

export const companyDataValidation = yup.object({
  name: yup.string(),
  nip: yup.string().matches(/^\d{10}$/, 'NIP składa się z 10 cyfr'),
  regon: yup.string().matches(/^\d{9}$/, 'REGON składa się z 9 cyfr'),
  bankAccount: yup
    .string()
    .matches(/^\d{26}$/, 'Nr konta składa się z 26 cyfr'),
});

export const companyAdressValidation = yup.object({
  name: yup.string(),
  city: yup.string(),
  address: yup.string(),
  postcode: yup.string().matches(/^\d{2}-\d{3}$/, 'Nieprawidłowy format'),
  description: yup.string(),
});

export const companyContactValidation = yup.object({
  name: yup.string(),
  email: yup.string().email('Email nieprawidłowy'),
  phone: yup.string(),
  description: yup.string(),
});

export const workActionDataValidation = yup.object({
  position: yup.string().required('Stanowisko jest wymagane'),
  action: yup
    .string()
    .min(1, 'Min. liczba znaków to 1')
    .max(250, 'Max. liczba znaków to 250')
    .required('Nazwa jest wymagana'),
  description: yup.string().max(250, 'Max. liczba znaków to 250'),
});
