import { ICustomer } from 'services/store/types/customers/Customers';
import { IUser } from 'services/store/types/users/Users';

export const sortingFuncion = {
  users: {
    byLastName: (a: IUser, b: IUser) =>
      `${a.lastName}`.localeCompare(b.lastName),
    byFirstName: (a: IUser, b: IUser) =>
      `${a.firstName}`.localeCompare(b.firstName),
  },
  customers: {
    byCompanyOrLastName: (a: ICustomer, b: ICustomer) =>
      `${a.company || a.lastName}`.localeCompare(b.company || b.lastName),
  },
};
