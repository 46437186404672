import dayjs from 'dayjs';
import humanizeDuration from 'humanize-duration';
import { isDate } from 'lodash';

export const getDurationText = (durationInMiliseconds: number | undefined) => {
  if (!durationInMiliseconds) return '';
  if (isDate(durationInMiliseconds))
    durationInMiliseconds = durationInMiliseconds.getTime();

  const durationText = humanizeDuration(durationInMiliseconds, {
    units: ['h', 'm'],
    round: true,
    language: 'pl',
  });
  return durationText;
};

export const getDurationTextFromDate = (dateFrom: Date | undefined) => {
  if (!dateFrom) return '-';
  const start = dayjs(dateFrom);
  const to = dayjs();
  const durationInMiliseconds = to.diff(start, 'milliseconds');
  return getDurationText(durationInMiliseconds);
};

export const getDuration = (startDate: Date, finishDate: Date): Date => {
  const start = dayjs(startDate);
  const finish = dayjs(finishDate);
  const durationInMs = finish.diff(start);
  const duration = dayjs(durationInMs).toDate();
  return duration;
};
