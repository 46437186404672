import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import {
  Typography,
  Card,
  Image,
  Empty,
  Divider,
  message,
  Popconfirm,
  Button,
} from 'antd';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { removeFileById } from 'services/requests/files.request';
import { TAppState } from 'services/store';
import { setLoading } from 'services/store/actions/view';
import { HistoryColor } from 'services/store/enums/historyColor.enum';
import { ProjectImageType } from 'services/store/enums/project.enum';
import { Positions } from 'services/store/enums/users.enum';
import { IFile } from 'services/store/types/files/Files';
import {
  IProject,
  IProjectImage,
} from 'services/store/types/projects/Projects';
import { IUser } from 'services/store/types/users/Users';
import { isUserHasPermission } from 'services/utils/users';

const { Title } = Typography;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow-x: auto;
`;

interface IProps {
  project: IProject;
  handleUpdateProject: (
    updateData: Partial<IProject>,
    historyUpdate: string[],
    historyColor: HistoryColor
  ) => Promise<any>;
}

const ProjectImages: React.FC<IProps> = ({ project, handleUpdateProject }) => {
  const user = useSelector((state: TAppState) => state.auth.user);

  const dispatch = useDispatch();

  const handleRemoveImage = async (image: IProjectImage) => {
    dispatch(setLoading(true));

    const removeFileResponse = await removeFileById((image.file as IFile)._id);
    if (removeFileResponse) {
      message.success('Plik usunięty');

      const newImages = project.images?.filter((img) => img._id !== image._id);
      const historyUpdate = [`Usunięto zdjęcie:`, image.name];

      await handleUpdateProject(
        { images: newImages },
        historyUpdate,
        HistoryColor.DANGER
      );
    }
  };

  return (
    <div>
      <ImagesWrapper
        title="Pomiar"
        images={project.images?.filter(
          (image) => image.type === ProjectImageType.MEASURE
        )}
        handleRemoveImage={handleRemoveImage}
        user={user}
        activePosition={user?.activePosition}
      />
      <ImagesWrapper
        title="Montaż"
        images={project.images?.filter(
          (image) => image.type === ProjectImageType.MONTAGE
        )}
        handleRemoveImage={handleRemoveImage}
        user={user}
        activePosition={user?.activePosition}
      />
      <ImagesWrapper
        title="Realizacja"
        images={project.images?.filter(
          (image) => image.type === ProjectImageType.REALIZATION
        )}
        handleRemoveImage={handleRemoveImage}
        user={user}
        activePosition={user?.activePosition}
      />
      <ImagesWrapper
        title="Inne"
        images={project.images?.filter(
          (image) => image.type === ProjectImageType.OTHER
        )}
        handleRemoveImage={handleRemoveImage}
        user={user}
        activePosition={user?.activePosition}
      />
    </div>
  );
};

export default ProjectImages;

interface IImagesWrapperProps {
  title: string;
  images: IProjectImage[];
  handleRemoveImage: (image: IProjectImage) => Promise<any>;
  user: IUser | null;
  activePosition: Positions | undefined;
}
const ImagesWrapper: React.FC<IImagesWrapperProps> = ({
  title,
  images,
  handleRemoveImage,
  user,
  activePosition,
}) => {
  return (
    <div>
      <Title level={4}>{title}</Title>
      {images?.length ? (
        <Wrapper>
          <Image.PreviewGroup>
            {images?.map((image) => (
              <Card
                style={{ marginRight: 10 }}
                key={image._id}
                bodyStyle={{ padding: '0 5px 5px 5px' }}
              >
                <div>
                  <small>{dayjs(image.date).format('DD.MM.YYYY')}</small>
                </div>
                <Image
                  alt="img"
                  width={120}
                  height={120}
                  src={`${(image.file as IFile)?.dir}`}
                  preview={{ src: `${(image.file as IFile)?.dir}` }}
                />
                <Card.Meta
                  title={image.name}
                  description={<div>{image.description}</div>}
                />
                <Popconfirm
                  key={1}
                  title="Chcesz usunąć to zdjęcie?"
                  okText="Tak"
                  cancelText="Nie"
                  onConfirm={() => handleRemoveImage(image)}
                  okButtonProps={{ danger: true }}
                >
                  {isUserHasPermission(user, [
                    Positions.DESIGNER,
                    Positions.TECHNOLOGIST,
                  ]) && (
                    <Button block danger size="small" style={{ marginTop: 5 }}>
                      <DeleteOutlined />
                      Usuń
                    </Button>
                  )}
                </Popconfirm>
              </Card>
            ))}
          </Image.PreviewGroup>
        </Wrapper>
      ) : (
        <Empty />
      )}
      <Divider />
    </div>
  );
};
