import React from 'react';
import {
  BarsOutlined,
  CaretDownFilled,
  FolderAddOutlined,
} from '@ant-design/icons';
import { Button, Menu } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import ChangePositionModal from 'components/Modals/ChangePosition/ChangePosition.modal';
import UserPositionTag from 'components/Tags/UserPositionTag/UserPositionTag';
import { useModal } from 'hook/useModals';
import { TAppState } from 'services/store';
import { logoutUser } from 'services/store/actions/auth';
import { Positions } from 'services/store/enums/users.enum';
import { isUserHasPermission } from 'services/utils/users';

const { SubMenu } = Menu;

const StyledMenu = styled(Menu)`
  display: flex;
  justify-content: flex-end;
`;

interface PropsT {}

const Navigation: React.FC<PropsT> = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state: TAppState) => state.auth.user);

  const { modal, closeModal, showModal } = useModal({ changePosition: false });

  const handleLogOut = () => {
    dispatch(logoutUser());
    history.push('/login');
  };
  const goToOrderCreate = () => history.push('/orders/create');

  if (!user) return null;
  return (
    <StyledMenu
      selectedKeys={[location.pathname]}
      theme="dark"
      mode="horizontal"
      overflowedIndicator={
        <Button type="primary">
          <BarsOutlined /> Menu
        </Button>
      }
    >
      {isUserHasPermission(user, [
        Positions.DESIGNER,
        Positions.TECHNOLOGIST,
      ]) && (
        <Menu.Item key="">
          <Button type="primary" onClick={goToOrderCreate}>
            <FolderAddOutlined />
            Nowe zamówienie
          </Button>
        </Menu.Item>
      )}

      <Menu.Item key="/">
        <NavLink to="/">Strona główna</NavLink>
      </Menu.Item>

      {isUserHasPermission(user, [
        Positions.DESIGNER,
        Positions.TECHNOLOGIST,
      ]) && (
        <SubMenu
          key="/production"
          title={
            <span className="submenu-title-wrappwe">
              Produkcja <CaretDownFilled />
            </span>
          }
        >
          <Menu.Item key="/orders">
            <NavLink to="/orders">Zamówienia</NavLink>
          </Menu.Item>
          <Menu.Item key="/projects">
            <NavLink to="/projects">Projekty</NavLink>
          </Menu.Item>
          <Menu.Item key="/production-schedule/edit">
            <NavLink to="/production-schedule/edit">
              Harmonogram produkcji
            </NavLink>
          </Menu.Item>
        </SubMenu>
      )}

      <Menu.Item key="/schedule">
        <NavLink to="/schedule">Terminarz</NavLink>
      </Menu.Item>

      {isUserHasPermission(user, []) && (
        <SubMenu
          key="/stats"
          title={
            <span className="submenu-title-wrappwe">
              Statystyki <CaretDownFilled />
            </span>
          }
        >
          <Menu.Item key="/stats/orders">
            <NavLink to="/stats/orders">Zamówień</NavLink>
          </Menu.Item>
          <Menu.Item key="/stats/employees">
            <NavLink to="/stats/employees">Pracowników</NavLink>
          </Menu.Item>
        </SubMenu>
      )}

      {isUserHasPermission(user, [
        Positions.DESIGNER,
        Positions.TECHNOLOGIST,
      ]) && (
        <Menu.Item key="/customers">
          <NavLink to="/customers">Klienci</NavLink>
        </Menu.Item>
      )}

      {isUserHasPermission(user, []) && (
        <Menu.Item key="/users">
          <NavLink to="/users">Użytkownicy</NavLink>
        </Menu.Item>
      )}

      <SubMenu
        key="/base"
        title={
          <span className="submenu-title-wrapper">
            Baza
            <CaretDownFilled />
          </span>
        }
      >
        <Menu.Item key="/base/accessories">
          <NavLink to="/base/accessories">Akcesoria</NavLink>
        </Menu.Item>
      </SubMenu>

      {isUserHasPermission(user, []) && (
        <Menu.Item key="/settings">
          <NavLink to="/settings">Ustawienia</NavLink>
        </Menu.Item>
      )}

      <SubMenu
        key="/account"
        title={
          <span className="submenu-title-wrapper">
            {user.activePosition ? (
              <UserPositionTag position={user.activePosition} />
            ) : (
              'Konto'
            )}
            <CaretDownFilled />
          </span>
        }
      >
        <Menu.Item
          key="/change-position"
          disabled={!user.positions.length}
          onClick={() => showModal('changePosition')}
        >
          Zmień stanowisko
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="/account/me">
          <NavLink to="/account">Moje dane</NavLink>
        </Menu.Item>
        <Menu.Item key="/logout" onClick={handleLogOut}>
          Wyloguj
        </Menu.Item>
      </SubMenu>
      {/* NOTE MODALS */}
      {modal.changePosition && <ChangePositionModal closeModal={closeModal} />}
    </StyledMenu>
  );
};

export default Navigation;
