import React, { useState } from 'react';
import { Alert, Empty, Tabs } from 'antd';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import TextButton from 'components/Buttons/TextButton/TextButton';
import InnerSpinner from 'components/Spinner/InnerSpinner';
import { AlertSource, IAlert } from 'services/store/interfaces/alert.interface';

const { TabPane } = Tabs;

const AlertsWrapper = styled.div`
  position: relative;
  max-height: 300px;
  overflow-y: scroll;
`;

interface IProps {
  projectsAlerts: IAlert[];
  ordersAlerts: IAlert[];
  isLoading: boolean;
}

const DashboardAlerts: React.FC<IProps> = ({
  projectsAlerts,
  ordersAlerts,
  isLoading,
}) => {
  const history = useHistory();
  const [key, setKey] = useState<string>('projects');

  const goToData = (id: string, source: AlertSource) =>
    source === AlertSource.PROJECTS
      ? history.push(`/projects/${id}`)
      : history.push(`/orders/${id}`);
  return (
    <AlertsWrapper>
      {isLoading && <InnerSpinner />}
      <Tabs defaultActiveKey={key} onChange={setKey} size="small">
        <TabPane tab="Zamówienia" key="orders">
          <AlertsList alerts={ordersAlerts} goToData={goToData} />
        </TabPane>
        <TabPane tab="Projekty" key="projects">
          <AlertsList alerts={projectsAlerts} goToData={goToData} />
        </TabPane>
      </Tabs>
    </AlertsWrapper>
  );
};

export default DashboardAlerts;

interface IListProps {
  alerts: IAlert[];
  goToData: (id: string, source: AlertSource) => void;
}

const AlertsList: React.FC<IListProps> = ({ alerts, goToData }) => {
  return (
    <div>
      {alerts?.length ? (
        alerts.map((alert, i) => (
          <div
            key={`${alert.message}${alert.title}${alert.id}${alert.name}`}
            title={alert.title}
          >
            <Alert
              type={alert.type}
              message={
                <TextButton
                  text={alert.name}
                  onClick={() => goToData(alert.id, alert.source)}
                  danger
                />
              }
              description={alert.message}
              //   showIcon
              style={{ marginBottom: 10, padding: 5 }}
            />
          </div>
        ))
      ) : (
        <Empty />
      )}
    </div>
  );
};
