import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import styled from 'styled-components';
import { TAppState } from 'services/store';
import { logoutUser } from 'services/store/actions/auth';

const StyledWrapper = styled.div`
  padding: 0 20px 20px 20px;
  @media (max-width: 768px) {
    padding: 20px 10px 0 10px;
  }
`;

const PageTemplate: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const isAuth = useSelector((state: TAppState) => state.auth.isAuth);
  const token = localStorage.getItem('token');
  if (!token) {
    dispatch(logoutUser());
    return <Redirect to="/login" />;
  }
  return <StyledWrapper>{children}</StyledWrapper>;
};

export default PageTemplate;
