import { OrderStatus } from '../enums/orders.enum';
import {
  HomePageKey,
  OrderPageKey,
  OrdersKey,
  ProjectPageKey,
  ProjectProductionEditView,
  ProjectsKey,
} from '../enums/view.enum';
import {
  ISetLoading,
  ISetOrdersKey,
  ISetProjectPageKey,
  ISetOrderPageKey,
  ISetProjectsKey,
  VIEW_SET_ORDERS_KEY,
  VIEW_SET_PROJECTS_KEY,
  VIEW_SET_PROJECT_PAGE_KEY,
  VIEW_SET_ORDER_PAGE_KEY,
  VIEW_SET_SPINNER,
  ISetProjectProductionEditView,
  VIEW_SET_PROJECT_PRODUCTION_EDIT_VIEW,
  VIEW_SET_PROJECT_PRODUCTION_EDIT_CELL_WIDTH,
  ISetProjectProductionEditCellWidth,
  ISetOrdersStatisticsPageKey,
  VIEW_SET_ORDER_STATISTICS_PAGE_KEY,
  ISetHomePageKey,
  VIEW_SET_HOME_PAGE_KEY,
} from '../types/view/actions';

export const setLoading = (isLoading: boolean): ISetLoading => ({
  type: VIEW_SET_SPINNER,
  isLoading,
});

export const setHomePageKey = (homePageKey: HomePageKey): ISetHomePageKey => ({
  type: VIEW_SET_HOME_PAGE_KEY,
  homePageKey,
});
export const setProjectsKey = (projectsKey: ProjectsKey): ISetProjectsKey => ({
  type: VIEW_SET_PROJECTS_KEY,
  projectsKey,
});

export const setOrdersKey = (ordersKey: OrdersKey): ISetOrdersKey => ({
  type: VIEW_SET_ORDERS_KEY,
  ordersKey,
});
export const setProjectPageKey = (
  projectPageKey: ProjectPageKey
): ISetProjectPageKey => ({
  type: VIEW_SET_PROJECT_PAGE_KEY,
  projectPageKey,
});
export const setOrderPageKey = (
  orderPageKey: OrderPageKey
): ISetOrderPageKey => ({
  type: VIEW_SET_ORDER_PAGE_KEY,
  orderPageKey,
});
export const setOrderStatisticsPageKey = (
  ordersStatisticsPageKey: OrderStatus
): ISetOrdersStatisticsPageKey => ({
  type: VIEW_SET_ORDER_STATISTICS_PAGE_KEY,
  ordersStatisticsPageKey,
});
export const setProjectProductionEditView = (
  projectProductionEditView: ProjectProductionEditView
): ISetProjectProductionEditView => ({
  type: VIEW_SET_PROJECT_PRODUCTION_EDIT_VIEW,
  projectProductionEditView,
});
export const setProjectProductionEditCellWidth = (
  projectProductionEditCellWidth: number
): ISetProjectProductionEditCellWidth => ({
  type: VIEW_SET_PROJECT_PRODUCTION_EDIT_CELL_WIDTH,
  projectProductionEditCellWidth,
});
