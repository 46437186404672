import React from 'react';
import { HistoryColor } from 'services/store/enums/historyColor.enum';
import { IProject } from 'services/store/types/projects/Projects';
import EquipmentList from './EquipmentList';

interface IProps {
  project: IProject;
  handleUpdateProject: (
    updateData: Partial<IProject>,
    historyUpdate: string[],
    historyColor: HistoryColor
  ) => Promise<any>;
}

const ProjectEquipment: React.FC<IProps> = ({ project, ...props }) => {
  return (
    <div>
      <EquipmentList equipment={project.equipment} {...props} />
    </div>
  );
};

export default ProjectEquipment;
