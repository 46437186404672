import React, { useState } from 'react';
import { Button, Modal, Radio, Row, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import UserPositionTag from 'components/Tags/UserPositionTag/UserPositionTag';
import { TAppState } from 'services/store';
import { setUserActivePosition } from 'services/store/actions/auth';
import { setUserCurrentNode } from 'services/store/actions/productionNodes';
import { setLoading } from 'services/store/actions/view';
import { Positions } from 'services/store/enums/users.enum';
import { IProject } from 'services/store/types/projects/Projects';
import { IWorkTime } from 'services/store/types/statistics/Statistics';

interface IProps {
  closeModal: () => void;
}

const ChangePositionModal: React.FC<IProps> = ({ closeModal }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state: TAppState) => state.auth.user);

  const [position] = useState<Positions | undefined>(user?.activePosition);

  const changePosition = (pos: Positions | undefined) => {
    if (user?._id) {
      dispatch(setLoading(true));
      if (pos) dispatch(setUserCurrentNode(user._id, pos));
      dispatch(
        setUserActivePosition(user._id, pos, {
          _onEnd: () => {
            dispatch(setLoading(false));
            closeModal();
          },
          _onError: () => {
            dispatch(setLoading(false));
          },
        })
      );
    }
  };
  return (
    <Modal
      title={'Zmiana stanowiska'}
      destroyOnClose
      visible
      onCancel={closeModal}
      maskClosable={false}
      footer={null}
    >
      {(user?.workingOn as IWorkTime)?.project &&
        (user?.workingOn as IWorkTime)?.position && (
          <div style={{ textAlign: 'center' }}>
            Jesteś w trakcie pracy nad projektem na stanowisku{' '}
            {
              <UserPositionTag
                position={(user?.workingOn as IWorkTime)?.position}
              />
            }
            . Upewnij się że zakończyłeś proces przed rozpoczęciem pracy na
            innym stanowisku!
            <div>
              <Button
                type="primary"
                onClick={() => {
                  history.push(
                    `/projects/${
                      ((user?.workingOn as IWorkTime)?.project as IProject)?._id
                    }`
                  );
                  closeModal();
                }}
              >
                Przejdź do projektu
              </Button>
            </div>
            <hr />
          </div>
        )}
      <Row justify="center">
        <div>
          <Radio.Group
            onChange={(e) => changePosition(e.target.value)}
            value={position}
          >
            <Space direction="vertical" size="large">
              {user?.positions.map((pos) => (
                <Radio key={pos} value={pos}>
                  <UserPositionTag position={pos} />
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </div>
      </Row>
    </Modal>
  );
};

export default ChangePositionModal;
