import React, { useEffect, useState } from 'react';
import { Select, Modal, Typography, message } from 'antd';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { TAppState } from 'services/store';
import { getAllProductionNodes } from 'services/store/actions/productionNodes';
import { getAllUsers } from 'services/store/actions/users';
import { Positions } from 'services/store/enums/users.enum';
import { IProductionNode } from 'services/store/types/productionNodes/ProductionNodes';
import { _userPositions } from 'services/utils/const';
import { capitalizeFirstLetter } from 'services/utils/string';

const { Title } = Typography;
const { Option } = Select;

const initValues = {
  position: 'projektant',
  // users: [],
  user: null,
  date: new Date(),
  actions: [{}, {}, {}, {}],
  nodeId: '',
};

interface IProps {
  closeModal: () => void;
  nodeToEdit?: IProductionNode;
}

// TODO Refoctor needer (handle submit)
const ProductionNodeModal: React.FC<IProps> = ({ closeModal, nodeToEdit }) => {
  const dispatch = useDispatch();
  const [productionNode, setProductionNode] = useState<any>(initValues);
  const [isLoading, setIsLoading] = useState(false);
  const users = useSelector((state: TAppState) => state.users.users);

  useEffect(() => {
    if (!users.length) {
      setIsLoading(true);
      dispatch(getAllUsers());
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (nodeToEdit) {
      // const usersIds = nodeToEdit.users.map((u) => u._id);
      // setProductionNode({ ...nodeToEdit, users: usersIds });
      setProductionNode({ ...nodeToEdit, user: nodeToEdit.user._id });
    }
  }, [nodeToEdit]);

  const handleChange = (field: keyof IProductionNode, value: any) => {
    if (field === 'position')
      return setProductionNode({
        ...productionNode,
        [field]: value,
        users: [],
      });
    return setProductionNode({ ...productionNode, [field]: value });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      if (!nodeToEdit)
        await axios.post(`/api/production-nodes`, productionNode);
      else
        await axios.patch(`/api/production-nodes/node/${nodeToEdit.nodeId}`, {
          // users: productionNode.users,
          user: productionNode.user,
          position: productionNode.position,
          name: productionNode.name,
        });
      dispatch(getAllProductionNodes());
      message.success(
        `${nodeToEdit ? 'Zaktualizowano' : 'Utworzono'} gniazdo produkcyjne`
      );
      return closeModal();
    } catch (error: any) {
      console.log(error, error.response);
      message.error('Błąd');
    }
    setIsLoading(false);
  };

  return (
    <Modal
      title={`${nodeToEdit ? 'Edycja' : 'Tworzenie'} gniazda produkcyjnego`}
      destroyOnClose
      visible
      onCancel={closeModal}
      maskClosable={false}
      onOk={handleSubmit}
      okButtonProps={{
        disabled: !productionNode.user || !productionNode.position,
        loading: isLoading,
      }}
    >
      <Title level={5}>Stanowisko</Title>
      <Select
        value={productionNode.position}
        onChange={(value) => handleChange('position', value)}
        placeholder="Stanowisko"
        style={{ width: '100%' }}
      >
        {_userPositions.map((position) => (
          <Option key={position} value={position}>
            {capitalizeFirstLetter(position)}
          </Option>
        ))}
      </Select>
      <Title level={5}>Pracownik</Title>
      <Select
        disabled={!productionNode.position}
        value={productionNode.user}
        // onChange={(value) => handleChange('users', [value])}
        onChange={(value) => handleChange('user', [value])}
        placeholder="Pracownicy"
        style={{ width: '100%' }}
      >
        {users
          .filter((user) => {
            if (!productionNode.position) return user;
            if (productionNode.position === 'projektant')
              return user.positions.includes(Positions.DESIGNER);
            if (productionNode.position === 'technolog')
              return user.positions.includes(Positions.TECHNOLOGIST);
            if (productionNode.position === 'stolarz')
              return user.positions.includes(Positions.CARPENTER);
            if (productionNode.position === 'montażysta')
              return user.positions.includes(Positions.INSTALLER);
            if (productionNode.position === 'magazyn')
              return user.positions.includes(Positions.STOREKEEPER);
            return user;
          })
          .map((user) => (
            <Option key={user._id} value={user._id}>
              {user.firstName} {user.lastName}
            </Option>
          ))}
      </Select>
    </Modal>
  );
};

export default ProductionNodeModal;
