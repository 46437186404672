import { RcFile } from 'antd/lib/upload';
import FileSaver from 'file-saver';
// eslint-disable-next-line import/no-extraneous-dependencies
const mime = require('mime');

export const isFileImage = (file: RcFile) => {
  if (file.type.includes('image')) return true;
  return false;
};

export const saveFileAs = (fileBlob: any, fileName: string) => {
  const fileType = mime.getType(fileName);
  return FileSaver.saveAs(
    new Blob([fileBlob], {
      type: `${fileType};charset=utf-8`,
    }),
    fileName
  );
};
