import React from 'react';
import { useSelector } from 'react-redux';
import { TAppState } from 'services/store';
import { Positions } from 'services/store/enums/users.enum';
import { ProjectProductionEditView } from 'services/store/enums/view.enum';
import { IProject } from 'services/store/types/projects/Projects';
import PartDayViewProductionSchedule from './DayPartView';
import DayViewProductionSchedule from './DayView';

interface IProps {
  project?: IProject;
  userId?: string;
  position?: Positions;
}

const ProductionScheduleDisplay: React.FC<IProps> = ({
  project,
  userId,
  position,
}) => {
  const view = useSelector(
    (state: TAppState) => state.view.projectProductionEditView
  );

  if (view === ProjectProductionEditView.DAYS)
    return (
      <DayViewProductionSchedule
        userId={userId}
        position={position}
        project={project}
      />
    );
  return (
    <PartDayViewProductionSchedule
      userId={userId}
      position={position}
      project={project}
    />
  );
};

export default ProductionScheduleDisplay;
