import { ReactNode } from 'react';
import { message } from 'antd';
import dayjs from 'dayjs';
import {
  IHandleProjectInDay,
  IDayIndexes,
  IDayPartIndexes,
  IHandleProjectInAction,
} from 'components/ProductionSchedule/interfaces/productionSchedule.interface';
import {
  getCellColor,
  getUniqueProjectsNumbersFromActions,
} from 'components/ProductionSchedule/utils';
import { Positions } from 'services/store/enums/users.enum';
import { IAction } from 'services/store/types/productionNodes/ProductionNodes';
import { cutString } from 'services/utils/string';

const projectNumberMaxLength = (cellWidth: number) => {
  if (cellWidth === 200) return 25;
  else if (cellWidth < 200 && cellWidth >= 180) return 20;
  else if (cellWidth < 180 && cellWidth >= 160) return 18;
  else if (cellWidth < 160 && cellWidth >= 140) return 16;
  else if (cellWidth < 140 && cellWidth >= 120) return 12;
  else if (cellWidth < 120 && cellWidth >= 100) return 10;
  else if (cellWidth < 100 && cellWidth >= 80) return 6;
  else if (cellWidth < 80 && cellWidth >= 60) return 4;
  return 10;
};

export const renderDayCell = (
  actions: IAction[] = [],
  date: Date,
  position: Positions,
  addProjectToSchedule: IHandleProjectInDay,
  removeProjectFromSchedule: IHandleProjectInDay,
  indexes: IDayIndexes,
  cellWidth: number,
  projectId?: string
): ReactNode => {
  const weekDay = dayjs(date).day();
  const isWeekend = [6, 0].includes(weekDay);

  const projectsNumbers = getUniqueProjectsNumbersFromActions(actions);

  const isProjectsInActions = actions.some((action) => !!action.project);

  const isOnlyCurrentProjectInActions =
    weekDay === 5
      ? actions.slice(0, 2).every((action) => action.project?._id === projectId)
      : actions.every((action) => action.project?._id === projectId);

  const isOnlyOtherProjectInDay = !!(
    isProjectsInActions && !isOnlyCurrentProjectInActions
  );

  const isCellActive = () => {
    if (!projectId) return false;
    if (isWeekend) return false;
    if (isProjectsInActions && !isOnlyCurrentProjectInActions) return false;
    return true;
  };
  const canAddProjectToCell = () => {
    if (!isCellActive()) return false;
    if (isOnlyCurrentProjectInActions) return false;
    return true;
  };
  const canRemoveProjectToCell = () => {
    if (!isCellActive()) return false;
    if (!isOnlyCurrentProjectInActions) return false;
    return true;
  };

  const handleCell = () => {
    if (canAddProjectToCell())
      return addProjectToSchedule(
        indexes?.nodeIndex,
        indexes?.scheduleIndex,
        date
      );
    if (canRemoveProjectToCell())
      return removeProjectFromSchedule(
        indexes?.nodeIndex,
        indexes?.scheduleIndex,
        date
      );
    return message.error('Nie możesz tego zrobić');
  };

  return {
    props: {
      className: `${isCellActive() ? 'table-cell-hover-scale' : ''} ${
        canAddProjectToCell() ? 'table-cell-hover-green' : ''
      } ${canRemoveProjectToCell() ? 'table-cell-hover-red' : ''}`,
      style: {
        backgroundColor: getCellColor(
          date,
          position,
          true,
          isOnlyOtherProjectInDay,
          !isProjectsInActions
        ),
        cursor: isCellActive() ? 'pointer' : 'default',
        wordWrap: 'nowrap',
      },
      onClick: handleCell,
    },
    children: (
      <div>
        {projectsNumbers?.map((nr, i) => (
          <div key={`${nr}${i}`} title={nr}>
            {cutString(nr, projectNumberMaxLength(cellWidth))}
          </div>
        ))}
      </div>
    ),
  };
};
export const renderPartDayCell = (
  action: IAction,
  date: Date,
  position: Positions,
  addProjectToSchedule: IHandleProjectInAction,
  removeProjectFromSchedule: IHandleProjectInAction,
  indexes: IDayPartIndexes,
  cellWidth: number,
  projectId?: string
): ReactNode => {
  const weekDay = dayjs(date).day();
  const isFriday = weekDay === 5;
  const isWeekend = [6, 0].includes(weekDay);

  const isProjectInAction = !!action?.project;

  const isCurrentProjectInAction = action?.project?._id === projectId;
  const isOtherProjectInAction = !!(
    isProjectInAction && !isCurrentProjectInAction
  );

  const isCellActive = () => {
    if (!projectId) return false;
    if (isWeekend) return false;
    if (isFriday && [2, 3].includes(indexes?.actionIndex)) return false;
    if (isOtherProjectInAction) return false;
    return true;
  };
  const canAddProjectToCell = () => {
    if (!projectId) return false;
    if (isWeekend) return false;
    if (isProjectInAction) return false;
    if (isFriday && [2, 3].includes(indexes?.actionIndex)) return false;
    return true;
  };
  const canRemoveProjectToCell = () => {
    if (!isProjectInAction) return false;
    if (!isCurrentProjectInAction) return false;
    return true;
  };

  const handleCell = () => {
    if (canAddProjectToCell())
      return addProjectToSchedule(
        indexes?.nodeIndex,
        indexes?.scheduleIndex,
        indexes?.actionIndex
      );
    if (canRemoveProjectToCell())
      return removeProjectFromSchedule(
        indexes?.nodeIndex,
        indexes?.scheduleIndex,
        indexes?.actionIndex
      );
    return message.error('Nie możesz tego zrobić');
  };

  return {
    props: {
      key: action?._id,
      className: `${isCellActive() ? 'table-cell-hover-scale' : ''} ${
        canAddProjectToCell() ? 'table-cell-hover-green' : ''
      } ${canRemoveProjectToCell() ? 'table-cell-hover-red' : ''}`,
      style: {
        backgroundColor: getCellColor(
          date,
          position,
          true,
          isOtherProjectInAction,
          !isProjectInAction
        ),
        cursor: isCellActive() ? 'pointer' : 'default',
      },
      onClick: handleCell,
    },
    children: (
      <div>
        {action?.project?.number
          ? cutString(action.project?.number, projectNumberMaxLength(cellWidth))
          : ''}
      </div>
    ),
  };
};
