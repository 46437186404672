import React from 'react';
import { ConfigProvider } from 'antd';
import locale from 'antd/es/locale/pl_PL';
import dayjs from 'dayjs';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import 'antd/dist/antd.css';
import 'styles/antd.css';
import 'dayjs/locale/pl';
import store from './services/store';

dayjs.locale('pl');

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={locale}>
      <App />
    </ConfigProvider>
  </Provider>,
  document.getElementById('root')
);
