export enum Permissions {
  ADMIN = 'admin',
  USER = 'user',
}
export enum Positions {
  DESIGNER = 'projektant',
  TECHNOLOGIST = 'technolog',
  CARPENTER = 'stolarz',
  INSTALLER = 'montażysta',
  STOREKEEPER = 'magazynier',
}
