import dayjs from 'dayjs';
import { renderDateTitle } from 'components/ProductionSchedule/components/renderDateTitle';
import { renderProductionNodeCell } from 'components/ProductionSchedule/components/renderProductionNodeCell';
import {
  getCellColor,
  getProductionNodeCellColor,
} from 'components/ProductionSchedule/utils';
import { Positions } from 'services/store/enums/users.enum';
import { IProject } from 'services/store/types/projects/Projects';
import { IUser } from 'services/store/types/users/Users';
import { renderPartDayCell } from './renderDisplayCells';

export const getDisplayDayPartVievTableHeaders = (
  dates: Date[],
  project?: IProject,
  userId?: string,
  position?: Positions
) => {
  const nodesItem = {
    title: 'Gniazda produkcyjne',
    width: 150,
    dataIndex: 'node',
    render: (data: { position: Positions; user: IUser }) => {
      return renderProductionNodeCell(data);
    },
    onCell: (data: any) => {
      const isCellDisabled = isDisabled(
        [data.node.user],
        data.node.position,
        userId,
        position
      );
      return {
        style: {
          backgroundColor: getProductionNodeCellColor(data.node.position),
          opacity: isCellDisabled ? 0.3 : 1,
        },
      };
    },
    key: 'node',
    fixed: 'left',
    align: 'center' as const,
  };
  const datesColumns = dates.reduce((acc: any, date: Date) => {
    const result = [];

    for (let i = 0; i < 4; i++) {
      result.push({
        colSpan: i === 0 ? 4 : 0,
        title: renderDateTitle(date),
        render: (projectNumber: string) => {
          return renderPartDayCell(projectNumber);
        },
        onCell: (data: any, index: any) => {
          const key = dayjs(date).format('DD.MM.YYYY')[i];
          const isProjectInCell = !!(project && project.number === data[key]);
          const isCellDisabled = isDisabled(
            [data.node.user],
            data.node.position,
            userId,
            position
          );

          return {
            style: {
              backgroundColor: isProjectInCell
                ? 'green'
                : getCellColor(date, data.node.position, false),
              opacity: isCellDisabled ? 0.3 : 1,
            },
          };
        },
        dataIndex: `${dayjs(date).format('DD.MM.YYYY')}[${i}]`,
        key: date,
        width: 120,
        align: 'center' as const,
      });
    }
    return [...acc, ...result];
  }, []);
  return [nodesItem, ...datesColumns];
};

const isDisabled = (
  users: IUser[],
  nodePosition: Positions,
  userId?: string,
  position?: Positions
) => {
  if (!userId || !position) return false;
  return (
    !users.map((user) => user?._id).includes(userId) ||
    nodePosition !== position
  );
};
