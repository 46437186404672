import React, { useState } from 'react';
import { Modal, Input } from 'antd';
import { useSelector } from 'react-redux';
import { TAppState } from 'services/store';
import { IComment } from 'services/store/interfaces/comment.interface';

interface IProps {
  closeModal: () => void;
  handleAddComment: (comment: IComment) => Promise<any>;
}

const AddCommentModal: React.FC<IProps> = ({
  closeModal,
  handleAddComment,
}) => {
  const user = useSelector((state: TAppState) => state.auth.user);
  const [comment, setComment] = useState<string>('');

  const onOk = async () => {
    if (user) {
      const commentItem: IComment = {
        user: user?._id,
        date: new Date(),
        comment,
      };
      handleAddComment(commentItem);
      closeModal();
    }
  };

  return (
    <Modal
      title="Nowy komentarz"
      destroyOnClose
      visible
      onCancel={closeModal}
      onOk={onOk}
      maskClosable={false}
    >
      <Input.TextArea
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        placeholder="Komentarz..."
        rows={4}
      />
    </Modal>
  );
};

export default AddCommentModal;
