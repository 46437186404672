import React, { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Popover, Upload, Typography } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { RcFile } from 'antd/lib/upload';
import { initFileData } from 'scenes/ProjectPage/data/initModals.data';
import { uploadFile } from 'services/requests/files.request';
import { FileDestination } from 'services/store/enums/files.enum';
import { HistoryColor } from 'services/store/enums/historyColor.enum';
import { IProject, IProjectFile } from 'services/store/types/projects/Projects';

const { Title } = Typography;

interface IInitFile extends IProjectFile {
  files: RcFile[];
}

interface IProps {
  project: IProject;
  handleUpdateProject: (
    updateData: Partial<IProject>,
    historyUpdate: string[],
    historyColor: HistoryColor
  ) => Promise<any>;
  closeModal: () => void;
}

const AddProjectFileModal: React.FC<IProps> = ({
  project,
  handleUpdateProject,
  closeModal,
}) => {
  const [file, setFiles] = useState<IInitFile>(initFileData);
  const onOk = async () => {
    let fileId: string | undefined = undefined;
    if (file.files.length) {
      fileId = await uploadFile(
        file.files[0],
        FileDestination.PROJECT,
        file.name
      );
    }

    const historyUpdate = [`Dodano plik:`, file.name];
    const fileItem = { ...file, file: fileId };
    const newFiles = [...(project.files || []), fileItem];
    await handleUpdateProject(
      { files: newFiles },
      historyUpdate,
      HistoryColor.SUCCESS
    );

    closeModal();
  };

  const handleChange = (field: keyof IInitFile, value: any) => {
    setFiles({ ...file, [field]: value });
  };

  return (
    <Modal
      title="Dodawanie pliku"
      destroyOnClose
      visible
      onCancel={closeModal}
      maskClosable={false}
      onOk={onOk}
      okButtonProps={{
        disabled: !file.files.length || !file.name,
      }}
    >
      <Title level={5}>Nazwa</Title>
      <Input
        value={file.name}
        onChange={(e) => handleChange('name', e.target.value)}
        placeholder="Nazwa"
      />

      <Title level={5}>Opis</Title>
      <Input
        value={file.description}
        onChange={(e) => handleChange('description', e.target.value)}
        placeholder="Opis"
      />

      <Title level={5}>Plik</Title>
      <Upload
        onRemove={() => handleChange('files', [])}
        beforeUpload={(f: RcFile) => {
          handleChange('files', [f]);
          return false;
        }}
        fileList={file.files}
        multiple={false}
      >
        <Button icon={<UploadOutlined />}>Dodaj plik</Button>
      </Upload>

      <Popover
        title="Dokument chroniony"
        content="Widoczny tylko dla administratora, projektana i technologa"
      >
        <Checkbox
          style={{ marginTop: 10 }}
          checked={file.protected}
          onChange={(e) => handleChange('protected', e.target.checked)}
        >
          Plik chroniony
        </Checkbox>
      </Popover>
    </Modal>
  );
};

export default AddProjectFileModal;
