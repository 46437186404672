import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { isMobile } from 'react-device-detect';
import AccessoriesList from 'scenes/Accessories/components/AccessoriesList';
import { IAccessory } from 'services/store/types/accessories/Accessories';
import { IProjectAccessory } from 'services/store/types/projects/Projects';

interface IProps {
  closeModal: () => void;
  accessories: IAccessory[];
  onSubmit: (accessories: IProjectAccessory[]) => void;
  picked: any[];
}

const PickAccessoriesModal: React.FC<IProps> = ({
  closeModal,
  accessories,
  onSubmit,
  picked,
}) => {
  const [pickedAccessories, setPickedAccessories] = useState<any[]>(picked);

  useEffect(() => {}, []);

  const handlePick = (id: string) => {
    if (!pickedAccessories.includes(id))
      setPickedAccessories((prev: string[]) => [...prev, id]);
    else setPickedAccessories((prev: string[]) => prev.filter((p) => p !== id));
  };

  const onOk = () => {
    const newPicked = accessories
      .filter((acc) => acc._id && pickedAccessories.includes(acc._id))
      .map((acc) => ({ accessory: acc, qty: 1 }));
    onSubmit(newPicked);
    closeModal();
  };

  return (
    <Modal
      title="Wybierz akcesoria"
      destroyOnClose
      width={!isMobile ? 800 : '98vw'}
      visible
      onCancel={closeModal}
      onOk={onOk}
      maskClosable={false}
    >
      <AccessoriesList
        accessories={accessories}
        onRowClick={handlePick}
        picked={pickedAccessories}
        isLoading={false}
      />
    </Modal>
  );
};

export default PickAccessoriesModal;
