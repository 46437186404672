import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

export const H1 = styled.h1`
  font-size: 38px;
  color: ${({ theme }) => theme.primary};
  letter-spacing: 5px;
  cursor: ${({ clickable }: { clickable?: boolean }) =>
    clickable ? 'pointer' : 'default'};
  ${() => {
    if (isMobile) return 'font-size: 28px; letter-spacing: 0px';
    return null;
  }}
`;
export const H2 = styled.h2`
  color: ${({ theme }) => theme.secondary};
  font-size: 26px;
  letter-spacing: 3px;
  ${() => {
    if (isMobile) return 'font-size: 22px; letter-spacing: 0px';
    return null;
  }}
`;
export const H3 = styled.h3`
  color: ${({ theme }) => theme.tertiary};
  font-size: 22px;
  letter-spacing: 2px;
  ${() => {
    if (isMobile) return 'font-size: 18px; letter-spacing:0 ';
    return null;
  }}
`;
export const H4 = styled.h4`
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: bold;
  ${() => {
    if (isMobile) return 'font-size: 16px; letter-spacing:0 ';
    return null;
  }}
`;
export const H5 = styled.h5`
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: bold;
  ${() => {
    if (isMobile) return 'font-size: 16px; letter-spacing:0 ';
    return null;
  }}
`;
