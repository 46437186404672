export enum PaintedElementsOrderPaintType {
  GLOSS = 'połysk',
  MATT = 'mat',
  SEMI_MATT = 'półmat',
}
export enum PaintedElementsOrderStatus {
  SENDED = 'wysłane',
  SAW = 'piła',
  MACHINING = 'obróbka',
  RAW = 'surówka',
  GRINGING = 'szlifiernia',
  BASE = 'podkład',
  PAINT = 'lakiernia',
  POLISH = 'polernia',
  PACKING = 'pakowanie',
  FINISHED = 'zakończone',
  PICKED = 'odebrane',
}
