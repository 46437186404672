import { useState } from 'react';
import { useQueries } from '@tanstack/react-query';
import { Button, message, PageHeader, Row, Select, Tabs } from 'antd';
import { AxiosResponse } from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import { RangeValue } from 'rc-picker/lib/interface';
import { useHistory } from 'react-router';
import AntDatePicker from 'components/DatePicker/DatePicker';
import { H1 } from 'components/Header';
import Spinner from 'components/Spinner';
import { useModal } from 'hook/useModals';
import usePagination from 'hook/usePagination';
import useUser from 'hook/useUser';
import { cashRegisterApi } from 'services/api/cashRegister.api';
import { ISearchResult } from 'services/store/interfaces/search-result.interface';
import {
  CashRegisterOperationType,
  ICashRegister,
  ICashRegisterOperation,
  ICashRegisterReport,
} from 'services/store/types/cashRegister/CashRegister';
import { _cashRegisterOperationTypes } from 'services/utils/const';
import PageTemplate from 'templates/PageTemplate';
import CashRegisterOperationsList from './components/CashRegisterOperationsList';
import CashRegisterReportsList from './components/CashRegisterReportsList';
import ChangeCashRegisterAuthorizedUsers from './components/ChangeCashRegisterAuthorizedUsers.modal';
import ChangeCashRegisterOperationDescriptionsModal from './components/ChangeCashRegisterOperationDescriptions.modal';
import DepositMoneyModal from './components/DepositMoney.modal';
import GenerateReportModal from './components/GenerateReport.modal';
import PayOutMoneyModal from './components/PayOutMoney.modal';
import { CashRegisterPageKey } from './utils/CashRegisterPageKey.enum';

const defaultDateFrom = dayjs().subtract(1, 'month').startOf('month');
const defaultDateTo = dayjs();

const CashRegisterPage = () => {
  const history = useHistory();
  const { user, isSuperAdmin, isUserExistsInArray } = useUser();

  // filters
  const [dateFrom, setDateFrom] = useState<Dayjs>(defaultDateFrom);
  const [dateTo, setDateTo] = useState<Dayjs>(defaultDateTo);
  const [operationType, setOperationType] = useState<
    CashRegisterOperationType | undefined
  >(undefined);
  const [description, setDescription] = useState<string | undefined>(undefined);

  const [key, setKey] = useState<CashRegisterPageKey>(
    CashRegisterPageKey.UNSETTLED
  );

  const {
    pageNumber,
    itemsPerPage,
    handlePagination,
    unpaged,
    totalItems,
    setTotalItems,
    resetPagination,
  } = usePagination({
    itemsPerPage: 50,
  });

  const { modal, showModal, closeModal } = useModal({
    changeAuthorizedUsers: false,
    changeOperationDescriptions: false,
    depositMoney: false,
    payOutMoney: false,
    generateReport: false,
  });

  const [cashRegister, operations, descriptions, reports] = useQueries({
    queries: [
      {
        queryKey: ['get-cash-register'],
        queryFn: () => cashRegisterApi.getCashRegister(),
        onSuccess: (res: AxiosResponse<ICashRegister>) => {
          if (!isSuperAdmin() && !isUserExistsInArray(res.data.authorizedUsers))
            return history.push('/');

          if (res.data.lastPayOut) setDateFrom(dayjs(res.data.lastPayOut));
        },
        onError: () => {
          message.error('Błąd!');
        },
        enabled: !!user,
      },
      {
        queryKey: [
          'get-cash-register-operations',
          dateTo,
          description,
          operationType,
          pageNumber,
          itemsPerPage,
        ],
        queryFn: () =>
          cashRegisterApi.getCashRegisterOperations({
            dateFrom: dateFrom.startOf('day').toISOString(),
            dateTo: dateTo.endOf('day').toISOString(),
            operationType,
            description: description || undefined,
            settled: key === CashRegisterPageKey.SETTLED,
            pageNumber,
            itemsPerPage,
            unpaged,
          }),
        onSuccess: (
          res: AxiosResponse<ISearchResult<ICashRegisterOperation>>
        ) => {
          setTotalItems(res.data.displayParams?.totalItems);
        },
        onError: (error: any) => {
          console.log(error, error.response);
          message.error('Błąd!');
        },
        enabled:
          !!user &&
          [CashRegisterPageKey.UNSETTLED, CashRegisterPageKey.SETTLED].includes(
            key
          ),
      },
      {
        queryKey: ['get-cash-register-operation-descriptions'],
        queryFn: () => cashRegisterApi.getCashRegisterOperationDescriptions(),
        onSuccess: () => {},
        onError: (error: any) => {
          console.log(error, error.response);
          message.error('Błąd!');
        },
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ['get-cash-register-reports', itemsPerPage, pageNumber],
        queryFn: () =>
          cashRegisterApi.getCashRegisterReports({ itemsPerPage, pageNumber }),
        onSuccess: (res: AxiosResponse<ISearchResult<ICashRegisterReport>>) => {
          setTotalItems(res.data.displayParams?.totalItems);
        },
        onError: (error: any) => {
          console.log(error, error.response);
          message.error('Błąd!');
        },
        enabled: isSuperAdmin() && key === CashRegisterPageKey.REPORTS,
        refetchOnWindowFocus: false,
      },
    ],
  });

  const refetchCashRegister = async () => {
    await cashRegister.refetch();
    await operations.refetch();
  };

  const handleDates = (dates: RangeValue<Dayjs>) => {
    if (dates && dates[0] && !dateFrom.isSame(dates[0], 'hour'))
      setDateFrom(dates[0]);
    if (dates && dates[1] && !dateTo.isSame(dates[1], 'hour'))
      setDateTo(dates[1]);
  };

  const clearFilters = () => {
    setDateFrom(defaultDateFrom);
    setDateTo(defaultDateTo);
    setOperationType(undefined);
    setDescription(undefined);
  };
  const handleTab = (k: CashRegisterPageKey) => {
    operations.remove();
    // clearFilters();
    // if (k === CashRegisterPageKey.UNSETTLED) setUnpaged(true);
    // else {
    resetPagination();
    // }

    setKey(k);
  };

  return (
    <PageTemplate>
      {cashRegister.isLoading && <Spinner />}
      <PageHeader
        title={
          <H1>
            Kasa
            {cashRegister.data?.data && `: ${cashRegister.data.data.value}zł`}
          </H1>
        }
        subTitle={`Ostatnie rozliczenie: ${
          cashRegister.data?.data.lastPayOut
            ? dayjs(cashRegister.data.data.lastPayOut).format(
                'DD.MM.YYYYr. hh:mm'
              )
            : '-'
        }`}
        extra={[
          isSuperAdmin() && (
            <Button key={0} onClick={() => showModal('changeAuthorizedUsers')}>
              Edytuj dostępy
            </Button>
          ),
          isSuperAdmin() && (
            <Button
              key={4}
              onClick={() => showModal('changeOperationDescriptions')}
            >
              Edytuj opisy
            </Button>
          ),
          <Button key={1} onClick={() => showModal('depositMoney')}>
            Zasil kasę
          </Button>,
          <Button key={2} onClick={() => showModal('payOutMoney')}>
            Wypłać z kasy
          </Button>,
          isSuperAdmin() ? (
            <Button
              key={3}
              type="primary"
              onClick={() => showModal('generateReport')}
              disabled={
                true
                // key !== CashRegisterPageKey.UNSETTLED ||
                // !operations.data?.data.content.length
              }
            >
              Generuj raport
            </Button>
          ) : null,
        ]}
      />

      {/* NOTE Filters */}
      <Row justify="space-around" style={{ marginBottom: 20 }}>
        <div>
          <AntDatePicker.RangePicker
            size="middle"
            value={[dateFrom, dateTo]}
            onChange={handleDates}
            allowClear={false}
            disabled={key === CashRegisterPageKey.REPORTS}
            format="DD.MM.YYYY"
          />
        </div>
        <div>
          <Select
            size="middle"
            value={operationType}
            onChange={setOperationType}
            style={{ width: 300 }}
            allowClear={true}
            placeholder="Typ"
            disabled={key === CashRegisterPageKey.REPORTS}
          >
            {_cashRegisterOperationTypes.map((type) => (
              <Select.Option key={type} value={type}>
                {type === CashRegisterOperationType.IN ? 'Wpłata' : 'Wypłata'}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div>
          <Select
            size="middle"
            value={description}
            onChange={setDescription}
            style={{ width: 300 }}
            allowClear={true}
            placeholder="Opis"
            disabled={key === CashRegisterPageKey.REPORTS || !descriptions.data}
            loading={descriptions.isLoading}
            options={[
              {
                label: 'Wpłaty',
                options:
                  descriptions.data?.data
                    .filter(
                      (desc) => desc.forType === CashRegisterOperationType.IN
                    )
                    .map((desc) => ({
                      label: desc.value,
                      value: desc.value,
                    })) || [],
              },
              {
                label: 'Wypłaty',
                options:
                  descriptions.data?.data
                    .filter(
                      (desc) => desc.forType === CashRegisterOperationType.OUT
                    )
                    .map((desc) => ({
                      label: desc.value,
                      value: desc.value,
                    })) || [],
              },
            ]}
          />
        </div>
        <div>
          <Button
            danger
            onClick={clearFilters}
            disabled={key === CashRegisterPageKey.REPORTS}
          >
            Wyczyść
          </Button>
        </div>
      </Row>

      {/* NOTE Content */}
      <Tabs
        activeKey={key}
        onChange={(k) => handleTab(k as CashRegisterPageKey)}
      >
        <Tabs.TabPane key={CashRegisterPageKey.UNSETTLED} tab="Operacje">
          <CashRegisterOperationsList
            operations={operations.data?.data.content}
            loading={operations.isFetching}
            pagination={{
              current: pageNumber,
              total: totalItems,
              pageSize: itemsPerPage,
              showSizeChanger: true,
              onChange: handlePagination,
              position: ['topRight', 'bottomRight'],
            }}
          />
        </Tabs.TabPane>
        {isSuperAdmin() && (
          <Tabs.TabPane
            key={CashRegisterPageKey.SETTLED}
            tab="Rozliczone operacje"
          >
            <CashRegisterOperationsList
              operations={operations.data?.data.content}
              loading={operations.isFetching}
              pagination={{
                current: pageNumber,
                total: totalItems,
                pageSize: itemsPerPage,
                showSizeChanger: true,
                onChange: handlePagination,
                position: ['topRight', 'bottomRight'],
              }}
            />
          </Tabs.TabPane>
        )}
        {isSuperAdmin() && (
          <Tabs.TabPane key={CashRegisterPageKey.REPORTS} tab="Raporty">
            <CashRegisterReportsList
              reports={reports.data?.data.content}
              loading={reports.isLoading}
              pagination={{
                current: pageNumber,
                total: totalItems,
                pageSize: itemsPerPage,
                showSizeChanger: true,
                onChange: handlePagination,
                position: ['topRight', 'bottomRight'],
              }}
            />
          </Tabs.TabPane>
        )}
      </Tabs>

      {/* NOTE Modals */}
      {modal.changeAuthorizedUsers && cashRegister?.data && (
        <ChangeCashRegisterAuthorizedUsers
          closeModal={closeModal}
          authorizedUsers={cashRegister.data.data.authorizedUsers}
          refetchCashRegister={refetchCashRegister}
        />
      )}
      {modal.changeOperationDescriptions && descriptions?.data && (
        <ChangeCashRegisterOperationDescriptionsModal
          closeModal={closeModal}
          descriptions={descriptions.data.data}
          refetchDescriptions={descriptions.refetch}
        />
      )}
      {modal.depositMoney && cashRegister?.data && descriptions?.data && (
        <DepositMoneyModal
          cashRegister={cashRegister.data.data}
          operationDescriptions={descriptions.data.data.filter(
            (desc) => desc.forType === CashRegisterOperationType.IN
          )}
          closeModal={closeModal}
          refetchCashRegister={refetchCashRegister}
        />
      )}
      {modal.payOutMoney && cashRegister?.data && descriptions?.data && (
        <PayOutMoneyModal
          cashRegister={cashRegister.data.data}
          operationDescriptions={descriptions.data.data.filter(
            (desc) => desc.forType === CashRegisterOperationType.OUT
          )}
          closeModal={closeModal}
          refetchCashRegister={refetchCashRegister}
        />
      )}
      {modal.generateReport && cashRegister.data && operations.data && (
        <GenerateReportModal
          cashRegister={cashRegister.data.data}
          closeModal={closeModal}
          refetchCashRegister={refetchCashRegister}
        />
      )}
    </PageTemplate>
  );
};

export default CashRegisterPage;
