export enum ProjectStatus {
  PENDING = 'oczekuje',
  PROJECT = 'projekt',
  TECHNOLOGIST = 'technolog',
  CARPENTER = 'stolarz',
  STORAGE = 'magazyn',
  MONTAGE = 'montaż',
  FINISHED = 'zakończony',
  CANCELED = 'anulowany',
}
export enum ProjectImageType {
  MEASURE = 'pomiar',
  MONTAGE = 'montaż',
  REALIZATION = 'realizacja',
  OTHER = 'inne',
}
export enum ProjectEquipmentDestination {
  BLOW = 'blow',
  LOCAL = 'lokal',
}
