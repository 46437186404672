import React, { useEffect, useState } from 'react';
import { Table, Typography } from 'antd';
import { useHistory } from 'react-router';
import UserPositionTag from 'components/Tags/UserPositionTag/UserPositionTag';
import { Positions } from 'services/store/enums/users.enum';
import { IUser } from 'services/store/types/users/Users';
import { capitalizeFirstLetter } from 'services/utils/string';

const { Text } = Typography;

const columns = [
  {
    title: 'Lp.',
    align: 'center' as const,
    render: (t: string, r: any, i: number) => i + 1,
  },
  {
    title: 'Imię',
    dataIndex: 'firstName',
    align: 'center' as const,
    sorter: (a: IUser, b: IUser) => a.firstName.localeCompare(b.firstName),
    render: (firstName: string) => capitalizeFirstLetter(firstName),
  },
  {
    title: 'Nazwisko',
    dataIndex: 'lastName',
    align: 'center' as const,
    sorter: (a: IUser, b: IUser) => a.lastName.localeCompare(b.lastName),
    render: (lastName: string) => capitalizeFirstLetter(lastName),
  },
  {
    title: 'E-mail',
    dataIndex: 'email',
    align: 'center' as const,
    sorter: (a: IUser, b: IUser) => a.email.localeCompare(b.email),
    render: (email: string) => <Text>{email}</Text>,
  },
  {
    title: 'Telefon',
    dataIndex: 'phone',
    align: 'center' as const,
    render: (phone: string) => <Text>{phone}</Text>,
  },
  {
    title: 'Uprawnienia',
    dataIndex: 'permission',
    align: 'center' as const,
    sorter: (a: IUser, b: IUser) => a.permission.localeCompare(b.permission),
    render: (permission: string) => capitalizeFirstLetter(permission),
  },
  {
    title: 'Stanowisko',
    dataIndex: 'positions',
    align: 'center' as const,
    render: (positions: Positions[]) =>
      positions.map((position) => (
        <UserPositionTag key={position} position={position} />
      )),
  },
  {
    title: 'Stawka godzinowa [zł]',
    dataIndex: 'earnings',
    align: 'center' as const,
    sorter: (a: IUser, b: IUser) => a.earnings - b.earnings,
  },
];

interface IProps {
  users?: IUser[];
  isLoading: boolean;
  maxHeight?: number;
}
const UsersList: React.FC<IProps> = ({ users, isLoading, maxHeight }) => {
  const history = useHistory();
  const [list, setList] = useState<any[]>([]);

  useEffect(() => {
    if (users) {
      const newList = users?.map((user: IUser, i: number) => ({
        key: user._id,
        ...user,
      }));

      setList(newList);
    }
  }, [users]);

  const handleRedirect = (id: string) => history.push(`/users/${id}`);

  return (
    <div>
      <Table
        columns={columns}
        dataSource={list}
        pagination={false}
        loading={isLoading}
        rowClassName={() => 'table-row-clicable'}
        scroll={{ x: 1000, y: maxHeight }}
        onRow={(record) => {
          return {
            onClick: () => {
              handleRedirect(record._id);
            },
          };
        }}
      />
    </div>
  );
};

export default UsersList;
