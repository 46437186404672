import { ProjectStatus } from 'services/store/enums/project.enum';
import { Positions } from 'services/store/enums/users.enum';
import { IProject } from 'services/store/types/projects/Projects';
import { _projectStatuses } from './const';

export const isProjectOnActivePosition = (
  projectStatus: ProjectStatus[],
  activePosition: Positions | undefined
): boolean => {
  if (!activePosition) return false;
  if (!projectStatus.length) return false;
  if (activePosition === Positions.DESIGNER) {
    return projectStatus.includes(ProjectStatus.PROJECT);
  } else if (activePosition === Positions.TECHNOLOGIST) {
    return projectStatus.includes(ProjectStatus.TECHNOLOGIST);
  } else if (activePosition === Positions.CARPENTER) {
    return projectStatus.includes(ProjectStatus.CARPENTER);
  } else if (activePosition === Positions.INSTALLER) {
    return projectStatus.includes(ProjectStatus.MONTAGE);
  } else if (activePosition === Positions.STOREKEEPER) {
    return projectStatus.includes(ProjectStatus.STORAGE);
  }
  return false;
};

export const getProjectStatusFromActivePosition = (
  activePosition: Positions | undefined
): ProjectStatus | undefined => {
  if (!activePosition) return undefined;
  if (activePosition === Positions.DESIGNER) {
    return ProjectStatus.PROJECT;
  } else if (activePosition === Positions.TECHNOLOGIST) {
    return ProjectStatus.TECHNOLOGIST;
  } else if (activePosition === Positions.CARPENTER) {
    return ProjectStatus.CARPENTER;
  } else if (activePosition === Positions.INSTALLER) {
    return ProjectStatus.MONTAGE;
  } else if (activePosition === Positions.STOREKEEPER) {
    return ProjectStatus.STORAGE;
  }
  return undefined;
};

export const getPositionFromProjectStatus = (
  status: ProjectStatus
): Positions | undefined => {
  if (status === ProjectStatus.PROJECT) {
    return Positions.DESIGNER;
  } else if (status === ProjectStatus.TECHNOLOGIST) {
    return Positions.TECHNOLOGIST;
  } else if (status === ProjectStatus.CARPENTER) {
    return Positions.CARPENTER;
  } else if (status === ProjectStatus.MONTAGE) {
    return Positions.INSTALLER;
  } else if (status === ProjectStatus.STORAGE) {
    return Positions.STOREKEEPER;
  }
  return undefined;
};

export const sortProjectStatusByProductionProcess = (
  status: ProjectStatus[]
): ProjectStatus[] => {
  const sortedStatus: ProjectStatus[] = [];

  _projectStatuses.forEach((st) => {
    if (status.includes(st)) sortedStatus.push(st);
  });
  return sortedStatus;
};

export const isUserWorkingOnProject = (project: IProject, userId: string) => {
  const userWorkingItem = project.workingOn?.find(
    (item) => item.user === userId
  );
  if (userWorkingItem) return true;
  return false;
};

export const isUserWorkingOnProjectAtPosition = (
  project: IProject,
  position: Positions,
  userId: string
) => {
  const userWorkingItem = project.workingOn?.find(
    (item) => item.position === position && item.user === userId
  );
  if (userWorkingItem) return true;
  return false;
};

export const isSomeoneWorkingOnProjectAtPosition = (
  project: IProject,
  position?: Positions
) => {
  if (!position) return false;
  const userWorkingItem = project.workingOn?.find(
    (item) => item.position === position
  );
  if (userWorkingItem) return true;
  return false;
};
