import { useState } from 'react';
import { debounce } from 'lodash';

const debounceControledInput = debounce(
  (value: string | undefined, dispatch: (v: string | undefined) => void) =>
    dispatch(value),
  700
);

export const useControledDebounce = () => {
  const [value, setValue] = useState<string | undefined>(undefined);
  const [debouncedValue, setDebouncedValue] = useState<string | undefined>(
    undefined
  );

  const handleDebouncedValue = (v: string | undefined) => {
    setValue(v);
    return debounceControledInput(v, setDebouncedValue);
  };

  return { value, debouncedValue, handleDebouncedValue };
};
