import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import { Field, Form, Formik } from 'formik';
import {
  AntInput,
  AntTextArea,
} from 'components/Formik-AntdFields/CreateAntdField';
import { IAddress } from 'services/store/types/address/Address';
import { addressValidation } from './utils/validation';

const initValues = {
  city: '',
  postcode: '',
  address: '',
  description: '',
};

interface PropsT {
  closeModal: () => void;
  onSubmit: (values: IAddress) => void;
  address?: IAddress;
  title?: string;
}

const AddressModal: React.FC<PropsT> = ({
  onSubmit,
  closeModal,
  address,
  title,
}) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values: IAddress) => {
    setLoading(true);
    onSubmit(values);
    setLoading(false);
    closeModal();
  };
  return (
    <Modal
      title={title || 'Adres'}
      destroyOnClose
      visible
      onCancel={closeModal}
      maskClosable={false}
      footer={[
        <Button key="back" onClick={closeModal}>
          Anuluj
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          htmlType="submit"
          form="EditCustomerAddress"
        >
          Zatwierdź
        </Button>,
      ]}
    >
      <Formik
        validationSchema={addressValidation}
        onSubmit={handleSubmit}
        initialValues={address || initValues}
      >
        {(props) => {
          return (
            <Form
              noValidate
              id="EditCustomerAddress"
              onSubmit={props.handleSubmit}
            >
              <Field
                component={AntInput}
                name="city"
                type="text"
                placeholder="Miejscowość"
                validate={props.errors.city}
                submitCount={props.submitCount}
                hasFeedback
              />
              <Field
                component={AntInput}
                name="postcode"
                type="text"
                placeholder="Kod pocztowy"
                validate={props.errors.postcode}
                submitCount={props.submitCount}
                hasFeedback
              />
              <Field
                component={AntInput}
                name="address"
                type="text"
                placeholder="Adres"
                validate={props.errors.address}
                submitCount={props.submitCount}
                hasFeedback
              />
              <Field
                component={AntTextArea}
                name="description"
                type="text"
                placeholder="Opis..."
                validate={props.errors.description}
                submitCount={props.submitCount}
                hasFeedback
              />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AddressModal;
