import React from 'react';
import { message } from 'antd';
import { useDispatch } from 'react-redux';
import { removeFileById } from 'services/requests/files.request';
import { setLoading } from 'services/store/actions/view';
import { HistoryColor } from 'services/store/enums/historyColor.enum';
import { IFile } from 'services/store/types/files/Files';
import { IOrder, IOrderDocument } from 'services/store/types/orders/Orders';
import DocumentsList from './DocumentsList';

interface IProps {
  order: IOrder;
  handleUpdateOrder: (
    updateData: Partial<IOrder>,
    historyUpdate: string[],
    historyColor: HistoryColor
  ) => Promise<any>;
}

const OrderDocuments: React.FC<IProps> = ({ order, handleUpdateOrder }) => {
  const dispatch = useDispatch();

  const handleRemoveDocument = async (document: IOrderDocument) => {
    dispatch(setLoading(true));

    const removeFileResponse = await removeFileById(
      (document.file as IFile)._id
    );
    if (removeFileResponse) {
      message.success('Plik usunięty');

      const newDocuments = order.documents?.filter(
        (doc) => doc._id !== document._id
      );
      const historyUpdate = [`Usunięto dokument:`, document.name];

      await handleUpdateOrder(
        { documents: newDocuments },
        historyUpdate,
        HistoryColor.DANGER
      );
    }
  };

  return (
    <div>
      <DocumentsList
        documents={order.documents}
        handleRemoveDocument={handleRemoveDocument}
      />
    </div>
  );
};

export default OrderDocuments;
