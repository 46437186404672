import React from 'react';
import { List } from 'antd';
import styled from 'styled-components';
import InnerSpinner from 'components/Spinner/InnerSpinner';
import { ISchedule } from 'services/store/types/schedule/Schedule';
import ScheduleItem from './ScheduleItem';

const ScheduleWrapper = styled.div`
  position: relative;
  max-height: 300px;
  overflow-y: scroll;
`;

interface IProps {
  schedule: ISchedule[];
  isLoading: boolean;
  getSchedule: () => Promise<any>;
}

const ScheduleList: React.FC<IProps> = ({
  schedule,
  isLoading,
  getSchedule,
}) => {
  return (
    <ScheduleWrapper>
      {isLoading && <InnerSpinner />}
      <List
        itemLayout="horizontal"
        dataSource={schedule}
        renderItem={(item) => (
          <ScheduleItem scheduleItem={item} getSchedule={getSchedule} />
        )}
      />
    </ScheduleWrapper>
  );
};

export default ScheduleList;
