import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { Form, InputNumber, message, Modal } from 'antd';
import dayjs from 'dayjs';
import FileSaver from 'file-saver';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  cashRegisterApi,
  cashRegisterApiV2,
} from 'services/api/cashRegister.api';
import {
  CashRegisterOperationType,
  ICashRegister,
} from 'services/store/types/cashRegister/CashRegister';

const schema = yup.object({
  value: yup.number(),
  description: yup
    .string()
    .required('Opis jest wymagany')
    .min(3, 'Min. 3 znaki')
    .max(250, 'Max. 250 znaków'),
});

interface IFormValues {
  value: number;
  description: string;
}

interface IProps {
  cashRegister: ICashRegister;
  closeModal: () => void;
  refetchCashRegister: () => Promise<any>;
}

const GenerateReportModal: React.FC<IProps> = ({
  closeModal,
  refetchCashRegister,
  cashRegister,
}) => {
  const { mutate: generateReport, isLoading } = useMutation({
    mutationKey: ['generate-cash-register-report'],
    mutationFn: (values: IFormValues) => {
      return cashRegisterApiV2.generateReport({
        ...values,
        operationType: CashRegisterOperationType.OUT,
      });
    },
    onSuccess: async (res) => {
      FileSaver.saveAs(
        new Blob([res.data], { type: 'application/pdf' }),
        `raport_${dayjs().format('DD-MM-YYYY_HH-mm')}.pdf`
      );
      await refetchCashRegister();
      message.success('Rozliczono operacje w kasie');
      closeModal();
    },
    onError: () => {
      message.error('Błąd!');
    },
  });

  const confirmGenerateReport = async (values: IFormValues) => {
    const result = cashRegister.value - formik.values.value;
    if (result < 0)
      return message.error('W kasie nie ma wystarczającej ilości pieniędzy');
    if (formik.values.value === 0) {
      return Modal.confirm({
        title: 'Wartość wypłaty wynosi 0zł',
        content:
          'Czy na pewno chcesz rozliczyć operacje bez wypłacania pieniędzy z kasy?',
        icon: <ExclamationCircleOutlined />,
        onOk: () => generateReport(values),
      });
    }
    return generateReport(values);
  };

  const formik = useFormik<IFormValues>({
    initialValues: { value: 0, description: 'Raport' },
    onSubmit: (values) => confirmGenerateReport(values),
    validationSchema: schema,
  });

  return (
    <Modal
      title="Generowanie raportu"
      destroyOnClose
      visible
      onCancel={closeModal}
      maskClosable={false}
      onOk={() => formik.handleSubmit()}
      okButtonProps={{ loading: isLoading, disabled: !formik.isValid }}
    >
      <Form labelCol={{ xs: { span: 24 }, sm: { span: 4 } }}>
        <Form.Item
          validateStatus={formik.errors.value && 'error'}
          help={formik.errors.value}
          label="Wartość"
        >
          <InputNumber
            {...formik.getFieldProps('value')}
            onChange={(value) => formik.setFieldValue('value', value)}
            min={0}
            max={cashRegister.value}
          />
        </Form.Item>

        <div>
          <div>Aktualna wartość kasy: {cashRegister.value} zł</div>
          <div>
            Wartość kasy po wypłacie: {cashRegister.value - formik.values.value}{' '}
            zł
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default GenerateReportModal;
