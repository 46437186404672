import React from 'react';
import { ArrowRightOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import styled from 'styled-components';

const FixedButton = styled(Button)`
  position: fixed;
  top: 75px;
  left: -5px;
`;

const AppInfoButton = ({ ...props }) => {
  return (
    <FixedButton type="primary" {...props}>
      <InfoCircleOutlined />
    </FixedButton>
  );
};

export default AppInfoButton;
