import dayjs from 'dayjs';
import { _weekDays } from 'services/utils/const';
import { getDateTextColor } from '../utils';

export const renderDateTitle = (date: Date) => {
  const day = dayjs(date);
  const dateName = day.format('DD.MM');
  const weekDay = day.day();
  const weekDayName = _weekDays[weekDay];
  return (
    <div style={{ color: getDateTextColor(weekDay) }}>
      {dateName}
      <div>
        <small>{weekDayName}</small>
      </div>
    </div>
  );
};
