import React, { useState } from 'react';
import { List } from 'antd';
import dayjs from 'dayjs';
import TextButton from 'components/Buttons/TextButton/TextButton';
import { ISchedule } from 'services/store/types/schedule/Schedule';
import { IUser } from 'services/store/types/users/Users';
import { getUserName } from 'services/utils/string';
import ScheduleItemDetailsModal from './ScheduleItemDetailsModal';

interface IProps {
  scheduleItem: ISchedule;
  getSchedule: () => Promise<any>;
}

const ScheduleItem: React.FC<IProps> = ({ scheduleItem, getSchedule }) => {
  const [isDetails, setIsDetails] = useState(false);
  const today = dayjs();
  const itemDate = dayjs(scheduleItem.date);
  const diff = today.diff(itemDate, 'hour');

  return (
    <List.Item
      extra={[
        <TextButton text="Pokaż" key={1} onClick={() => setIsDetails(true)} />,
      ]}
      style={{
        padding: 5,
        backgroundColor:
          diff > 0
            ? 'rgba(255, 0, 17, .2)'
            : diff === 0
            ? 'rgba(8, 92, 0, .2)'
            : 'white',
      }}
    >
      <List.Item.Meta
        title={`${itemDate.format('DD.MM.YYYY')} - ${scheduleItem.name}`}
        description={
          <small>
            Utworzone przez:{' '}
            <strong>{getUserName(scheduleItem.createdBy as IUser)}</strong>
          </small>
        }
      />
      {/* MODAL */}
      {isDetails && (
        <ScheduleItemDetailsModal
          scheduleItem={scheduleItem}
          closeModal={() => setIsDetails(false)}
          getSchedule={getSchedule}
        />
      )}
    </List.Item>
  );
};

export default ScheduleItem;
