import axios from 'axios';

const interceptor = (history: any = null) => {
  axios.interceptors.request.use(
    (config) => {
      if (localStorage.token)
        config.headers['x-auth-token'] = localStorage.token;
      else delete config.headers['x-auth-token'];
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  // axios.interceptors.response.use(
  //   (response) => {
  //     return response;
  //   },
  //   (error) => {
  //     console.log(error, error.response);
  //     if (error.response?.status === 401) {
  //       // localStorage.removeItem('token');
  //       // localStorage.removeItem('permission');
  //       // window.location.replace('/login');
  //     }
  //     throw error;
  //   }
  // );
};

export default interceptor;
