import { Positions } from '../enums/users.enum';
import {
  TAuthActions,
  AUTH_USER_LOAD_SUCCESS,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_ERROR,
  AUTH_LOGOUT_USER,
} from '../types/auth/actions';
import { IAuth } from '../types/auth/Auth';

const initialState: IAuth = {
  token: localStorage.getItem('token'),
  isAuth: false,
  user: null,
};

const authReducer = (state = initialState, action: TAuthActions): IAuth => {
  switch (action.type) {
    case AUTH_LOGIN_SUCCESS: {
      localStorage.setItem('token', action.token);
      return { ...state, isAuth: true, token: action.token };
    }

    case AUTH_LOGIN_ERROR: {
      localStorage.removeItem('token');
      localStorage.removeItem('permission');
      return { ...initialState };
    }

    case AUTH_USER_LOAD_SUCCESS: {
      localStorage.setItem('permission', action.permission);
      return { ...state, isAuth: true, user: action.user };
    }

    case AUTH_LOGOUT_USER: {
      localStorage.removeItem('token');
      localStorage.removeItem('permission');
      return { ...initialState };
    }
    default:
      return state;
  }
};

export { authReducer };
