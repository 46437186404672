import {
  BankOutlined,
  ContactsOutlined,
  DesktopOutlined,
  FolderAddOutlined,
  HomeOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';

interface IActions {
  showModal: (key: any) => void;
}

const SettingsActions = (key: string, actions: IActions) => {
  if (key === 'productionNode')
    return [
      <Button key={1} onClick={() => actions.showModal('addProductionNode')}>
        <FolderAddOutlined />
        Utwórz gniazdo productyjne
      </Button>,
    ];
  else if (key === 'categories')
    return [
      <Button key={1} onClick={() => actions.showModal('addCategory')}>
        <UnorderedListOutlined />
        Dodaj kategorię
      </Button>,
    ];
  else if (key === 'workActions')
    return [
      <Button key={1} onClick={() => actions.showModal('addWorkAction')}>
        <UnorderedListOutlined />
        Dodaj akcję
      </Button>,
    ];
  else if (key === 'companyData')
    return [
      <Button key={1} onClick={() => actions.showModal('editCompanyData')}>
        <BankOutlined />
        Zmień dane firmy
      </Button>,
      <Button key={2} onClick={() => actions.showModal('addAddress')}>
        <HomeOutlined />
        Dodaj adres
      </Button>,
      <Button key={3} onClick={() => actions.showModal('addContact')}>
        <ContactsOutlined />
        Dodaj kontakt
      </Button>,
      <Button key={4} onClick={() => actions.showModal('addWebPage')}>
        <DesktopOutlined />
        Dodaj stronę
      </Button>,
    ];

  return [];
};

export default SettingsActions;
