import React, { useEffect, useState } from 'react';
import { Table, TableColumnsType, Typography } from 'antd';
import dayjs from 'dayjs';
import humanizeDuration from 'humanize-duration';
import { H5 } from 'components/Header';
import UserPositionTag from 'components/Tags/UserPositionTag/UserPositionTag';
import { Positions } from 'services/store/enums/users.enum';
import { IWorkAction } from 'services/store/types/settings/Settings';
import { IWorkTime } from 'services/store/types/statistics/Statistics';
import { ISortedByUserWorkingTime } from 'services/utils/statistics.utils';
import { getUserName } from 'services/utils/string';
import UpdateWorkTimeItemModal from '../Modals/UpdateWorkTimeItem.modal';

const columns: TableColumnsType<IWorkTime> = [
  {
    dataIndex: 'lp',
    title: 'Lp.',
    align: 'center' as const,
    render: (e: any, r: any, i: number) => i + 1,
    width: 50,
  },
  {
    title: 'Stanowisko',
    dataIndex: 'position',
    align: 'center' as const,
    render: (position: Positions) => <UserPositionTag position={position} />,
  },
  {
    title: 'Data rozpoczęcia',
    dataIndex: 'start',
    align: 'center' as const,
    render: (start: Date) => dayjs(start).format('DD.MM.YYYY, HH:mm'),
  },
  {
    title: 'Data zakończenia',
    dataIndex: 'finish',
    align: 'center' as const,
    render: (finish: Date) => dayjs(finish).format('DD.MM.YYYY, HH:mm'),
  },
  {
    title: 'Czas trwania',
    dataIndex: 'duration',
    align: 'center' as const,
    render: (duration: Date) => {
      const durationTimestamp = new Date(duration).getTime();
      const durationText = humanizeDuration(durationTimestamp, {
        units: ['h', 'm'],
        round: true,
        language: 'pl',
      });
      return durationText;
    },
  },
  {
    title: 'Koszt',
    dataIndex: 'cost',
    align: 'center' as const,
    render: (cost: number) => {
      return `${cost} zł`;
    },
  },
  {
    title: 'Akcja',
    dataIndex: 'action',
    align: 'center' as const,
    render: (action?: IWorkAction) => action || '-',
  },
];

interface IProps {
  workingTimes: ISortedByUserWorkingTime;
  maxHeight?: number;
  getWorkTimes?: () => Promise<any>;
}

const UserWorkingTimeTable: React.FC<IProps> = ({
  workingTimes,
  maxHeight,
  getWorkTimes,
}) => {
  const [list, setList] = useState<IWorkTime[]>(workingTimes.workingTime);
  const [workTimeItemToUpdate, setWorkTimeItemToUpdate] =
    useState<IWorkTime | null>(null);

  useEffect(() => {
    const newList = workingTimes.workingTime.map((item, i) => {
      return {
        ...item,
        key: `${item._id}${new Date(item.start).getTime()}`,
      };
    });
    setList(newList);
  }, []);

  return (
    <div style={{ marginBottom: 30 }}>
      <H5>
        {getUserName(workingTimes?.user)} ({workingTimes?.user?.earnings}zł/h)
      </H5>
      <Table
        className="table-row-clicable"
        columns={columns}
        pagination={false}
        dataSource={list}
        scroll={{ x: 1000, y: maxHeight }}
        onRow={(item: IWorkTime) => {
          return { onClick: () => setWorkTimeItemToUpdate(item) };
        }}
        size="small"
        summary={(data) => {
          const totalDuration = data.reduce((acc, item) => {
            const timeStamp = new Date(item.duration).getTime();
            return (acc += timeStamp);
          }, 0);
          const durationText = humanizeDuration(totalDuration, {
            units: ['h', 'm'],
            round: true,
            language: 'pl',
          });

          const totalCost = data.reduce((acc, item) => {
            return (acc += item.cost || 0);
          }, 0);
          return (
            <>
              <Table.Summary.Row style={{ backgroundColor: 'lightgray' }}>
                <Table.Summary.Cell index={1} colSpan={4}>
                  Podsumowanie
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} align="center">
                  <Typography.Text strong>{durationText}</Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} align="center">
                  <Typography.Text strong>{totalCost}zł</Typography.Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />

      {workTimeItemToUpdate && (
        <UpdateWorkTimeItemModal
          closeModal={() => setWorkTimeItemToUpdate(null)}
          workTimeItem={workTimeItemToUpdate}
          getWorkTimes={getWorkTimes}
        />
      )}
    </div>
  );
};

export default UserWorkingTimeTable;
