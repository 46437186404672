import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { ThemeProvider } from 'styled-components';
import permissionContext from 'context/PermissionContext';
import { loadUserData } from 'services/store/actions/auth';
import GlobalStyle from 'theme/GlobalStyle';
import { theme } from 'theme/MainTheme';

const MainTemplate: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const permission = localStorage.getItem('permission');

  useEffect(() => {
    dispatch(
      loadUserData(() => {
        if (!location.pathname.includes('login')) history.push('/login');
      })
    );
  }, []);

  return (
    <>
      <permissionContext.Provider value={permission || 'user'}>
        <GlobalStyle />
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </permissionContext.Provider>
    </>
  );
};

export default MainTemplate;
