import { Spin } from 'antd';
import styled from 'styled-components';

const SpinnerWrapper = styled.div`
  z-index: 10;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.6);
  position: absolute;
  width: 100%;
  height: 100%;
`;
const SpinnerBox = styled.div`
  z-index: 11;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const InnerSpinner = () => (
  <SpinnerWrapper>
    <SpinnerBox>
      <Spin size="large" />
    </SpinnerBox>
  </SpinnerWrapper>
);

export default InnerSpinner;
