import React from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Col, Descriptions, Row, Skeleton, Typography } from 'antd';
import Address from 'components/Address/Address';
import { ICustomer } from 'services/store/types/customers/Customers';

const { Text } = Typography;

interface IProps {
  customer?: ICustomer;
  showAddress?: boolean;
}

const CustomerData: React.FC<IProps> = ({ customer, showAddress = false }) => {
  if (customer)
    return (
      <Row gutter={8}>
        <Col md={6} span={24}>
          <Descriptions
            title={
              <>
                {customer.isOnBlackList && (
                  <ExclamationCircleFilled
                    style={{ color: 'red' }}
                    title="Klient jest na czarnej liście"
                  />
                )}
                Dane osobowe
              </>
            }
            size="small"
            column={1}
          >
            {customer.company && (
              <Descriptions.Item label="Nazwa firmy">
                <strong>{customer.company}</strong>
              </Descriptions.Item>
            )}
            {customer.nip && (
              <Descriptions.Item label="NIP">
                <strong>{customer.nip}</strong>
              </Descriptions.Item>
            )}
            <Descriptions.Item label="Imię">
              <strong>{customer.firstName}</strong>
            </Descriptions.Item>
            <Descriptions.Item label="Nazwisko">
              <strong>{customer.lastName}</strong>
            </Descriptions.Item>
            <Descriptions.Item label="E-mail">
              <strong>
                <Text copyable>{customer.email}</Text>
              </strong>
            </Descriptions.Item>
            <Descriptions.Item label="Telefon">
              <strong>
                <Text copyable>{customer.phone}</Text>
              </strong>
            </Descriptions.Item>
          </Descriptions>
        </Col>

        {showAddress && (
          <Col md={6} span={24}>
            <Address address={customer.address} />
          </Col>
        )}
      </Row>
    );
  return (
    <Row gutter={8}>
      <Col md={6} span={24}>
        <Skeleton title={{ width: 100 }} paragraph={{ rows: 7 }} />
      </Col>

      <Col md={6} span={24}>
        <Skeleton title={{ width: 100 }} paragraph={{ rows: 4 }} />
      </Col>
    </Row>
  );
};

export default CustomerData;
