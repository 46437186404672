import styled from 'styled-components';

export const ProjectsCartsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 20px 10px;
  & > .ant-card {
    margin-right: 10px;
    width: 300px;
    &:hover {
      cursor: pointer;
      box-shadow: 2px 2px 10px 0px rgba(66, 68, 90, 0.5);
    }
  }
`;
export const SliderWrapper = styled.div`
  width: 300px;
`;
