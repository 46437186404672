import { IWorkTime } from 'services/store/types/statistics/Statistics';

export const getUsersStatisticsSummary = (workTimes: readonly IWorkTime[]) => {
  const summary = workTimes.reduce(
    (acc, workTime) => {
      acc.cost += workTime.cost || 0;
      acc.duration += new Date(workTime.duration).getTime();
      return acc;
    },
    {
      cost: 0,
      duration: 0,
    }
  );
  return summary;
};
