import React, { useEffect, useState } from 'react';
import { Empty, message, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { TAppState } from 'services/store';
import {
  addProjectToNodeDay,
  removeProjectFromNodeDay,
} from 'services/store/actions/productionNodes';
import { IProject } from 'services/store/types/projects/Projects';
import { getEditDayViewData } from './utils/getEditDayViewData';
import { getEditDayViewTableHeaders } from './utils/getEditDayViewTableHeaders';

interface IProps {
  project?: IProject;
  isChanges: boolean;
  setIsChanges: (value: boolean) => void;
  addProjectToChanged: (id: string) => void;
}

const DayView: React.FC<IProps> = ({
  project,
  isChanges,
  setIsChanges,
  addProjectToChanged,
}) => {
  const dispatch = useDispatch();
  const nodes = useSelector((state: TAppState) => state.productionNodes.nodes);
  const dates = useSelector((state: TAppState) => state.productionNodes.dates);
  const cellWitdh = useSelector(
    (state: TAppState) => state.view.projectProductionEditCellWidth
  );

  const [columns, setColumns] = useState<any[]>([]);
  const [list, setList] = useState<any>([]);

  // --------------------------------------------------------------------------
  // NOTE COLUMNS
  // --------------------------------------------------------------------------
  useEffect(() => {
    if (dates.length) {
      const columnHeaders = getEditDayViewTableHeaders(
        dates,
        addProjectToSchedule,
        removeProjectFromSchedule,
        cellWitdh,
        project
      );
      setColumns(columnHeaders);
    }
  }, [dates, project, cellWitdh]);

  //   DATA
  useEffect(() => {
    if (nodes.length && columns.length) {
      const newList = getEditDayViewData(nodes);
      setList(newList);
    }
  }, [nodes, columns]);

  const addProjectToSchedule = async (
    nodeIndex: number,
    scheduleItemIndex: number,
    date: Date
  ) => {
    if (!project) return message.error('Brak projektu do dodania');
    if (!project._id) return message.error('Brak projektu do dodania');
    if (!isChanges) setIsChanges(true);
    addProjectToChanged(project._id);
    dispatch(addProjectToNodeDay(nodeIndex, scheduleItemIndex, project, date));
  };
  const removeProjectFromSchedule = async (
    nodeIndex: number,
    scheduleItemIndex: number,
    date: Date
  ) => {
    if (!isChanges) setIsChanges(true);
    if (project?._id) addProjectToChanged(project._id);
    dispatch(removeProjectFromNodeDay(nodeIndex, scheduleItemIndex, date));
  };

  if (!columns.length || !list.length) return <Empty />;
  return (
    <Table
      bordered
      columns={columns}
      dataSource={list}
      pagination={false}
      size="small"
      scroll={{ x: 1000, y: '80vh' }}
    />
  );
};

export default DayView;
