import React, { useEffect, useState } from 'react';
import { Empty, Table } from 'antd';
import { useSelector } from 'react-redux';
import { TAppState } from 'services/store';
import { Positions } from 'services/store/enums/users.enum';
import { IProject } from 'services/store/types/projects/Projects';
import { getDisplayDayViewData } from './utils/getDisplayDayViewData';
import { getDisplayDayViewTableHeaders } from './utils/getDisplayDayViewTableHeaders';

interface IProps {
  project?: IProject;
  userId?: string;
  position?: Positions;
}

const DayViewProductionSchedule: React.FC<IProps> = ({
  project,
  userId,
  position,
}) => {
  const dates = useSelector((state: TAppState) => state.productionNodes.dates);
  const nodes = useSelector((state: TAppState) => state.productionNodes.nodes);

  const [columns, setColumns] = useState<any[]>([]);
  const [list, setList] = useState<any[]>([]);

  // --------------------------------------------------------------------------
  // NOTE COLUMNS
  // --------------------------------------------------------------------------
  useEffect(() => {
    if (dates.length) {
      const columnHeaders = getDisplayDayViewTableHeaders(
        dates,
        project,
        userId,
        position
      );
      setColumns(columnHeaders);
    }
  }, [dates]);

  //   DATA
  useEffect(() => {
    if (nodes.length && columns.length) {
      const newList = getDisplayDayViewData(nodes);
      setList(newList);
    }
  }, [nodes, columns]);

  if (!columns.length || !list.length) return <Empty />;
  return (
    <Table
      bordered
      columns={columns}
      dataSource={list}
      pagination={false}
      size="small"
      scroll={{ x: 1000, y: 400 }}
    />
  );
};

export default DayViewProductionSchedule;
