import React, { useEffect, useState } from 'react';
import { Table, TableProps, Typography } from 'antd';
import dayjs from 'dayjs';
import CashRegisterOperationTypeTag from 'components/Tags/CashRegisterOperationTypeTag/CashRegisterOperationTypeTag';
import {
  CashRegisterOperationType,
  ICashRegisterOperation,
} from 'services/store/types/cashRegister/CashRegister';
import { IUser } from 'services/store/types/users/Users';
import { cutString, getUserName } from 'services/utils/string';
import { getCashRegisterOperationBalance } from '../utils/cashRegister.utils';
const { Text } = Typography;

const columns = [
  {
    title: 'Data',
    align: 'center' as const,
    dataIndex: 'createdAt',
    render: (createdAt: Date, r: any, i: number) =>
      dayjs(createdAt).format('DD.MM.YYYY, hh:mm'),
  },
  {
    title: 'Użytkownik',
    dataIndex: 'user',
    align: 'center' as const,
    render: (user: IUser) => (user ? getUserName(user) : '-'),
  },
  {
    title: 'Typ',
    dataIndex: 'operationType',
    align: 'center' as const,
    render: (operationType: CashRegisterOperationType) => (
      <CashRegisterOperationTypeTag operationType={operationType} />
    ),
  },
  {
    title: 'Opis',
    dataIndex: 'description',
    align: 'center' as const,
    render: (description: string) => cutString(description, 50),
  },
  {
    title: 'Dodatkowe informacje',
    dataIndex: 'additionalInfo',
    align: 'center' as const,
    render: (additionalInfo: string) => cutString(additionalInfo, 50),
  },
  {
    title: 'Wartość',
    dataIndex: 'value',
    align: 'center' as const,
    render: (value: number) => `${value}zł`,
  },
  {
    title: 'Stan kasy "po"',
    dataIndex: 'cashRegisterValue',
    align: 'center' as const,
    render: (cashRegisterValue: number) => `${cashRegisterValue}zł`,
  },
];

interface IProps extends TableProps<ICashRegisterOperation> {
  operations?: ICashRegisterOperation[];
}

const CashRegisterOperationsList: React.FC<IProps> = ({
  operations,
  ...props
}) => {
  const [list, setList] = useState<ICashRegisterOperation[]>([]);

  useEffect(() => {
    if (operations) {
      const newList = operations?.map(
        (operation: ICashRegisterOperation, i: number) => ({
          key: operation._id,
          ...operation,
        })
      );

      setList(newList);
    }
  }, [operations]);

  return (
    <div>
      <Table
        columns={columns}
        dataSource={list}
        scroll={{ x: 1000 }}
        size="small"
        {...props}
        summary={(data) => {
          if (!operations) return null;
          const balance = getCashRegisterOperationBalance(operations);

          return (
            <Table.Summary.Row style={{ backgroundColor: 'lightgray' }}>
              <Table.Summary.Cell index={1} colSpan={5}>
                <Text strong>Bilans</Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2} align="center">
                <Typography.Text strong>
                  {balance.toFixed(2)} zł
                </Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3} colSpan={2} align="center" />
            </Table.Summary.Row>
          );
        }}
      />
    </div>
  );
};

export default CashRegisterOperationsList;
