import React from 'react';
import { Divider } from 'antd';

interface IProps {
  marginTop?: number;
  marginBottom?: number;
}

const AntDivider: React.FC<IProps> = ({
  marginBottom = 20,
  marginTop = 80,
}) => {
  return (
    <Divider
      style={{ borderTop: '1px solid green', marginBottom, marginTop }}
    />
  );
};

export default AntDivider;
