import React, { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Button, message, Table, TableProps } from 'antd';
import dayjs from 'dayjs';
import FileSaver from 'file-saver';
import {
  cashRegisterApi,
  cashRegisterApiV2,
} from 'services/api/cashRegister.api';
import { ICashRegisterReport } from 'services/store/types/cashRegister/CashRegister';

const columns = [
  {
    title: 'Data',
    align: 'center' as const,
    dataIndex: 'createdAt',
    render: (createdAt: Date, r: any, i: number) =>
      dayjs(createdAt).format('DD.MM.YYYY, hh:mm'),
  },
  {
    title: 'Nazwa',
    dataIndex: 'name',
    align: 'center' as const,
  },
  {
    title: 'Pobierz',
    dataIndex: 'download',
    align: 'center' as const,
    render: (handleDownload: () => Promise<any>) => (
      <Button onClick={handleDownload} size="small">
        Pobierz
      </Button>
    ),
  },
];

interface IProps extends TableProps<ICashRegisterReport> {
  reports?: ICashRegisterReport[];
}

const CashRegisterReportsList: React.FC<IProps> = ({ reports, ...props }) => {
  const [list, setList] = useState<ICashRegisterReport[]>([]);

  const getReport = useMutation({
    mutationKey: ['get-report-file'],
    mutationFn: (file: ICashRegisterReport) =>
      cashRegisterApiV2.getReport(file._id),
    onSuccess: (res, file) => {
      FileSaver.saveAs(
        new Blob([res.data], { type: 'application/pdf' }),
        file.name || file.path.split('/').pop()
      );
    },
    onError: (error: any) => {
      console.log(error, error.response);
      message.error('Błąd!');
    },
  });

  useEffect(() => {
    if (reports) {
      const newList = reports?.map(
        (report: ICashRegisterReport, i: number) => ({
          key: report._id,
          download: () => getReport.mutate(report),
          ...report,
        })
      );

      setList(newList);
    }
  }, [reports]);

  return (
    <div>
      <Table
        columns={columns}
        dataSource={list}
        scroll={{ x: 1000 }}
        size="small"
        {...props}
      />
    </div>
  );
};

export default CashRegisterReportsList;
