import { OrderStatus } from 'services/store/enums/orders.enum';
import { IOrder } from 'services/store/types/orders/Orders';

export const initOrderData: IOrder = {
  customer: undefined,
  createdBy: undefined,
  name: '',
  number: '',
  status: OrderStatus.PENDING,
  price: 0,
  address: undefined,
  createdAt: new Date(),
  finishedAt: undefined,
  payments: [],
  costs: [],
  documents: [],
  comments: [],
  history: [],
};
