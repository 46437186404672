import React, { useState } from 'react';
import { Button, message, Modal } from 'antd';
import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import {
  AntInput,
  AntSelect,
} from 'components/Formik-AntdFields/CreateAntdField';
import { workActionDataValidation } from 'scenes/Settings/utils/validate';
import { getAllWorkActions } from 'services/store/actions/settings';
import { Positions } from 'services/store/enums/users.enum';
import { IWorkAction } from 'services/store/types/settings/Settings';
import { _userPositions } from 'services/utils/const';

const initValues: IWorkAction = {
  position: Positions.DESIGNER,
  action: '',
  description: '',
};

interface PropsT {
  closeModal: () => void;
  workAction?: IWorkAction;
}

const AddWorkActionModal: React.FC<PropsT> = ({ closeModal, workAction }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values: IWorkAction) => {
    console.log(values);
    setLoading(true);
    try {
      if (!workAction) await axios.post(`/api/settings/work-actions`, values);
      else
        await axios.patch(
          `/api/settings/work-actions/${workAction._id}`,
          values
        );

      dispatch(getAllWorkActions());
      message.success('Akcja pracownika utworzona');
      return closeModal();
    } catch (error: any) {
      message.error('Błąd');
      console.log(error, error.response);
    }
    setLoading(false);
  };

  return (
    <Modal
      title={workAction ? 'Edycja akcji pracownika' : 'Nowa akcja pracownika'}
      destroyOnClose
      visible
      onCancel={closeModal}
      maskClosable={false}
      footer={[
        <Button key="back" onClick={closeModal}>
          Anuluj
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          htmlType="submit"
          form="addWorkActionForm"
        >
          Zatwierdź
        </Button>,
      ]}
    >
      <Formik
        validationSchema={workActionDataValidation}
        onSubmit={handleSubmit}
        initialValues={workAction || initValues}
      >
        {(props) => {
          return (
            <Form
              noValidate
              id="addWorkActionForm"
              onSubmit={props.handleSubmit}
            >
              <Field
                component={AntSelect}
                name="position"
                placeholder="Stanowisko*"
                validate={props.errors.position}
                submitCount={props.submitCount}
                hasFeedback
                selectOptions={_userPositions}
              />
              <Field
                component={AntInput}
                name="action"
                type="text"
                placeholder="Akcja*"
                validate={props.errors.action}
                submitCount={props.submitCount}
                hasFeedback
              />
              <Field
                component={AntInput}
                name="description"
                type="text"
                placeholder="Opis"
                validate={props.errors.description}
                submitCount={props.submitCount}
                hasFeedback
              />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AddWorkActionModal;
