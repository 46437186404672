import React, { useState } from 'react';
import { message } from 'antd';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import CreateCategoryModal from 'components/Modals/CreateCategoryModal/CreateCategory.modal';
import { getAllCategories } from 'services/store/actions/settings';
import { setLoading } from 'services/store/actions/view';
import { ICategory } from 'services/store/types/settings/Settings';
import CategoriesList from './CategoriesList';

interface IProps {
  categories: ICategory[];
}

const Categories: React.FC<IProps> = ({ categories }) => {
  const [categoryToEdit, setCategoryToEdit] = useState<ICategory | null>(null);
  const dispatch = useDispatch();

  const handleRemoveCategory = async (id: string) => {
    dispatch(setLoading(true));
    try {
      await axios.delete(`/api/settings/categories/${id}`);
      dispatch(getAllCategories());
      message.success('Kategoria usunięta');
    } catch (error) {
      message.error('Błąd');
    }
    dispatch(setLoading(false));
  };
  return (
    <div>
      <CategoriesList
        categories={categories}
        removeCategory={handleRemoveCategory}
        setToEdit={setCategoryToEdit}
      />
      {/* NOTE MODALS */}
      {categoryToEdit && (
        <CreateCategoryModal
          closeModal={() => setCategoryToEdit(null)}
          category={categoryToEdit}
        />
      )}
    </div>
  );
};

export default Categories;
