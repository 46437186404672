import React, { useEffect, useState } from 'react';
import { Table, Tag, Typography } from 'antd';
import humanizeDuration from 'humanize-duration';
import { useHistory } from 'react-router';
import { IOrderStatistics } from 'services/store/types/statistics/Statistics';
import { getOrdersStatisticsSummary } from '../utils/ordersStatistics.utils';

const { Text } = Typography;

const columns: any[] = [
  {
    dataIndex: 'lp',
    title: 'Lp.',
    align: 'center' as const,
    render: (e: any, r: any, i: number) => i + 1,
    width: 50,
  },
  {
    title: 'Zamówienie',
    dataIndex: 'orderNumber',
    sorter: (a: IOrderStatistics, b: IOrderStatistics) =>
      a.orderNumber.localeCompare(b.orderNumber),
    align: 'center' as const,
  },
  {
    title: 'Liczba projektów',
    dataIndex: 'projectsCount',
    align: 'center' as const,
  },
  {
    title: 'W pełni opłacone',
    dataIndex: 'isFullyPaid',
    align: 'center' as const,
    render: (isFullyPaid: boolean) => (
      <Tag color={isFullyPaid ? 'green' : 'red'}>
        {isFullyPaid ? 'TAK' : 'NIE'}
      </Tag>
    ),
  },
  {
    title: 'Cena [zł]',
    dataIndex: 'price',
    align: 'center' as const,
    sorter: (a: IOrderStatistics, b: IOrderStatistics) => a.price - b.price,
    render: (price: number) => (
      <Text strong mark>
        {price}
      </Text>
    ),
  },
  {
    title: 'Zapłacono [zł]',
    dataIndex: 'alreadyPaid',
    align: 'center' as const,
  },
  {
    title: 'Do zapłaty [zł]',
    dataIndex: 'toPay',
    align: 'center' as const,
    sorter: (a: IOrderStatistics, b: IOrderStatistics) => a.toPay - b.toPay,
  },

  {
    title: 'Koszty [zł]',
    dataIndex: 'costs',
    align: 'center' as const,
    sorter: (a: IOrderStatistics, b: IOrderStatistics) => a.costs - b.costs,
    render: (cost: number) => <Text type="danger">{cost}</Text>,
  },
  {
    title: 'Czas pracy',
    dataIndex: 'workTimeInMs',
    align: 'center' as const,
    render: (workTimeInMs: number) => {
      const durationText = humanizeDuration(workTimeInMs, {
        units: ['h', 'm'],
        round: true,
        language: 'pl',
      });
      return <Text>{durationText}</Text>;
    },
  },
  {
    title: 'Koszt pracowników [zł]',
    dataIndex: 'employeesCosts',
    align: 'center' as const,
    sorter: (a: IOrderStatistics, b: IOrderStatistics) =>
      a.employeesCosts - b.employeesCosts,
    render: (employeesCost: number) => (
      <Text type="danger">{employeesCost}</Text>
    ),
  },
  {
    title: 'Przewidywany zysk [zł]',
    dataIndex: 'expectedProfit',
    align: 'center' as const,
    sorter: (a: IOrderStatistics, b: IOrderStatistics) =>
      a.expectedProfit - b.expectedProfit,
    render: (expectedProfit: number) => (
      <Text type={expectedProfit > 0 ? 'success' : 'danger'}>
        {expectedProfit}
      </Text>
    ),
  },
];

interface IProps {
  ordersStatistics: IOrderStatistics[];
  isLoading?: boolean;
  maxHeight?: number;
}

const OrdersStatisticsList: React.FC<IProps> = ({
  ordersStatistics,
  isLoading = false,
  maxHeight,
}) => {
  const history = useHistory();
  const [list, setList] = useState<any[]>([]);
  useEffect(() => {
    if (ordersStatistics) {
      const newList = ordersStatistics.map(
        (order: IOrderStatistics, i: number) => ({
          ...order,

          key: order.orderId,
        })
      );
      setList(newList);
    }
  }, [ordersStatistics]);

  const handleRedirect = (id: string) => {
    history.push(`/orders/${id}`);
  };

  return (
    <div>
      <Table
        columns={columns}
        pagination={false}
        dataSource={list}
        loading={isLoading}
        rowClassName={(r: any) => `table-row-clicable`}
        scroll={{ x: 1000, y: maxHeight }}
        size="small"
        onRow={(record) => {
          return {
            onClick: () => {
              handleRedirect(record.orderId);
            },
          };
        }}
        summary={(data) => {
          const summary = getOrdersStatisticsSummary(
            data as IOrderStatistics[]
          );
          return (
            <>
              <Table.Summary.Row style={{ backgroundColor: 'lightgray' }}>
                <Table.Summary.Cell index={1} colSpan={2}>
                  <Text strong>Podsumowanie</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} align="center">
                  <Typography.Text strong>
                    {summary.projectsCount}
                  </Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} align="center" />
                <Table.Summary.Cell index={2} align="center">
                  <Typography.Text strong>{summary.price}</Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3} align="center">
                  <Typography.Text strong>
                    {summary.alreadyPaid}
                  </Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4} align="center">
                  <Typography.Text strong>{summary.toPay}</Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5} align="center">
                  <Typography.Text strong>{summary.costs}</Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} align="center" />
                <Table.Summary.Cell index={6} align="center">
                  <Typography.Text strong>
                    {summary.employeesCosts}
                  </Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7} align="center">
                  <Typography.Text strong>
                    {summary.expectedProfit}
                  </Typography.Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </div>
  );
};

export default OrdersStatisticsList;
