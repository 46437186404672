import React, { useEffect, useState } from 'react';
import { Button, Table, TableProps, Typography } from 'antd';
import dayjs from 'dayjs';
import humanizeDuration from 'humanize-duration';
import UserPositionTag from 'components/Tags/UserPositionTag/UserPositionTag';
import { Positions } from 'services/store/enums/users.enum';
import { IProject } from 'services/store/types/projects/Projects';
import { IWorkTime } from 'services/store/types/statistics/Statistics';
import { IUser } from 'services/store/types/users/Users';
import { getDurationText } from 'services/utils/dates.utils';
import { getUserName } from 'services/utils/string';
import { getUsersStatisticsSummary } from '../utils/usersStatistics.utils';
const { Text } = Typography;

const columns: any[] = [
  {
    dataIndex: 'lp',
    title: 'Lp.',
    align: 'center' as const,
    render: (e: any, r: any, i: number) => i + 1,
    width: 50,
  },
  {
    title: 'Pracownik',
    dataIndex: 'user',
    align: 'center' as const,
    render: (user: IUser) => getUserName(user),
  },
  {
    title: 'Stanowisko',
    dataIndex: 'position',
    align: 'center' as const,
    render: (position: Positions) => <UserPositionTag position={position} />,
  },
  {
    title: 'Akcja',
    dataIndex: 'action',
    align: 'center' as const,
    render: (action: string) => action,
    width: 400,
  },
  {
    title: 'Numer projektu',
    dataIndex: 'project',
    align: 'center' as const,
    render: (project: IProject | null) => project?.number,
  },
  {
    title: 'Start',
    dataIndex: 'start',
    align: 'center' as const,
    render: (start: Date) => dayjs(start).format('DD.MM.YYYY, HH:mm'),
  },
  {
    title: 'Koniec',
    dataIndex: 'finish',
    align: 'center' as const,
    render: (finish: Date) => dayjs(finish).format('DD.MM.YYYY, HH:mm'),
  },
  {
    title: 'Czas trwania',
    dataIndex: 'duration',
    align: 'center' as const,
    render: (duration: Date) => getDurationText(new Date(duration).getTime()),
  },
  {
    title: 'Koszt',
    dataIndex: 'cost',
    align: 'center' as const,
    render: (cost?: number) => (cost ? `${cost} zł` : '-'),
  },
  {
    title: 'Zmień',
    dataIndex: 'setWorkTimeToUpdate',
    align: 'center' as const,
    render: (setWorkTimeToUpdate: () => void) => (
      <Button size="small" onClick={setWorkTimeToUpdate}>
        Zmień
      </Button>
    ),
  },
  {
    title: 'Historia zmian',
    dataIndex: 'showWorkTimeUpdateHistory',
    align: 'center' as const,
    render: (showWorkTimeUpdateHistory: () => void, record: IWorkTime) => (
      <Button
        disabled={!record.updateHistory?.length}
        size="small"
        onClick={showWorkTimeUpdateHistory}
      >
        Wyświetl
      </Button>
    ),
  },
];

interface IProps extends TableProps<IWorkTime> {
  workTimes?: IWorkTime[];
  setWorkTimeToUpdate: (workTime: IWorkTime) => void;
  showWorkTimeUpdateHistory: (workTime: IWorkTime) => void;
}

const UserStatisticsList: React.FC<IProps> = ({
  workTimes,
  setWorkTimeToUpdate,
  showWorkTimeUpdateHistory,
  ...props
}) => {
  const [list, setList] = useState<any[]>([]);

  useEffect(() => {
    if (workTimes) {
      const newList = workTimes.map((workTime: IWorkTime) => ({
        ...workTime,
        key: workTime._id,
        setWorkTimeToUpdate: () => setWorkTimeToUpdate(workTime),
        showWorkTimeUpdateHistory: () => showWorkTimeUpdateHistory(workTime),
      }));
      setList(newList);
    }
  }, [workTimes]);

  return (
    <div>
      <Table
        columns={columns}
        dataSource={list}
        scroll={{ x: 1000 }}
        size="small"
        {...props}
        summary={(data) => {
          const summary = getUsersStatisticsSummary(data);
          const durationText = humanizeDuration(summary.duration, {
            units: ['h', 'm'],
            round: true,
            language: 'pl',
          });
          return (
            <Table.Summary.Row style={{ backgroundColor: 'lightgray' }}>
              <Table.Summary.Cell index={1} colSpan={7}>
                <Text strong>Podsumowanie</Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2} align="center">
                <Typography.Text strong>{durationText}</Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3} align="center">
                <Typography.Text strong>{summary.cost} zł</Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3} colSpan={2} align="center" />
            </Table.Summary.Row>
          );
        }}
      />
    </div>
  );
};

export default UserStatisticsList;
