export const initOrderPageModals = {
  changeNumber: false,
  changeName: false,
  changeAddress: false,
  editPrice: false,
  editPayments: false,
  addCost: false,
  addDocument: false,
  addComment: false,
  generateDocuments: false,
};

export const initOrderCostData = {
  name: '',
  description: '',
  date: new Date(),
  valueGross: 0,
  valueNet: 0,
  files: [],
  file: '',
};
export const initDocumentData = {
  name: '',
  description: '',
  date: new Date(),
  files: [],
  protected: true,
};
