import React, { useEffect, useState } from 'react';
import { Button, Popconfirm, Table } from 'antd';
import { IWorkAction } from 'services/store/types/settings/Settings';
import { cutString } from 'services/utils/string';

const columns: any[] = [
  {
    dataIndex: 'lp',
    title: 'Lp.',
    align: 'center' as const,
  },
  {
    title: 'Stanowisko',
    dataIndex: 'position',
    align: 'center' as const,
    sorter: (a: IWorkAction, b: IWorkAction) =>
      a.position.localeCompare(b.position),
  },
  {
    title: 'Akcja',
    dataIndex: 'action',
    align: 'center' as const,
    defaultSortOrder: 'ascend' as const,
    render: (action: string) => cutString(action || '', 25),
    sorter: (a: IWorkAction, b: IWorkAction) =>
      a.action.localeCompare(b.action),
  },
  {
    title: 'Opis',
    dataIndex: 'description',
    align: 'center' as const,
    render: (description: string) => cutString(description || '', 20),
  },
  {
    title: 'Edytuj',
    dataIndex: 'edit',
    align: 'center' as const,
  },
  {
    title: 'Usuń',
    dataIndex: 'delete',
    align: 'center' as const,
  },
];

interface IProps {
  workActions: IWorkAction[];
  removeWorkAction: (id: string) => Promise<any>;
  setToEdit?: (workActionToEdit: IWorkAction) => void;
  maxHeight?: number;
}

const WorkActionsList: React.FC<IProps> = ({
  workActions,
  removeWorkAction,
  setToEdit,
  maxHeight,
}) => {
  const [list, setList] = useState<any[]>([]);

  useEffect(() => {
    const newWorkActions = workActions.map(
      (workAction: IWorkAction, i: number) => ({
        ...workAction,
        lp: i + 1,
        key: workAction._id,
        edit: (
          <Button
            size="small"
            onClick={() => setToEdit && setToEdit(workAction)}
          >
            Edytuj
          </Button>
        ),
        delete: (
          <Popconfirm
            key={1}
            title="Na pewno chcesz usunąć tą akcję?"
            onConfirm={() => workAction._id && removeWorkAction(workAction._id)}
            okText="Tak"
            cancelText="Nie"
          >
            <Button danger size="small">
              Usuń
            </Button>
          </Popconfirm>
        ),
      })
    );
    setList(newWorkActions);
  }, [workActions]);

  return (
    <Table
      columns={columns}
      size="small"
      pagination={false}
      dataSource={list}
      scroll={{ x: 1000, y: maxHeight }}
    />
  );
};

export default WorkActionsList;
