import React, { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import {
  Button,
  Input,
  Select,
  message,
  Modal,
  Typography,
  Upload,
} from 'antd';
import { RcFile } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import dayjs from 'dayjs';
import AntDatePicker from 'components/DatePicker/DatePicker';
import { ErrorModal } from 'components/Modals/ErrorModal/ErrorModal';
import { uploadMultipleFiles } from 'services/requests/files.request';
import { FileDestination } from 'services/store/enums/files.enum';
import { HistoryColor } from 'services/store/enums/historyColor.enum';
import { ProjectImageType } from 'services/store/enums/project.enum';
import {
  IProject,
  IProjectImage,
} from 'services/store/types/projects/Projects';
import { _projectImagesTypes } from 'services/utils/const';
import { isFileImage } from 'services/utils/files.utils';
import setAuthToken from 'services/utils/setAuthToken';
import { capitalizeFirstLetter } from 'services/utils/string';

const { Title } = Typography;
const { Option } = Select;

interface IInitImage extends IProjectImage {
  files: RcFile[];
}

const initValues: IInitImage = {
  name: '',
  description: '',
  date: new Date(),
  type: ProjectImageType.MEASURE,
  files: [],
  file: '',
};

interface IProps {
  project: IProject;
  closeModal: () => void;
  handleUpdateProject: (
    updateData: Partial<IProject>,
    historyUpdate: string[],
    historyColor: HistoryColor
  ) => Promise<any>;
}

const AddProjectImageModal: React.FC<IProps> = ({
  project,
  closeModal,
  handleUpdateProject,
}) => {
  const [image, setImage] = useState(initValues);
  const [isLoading, setIsLoading] = useState(false);

  const onOk = async () => {
    if (!image.files.length) return;
    try {
      setAuthToken();

      const isAllFilesImages = image.files.every(isFileImage);
      if (!isAllFilesImages)
        return ErrorModal([
          'W tym miejscu możesz dodawać wyłącznie pliki o rozszerzeniach .jpg, .jpeg, .png, .gif.',
        ]);

      setIsLoading(true);
      const filesIds: string[] = await uploadMultipleFiles(
        image.files,
        FileDestination.PROJECT,
        image.name
      );

      const images = filesIds.map((fileId) => ({ ...image, file: fileId }));
      const newImages = [...project.images, ...images];

      const historyUpdate = [`Dodano zdjęcia: ${image.type}.`];

      await handleUpdateProject(
        { images: newImages },
        historyUpdate,
        HistoryColor.SUCCESS
      );
    } catch (error: any) {
      console.log(error, error.response);
      message.error('Błąd');
    }
    setIsLoading(false);

    closeModal();
  };

  const handleChange = (field: keyof IInitImage, value: any) => {
    setImage({ ...image, [field]: value });
  };
  const handleAddFile = (file: RcFile, fileList: RcFile[]) => {
    const newList = fileList.filter(
      (fi) => !image.files.map((f) => f.name).includes(fi.name)
    );
    const files = [...image.files, ...newList].slice(0, 5);
    handleChange('files', files);
    return false;
  };
  const handleRemoveFile = (f: UploadFile) => {
    const fileList = image.files.filter((file: RcFile) => file.uid !== f.uid);
    handleChange('files', fileList);
  };

  // TODO DODAWAC MINIATURY => w tym samym katalogu z informacją że to thumbnail
  return (
    <Modal
      title="Dodawanie zdjęć do projektu"
      destroyOnClose
      visible
      onCancel={closeModal}
      maskClosable={false}
      onOk={onOk}
      okButtonProps={{
        disabled: !image.files.length,
        loading: isLoading,
      }}
    >
      <Title level={5}>Nazwa</Title>
      <Input
        value={image.name}
        onChange={(e) => handleChange('name', e.target.value)}
        placeholder="Nazwa"
      />
      <Title level={5}>Opis</Title>
      <Input
        value={image.description}
        onChange={(e) => handleChange('description', e.target.value)}
        placeholder="Opis"
      />
      <Title level={5}>Typ</Title>
      <Select
        value={image.type}
        onChange={(value) => handleChange('type', value)}
        placeholder="Typ"
        style={{ width: '100%' }}
      >
        {_projectImagesTypes.map((type) => (
          <Option key={type} value={type}>
            {capitalizeFirstLetter(type)}
          </Option>
        ))}
      </Select>
      <Title level={5}>Data</Title>
      <AntDatePicker
        value={dayjs(image.date)}
        onChange={(value) =>
          value && handleChange('date', new Date(value.format()))
        }
        format="DD.MM.YYYY"
      />
      <Title level={5}>Pliki</Title>
      <Upload
        onRemove={handleRemoveFile}
        beforeUpload={handleAddFile}
        fileList={image.files}
        accept="image/*"
        multiple
        maxCount={5}
      >
        <Button icon={<UploadOutlined />}>Dodaj pliki</Button>
        <div>
          <small>*Możesz dodać max. 5 plików za jednym razem</small>
        </div>
      </Upload>
    </Modal>
  );
};

export default AddProjectImageModal;
