import React from 'react';
import { Col, Descriptions, Row } from 'antd';
import dayjs from 'dayjs';
import Address from 'components/Address/Address';
import { Positions } from 'services/store/enums/users.enum';
import { IProject } from 'services/store/types/projects/Projects';
import { IUser } from 'services/store/types/users/Users';
import { getUserName } from 'services/utils/string';
import { isUserHasPermission } from 'services/utils/users';

interface IProps {
  project: IProject;
  user: IUser | null;
}

const ProjectHeader: React.FC<IProps> = ({ project, user }) => {
  return (
    <Row>
      {/* NOTE DATA  */}
      <Col md={6} span={24}>
        <Descriptions title="Dane projektu" column={1}>
          <Descriptions.Item label="Numer">
            <strong>{project.number}</strong>
          </Descriptions.Item>
          <Descriptions.Item label="Nazwa">
            <strong>{project.name}</strong>
          </Descriptions.Item>
          <Descriptions.Item label="Utworzony przez">
            <strong>
              {project.createdBy && getUserName(project.createdBy)}
            </strong>
          </Descriptions.Item>
          <Descriptions.Item label="Projektant">
            <strong>{project.designer && getUserName(project.designer)}</strong>
          </Descriptions.Item>
          <Descriptions.Item label="Technolog">
            <strong>
              {project.technologist && getUserName(project.technologist)}
            </strong>
          </Descriptions.Item>
        </Descriptions>
      </Col>

      {/* NOTE DATES */}
      <Col md={6} span={24}>
        <Descriptions title="Daty" column={1}>
          <Descriptions.Item label="Data utworzenia">
            <strong>{dayjs(project.createdAt).format('DD.MM.YYYY')}</strong>
          </Descriptions.Item>
          <Descriptions.Item label="Data rozpoczęcia produkcji">
            {project.productionStartedAt && (
              <strong>
                {dayjs(project.productionStartedAt).format('DD.MM.YYYY')}
              </strong>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Data montażu">
            <strong>
              {project.montageAt &&
                dayjs(project.montageAt).format('DD.MM.YYYY')}
            </strong>
          </Descriptions.Item>
          <Descriptions.Item label="Data zakończenia">
            <strong>
              {project.finishedAt &&
                dayjs(project.finishedAt).format('DD.MM.YYYY')}
            </strong>
          </Descriptions.Item>
        </Descriptions>
      </Col>

      {/* NOTE MONTAGE ADDRESS */}
      <Col md={5} span={24}>
        <Address address={project.order?.address} title="Adres montażu" />
      </Col>

      {/* NOTE PRODUCTION DAYS */}
      {user &&
        isUserHasPermission(user, [
          Positions.DESIGNER,
          Positions.TECHNOLOGIST,
        ]) && (
          <Col md={5} span={24}>
            <Descriptions title="Dni produkcyjne" column={1}>
              <Descriptions.Item label="Projekt">
                <strong>{project.productionDays?.project}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="Technolog">
                <strong>{project.productionDays.technologist}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="Stolarnia">
                <strong>{project.productionDays?.carpenter}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="Montaż">
                <strong>{project.productionDays?.montage}</strong>
              </Descriptions.Item>
            </Descriptions>
          </Col>
        )}
    </Row>
  );
};

export default ProjectHeader;
