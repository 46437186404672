import React, { useCallback, useEffect, useState } from 'react';
import { FolderAddOutlined } from '@ant-design/icons';
import { Button, message, PageHeader, Tabs } from 'antd';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { H1 } from 'components/Header';
import OrdersList from 'components/ordersList/OrdersList';
import { TAppState } from 'services/store';
import { setOrdersKey } from 'services/store/actions/view';
import { OrderStatus } from 'services/store/enums/orders.enum';
import { Positions } from 'services/store/enums/users.enum';
import { OrdersKey } from 'services/store/enums/view.enum';
import { IOrdersSearchQuery } from 'services/store/types/orders/ordersSearch.interface';
import setAuthToken from 'services/utils/setAuthToken';
import { isUserHasPermission } from 'services/utils/users';
import PageTemplate from 'templates/PageTemplate';

const { TabPane } = Tabs;

const OrdersPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const tabKey = useSelector((state: TAppState) => state.view.ordersKey);
  const user = useSelector((state: TAppState) => state.auth.user);

  const [isLoading, setIsLoading] = useState(false);
  const [orders, setOrders] = useState([]);

  const getOrders = useCallback(async (query: IOrdersSearchQuery) => {
    setIsLoading(true);
    try {
      setAuthToken();
      console.log(query);
      const res = await axios.get(`/api/orders`, { params: query });
      console.log(res);
      setOrders(res.data);
    } catch (error) {
      console.log(error);
      message.error('Błąd');
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (tabKey === 'inProduction') {
      getOrders({
        status: OrderStatus.IN_PROGRESS,
      });
    } else if (tabKey === 'pending') {
      getOrders({ status: OrderStatus.PENDING });
    } else if (tabKey === 'finished') {
      getOrders({ status: OrderStatus.FINISHED });
    }
  }, [tabKey, getOrders]);

  const goToOrderCreate = () => history.push(`/api/orders/create`);
  const handleTabKey = (key: OrdersKey) => {
    setOrders((prev) => []);
    dispatch(setOrdersKey(key));
  };

  return (
    <PageTemplate>
      <PageHeader
        title={<H1>Zamówienia</H1>}
        extra={
          isUserHasPermission(user, [
            Positions.DESIGNER,
            Positions.TECHNOLOGIST,
          ]) && [
            <Button key={1} onClick={goToOrderCreate}>
              <FolderAddOutlined />
              Dodaj
            </Button>,
          ]
        }
      />

      <Tabs
        defaultActiveKey={tabKey}
        onChange={(key) => handleTabKey(key as OrdersKey)}
        size="large"
        style={{ marginBottom: 32 }}
      >
        <TabPane tab="W produkcji" key="inProduction">
          <OrdersList orders={orders} isLoading={isLoading} user={user} />
        </TabPane>
        <TabPane tab="Oczekujące" key="pending">
          <OrdersList orders={orders} isLoading={isLoading} user={user} />
        </TabPane>
        <TabPane tab="Zakończone" key="finished">
          <OrdersList orders={orders} isLoading={isLoading} user={user} />
        </TabPane>
      </Tabs>
    </PageTemplate>
  );
};

export default OrdersPage;
