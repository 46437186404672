import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import { accessoriesReducer } from './reducers/accessories';
import { authReducer } from './reducers/auth';
import { productionNodesReducer } from './reducers/productionNodes';
import { settingsReducer } from './reducers/settings';
import { usersReducer } from './reducers/users';
import { viewReducer } from './reducers/view';
import { TAppActions } from './types/appActionsTypes';

const rootReducer = combineReducers({
  auth: authReducer,
  users: usersReducer,
  accessories: accessoriesReducer,
  productionNodes: productionNodesReducer,
  settings: settingsReducer,
  view: viewReducer,
});
export type TAppState = ReturnType<typeof rootReducer>;

const store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(thunk as ThunkMiddleware<TAppState, TAppActions>)
  )
);

export default store;
