import * as yup from 'yup';

export const addressValidation = yup.object({
  city: yup.string(),
  address: yup.string().when('city', {
    is: (value: string) => !!value,
    then: yup.string().required('Adres jest wymagany'),
    otherwise: yup.string(),
  }),
  postcode: yup.string().matches(/^\d{2}-\d{3}$/, 'Nieprawidłowy format'),
});
