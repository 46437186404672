import dayjs from 'dayjs';
import {
  IOrderWorkTimeStatistics,
  IWorkTime,
} from 'services/store/types/statistics/Statistics';
import { IUser } from 'services/store/types/users/Users';
import { getDuration, getDurationText } from './dates.utils';

export interface ISortedByUserWorkingTime {
  user?: IUser;
  workingTime: IWorkTime[];
}
export const sortWorkingTimesByUsers = (workingTimes: IWorkTime[]) => {
  const result: ISortedByUserWorkingTime[] = workingTimes.reduce(
    (acc: ISortedByUserWorkingTime[], workingTime: IWorkTime) => {
      const existingUserIndex = acc.findIndex(
        (item) => item.user?._id === workingTime.user?._id
      );

      if (existingUserIndex === -1) {
        return [...acc, { user: workingTime.user, workingTime: [workingTime] }];
      } else {
        const newAcc = [...acc];
        newAcc[existingUserIndex].workingTime.push(workingTime);
        return newAcc;
      }
    },
    []
  );

  return result;
};

export const statisticsUtils = {
  workTimes: {
    getDuraionText: function (startDate: Date, finishDate: Date) {
      const start = dayjs(startDate);
      const finish = dayjs(finishDate);
      if (finish.isBefore(start) || start.isAfter(finish)) return 'Błąd!';
      const duration = getDuration(startDate, finishDate);
      const text = getDurationText(duration.getTime());
      return text;
    },
  },
};

export const summarizeOrderWorkTimesStatisticsDuration = (
  workTimes: IOrderWorkTimeStatistics[]
) => workTimes.reduce((acc, workTime) => (acc += workTime.durationInMs), 0);

export const summarizeWorkTimesDuration = (workTimes: IWorkTime[]) =>
  workTimes.reduce(
    (acc, workTime) => (acc += new Date(workTime.duration).getTime()),
    0
  );
