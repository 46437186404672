import React from 'react';
import {
  EllipsisOutlined,
  MessageOutlined,
  RedEnvelopeOutlined,
  UserDeleteOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import { isMobile } from 'react-device-detect';
import { Permissions } from 'services/store/enums/users.enum';
import { IUser } from 'services/store/types/users/Users';

interface IActions {
  showModal: (key: any) => void;
  confirmChangeUserPermission: () => void;
  confirmDeleteUser: () => void;
}

interface IProps {
  user: IUser;
  loggedUser: IUser | null;
  actions: IActions;
}

const UserActions: React.FC<IProps> = ({ loggedUser, user, actions }) => {
  if (!isMobile)
    return (
      <>
        <Button
          type="primary"
          key={4}
          onClick={() => actions.showModal('sendMessage')}
          disabled={loggedUser?.permission !== Permissions.ADMIN}
        >
          <MessageOutlined /> Wyślij wiadomość
        </Button>
        <Button
          key={0}
          onClick={() => actions.showModal('changeUserData')}
          disabled={loggedUser?.permission !== Permissions.ADMIN}
        >
          <UserOutlined /> Zmień dane
        </Button>
        <Button key={1} onClick={actions.confirmChangeUserPermission}>
          <UserSwitchOutlined /> Zmień uprawnienia
        </Button>
        <Button
          key={2}
          onClick={() => actions.showModal('changePosition')}
          disabled={
            user.permission === Permissions.ADMIN &&
            loggedUser?._id !== user._id
          }
        >
          <UserSwitchOutlined /> Zmień stanowisko
        </Button>
        <Button
          key={3}
          danger
          onClick={actions.confirmDeleteUser}
          disabled={user.permission === Permissions.ADMIN}
        >
          <UserDeleteOutlined /> Usuń
        </Button>
      </>
    );
  return (
    <Dropdown
      key="more"
      overlay={dropDownMenu(user, loggedUser, actions)}
      trigger={['click']}
    >
      <Button type="primary">
        <EllipsisOutlined /> Menu
      </Button>
    </Dropdown>
  );
};

export default UserActions;

const dropDownMenu = (
  user: IUser,
  loggedUser: IUser | null,
  actions: IActions
) => (
  <Menu>
    <Menu.Item
      key={4}
      onClick={() => actions.showModal('sendMessage')}
      disabled={loggedUser?.permission !== Permissions.ADMIN}
    >
      <MessageOutlined /> Wyślij wiadomość
    </Menu.Item>
    <Menu.Item
      key={0}
      onClick={() => actions.showModal('changeUserData')}
      disabled={loggedUser?.permission !== Permissions.ADMIN}
    >
      <UserOutlined /> Zmień dane
    </Menu.Item>
    <Menu.Item key={1} onClick={actions.confirmChangeUserPermission}>
      <UserSwitchOutlined /> Zmień uprawnienia
    </Menu.Item>
    <Button
      key={2}
      onClick={() => actions.showModal('changePosition')}
      disabled={
        user.permission === Permissions.ADMIN && loggedUser?._id !== user._id
      }
    >
      <UserSwitchOutlined /> Zmień stanowisko
    </Button>
    <Menu.Item
      key={3}
      danger
      onClick={actions.confirmDeleteUser}
      disabled={user.permission === Permissions.ADMIN}
    >
      <UserDeleteOutlined /> Usuń
    </Menu.Item>
  </Menu>
);
