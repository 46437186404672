import React from 'react';
import { useSelector } from 'react-redux';
import { TAppState } from 'services/store';
import { ProjectProductionEditView } from 'services/store/enums/view.enum';
import { IProject } from 'services/store/types/projects/Projects';
import DayPartsView from './DayPartsView';
import DayView from './DayView';

interface IProps {
  project?: IProject;
  isChanges: boolean;
  setIsChanges: (value: boolean) => void;
  addProjectToChanged: (id: string) => void;
}

const ProductionScheduleEdit: React.FC<IProps> = ({
  project,
  isChanges,
  setIsChanges,
  addProjectToChanged,
}) => {
  const view = useSelector(
    (state: TAppState) => state.view.projectProductionEditView
  );

  if (view === ProjectProductionEditView.DAYS)
    return (
      <DayView
        project={project}
        isChanges={isChanges}
        setIsChanges={setIsChanges}
        addProjectToChanged={addProjectToChanged}
      />
    );
  return (
    <DayPartsView
      project={project}
      isChanges={isChanges}
      setIsChanges={setIsChanges}
      addProjectToChanged={addProjectToChanged}
    />
  );
};

export default ProductionScheduleEdit;
