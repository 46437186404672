import React from 'react';
import { Form, Input, TimePicker, Select, InputNumber, Checkbox } from 'antd';

const FormItem = Form.Item;
const { Option } = Select;

const CreateAntField =
  (AntComponent: any) =>
  ({
    field,
    form,
    hasFeedback,
    label,
    selectOptions,
    submitCount,
    type,
    checkbox,
    ...props
  }: any) => {
    const touched = form.touched[field.name];
    const submitted = submitCount > 0;
    const hasError = form.errors[field.name];
    const submittedError = hasError && submitted;
    const touchedError = hasError && touched;
    const onInputChange = ({ target: { value } }: any) =>
      form.setFieldValue(field.name, value);
    const onChange = (e: any) =>
      form.setFieldValue(field.name, checkbox ? e.target.checked : e);
    const onBlur = () => form.setFieldTouched(field.name, true);

    return (
      <div className="field-container">
        <FormItem
          label={label}
          hasFeedback={
            (hasFeedback && submitted) || (hasFeedback && touched)
              ? true
              : false
          }
          help={submittedError || touchedError ? hasError : false}
          validateStatus={submittedError || touchedError ? 'error' : 'success'}
        >
          <AntComponent
            {...field}
            {...props}
            checked={field.value}
            onBlur={onBlur}
            onChange={type ? onInputChange : onChange}
          >
            {selectOptions &&
              selectOptions.map((item: any) => (
                <Option key={item?._id || item} value={item?._id || item}>
                  {item?.name || item}
                </Option>
              ))}
          </AntComponent>
        </FormItem>
      </div>
    );
  };

export const AntSelect = CreateAntField(Select);
export const AntCheckbox = CreateAntField(Checkbox);
export const AntInput = CreateAntField(Input);
export const AntInputNumber = CreateAntField(InputNumber);
export const AntTextArea = CreateAntField(Input.TextArea);
export const AntPassword = CreateAntField(Input.Password);
export const AntTimePicker = CreateAntField(TimePicker);
