import React, { useState } from 'react';
import { Modal, message, Select } from 'antd';
import axios from 'axios';
import { Positions } from 'services/store/enums/users.enum';
import { IUser } from 'services/store/types/users/Users';
import { _userPositions } from 'services/utils/const';
import { capitalizeFirstLetter, getUserName } from 'services/utils/string';

const { Option } = Select;

interface PropsT {
  user: IUser;
  setUser: (user: IUser) => void;
  closeModal: () => void;
}

const UserChangePositionModal: React.FC<PropsT> = ({
  user,
  setUser,
  closeModal,
}) => {
  const [loading, setLoading] = useState(false);
  const [positions, setPositions] = useState<Positions[]>(user.positions || []);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const res = await axios.patch(`/api/users/${user._id}`, {
        positions,
        userAction: `Zmienił stanowisko użytkownika ${getUserName(user)}`,
      });
      setUser(res.data);
      message.success(
        `Stanowisko użytkownika zmienione na: ${positions.map(
          (pos) => `${pos}, `
        )}`
      );
    } catch (error) {
      message.error('Błąd');
    }
    setLoading(false);
    closeModal();
  };

  return (
    <Modal
      title="Stanowisko użytkownika"
      destroyOnClose
      visible
      onCancel={closeModal}
      onOk={handleSubmit}
      okButtonProps={{ loading }}
      maskClosable={false}
    >
      <Select
        value={positions}
        onChange={(value) => setPositions(value)}
        placeholder="Stanowisko"
        style={{ width: '100%' }}
        mode="multiple"
      >
        {_userPositions.map((pos) => (
          <Option key={pos} value={pos}>
            {capitalizeFirstLetter(pos)}
          </Option>
        ))}
      </Select>
    </Modal>
  );
};

export default UserChangePositionModal;
