import Axios from 'axios';
import dayjs from 'dayjs';
import { Dispatch } from 'redux';
import { getPositionNodesFromProductionNodes } from 'services/utils/projectProduction.utils';
import { Positions } from '../enums/users.enum';
import {
  IAddProjectToNodeAction,
  IAddProjectToNodeDay,
  IProductionNodesClear,
  IRemoveProjectFromNodeAction,
  IRemoveProjectFromNodeDay,
  ISetProductionNodes,
  ISetUserCurrentNode,
  PRODUCTION_NODES_ADD_PROJECT_TO_ACTION,
  PRODUCTION_NODES_ADD_PROJECT_TO_DAY,
  PRODUCTION_NODES_CLEAR_DATA,
  PRODUCTION_NODES_LOAD_SUCCESS,
  PRODUCTION_NODES_REMOVE_PROJECT_FROM_ACTION,
  PRODUCTION_NODES_REMOVE_PROJECT_FROM_DAY,
  PRODUCTION_NODES_SET_USER_CURRENT_NODE,
} from '../types/productionNodes/actions';
import { IPositionNode } from '../types/productionNodes/ProductionNodes';
import { IProject } from '../types/projects/Projects';

export const setProductionNodes = (
  positionNodes: IPositionNode[],
  dates: Date[]
): ISetProductionNodes => ({
  type: PRODUCTION_NODES_LOAD_SUCCESS,
  positionNodes,
  dates,
});

export const setUserCurrentNode = (
  userId: string,
  position: Positions
): ISetUserCurrentNode => ({
  type: PRODUCTION_NODES_SET_USER_CURRENT_NODE,
  userId,
  position,
});

export const clearProductionNodes = (): IProductionNodesClear => ({
  type: PRODUCTION_NODES_CLEAR_DATA,
});

export const addProjectToNodeAction = (
  nodeIndex: number,
  scheduleIndex: number,
  actionIndex: number,
  project: IProject
): IAddProjectToNodeAction => ({
  type: PRODUCTION_NODES_ADD_PROJECT_TO_ACTION,
  nodeIndex,
  scheduleIndex,
  actionIndex,
  project,
});
export const addProjectToNodeDay = (
  nodeIndex: number,
  scheduleIndex: number,
  project: IProject,
  date: Date
): IAddProjectToNodeDay => {
  const weekDay = dayjs(date).day();
  return {
    type: PRODUCTION_NODES_ADD_PROJECT_TO_DAY,
    nodeIndex,
    scheduleIndex,
    project,
    isFriday: weekDay === 5,
  };
};
export const removeProjectFromNodeAction = (
  nodeIndex: number,
  scheduleIndex: number,
  actionIndex: number
): IRemoveProjectFromNodeAction => ({
  type: PRODUCTION_NODES_REMOVE_PROJECT_FROM_ACTION,
  nodeIndex,
  scheduleIndex,
  actionIndex,
});

export const removeProjectFromNodeDay = (
  nodeIndex: number,
  scheduleIndex: number,
  date: Date
): IRemoveProjectFromNodeDay => {
  const weekDay = dayjs(date).day();
  return {
    type: PRODUCTION_NODES_REMOVE_PROJECT_FROM_DAY,
    nodeIndex,
    scheduleIndex,
    isFriday: weekDay === 5,
  };
};

/**--------------------
 * THUNK
 ----------------------*/
export const getAllProductionNodes =
  (onEnd?: () => void) => async (dispatch: Dispatch) => {
    try {
      const res = await Axios.get('/api/production-nodes/');

      if (res.status === 200) {
        const mappedData = getPositionNodesFromProductionNodes(res.data);

        dispatch(setProductionNodes(mappedData.nodes, mappedData.dates));
      }
      if (onEnd) onEnd();
    } catch (error) {
      dispatch(clearProductionNodes());
    }
  };
