import React, { useCallback, useEffect, useState } from 'react';
import { CalendarOutlined } from '@ant-design/icons';
import { Button, message, PageHeader } from 'antd';
import axios from 'axios';
import { Dayjs } from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { H1 } from 'components/Header';
import AddScheduleItemModal from 'components/Modals/ScheduleModal/AddScheduleItem.modal';
import { useModal } from 'hook/useModals';
import { TAppState } from 'services/store';
import { setLoading } from 'services/store/actions/view';
import { ProjectStatus } from 'services/store/enums/project.enum';
import { Positions } from 'services/store/enums/users.enum';
import { IProject } from 'services/store/types/projects/Projects';
import { ISchedule } from 'services/store/types/schedule/Schedule';
import setAuthToken from 'services/utils/setAuthToken';
import { isUserHasPermission } from 'services/utils/users';
import PageTemplate from 'templates/PageTemplate';
import CalendarView from './components/CalendarView';
import { initSchedulePageModals } from './data/initModals.data';

const SchedulePage = () => {
  const user = useSelector((state: TAppState) => state.auth.user);

  const dispatch = useDispatch();
  const [date, setDate] = useState<Date>(new Date());
  const [schedule, setSchedule] = useState<ISchedule[]>([]);
  const [projects, setProjects] = useState<IProject[] | null>(null);

  const { modal, showModal, closeModal } = useModal(initSchedulePageModals);

  // --------------------------------------------------------------------------
  // NOTE FETCH DATA
  // --------------------------------------------------------------------------

  const getSchedule = useCallback(async () => {
    try {
      dispatch(setLoading(true));
      setAuthToken();
      if (
        isUserHasPermission(user, [Positions.DESIGNER, Positions.TECHNOLOGIST])
      ) {
        const res = await axios.get(`/api/schedule`);
        setSchedule(res.data);
      } else {
        const res = await axios.get(`/api/schedule/self`);
        setSchedule(res.data);
      }
    } catch (error) {
      console.log(error);
      message.error('Błąd');
    } finally {
      dispatch(setLoading(false));
    }
  }, []);

  const getProjects = useCallback(async () => {
    try {
      setAuthToken();
      const res = await axios.get(`/api/projects`, {
        params: {
          status: [
            ProjectStatus.PENDING,
            ProjectStatus.PROJECT,
            ProjectStatus.TECHNOLOGIST,
            ProjectStatus.CARPENTER,
            ProjectStatus.STORAGE,
            ProjectStatus.MONTAGE,
          ],
          unpaged: true,
        },
      });
      setProjects(res.data.content);
    } catch (error) {
      console.log(error);
      message.error('Błąd');
    }
  }, []);

  // --------------------------------------------------------------------------
  // NOTE LIFECYCLE
  // --------------------------------------------------------------------------

  useEffect(() => {
    getSchedule();
    getProjects();
  }, []);

  // --------------------------------------------------------------------------
  // NOTE HANDLERS
  // --------------------------------------------------------------------------

  const handleDate = (d: Dayjs) => setDate(d.toDate());

  return (
    <PageTemplate>
      <PageHeader
        title={<H1>Terminarz</H1>}
        extra={[
          <Button
            key={1}
            type="primary"
            onClick={() => showModal('addScheduleItem')}
            disabled={!projects}
          >
            <CalendarOutlined />
            Dodaj
          </Button>,
        ]}
      />
      <CalendarView
        schedule={schedule}
        date={date}
        handleDate={handleDate}
        getSchedule={getSchedule}
      />

      {/* NOTE MODALS */}
      {modal.addScheduleItem && projects && (
        <AddScheduleItemModal
          closeModal={closeModal}
          getSchedule={getSchedule}
          projects={projects}
        />
      )}
    </PageTemplate>
  );
};

export default SchedulePage;
