import React, { useState } from 'react';
import { Modal, Row, InputNumber } from 'antd';
import { HistoryColor } from 'services/store/enums/historyColor.enum';
import {
  IProject,
  IProjectProductionDays,
} from 'services/store/types/projects/Projects';

const initData = {
  project: 1,
  technologist: 1,
  carpenter: 1,
  montage: 1,
};

interface IProps {
  project: IProject;
  closeModal: () => void;
  handleUpdateProject: (
    updateData: Partial<IProject>,
    historyUpdate: string[],
    historyColor: HistoryColor
  ) => Promise<any>;
}

const SetProjectProductionDaysModal: React.FC<IProps> = ({
  project,
  closeModal,
  handleUpdateProject,
}) => {
  const [productionDays, setProductionDays] = useState<IProjectProductionDays>(
    project.productionDays || initData
  );

  const onOk = async () => {
    const historyUpdate = ['Ustalono dni produkcyjne'];
    await handleUpdateProject(
      { productionDays },
      historyUpdate,
      HistoryColor.SUCCESS
    );
    closeModal();
  };

  const handleChange = (key: string, value: number) =>
    setProductionDays((prev) => ({ ...prev, [key]: value }));

  return (
    <Modal
      title="Ustalanie dni produkcyjnych"
      destroyOnClose
      visible
      onCancel={closeModal}
      maskClosable={false}
      onOk={onOk}
      okButtonProps={{
        disabled: false,
      }}
    >
      <div style={{ marginBottom: 10 }}>
        Ustal ilość dni potrzebnych na poszczególne procesy
      </div>

      <Row justify="space-between" style={{ marginBottom: 10 }}>
        <strong>Projekt: </strong>
        <InputNumber
          value={productionDays.project}
          onChange={(value) => value && handleChange('project', value)}
        />
      </Row>
      <Row justify="space-between" style={{ marginBottom: 10 }}>
        <strong>Technolog: </strong>
        <InputNumber
          value={productionDays.technologist}
          onChange={(value) => value && handleChange('technologist', value)}
        />
      </Row>
      <Row justify="space-between" style={{ marginBottom: 10 }}>
        <strong>Stolarnia: </strong>
        <InputNumber
          value={productionDays.carpenter}
          onChange={(value) => value && handleChange('carpenter', value)}
        />
      </Row>
      <Row justify="space-between" style={{ marginBottom: 10 }}>
        <strong>Montaż: </strong>
        <InputNumber
          value={productionDays.montage}
          onChange={(value) => value && handleChange('montage', value)}
        />
      </Row>
    </Modal>
  );
};

export default SetProjectProductionDaysModal;
