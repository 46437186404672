import { message } from 'antd';
import axios from 'axios';
import FileSaver from 'file-saver';
import { FileDestination } from 'services/store/enums/files.enum';
import setAuthToken from 'services/utils/setAuthToken';

export const uploadFile = async (
  file: any,
  destination: FileDestination,
  name: string
) => {
  try {
    setAuthToken();
    const fileBody = new FormData();
    fileBody.append('file', file, file.fileName);

    const res = await axios.post(`/api/files/single`, fileBody, {
      params: {
        destination,
        name,
      },
    });

    const fileID = res.data;

    return fileID;
  } catch (error: any) {
    console.log(error, error.response);
  }
};

export const uploadMultipleFiles = async (
  files: any[],
  destination: FileDestination,
  name: string
) => {
  try {
    setAuthToken();
    const fileBody = new FormData();
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      fileBody.append('files', file, file.fileName);
    }

    const res = await axios.post(`/api/files/multiple`, fileBody, {
      params: {
        destination,
        name,
      },
    });
    console.log(res);
    const filesIDs = res.data;

    return filesIDs;
  } catch (error: any) {
    console.log(error, error.response);
  }
};

export const getFileFromPath = async (path: string) => {
  try {
    setAuthToken();
    const res = await axios({
      url: '/api/files/from-path',
      method: 'GET',
      responseType: 'arraybuffer',
      params: { path },
    });

    return FileSaver.saveAs(new Blob([res.data]), path.split('/').pop());
  } catch (error: any) {
    console.log(error, error.response);
    message.error('Błąd');
  }
};

export const removeFileById = async (id: string) => {
  try {
    setAuthToken();
    const res = await axios({
      url: `/api/files/${id}`,
      method: 'DELETE',
      responseType: 'arraybuffer',
    });

    return res;
  } catch (error: any) {
    console.log(error, error.response);
    message.error('Błąd');
  }
};
