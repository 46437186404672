import React, { useState } from 'react';
import { Modal, Button, message } from 'antd';
import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import {
  AntInput,
  AntSelect,
} from 'components/Formik-AntdFields/CreateAntdField';
import { getAllCategories } from 'services/store/actions/settings';
import { CategoryType } from 'services/store/enums/categories.enum';
import { ICategory } from 'services/store/types/settings/Settings';
import { _categoryTypes } from 'services/utils/const';
import { categoryDataValidation } from './utils/validation';

const initValues: ICategory = {
  type: CategoryType.ACCESSORY,
  name: '',
  description: '',
};

interface PropsT {
  closeModal: () => void;
  category?: ICategory;
}

const CreateCategoryModal: React.FC<PropsT> = ({ closeModal, category }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values: ICategory) => {
    setLoading(true);
    try {
      if (!category) await axios.post(`/api/settings/categories`, values);
      else
        await axios.patch(`/api/settings/categories/${category._id}`, values);

      dispatch(getAllCategories());
      message.success('Kategoria utworzona');
      return closeModal();
    } catch (error: any) {
      message.error('Błąd');
      console.log(error, error.response);
    }
    setLoading(false);
  };
  return (
    <Modal
      title={category ? 'Edycja kategorii' : 'Nowa kategoria'}
      destroyOnClose
      visible
      onCancel={closeModal}
      maskClosable={false}
      footer={[
        <Button key="back" onClick={closeModal}>
          Anuluj
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          htmlType="submit"
          form="addCategoryForm"
        >
          Zatwierdź
        </Button>,
      ]}
    >
      <Formik
        validationSchema={categoryDataValidation}
        onSubmit={handleSubmit}
        initialValues={category || initValues}
      >
        {(props) => {
          return (
            <Form noValidate id="addCategoryForm" onSubmit={props.handleSubmit}>
              <Field
                component={AntSelect}
                name="type"
                placeholder="Typ*"
                validate={props.errors.type}
                submitCount={props.submitCount}
                hasFeedback
                selectOptions={_categoryTypes}
                disabled={!!category}
              />
              <Field
                component={AntInput}
                name="name"
                type="text"
                placeholder="Nazwa*"
                validate={props.errors.name}
                submitCount={props.submitCount}
                hasFeedback
              />
              <Field
                component={AntInput}
                name="description"
                type="text"
                placeholder="Opis"
                validate={props.errors.description}
                submitCount={props.submitCount}
                hasFeedback
              />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default CreateCategoryModal;
