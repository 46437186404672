import axios, { AxiosResponse } from 'axios';
import { ISearchParams } from 'services/store/interfaces/search-params.interface';
import { ISearchResult } from 'services/store/interfaces/search-result.interface';
import { ICustomer } from 'services/store/types/customers/Customers';
import setAuthToken from 'services/utils/setAuthToken';
import { getUserName } from 'services/utils/string';

interface ISearchCustomersParams extends ISearchParams {
  name?: string;
}

export const customersApi = {
  createCustomer: async (
    customer: ICustomer
  ): Promise<AxiosResponse<ICustomer>> => {
    setAuthToken();
    return axios.post(`/api/customers`, {
      ...customer,
      userAction: `Dodał nowego klienta: ${getUserName(customer)}`,
    });
  },
  getCustomers: async (
    params: ISearchCustomersParams
  ): Promise<AxiosResponse<ISearchResult<ICustomer>>> => {
    setAuthToken();
    return axios.get('/api/customers', { params });
  },
  getNextOrderNumber: async (
    customerId: string
  ): Promise<AxiosResponse<string>> => {
    setAuthToken();
    return axios.get(`/api/customers/${customerId}/next-order-number`);
  },
};
