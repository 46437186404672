import React, { useState } from 'react';
import { Modal, Button, message, Typography } from 'antd';
import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import { AntInput } from 'components/Formik-AntdFields/CreateAntdField';
import { customerDataValidation } from 'components/Modals/CustomerCreateModal/utils/validation';
import { ICustomer } from 'services/store/types/customers/Customers';
const { Title } = Typography;

interface PropsT {
  customer: ICustomer;
  setCustomer: (customer: ICustomer) => void;
  closeModal: () => void;
}

const ChangeCustomerDataModal: React.FC<PropsT> = ({
  customer,
  setCustomer,
  closeModal,
}) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values: ICustomer, actions: any) => {
    setLoading(true);
    try {
      const res = await axios.patch(`/api/customers/${customer._id}`, values);
      setCustomer(res.data);
      message.success('Dane klienta zaktualizowane');
      closeModal();
    } catch (error: any) {
      if (error.response?.data?.error)
        actions.setErrors(error.response.data.error);
      else message.error('Błąd');
      console.log(error, error.response);
    }
  };
  return (
    <Modal
      title="Dane osobowe klienta"
      destroyOnClose
      visible
      onCancel={closeModal}
      maskClosable={false}
      footer={[
        <Button key="back" onClick={closeModal}>
          Anuluj
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          htmlType="submit"
          form="ChangeCustomerDataForm"
        >
          Zatwierdź
        </Button>,
      ]}
    >
      <Formik
        validationSchema={customerDataValidation}
        onSubmit={handleSubmit}
        initialValues={customer}
      >
        {(props) => {
          return (
            <Form
              noValidate
              id="ChangeCustomerDataForm"
              onSubmit={props.handleSubmit}
            >
              <Title level={5}>Dane klienta</Title>
              <Field
                component={AntInput}
                name="firstName"
                type="text"
                placeholder="Imię*"
                validate={props.errors.firstName}
                submitCount={props.submitCount}
                hasFeedback
              />
              <Field
                component={AntInput}
                name="lastName"
                type="text"
                placeholder="Nazwisko*"
                validate={props.errors.lastName}
                submitCount={props.submitCount}
                hasFeedback
              />
              <Field
                component={AntInput}
                name="email"
                type="email"
                placeholder="E-mail"
                validate={props.errors.email}
                submitCount={props.submitCount}
                hasFeedback
              />
              <Field
                component={AntInput}
                name="phone"
                type="text"
                placeholder="Nr telefonu"
                validate={props.errors.phone}
                submitCount={props.submitCount}
                hasFeedback
              />
              <Title level={5}>Dane firmy</Title>
              <Field
                component={AntInput}
                name="company"
                type="text"
                placeholder="Nazwa firmy"
                validate={props.errors.company}
                submitCount={props.submitCount}
                hasFeedback
              />
              <Field
                component={AntInput}
                name="nip"
                type="text"
                placeholder="NIP"
                validate={props.errors.nip}
                submitCount={props.submitCount}
                hasFeedback
              />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default ChangeCustomerDataModal;
