import React, { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Input,
  InputNumber,
  Modal,
  Row,
  Typography,
  Upload,
} from 'antd';
import { RcFile } from 'antd/lib/upload';
import dayjs from 'dayjs';
import AntDatePicker from 'components/DatePicker/DatePicker';
import { initOrderCostData } from 'scenes/OrderPage/data/initModals.data';
import { uploadFile } from 'services/requests/files.request';
import { FileDestination } from 'services/store/enums/files.enum';
import { HistoryColor } from 'services/store/enums/historyColor.enum';
import { IOrder, IOrderCost } from 'services/store/types/orders/Orders';

const { Title } = Typography;

interface IInitCost extends IOrderCost {
  files: RcFile[];
}

interface IProps {
  order: IOrder;
  closeModal: () => void;
  handleUpdateOrder: (
    updateData: Partial<IOrder>,
    historyUpdate: string[],
    historyColor: HistoryColor
  ) => Promise<any>;
}

const AddOrderCost: React.FC<IProps> = ({
  order,
  closeModal,
  handleUpdateOrder,
}) => {
  const [cost, setCost] = useState<IInitCost>(initOrderCostData);

  const onOk = async () => {
    let fileId: string | undefined = undefined;
    if (cost.files.length) {
      fileId = await uploadFile(
        cost.files[0],
        FileDestination.ORDERS,
        cost.name
      );
    }

    const historyUpdate = [`Dodano koszt`];
    const costItem = { ...cost, file: fileId };
    const newCosts = [...order.costs, costItem];
    await handleUpdateOrder(
      { costs: newCosts },
      historyUpdate,
      HistoryColor.SUCCESS
    );

    closeModal();
  };

  const handleChange = (field: keyof IInitCost, value: any) => {
    setCost({ ...cost, [field]: value });
  };

  return (
    <Modal
      title="Dodawanie kosztów zamówienia"
      destroyOnClose
      visible
      onCancel={closeModal}
      maskClosable={false}
      onOk={onOk}
      okButtonProps={{
        disabled:
          !cost.files.length ||
          !cost.name ||
          !cost.valueGross ||
          !cost.valueNet,
      }}
    >
      <Title level={5}>Nazwa</Title>
      <Input
        value={cost.name}
        onChange={(e) => handleChange('name', e.target.value)}
        placeholder="Nazwa"
      />
      <Title level={5}>Opis</Title>
      <Input
        value={cost.description}
        onChange={(e) => handleChange('description', e.target.value)}
        placeholder="Opis"
      />
      <Row>
        <Col span={12}>
          <Title level={5}>Wartość brutto</Title>
          <InputNumber
            min={0}
            value={cost.valueGross}
            onChange={(value) => handleChange('valueGross', value)}
          />
        </Col>
        <Col span={12}>
          <Title level={5}>Wartość netto</Title>
          <InputNumber
            min={0}
            value={cost.valueNet}
            onChange={(value) => handleChange('valueNet', value)}
          />
        </Col>
      </Row>
      <Title level={5}>Data</Title>
      <AntDatePicker
        value={dayjs(cost.date)}
        onChange={(value) =>
          value && handleChange('date', new Date(value.format()))
        }
        format="DD.MM.YYYY"
      />
      <Title level={5}>Plik</Title>
      <Upload
        onRemove={() => handleChange('files', [])}
        beforeUpload={(file: RcFile) => {
          handleChange('files', [file]);
          return false;
        }}
        fileList={cost.files}
        multiple={false}
        accept="image/*"
      >
        <Button icon={<UploadOutlined />}>Dodaj plik</Button>
      </Upload>
    </Modal>
  );
};

export default AddOrderCost;
