import {
  CashRegisterOperationType,
  ICashRegisterOperation,
} from 'services/store/types/cashRegister/CashRegister';

export const getCashRegisterOperationBalance = (
  operations: ICashRegisterOperation[]
): number => {
  const result = operations.reduce((acc, operation) => {
    if (operation.operationType === CashRegisterOperationType.IN)
      return (acc += operation.value);
    if (operation.operationType === CashRegisterOperationType.OUT)
      return (acc -= operation.value);
    return acc;
  }, 0);
  return result;
};
