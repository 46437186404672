import { useEffect, useState } from 'react';
import { HomeOutlined, UserAddOutlined } from '@ant-design/icons';
import { useMutation, useQueries } from '@tanstack/react-query';
import {
  Descriptions,
  PageHeader,
  Typography,
  Select,
  Skeleton,
  Space,
  Button,
  Input,
  InputNumber,
  message,
  Row,
  Col,
} from 'antd';
import { AxiosResponse } from 'axios';
import { useHistory } from 'react-router';
import CustomerData from 'components/CustomerData/CustomerData';
import AntDivider from 'components/Divider/Divider';
import { H1, H2, H4 } from 'components/Header';
import AddressModal from 'components/Modals/AddressModal/AddressModal';
import CreateCustomerModal from 'components/Modals/CustomerCreateModal/CreateCustomerModal';
import { ErrorModal } from 'components/Modals/ErrorModal/ErrorModal';
import { useControledDebounce } from 'hook/useControledDebounce';
import { useModal } from 'hook/useModals';
import useUser from 'hook/useUser';
import { customersApi } from 'services/api/customers.api';
import { ordersApi } from 'services/api/orders.api';
import { IAddress } from 'services/store/types/address/Address';
import { ICustomer } from 'services/store/types/customers/Customers';
import { IOrder } from 'services/store/types/orders/Orders';
import { sortingFuncion } from 'services/utils/sort.utils';
import { getUserName } from 'services/utils/string';
import PageTemplate from 'templates/PageTemplate';
import { initOrderData } from './data/initOrder.data';
import { newOrderValidation } from './utils/validation';

const { Text } = Typography;

const OrderCreate = () => {
  const history = useHistory();
  const { user, isAuth } = useUser();
  const { value, debouncedValue, handleDebouncedValue } =
    useControledDebounce();
  const { modal, showModal, closeModal } = useModal({
    addAddress: false,
    addCustomer: false,
  });

  const [order, setOrder] = useState<IOrder>(initOrderData);

  useEffect(() => {
    if (user) {
      setOrder((prev: any) => ({ ...prev, createdBy: user }));
    }
  }, [user]);

  const [customers] = useQueries({
    queries: [
      {
        queryKey: ['get-customers', debouncedValue],
        queryFn: () =>
          customersApi.getCustomers({
            pageNumber: 1,
            itemsPerPage: 10,
            name: debouncedValue,
          }),
        onError: (error: any) => {
          console.log(error, error.response);
          message.error('Błąd!');
        },
        enabled: isAuth && !!debouncedValue,
      },
      {
        queryKey: ['get-order-number', order.customer],
        queryFn: () => {
          if (!!order.customer?._id)
            return customersApi.getNextOrderNumber(order.customer._id);
        },
        onSuccess: (res?: AxiosResponse<string>) => {
          if (res) {
            setOrder((prev) => ({ ...prev, number: res.data }));
            message.success('Wygenerowano numer zamówienia');
          }
        },
        onError: (error: any) => {
          console.log(error, error.response);
          message.error('Błąd!');
        },
        enabled: !!order.customer?._id,
      },
    ],
  });

  //   NOTE Order
  const handleCreateOrder = async () => {
    const errors = newOrderValidation(order);
    if (errors.length) return ErrorModal(errors);
    return createOrder(order);
  };

  const { mutate: createOrder, isLoading } = useMutation({
    mutationKey: ['create-order'],
    mutationFn: (orderData: IOrder) => ordersApi.createOrder(orderData),
    onSuccess: (res: AxiosResponse<string>) => {
      message.success('Utworzono zamówienie');
      history.push(`/orders/${res.data}`);
      closeModal();
    },
    onError: () => {
      message.error('Błąd!');
    },
  });

  // NOTE Customer
  const handleSelectCustomer = (_id?: string) => {
    if (customers) {
      const customer = customers.data?.data.content.find((c) => c._id === _id);
      setCustomer(customer);
    }
  };
  const setCustomer = (customer?: ICustomer) => {
    const updateData: Partial<IOrder> = { customer, address: undefined };
    if (!customer) updateData.number = '';
    handleDebouncedValue(undefined);
    setOrder((prev: IOrder) => ({ ...prev, ...updateData }));
  };

  // NOTE Address
  const handleSetAddress = (address: IAddress) => {
    setOrder((prev: IOrder) => ({
      ...prev,
      address: address,
    }));
  };
  const handleSetCustomerAddress = () => {
    if (order.customer?.address) handleSetAddress(order.customer.address);
  };

  return (
    <PageTemplate>
      <PageHeader title={<H1>Nowe zamówienie</H1>} />
      {/* NOTE created by */}
      <H2>Utworzony przez</H2>
      {order.createdBy ? (
        <Descriptions>
          <Descriptions.Item label="Imię i nazwisko">
            <strong>
              {order.createdBy?.firstName} {order.createdBy?.lastName}
            </strong>
          </Descriptions.Item>
          <Descriptions.Item label="E-mail">
            <strong>
              <Text copyable>{order.createdBy?.email}</Text>
            </strong>
          </Descriptions.Item>
          <Descriptions.Item label="Nr telefonu">
            <strong>
              <Text copyable>{order.createdBy?.phone}</Text>
            </strong>
          </Descriptions.Item>
        </Descriptions>
      ) : (
        <Skeleton title={false} paragraph={{ rows: 1 }} />
      )}
      <AntDivider />
      {/* NOTE customer */}
      <Space size="large" align="start">
        <H2>Klient</H2>
        <Button key={1} onClick={() => showModal('addCustomer')} type="primary">
          <UserAddOutlined />
          Dodaj klienta
        </Button>
      </Space>
      <div title="Imię, nazwisko lub nazwa firmy">
        <Select
          showSearch
          value={value}
          placeholder="Wyszukaj..."
          size="large"
          style={{ width: 300, marginBottom: 30 }}
          defaultActiveFirstOption={false}
          filterOption={false}
          notFoundContent="Wpisz imię, nazwisko lub nazwę firmy"
          loading={customers.isFetching}
          onSearch={handleDebouncedValue}
          onChange={handleSelectCustomer}
          options={(customers.data?.data.content || [])
            .sort(sortingFuncion.customers.byCompanyOrLastName)
            .map((customer) => ({
              value: customer._id,
              label: customer.company || getUserName(customer, 'l-f'),
            }))}
        />
      </div>
      <CustomerData customer={order.customer} showAddress />
      <AntDivider />
      {/* NOTE Adres */}
      <Space size="large" align="start">
        <H2>Adres</H2>
        <Button
          type="primary"
          disabled={!order.customer}
          onClick={handleSetCustomerAddress}
        >
          <HomeOutlined />
          Adres klienta
        </Button>
        <Button onClick={() => showModal('addAddress')}>
          <HomeOutlined />
          Dodaj/Edytuj adres
        </Button>
      </Space>
      {order.address ? (
        <Descriptions title="Adres montażu">
          <Descriptions.Item label="Miejscowość">
            <strong>{order.address.city}</strong>
          </Descriptions.Item>
          <Descriptions.Item label="Kod pocztowy">
            <strong>{order.address.postcode}</strong>
          </Descriptions.Item>
          <Descriptions.Item label="Adres">
            <strong>{order.address.address}</strong>
          </Descriptions.Item>
          <Descriptions.Item label="Opis">
            <strong>{order.address.description}</strong>
          </Descriptions.Item>
        </Descriptions>
      ) : (
        <Skeleton paragraph={{ rows: 2 }} title={{ width: 200 }} />
      )}
      <AntDivider />
      {/* NOTE Order data */}
      <H2>Dane do zamówienia</H2>
      <div>
        <Space>
          <H4>Numer zamówienia:</H4>
          <Input
            size="large"
            value={order.number}
            onChange={(e) =>
              setOrder((prev) => ({ ...prev, number: e.target.value }))
            }
            placeholder="Numer zamówienia*"
          />
        </Space>
      </div>
      <br />
      <div>
        <Space>
          <H4>Nazwa zamówienia:</H4>
          <Input
            size="large"
            value={order.name}
            onChange={(e) =>
              setOrder((prev) => ({ ...prev, name: e.target.value }))
            }
            placeholder="Nazwa zamówienia"
          />
        </Space>
      </div>
      <br />
      <div>
        <Space>
          <H4>Cena:</H4>
          <InputNumber
            size="large"
            value={order.price}
            onChange={(price) =>
              price && setOrder((prev) => ({ ...prev, price: price }))
            }
            placeholder="Cena"
          />
        </Space>
      </div>
      <AntDivider />

      <Row style={{ marginTop: 50, marginBottom: 20 }}>
        <Col offset={21}>
          <Button
            size="large"
            type="primary"
            onClick={handleCreateOrder}
            loading={isLoading}
          >
            Utwórz zamówienie
          </Button>
        </Col>
      </Row>

      {/* NOTE MODALS */}
      {modal.addCustomer && (
        <CreateCustomerModal
          closeModal={closeModal}
          afterCreate={setCustomer}
        />
      )}
      {modal.addAddress && (
        <AddressModal
          closeModal={closeModal}
          onSubmit={handleSetAddress}
          address={order.address}
        />
      )}
    </PageTemplate>
  );
};

export default OrderCreate;
