export interface ITheme {
  primary: string;
  secondary: string;
  tertiary: string;
  positions: {
    projektant: string;
    technolog: string;
    stolarz: string;
    montażysta: string;
    magazynier: string;
    projektantLight: string;
    technologLight: string;
    stolarzLight: string;
    montażystaLight: string;
    magazynierLight: string;
  };
  saturday: string;
  sunday: string;
}
export const theme: ITheme = {
  primary: '#73a52e',
  secondary: '#308317',
  tertiary: '#162500',
  positions: {
    projektant: '#ffe260',
    technolog: '#8ccbff',
    stolarz: '#b2ff93',
    montażysta: '#91fff9',
    magazynier: '#e4bcff',
    projektantLight: '#fff8d9',
    technologLight: '#e8f5ff',
    stolarzLight: '#f0ffea',
    montażystaLight: '#e1fffd',
    magazynierLight: '#faf4ff',
  },
  saturday: 'lightgreen',
  sunday: 'rgba(255, 65, 65, 0.4)',
};
