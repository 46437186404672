import dayjs from 'dayjs';
import { Positions } from 'services/store/enums/users.enum';
import {
  IAction,
  IPositionNode,
  IProductionNode,
} from 'services/store/types/productionNodes/ProductionNodes';
import { _userPositions } from './const';

export const getChangedNodesFromSchedule = (nodes: IPositionNode[]) => {
  return nodes
    .reduce((acc: IProductionNode[], node) => {
      return [...acc, ...node.schedule];
    }, [])
    .filter((node) => node.isChanged)
    .map((node) => ({
      _id: node._id,
      nodeId: node.nodeId,
      date: node.date,
      actions: node.actions.map((action) => ({
        ...action,
        project: action.project ? action.project._id : null,
      })),
    }));
};

export const isProjectProductionPlaned = (
  nodes: IPositionNode[],
  projectId: string
) => {
  const schedules = nodes.reduce((acc: IProductionNode[], node) => {
    return [...acc, ...node.schedule];
  }, []);
  const actions = schedules.reduce((acc: IAction[], schedule) => {
    return [...acc, ...schedule.actions];
  }, []);
  return actions.some((action) => action.project?._id === projectId);
};

export const getUserCurrentNode = (
  userId: string,
  position: Positions,
  nodes: IPositionNode[]
) => {
  const today = dayjs();
  const userSchedule = nodes?.find((node) => {
    const isUserExist = node?.user?._id === userId;
    if (isUserExist && position === node.position) return node;
    return undefined;
  });
  if (userSchedule) {
    const userCurrentNode = userSchedule.schedule.find((node) => {
      const nodeDate = dayjs(node.date);
      const isSameDay = today.isSame(nodeDate, 'day');
      if (isSameDay) return node;
      return undefined;
    });
    if (userCurrentNode) return userCurrentNode;
  }
  return null;
};

export const getPositionNodesFromProductionNodes = (
  nodes: IProductionNode[]
) => {
  let dates: Date[] = getDatesFromProductionNodes(nodes);
  let positionNodes: IPositionNode[] = mapProductionNodesToPositionNodes(nodes);

  return { dates, nodes: positionNodes };
};

const getDatesFromProductionNodes = (nodes: IProductionNode[]): Date[] => {
  const nodeId = nodes[0].nodeId;
  const dates = nodes
    .filter((n) => n.nodeId === nodeId)
    .map((n) => n.date)
    .sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

  return dates;
};

const mapProductionNodesToPositionNodes = (
  productionNodes: IProductionNode[]
) => {
  let nodes: IPositionNode[] = [];
  _userPositions.forEach((position) => {
    const positionNodes = productionNodes
      .filter((n) => n.position === position)
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
      .reduce((acc: IPositionNode[], node: IProductionNode) => {
        const isExist = acc.find((n) => n.nodeId === node.nodeId);
        if (!isExist)
          return [
            ...acc,
            {
              position: node.position,
              nodeId: node.nodeId,
              // users: node.users,
              user: node.user,
              schedule: [node],
            },
          ];
        return acc.map((n) => {
          if (n.nodeId === node.nodeId)
            return { ...n, schedule: [...n.schedule, node] };
          return n;
        });
      }, []);

    nodes = nodes.concat(positionNodes);
  });
  return nodes;
};
