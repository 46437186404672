import React, { useEffect, useState } from 'react';
import { CheckCircleFilled, CheckOutlined } from '@ant-design/icons';
import { Image, Table } from 'antd';
import { useHistory } from 'react-router';
import { IAccessory } from 'services/store/types/accessories/Accessories';
import { IFile } from 'services/store/types/files/Files';
import { ICategory } from 'services/store/types/settings/Settings';
import { cutString } from 'services/utils/string';

const columns: any[] = [
  {
    dataIndex: 'lp',
    title: 'Lp.',
    align: 'center' as const,
  },
  {
    title: 'Zdjęcie',
    dataIndex: 'image',
    align: 'center' as const,
    render: (image: IFile) =>
      image ? (
        <Image
          alt="img"
          width={40}
          height={40}
          src={`${image.dir}`}
          preview={{ src: `${image.dir}` }}
        />
      ) : (
        '-'
      ),
  },
  {
    title: 'Kategoria',
    dataIndex: 'category',
    align: 'center' as const,
    render: (category: ICategory) => category.name,
  },
  {
    title: 'Nazwa',
    dataIndex: 'name',
    align: 'center' as const,
    defaultSortOrder: 'ascend' as const,
    sorter: (a: any, b: any) => a.name.localeCompare(b.name),
  },
  {
    title: 'Symbol',
    dataIndex: 'symbol',
    align: 'center' as const,
    sorter: (a: any, b: any) => (a.symbol || '').localeCompare(b.symbol || ''),
  },
  {
    title: 'Opis',
    dataIndex: 'description',
    align: 'center' as const,
    render: (description: string) => cutString(description || '', 20),
  },
  {
    title: 'Cena',
    dataIndex: 'price',
    align: 'center' as const,
  },
  {
    title: 'Sugerowany',
    dataIndex: 'isSuggested',
    align: 'center' as const,
    render: (isSuggested: boolean) => (isSuggested ? <CheckOutlined /> : null),
  },
];

interface IProps {
  accessories: IAccessory[];
  isLoading: boolean;
  onRowClick?: (id: string) => void;
  picked?: string[];
  qty?: boolean;
  maxHeight?: number;
}

const AccessoriesList: React.FC<IProps> = ({
  accessories,
  isLoading,
  onRowClick,
  picked,
  qty,
  maxHeight,
}) => {
  const history = useHistory();
  const [list, setList] = useState<any[]>([]);

  useEffect(() => {
    const newAccessories = accessories.map(
      (accessory: IAccessory, i: number) => ({
        ...accessory,
        lp:
          picked && accessory._id && picked.includes(accessory._id) ? (
            <CheckCircleFilled />
          ) : (
            i + 1
          ),
        key: accessory._id,
      })
    );
    setList(newAccessories);
  }, [accessories, picked]);

  const handleRedirect = (id: string) => {
    if (onRowClick) return onRowClick(id);
    history.push(`/base/accessories/${id}`);
  };

  return (
    <Table
      columns={
        qty
          ? [
              ...columns,
              {
                title: 'Ilość',
                dataIndex: 'qty',
                align: 'center' as const,
              },
            ]
          : columns
      }
      size="small"
      pagination={false}
      dataSource={list}
      loading={isLoading}
      scroll={{ x: 1000, y: maxHeight }}
      rowClassName={(r: any) => `table-row-clicable`}
      onRow={(record) => {
        return {
          onClick: () => {
            handleRedirect(record._id);
          },
        };
      }}
    />
  );
};

export default AccessoriesList;
