import React from 'react';
import { Tag } from 'antd';
import { OrderStatus } from 'services/store/enums/orders.enum';

interface IProps {
  status: OrderStatus;
}

const OrderStatusTag: React.FC<IProps> = ({ status }) => {
  if (status === OrderStatus.PENDING)
    return <Tag color="gold">{status.toUpperCase()}</Tag>;
  if (status === OrderStatus.IN_PROGRESS)
    return <Tag color="geekblue">{status.toUpperCase()}</Tag>;
  if (status === OrderStatus.FINISHED)
    return <Tag color="green">{status.toUpperCase()}</Tag>;
  return null;
};

export default OrderStatusTag;
