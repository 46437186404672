import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import {
  Form,
  Modal,
  message,
  Typography,
  Row,
  Col,
  Checkbox,
  Input,
} from 'antd';
import { useFormik } from 'formik';
import { isMobile } from 'react-device-detect';
import { customersApi } from 'services/api/customers.api';
import { ICustomer } from 'services/store/types/customers/Customers';
import { customerDataValidation, isAddressValid } from './utils/validation';

const { Title } = Typography;

const initialValues: ICustomer = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  nip: '',
  company: '',
  isOnBlackList: false,
  address: {
    city: '',
    postcode: '',
    address: '',
    description: '',
  },
};

interface PropsT {
  closeModal: () => void;
  refetch?: () => Promise<any>;
  afterCreate?: (customer: ICustomer) => void;
}

const CreateCustomerModal: React.FC<PropsT> = ({
  closeModal,
  refetch,
  afterCreate,
}) => {
  const [isAddressRequired, setIsAddressRequired] = useState<boolean>(false);

  const { mutate: createCustomer, isLoading } = useMutation({
    mutationKey: ['create-customer'],
    mutationFn: (customer: ICustomer) => customersApi.createCustomer(customer),
    onSuccess: async (res) => {
      message.success('Klient utworzony');
      if (refetch) await refetch();
      if (afterCreate) afterCreate(res.data);
      closeModal();
    },
    onError: (error: any) => {
      console.log(error, error.response);
      message.error('Błąd!');
    },
  });

  const formik = useFormik<ICustomer>({
    initialValues,
    onSubmit: (values) => {
      console.log(values);
      return createCustomer(values);
    },
    validationSchema: customerDataValidation,
    validate: (values) => {
      if (!values.lastName && !values.company)
        return {
          firstName: 'Musisz podać imię i nazwisko lub nazwę firmy',
          lastName: 'Musisz podać imię i nazwisko lub nazwę firmy',
          company: 'Musisz podać imię i nazwisko lub nazwę firmy',
        };
      if (!isAddressRequired) return isAddressValid(values.address);
    },
  });

  return (
    <Modal
      title="Nowy klient"
      destroyOnClose
      width={!isMobile ? 800 : '98vw'}
      maskClosable={false}
      visible
      onCancel={closeModal}
      onOk={() => formik.handleSubmit()}
      okButtonProps={{ loading: isLoading, disabled: !formik.isValid }}
      cancelButtonProps={{ disabled: isLoading }}
    >
      <Form labelCol={{ xs: { span: 24 }, sm: { span: 6 } }}>
        <Row gutter={16}>
          <Col span={12}>
            <Title level={5}>Osoba prywatna</Title>
            <Form.Item
              validateStatus={formik.errors.firstName && 'error'}
              help={formik.errors.firstName}
              label="Imię"
            >
              <Input
                {...formik.getFieldProps('firstName')}
                onChange={(e) =>
                  formik.setFieldValue('firstName', e.target.value)
                }
                autoComplete="none"
              />
            </Form.Item>
            <Form.Item
              validateStatus={formik.errors.lastName && 'error'}
              help={formik.errors.lastName}
              label="Nazwisko"
            >
              <Input
                {...formik.getFieldProps('lastName')}
                onChange={(e) =>
                  formik.setFieldValue('lastName', e.target.value)
                }
                autoComplete="none"
              />
            </Form.Item>

            <Title level={5}>Firma</Title>
            <Form.Item
              validateStatus={formik.errors.company && 'error'}
              help={formik.errors.company}
              label="Nazwa firmy"
            >
              <Input
                {...formik.getFieldProps('company')}
                onChange={(e) =>
                  formik.setFieldValue('company', e.target.value)
                }
                autoComplete="none"
              />
            </Form.Item>
            <Form.Item
              validateStatus={formik.errors.nip && 'error'}
              help={formik.errors.nip}
              label="NIP"
            >
              <Input
                {...formik.getFieldProps('nip')}
                onChange={(e) => formik.setFieldValue('nip', e.target.value)}
                autoComplete="none"
              />
            </Form.Item>

            <Title level={5}>Dane kontaktowe</Title>
            <Form.Item
              validateStatus={formik.errors.email && 'error'}
              help={formik.errors.email}
              label="E-mail"
            >
              <Input
                type="email"
                {...formik.getFieldProps('email')}
                onChange={(e) => formik.setFieldValue('email', e.target.value)}
                autoComplete="none"
              />
            </Form.Item>
            <Form.Item
              validateStatus={formik.errors.phone && 'error'}
              help={formik.errors.phone}
              label="Nr telefonu"
            >
              <Input
                type="tel"
                {...formik.getFieldProps('phone')}
                onChange={(e) => formik.setFieldValue('phone', e.target.value)}
                autoComplete="none"
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Title level={5}>Adres</Title>
            <Form.Item
              validateStatus={formik.errors.address?.city && 'error'}
              help={formik.errors.address?.city}
              label="Miejscowość"
            >
              <Input
                {...formik.getFieldProps('address.city')}
                onChange={(e) =>
                  formik.setFieldValue('address.city', e.target.value)
                }
                autoComplete="none"
              />
            </Form.Item>
            <Form.Item
              validateStatus={formik.errors.address?.postcode && 'error'}
              help={formik.errors.address?.postcode}
              label="Kod pocztowy"
            >
              <Input
                {...formik.getFieldProps('address.postcode')}
                onChange={(e) =>
                  formik.setFieldValue('address.postcode', e.target.value)
                }
                autoComplete="none"
              />
            </Form.Item>
            <Form.Item
              validateStatus={formik.errors.address?.address && 'error'}
              help={formik.errors.address?.address}
              label="Adres"
            >
              <Input
                {...formik.getFieldProps('address.address')}
                onChange={(e) =>
                  formik.setFieldValue('address.address', e.target.value)
                }
                autoComplete="none"
              />
            </Form.Item>
            <Form.Item
              validateStatus={formik.errors.address?.description && 'error'}
              help={formik.errors.address?.description}
              label="Opis"
            >
              <Input
                {...formik.getFieldProps('address.description')}
                onChange={(e) =>
                  formik.setFieldValue('address.description', e.target.value)
                }
                autoComplete="none"
              />
            </Form.Item>

            <Checkbox
              checked={isAddressRequired}
              onChange={(e) => setIsAddressRequired(e.target.checked)}
            >
              <small>Dodaj adres później</small>
            </Checkbox>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CreateCustomerModal;
