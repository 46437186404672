import dayjs from 'dayjs';
import { renderDateTitle } from 'components/ProductionSchedule/components/renderDateTitle';
import { renderProductionNodeCell } from 'components/ProductionSchedule/components/renderProductionNodeCell';
import {
  IDayIndexes,
  IHandleProjectInDay,
} from 'components/ProductionSchedule/interfaces/productionSchedule.interface';
import { Positions } from 'services/store/enums/users.enum';
import { IAction } from 'services/store/types/productionNodes/ProductionNodes';
import { IProject } from 'services/store/types/projects/Projects';
import { IUser } from 'services/store/types/users/Users';
import { renderDayCell } from './renderEditCells';

export const getEditDayViewTableHeaders = (
  dates: Date[],
  addProjectToSchedule: IHandleProjectInDay,
  removeProjectFromSchedule: IHandleProjectInDay,
  cellWidth: number,
  project?: IProject
) => {
  const nodesItem = {
    title: 'Gniazda produkcyjne',
    width: 150,
    dataIndex: 'node',
    render: (data: { position: Positions; user: IUser }) => {
      return renderProductionNodeCell(data);
    },
    key: 'node',
    fixed: 'left',
    align: 'center' as const,
  };
  const datesColumns = dates.map((date) => {
    return {
      title: renderDateTitle(date),
      render: (
        data: {
          actions: IAction[];
          indexes: IDayIndexes;
        },
        record: any
      ) => {
        return renderDayCell(
          data?.actions,
          date,
          record.node.position,
          addProjectToSchedule,
          removeProjectFromSchedule,
          data?.indexes,
          cellWidth,
          project?._id
        );
      },
      onCell: () => {},
      dataIndex: dayjs(date).format('DD.MM.YYYY'),
      key: date,
      width: cellWidth,
      align: 'center' as const,
    };
  });

  return [nodesItem, ...datesColumns];
};
