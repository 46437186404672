import React from 'react';
import { Modal, Row, Button, Typography, message as messagePopUp } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { setLoading } from 'services/store/actions/view';
import { IMessage } from 'services/store/types/messages/Messages';
import { IUser } from 'services/store/types/users/Users';
import { getUserName } from 'services/utils/string';

interface IProps {
  message: IMessage;
  closeModal: () => void;
  refetchMessages: () => Promise<any>;
}

const ImportantMessageModal: React.FC<IProps> = ({
  message,
  closeModal,
  refetchMessages,
}) => {
  const dispatch = useDispatch();

  const markMessageReaded = async () => {
    try {
      dispatch(setLoading(true));
      await axios.patch(`/api/messages/${message._id}/mark-readed`);
      await refetchMessages();
      messagePopUp.info('Wiadomość odczytana');
    } catch (error) {
      console.log(error);
      messagePopUp.error('Błąd');
    } finally {
      dispatch(setLoading(false));
      closeModal();
    }
  };

  return (
    <Modal
      title={`Wiadomość od: ${getUserName(message.user as IUser)}`}
      destroyOnClose
      maskClosable={false}
      visible
      footer={
        <Row justify="end">
          <Button type="primary" onClick={markMessageReaded}>
            Przeczytałem
          </Button>
        </Row>
      }
    >
      <Row justify={'space-between'}>
        <Typography.Title level={4}>{message.subject}</Typography.Title>
        <div>{dayjs(message.date).format('YYYY.MM.DD HH:mm')}</div>
      </Row>
      <Typography.Text>{message.message}</Typography.Text>
    </Modal>
  );
};

export default ImportantMessageModal;
