import React, { useEffect, useState } from 'react';
import { AppstoreAddOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import PickAccessoriesModal from 'components/Modals/PickAccessoriesModal/PickAccessoriesModal';
import { useModal } from 'hook/useModals';
import ProjectAccessoriesList from 'scenes/ProjectCreate/components/ProjectAccessoriesList';
import { TAppState } from 'services/store';
import { getAllAccessories } from 'services/store/actions/accessories';
import { setLoading } from 'services/store/actions/view';
import { HistoryColor } from 'services/store/enums/historyColor.enum';
import { IHistory } from 'services/store/interfaces/history.interface';
import { IAccessory } from 'services/store/types/accessories/Accessories';
import {
  IProject,
  IProjectAccessory,
} from 'services/store/types/projects/Projects';

interface IProps {
  project: IProject;
  closeModal: () => void;
  handleUpdateProject: (
    updateData: Partial<IProject>,
    historyUpdate: string[],
    historyColor: HistoryColor
  ) => Promise<any>;
}

const EditProjectAccessoriesModal: React.FC<IProps> = ({
  project,
  closeModal,
  handleUpdateProject,
}) => {
  const dispatch = useDispatch();
  const allAccessories = useSelector(
    (state: TAppState) => state.accessories.accessories
  );
  const [list, setList] = useState<IProjectAccessory[]>(project.accessories);
  const {
    modal,
    closeModal: closeInnerModal,
    showModal,
  } = useModal({ addAccessory: false });

  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(getAllAccessories({}, () => dispatch(setLoading(false))));
  }, []);

  const handleDelete = (index: number) => {
    const newList = list.filter((acc, i) => i !== index);
    setList(newList);
  };
  const handleQty = (index: number, value: number) => {
    const newList = list.map((acc, i) => {
      if (i === index) return { ...acc, qty: value };
      return acc;
    });
    setList(newList);
  };

  const handleAddAccessories = (picked: IProjectAccessory[]) => {
    const newAccessories = picked.map((acc) => {
      const existing = list.find(
        (a) =>
          (a.accessory as IAccessory)._id === (acc.accessory as IAccessory)._id
      );
      if (existing) return existing;
      return acc;
    });
    setList(newAccessories);
  };

  const onOk = async () => {
    const historyUpdate = ['Zaktualizowano listę akcesoriów'];

    await handleUpdateProject(
      { accessories: list },
      historyUpdate,
      HistoryColor.SUCCESS
    );
    closeModal();
  };

  return (
    <Modal
      title="Edycja akcesoriów"
      destroyOnClose
      width={'95vw'}
      visible
      onCancel={closeModal}
      maskClosable={false}
      onOk={onOk}
    >
      <Button
        type="primary"
        style={{ marginBottom: 10 }}
        onClick={() => showModal('addAccessory')}
      >
        <AppstoreAddOutlined /> Dodaj
      </Button>
      <ProjectAccessoriesList
        accessories={list}
        handleDelete={handleDelete}
        handleQty={handleQty}
      />

      {/* MODALS */}
      {modal.addAccessory && allAccessories && (
        <PickAccessoriesModal
          picked={list.map((acc) => (acc.accessory as IAccessory)._id)}
          accessories={allAccessories}
          closeModal={closeInnerModal}
          onSubmit={handleAddAccessories}
        />
      )}
    </Modal>
  );
};

export default EditProjectAccessoriesModal;
