import axios from 'axios';
import { CategoryType } from 'services/store/enums/categories.enum';
import setAuthToken from 'services/utils/setAuthToken';

export const getCategoriesByType = async (type: CategoryType) => {
  try {
    setAuthToken();
    const res = await axios.get(`/api/settings/categories/${type}`);

    return res.data;
  } catch (error) {
    console.log(error);
  }
};
