import dayjs from 'dayjs';
import { Positions } from 'services/store/enums/users.enum';
import { IAction } from 'services/store/types/productionNodes/ProductionNodes';
import { IProject } from 'services/store/types/projects/Projects';
import { theme } from 'theme/MainTheme';

export const getDateTextColor = (weekDay: number) => {
  if (weekDay === 0) return 'red';
  else if (weekDay === 6) return 'green';
  return 'black';
};
export const getProductionNodeCellColor = (position?: Positions): string => {
  if (position) return theme.positions[position];
  else return 'white';
};
export const getCellColor = (
  date: Date,
  position: Positions,
  editable: boolean,
  isBlocked?: boolean,
  clickable?: boolean
): string => {
  const weekDay = dayjs(date).day();
  if (editable && isBlocked) return 'lightgray';
  if (editable && !clickable && !isBlocked) return 'green';
  if (weekDay === 0) return theme.sunday;
  if (weekDay === 6) return theme.saturday;
  return theme.positions[`${position}Light`];
};

export const getUniqueProjectsNumbersFromActions = (actions?: IAction[]) => {
  const projectsNumbers = actions
    ?.filter((action) => action.project)
    .map((action) => (action.project as IProject).number)
    .reduce((acc: string[], number: string) => {
      const isExist = acc.find((nr) => nr === number);
      if (isExist) return acc;

      return [...acc, number];
    }, []);

  return projectsNumbers || [];
};
