import React, { useState, useEffect } from 'react';
import {
  Modal,
  Input,
  Space,
  Select,
  message as messageModal,
  Checkbox,
} from 'antd';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { TAppState } from 'services/store';
import { getAllUsers } from 'services/store/actions/users';
import { IUser } from 'services/store/types/users/Users';
import setAuthToken from 'services/utils/setAuthToken';

const { Option } = Select;

interface IProps {
  closeModal: () => void;
  getMessages: () => Promise<any>;
}

const NewMessageModal: React.FC<IProps> = ({ closeModal, getMessages }) => {
  const dispatch = useDispatch();
  const users = useSelector((state: TAppState) => state.users.users);
  const user = useSelector((state: TAppState) => state.auth.user);

  const [loading, setLoading] = useState(false);
  const [subject, setSubject] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [recipients, setRecipients] = useState<string[]>([]);
  const [isImportant, setIsImportant] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    dispatch(getAllUsers(() => setLoading(false)));
  }, []);

  const createMessage = async () => {
    if (user)
      try {
        setLoading(true);
        setAuthToken();

        await axios.post(`/api/messages`, {
          subject,
          message,
          recipients,
          isImportant,
        });

        await getMessages();
        closeModal();
      } catch (error: any) {
        console.log(error, error.response);
        messageModal.error('Błąd');
      } finally {
        setLoading(false);
      }
  };

  return (
    <Modal
      title="Nowa wiadomość"
      destroyOnClose
      maskClosable={false}
      visible
      onCancel={closeModal}
      onOk={createMessage}
      okButtonProps={{
        loading,
        disabled: loading || !subject || !message || !recipients.length,
      }}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <Select
          allowClear
          mode="multiple"
          placeholder="Odbiorcy"
          disabled={loading}
          loading={loading}
          value={recipients}
          style={{ width: '100%' }}
          optionFilterProp="children"
          filterOption={(input, option: any) =>
            option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          filterSort={(optionA, optionB) =>
            optionA?.children
              ?.toLowerCase()
              .localeCompare(optionB?.children?.toLowerCase())
          }
          onClear={() => setRecipients([])}
          onChange={(value) => setRecipients(value)}
        >
          {users
            .filter((item: any) => item._id !== user?._id)
            .sort((a: any, b: any) => `${a.lastName}`.localeCompare(b.lastName))
            .map((item: IUser) => {
              if (item._id)
                return (
                  <Option key={item._id} value={item._id}>
                    {`${item.firstName} ${item.lastName}`}
                  </Option>
                );
            })}
        </Select>
        <Input
          value={subject}
          onChange={(e: any) => setSubject(e.target.value)}
          placeholder="Temat"
        />
        <Input.TextArea
          value={message}
          onChange={(e: any) => setMessage(e.target.value)}
          placeholder="Wiadomość..."
          rows={4}
        />
        <Checkbox
          checked={isImportant}
          onChange={(e) => setIsImportant(e.target.checked)}
        >
          Priorytet
        </Checkbox>
      </Space>
    </Modal>
  );
};

export default NewMessageModal;
