import React, { useEffect, useState } from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Table, TableProps, Typography } from 'antd';
import { useHistory } from 'react-router';
import { ICustomer } from 'services/store/types/customers/Customers';
import { capitalizeFirstLetter } from 'services/utils/string';

const { Text } = Typography;

const columns = [
  {
    title: 'Lp.',
    dataIndex: 'isOnBlackList',
    align: 'center' as const,
    render: (isOnBlackList: boolean, r: any, i: number) =>
      isOnBlackList ? (
        <ExclamationCircleFilled
          style={{ color: 'red' }}
          title="Klient jest na czarnej liście"
        />
      ) : (
        i + 1
      ),
  },
  {
    title: 'Imię',
    dataIndex: 'firstName',
    align: 'center' as const,
    sorter: (a: ICustomer, b: ICustomer) =>
      a.firstName.localeCompare(b.firstName),
    render: (firstName: string) => capitalizeFirstLetter(firstName),
  },
  {
    title: 'Nazwisko',
    dataIndex: 'lastName',
    align: 'center' as const,
    sorter: (a: ICustomer, b: ICustomer) =>
      a.lastName.localeCompare(b.lastName),
    render: (lastName: string) => capitalizeFirstLetter(lastName),
  },
  {
    title: 'Nazwa firmy',
    dataIndex: 'company',
    align: 'center' as const,
    sorter: (a: ICustomer, b: ICustomer) =>
      (a.company || '').localeCompare(b.company || ''),
    render: (company: string) => capitalizeFirstLetter(company),
  },
  {
    title: 'E-mail',
    dataIndex: 'email',
    align: 'center' as const,
    sorter: (a: ICustomer, b: ICustomer) => a.email.localeCompare(b.email),
    render: (email: string) => <Text>{email}</Text>,
  },
  {
    title: 'Telefon',
    dataIndex: 'phone',
    align: 'center' as const,
    render: (phone: string) => <Text>{phone}</Text>,
  },
];

interface IProps extends TableProps<ICustomer> {
  customers?: ICustomer[];
}
const CustomersList: React.FC<IProps> = ({ customers, ...props }) => {
  const history = useHistory();
  const [list, setList] = useState<any[]>([]);

  useEffect(() => {
    if (customers) {
      const newList = customers?.map((customer: ICustomer, i: number) => ({
        key: customer._id,
        ...customer,
      }));

      setList(newList);
    }
  }, [customers]);

  const handleRedirect = (id: string) => history.push(`/customers/${id}`);

  return (
    <div>
      <Table
        columns={columns}
        dataSource={list}
        scroll={{ x: 1000 }}
        rowClassName={() => 'table-row-clicable'}
        onRow={(record) => {
          return {
            onClick: () => {
              record._id && handleRedirect(record._id);
            },
          };
        }}
        {...props}
      />
    </div>
  );
};

export default CustomersList;
