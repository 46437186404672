import React from 'react';
import { PageHeader, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { H1, H2 } from 'components/Header';
import { TAppState } from 'services/store';
import { setOrderStatisticsPageKey } from 'services/store/actions/view';
import { OrderStatus } from 'services/store/enums/orders.enum';
import PageTemplate from 'templates/PageTemplate';
import OrdersStatistics from './components/OrdersStatistics';
const { TabPane } = Tabs;

const OrdersStatisticsPage = () => {
  const dispatch = useDispatch();
  const key = useSelector(
    (state: TAppState) => state.view.ordersStatisticsPageKey
  );

  const handleKey = (k: OrderStatus) => dispatch(setOrderStatisticsPageKey(k));

  return (
    <PageTemplate>
      <PageHeader title={<H1>Statystyki zamówień</H1>} />
      <Tabs
        defaultActiveKey={key}
        onChange={(k: any) => handleKey(k as OrderStatus)}
        size="small"
      >
        <TabPane tab={<H2>Oczekujące</H2>} key={OrderStatus.PENDING}>
          {key === OrderStatus.PENDING && <OrdersStatistics status={key} />}
        </TabPane>
        <TabPane tab={<H2>W produkcji</H2>} key={OrderStatus.IN_PROGRESS}>
          {key === OrderStatus.IN_PROGRESS && <OrdersStatistics status={key} />}
        </TabPane>
        <TabPane tab={<H2>Zakończone</H2>} key={OrderStatus.FINISHED}>
          {key === OrderStatus.FINISHED && <OrdersStatistics status={key} />}
        </TabPane>
      </Tabs>
    </PageTemplate>
  );
};

export default OrdersStatisticsPage;
