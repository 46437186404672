import Axios from 'axios';
import { Dispatch } from 'redux';
import setAuthToken from 'services/utils/setAuthToken';
import { Positions } from '../enums/users.enum';
import {
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_ERROR,
  AUTH_LOGOUT_USER,
  AUTH_USER_LOAD_SUCCESS,
  IUserLoginSuccess,
  IUserLoginError,
  IUserLogout,
  IUserLoadSuccess,
} from '../types/auth/actions';
import { IUser } from '../types/users/Users';

export const loginSuccess = (token: string): IUserLoginSuccess => ({
  type: AUTH_LOGIN_SUCCESS,
  token,
});
export const loginError = (): IUserLoginError => ({ type: AUTH_LOGIN_ERROR });
export const logoutUser = (): IUserLogout => ({ type: AUTH_LOGOUT_USER });

export const userLoadSuccess = (
  user: IUser,
  permission: string
): IUserLoadSuccess => ({
  type: AUTH_USER_LOAD_SUCCESS,
  user,
  permission,
});

/**--------------------
 * THUNK
 ----------------------*/

export const loadUserData =
  (_onError?: () => void) => async (dispatch: Dispatch) => {
    setAuthToken();
    try {
      const res = await Axios.get('/api/auth/get-me');

      const user = res.data;

      dispatch(userLoadSuccess(user, user.permission));
    } catch (error) {
      dispatch(logoutUser());
      if (_onError) _onError();
    }
  };

export const setUserActivePosition =
  (
    id: string,
    position: Positions | undefined,
    actions?: { _onError?: () => void; _onEnd?: () => void }
  ) =>
  async (dispatch: Dispatch) => {
    setAuthToken();
    try {
      const res = await Axios.patch(`/api/users/${id}`, {
        activePosition: position,
      });

      const user = res.data;

      dispatch(userLoadSuccess(user, user.permission));
      if (actions?._onEnd) actions?._onEnd();
    } catch (error) {
      dispatch(logoutUser());
      if (actions?._onError) actions?._onError();
    }
  };
