import React, { useEffect, useState } from 'react';
import { message, PageHeader, Tabs } from 'antd';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { H1 } from 'components/Header';
import CreateCategoryModal from 'components/Modals/CreateCategoryModal/CreateCategory.modal';
import { useModal } from 'hook/useModals';
import { TAppState } from 'services/store';
import {
  getAllCategories,
  getAllWorkActions,
  getGlobalSettings,
  setSettings,
} from 'services/store/actions/settings';
import { setLoading } from 'services/store/actions/view';
import { IProductionNode } from 'services/store/types/productionNodes/ProductionNodes';
import { ISettings } from 'services/store/types/settings/Settings';
import setAuthToken from 'services/utils/setAuthToken';
import PageTemplate from 'templates/PageTemplate';
import Categories from './components/Categories';
import CompanyData from './components/CompanyData';
import AddCompanyAddressModal from './components/Modals/AddAddressModal';
import AddCompanyContactModal from './components/Modals/AddContactModal';
import AddCompanyWebpageModal from './components/Modals/AddWebpageModal';
import AddWorkActionModal from './components/Modals/AddWorkAction.modal';
import ChangeCompanyDataModal from './components/Modals/ChangeCompanyDataModal';
import ProductionNodeModal from './components/Modals/ProductionNode.modal';
import ProductionNodes from './components/ProductionNodes';
import SettingsActions from './components/SettingsActions';
import WorkActions from './components/WorkActions';
import { initSettingsModals } from './data/initModals.data';

// TOOD Dodać możliwość edycji adresów, kontaktów, stron

const { TabPane } = Tabs;

const SettingsPage = () => {
  const dispatch = useDispatch();
  const [tab, setTab] = useState('companyData');
  const { modal, showModal, closeModal } = useModal(initSettingsModals);

  const settings = useSelector((state: TAppState) => state.settings);
  const [nodeToEdit, setNodeToEdit] = useState<IProductionNode>();

  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(getGlobalSettings(() => dispatch(setLoading(false))));
    dispatch(getAllCategories());
    dispatch(getAllWorkActions());
  }, []);

  const updateSettings = async (updatedSettings: Partial<ISettings>) => {
    try {
      dispatch(setLoading(true));
      setAuthToken();
      const res = await axios.patch(`/api/settings`, updatedSettings);

      dispatch(setSettings(res.data));
    } catch (error) {
      console.log(error);
      message.error('Błąd!');
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <PageTemplate>
      <PageHeader
        title={<H1>Ustawienia główne</H1>}
        extra={SettingsActions(tab, { showModal })}
      />
      <Tabs
        defaultActiveKey={tab}
        onChange={(key) => setTab(key)}
        size="large"
        style={{ marginBottom: 32 }}
      >
        <TabPane tab="Dane firmy" key="companyData">
          <CompanyData settings={settings} updateSettings={updateSettings} />
        </TabPane>
        <TabPane tab="Gniazda produkcyjne" key="productionNode">
          <ProductionNodes setNodeToEdit={setNodeToEdit} />
        </TabPane>
        <TabPane tab="Kategorie" key="categories">
          <Categories categories={settings.categories} />
        </TabPane>
        <TabPane tab="Akcje pracowników" key="workActions">
          <WorkActions workActions={settings.workActions} />
        </TabPane>
      </Tabs>

      {/* MODALS */}
      {(modal.addProductionNode || nodeToEdit) && (
        <ProductionNodeModal
          closeModal={() => {
            setNodeToEdit(() => undefined);
            closeModal();
          }}
          nodeToEdit={nodeToEdit}
        />
      )}
      {modal.editCompanyData && (
        <ChangeCompanyDataModal
          closeModal={closeModal}
          companyData={settings.companyData}
          updateSettings={updateSettings}
        />
      )}
      {modal.addAddress && (
        <AddCompanyAddressModal
          closeModal={closeModal}
          address={settings.address}
          updateSettings={updateSettings}
        />
      )}
      {modal.addContact && (
        <AddCompanyContactModal
          closeModal={closeModal}
          contact={settings.contact}
          updateSettings={updateSettings}
        />
      )}
      {modal.addWebPage && (
        <AddCompanyWebpageModal
          closeModal={closeModal}
          webPages={settings.webPages}
          updateSettings={updateSettings}
        />
      )}
      {modal.addCategory && <CreateCategoryModal closeModal={closeModal} />}
      {modal.addWorkAction && <AddWorkActionModal closeModal={closeModal} />}
    </PageTemplate>
  );
};

export default SettingsPage;
