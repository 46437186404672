import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, message, Modal } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import TextButton from 'components/Buttons/TextButton/TextButton';
import AntDivider from 'components/Divider/Divider';
import { setLoading } from 'services/store/actions/view';
import { ICustomer } from 'services/store/types/customers/Customers';
import { IProject } from 'services/store/types/projects/Projects';
import { ISchedule } from 'services/store/types/schedule/Schedule';
import { IUser } from 'services/store/types/users/Users';
import { getUserName } from 'services/utils/string';

interface IProps {
  scheduleItem: ISchedule;
  closeModal: () => void;
  getSchedule: () => Promise<any>;
}

const ScheduleItemDetailsModal: React.FC<IProps> = ({
  scheduleItem,
  closeModal,
  getSchedule,
}) => {
  const dispatch = useDispatch();
  const project = scheduleItem.project as IProject;

  const history = useHistory();
  const goToProject = () => history.push(`/projects/${project._id}`);

  const finishEvent = async () => {
    try {
      dispatch(setLoading(true));

      // TODO Update project
      if (project) {
        // const historyItem: Partial<IHistory> = {
        //   color: 'green' as HistoryColor,
        //   actions: [
        //     'Zakończono wydarzenie związane z projektem',
        //     `${dayjs(scheduleItem.date).format('DD.MM.YYYY')} - ${
        //       scheduleItem.name
        //     }`,
        //   ],
        // };
        // await axios.put(`/api/projects/${project._id}`, {
        //   updateData: {},
        //   historyItem,
        //   userAction: `Zakończył wydarzenie ${scheduleItem.name}`,
        // });
      }

      await axios.delete(`/api/schedule/${scheduleItem._id}`);
      await getSchedule();
      message.success('Wydarzenie zakończone');
      closeModal();
    } catch (error) {
      console.log(error);
      message.error('Błąd!');
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleFinish = () =>
    Modal.confirm({
      title: 'Czy chcesz zakończyć wydarzenie?',
      icon: <ExclamationCircleOutlined />,
      content: 'Wydarzenie zostanie usunięte z terminarza',
      onOk() {
        finishEvent();
      },
      onCancel() {},
    });

  return (
    <Modal
      title={`${dayjs(scheduleItem.date).format('DD.MM.YYYY')} - ${
        scheduleItem.name
      }`}
      destroyOnClose
      visible
      onCancel={closeModal}
      bodyStyle={{ padding: 10 }}
      footer={
        <div>
          <Button type="primary" onClick={handleFinish}>
            Zakończ wydarzenie
          </Button>
        </div>
      }
    >
      {project && (
        <div>
          Dotyczy projektu:{' '}
          <TextButton
            text={`${getUserName(project.order?.customer as ICustomer)} - ${
              project.name
            }`}
            onClick={goToProject}
          />
          <AntDivider marginBottom={10} marginTop={10} />
        </div>
      )}
      <div>
        <small>
          Utworzone dnia:{' '}
          <strong>{dayjs(scheduleItem.createdAt).format('DD.MM.YYYY')}</strong>
        </small>
      </div>
      <div>
        <small>
          Przez: <strong>{getUserName(scheduleItem.createdBy as IUser)}</strong>
        </small>
      </div>
      <div>
        <small>
          Dla:{' '}
          {scheduleItem.createdFor.map((user) => (
            <strong key={(user as IUser)._id}>
              {getUserName(user as IUser)},{' '}
            </strong>
          ))}
        </small>
      </div>

      {scheduleItem.description && (
        <div>
          <AntDivider marginBottom={10} marginTop={10} />
          {scheduleItem.description}
        </div>
      )}
    </Modal>
  );
};

export default ScheduleItemDetailsModal;
