import axios, { AxiosResponse } from 'axios';
import {
  ISearchByDateParams,
  ISearchParams,
} from 'services/store/interfaces/search-params.interface';
import { ISearchResult } from 'services/store/interfaces/search-result.interface';
import {
  CashRegisterOperationType,
  ICashRegister,
  ICashRegisterOperation,
  ICashRegisterOperationDescription,
  ICashRegisterReport,
} from 'services/store/types/cashRegister/CashRegister';
import setAuthToken from 'services/utils/setAuthToken';

interface INewCashRegisterOperation
  extends Pick<
    ICashRegisterOperation,
    'value' | 'description' | 'operationType' | 'additionalInfo'
  > {}
export interface INewCashRegisterOperationDescription
  extends Pick<ICashRegisterOperationDescription, 'forType' | 'value'> {}

interface IGetCashRegisterOperationsParams extends ISearchByDateParams {
  operationType?: CashRegisterOperationType;
  description?: string;
  settled?: any;
}

export const cashRegisterApi = {
  getCashRegister: async (): Promise<AxiosResponse<ICashRegister>> => {
    setAuthToken();
    return axios.get('/api/cash-register/main');
  },
  getCashRegisterOperations: async (
    params: IGetCashRegisterOperationsParams
  ): Promise<AxiosResponse<ISearchResult<ICashRegisterOperation>>> => {
    setAuthToken();
    return axios.get('/api/cash-register/operations', { params });
  },
  getCashRegisterOperationDescriptions: async (): Promise<
    AxiosResponse<ICashRegisterOperationDescription[]>
  > => {
    setAuthToken();
    return axios.get('/api/cash-register/operation-descriptions');
  },
  getCashRegisterReports: async (
    params: ISearchParams
  ): Promise<AxiosResponse<ISearchResult<ICashRegisterReport>>> => {
    setAuthToken();
    return axios.get('/api/cash-register/reports', { params });
  },

  updateAuthorizedUsers: async (authorizedUsers: string[]) => {
    setAuthToken();
    return axios.patch('/api/cash-register/authorized-users', {
      authorizedUsers,
    });
  },

  createCashRegisterOperation: async (
    newOperation: INewCashRegisterOperation
  ): Promise<AxiosResponse<string>> => {
    setAuthToken();
    return axios.post('/api/cash-register/new-operation', newOperation);
  },

  createCashRegisterOperationDescription: async (
    newOperationDescription: INewCashRegisterOperationDescription
  ): Promise<AxiosResponse<string>> => {
    setAuthToken();
    return axios.post(
      '/api/cash-register/new-operation-description',
      newOperationDescription
    );
  },

  generateReport: async (
    newOperation: INewCashRegisterOperation
  ): Promise<AxiosResponse<string>> => {
    setAuthToken();
    return axios({
      method: 'PATCH',
      url: '/api/cash-register/generate-report',
      responseType: 'arraybuffer',
      data: newOperation,
    });
  },

  getReport: async (id: string): Promise<AxiosResponse<string>> => {
    setAuthToken();
    return axios({
      method: 'GET',
      url: `/api/cash-register/report-file/${id}`,
      responseType: 'arraybuffer',
    });
  },

  removeCashRegisterOperationDescription: async (
    id: string
  ): Promise<AxiosResponse<any>> => {
    setAuthToken();
    return axios.delete(`/api/cash-register/operation-description/${id}`);
  },
};

export const cashRegisterApiV2 = {
  getCashRegister: async (): Promise<AxiosResponse<ICashRegister>> => {
    setAuthToken();
    return axios.get('/api/cash-register-v2/main');
  },
  getCashRegisterOperations: async (
    params: IGetCashRegisterOperationsParams
  ): Promise<AxiosResponse<ISearchResult<ICashRegisterOperation>>> => {
    setAuthToken();
    return axios.get('/api/cash-register-v2/operations', { params });
  },
  getCashRegisterOperationDescriptions: async (): Promise<
    AxiosResponse<ICashRegisterOperationDescription[]>
  > => {
    setAuthToken();
    return axios.get('/api/cash-register-v2/operation-descriptions');
  },
  getCashRegisterReports: async (
    params: ISearchParams
  ): Promise<AxiosResponse<ISearchResult<ICashRegisterReport>>> => {
    setAuthToken();
    return axios.get('/api/cash-register-v2/reports', { params });
  },

  updateAuthorizedUsers: async (authorizedUsers: string[]) => {
    setAuthToken();
    return axios.patch('/api/cash-register-v2/authorized-users', {
      authorizedUsers,
    });
  },

  createCashRegisterOperation: async (
    newOperation: INewCashRegisterOperation
  ): Promise<AxiosResponse<string>> => {
    setAuthToken();
    return axios.post('/api/cash-register-v2/new-operation', newOperation);
  },

  createCashRegisterOperationDescription: async (
    newOperationDescription: INewCashRegisterOperationDescription
  ): Promise<AxiosResponse<string>> => {
    setAuthToken();
    return axios.post(
      '/api/cash-register-v2/new-operation-description',
      newOperationDescription
    );
  },

  generateReport: async (
    newOperation: INewCashRegisterOperation
  ): Promise<AxiosResponse<string>> => {
    setAuthToken();
    return axios({
      method: 'PATCH',
      url: '/api/cash-register-v2/generate-report',
      responseType: 'arraybuffer',
      data: newOperation,
    });
  },

  getReport: async (id: string): Promise<AxiosResponse<string>> => {
    setAuthToken();
    return axios({
      method: 'GET',
      url: `/api/cash-register-v2/report-file/${id}`,
      responseType: 'arraybuffer',
    });
  },

  removeCashRegisterOperationDescription: async (
    id: string
  ): Promise<AxiosResponse<any>> => {
    setAuthToken();
    return axios.delete(`/api/cash-register-v2/operation-description/${id}`);
  },
};
