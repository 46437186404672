import React from 'react';
import { CalendarOutlined, RedoOutlined } from '@ant-design/icons';
import { Button, Col, Row, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import CommentsList from 'components/Coments/CommentsList/CommentsList';
import AntDivider from 'components/Divider/Divider';
import { H4 } from 'components/Header';
import ProductionSchedule from 'components/ProductionSchedule/Display/ProductionScheduleDisplay';
import ScheduleList from 'components/Schedule/ScheduleList/ScheduleList';
import { TAppState } from 'services/store';
import { setProjectProductionEditView } from 'services/store/actions/view';
import { Positions } from 'services/store/enums/users.enum';
import { ProjectProductionEditView } from 'services/store/enums/view.enum';
import { IAlert } from 'services/store/interfaces/alert.interface';
import { IComment } from 'services/store/interfaces/comment.interface';
import { IPaintedElementsOrder } from 'services/store/types/projects/paintedElementsOrder.interface';
import { IProject } from 'services/store/types/projects/Projects';
import { ISchedule } from 'services/store/types/schedule/Schedule';
import { IUser } from 'services/store/types/users/Users';
import { isUserHasPermission } from 'services/utils/users';
import OrdersList from './ProjectPaintedElements/PaintedElementsOrdersList';
import AlertsList from '../../../components/AlertsList/AlertsList';

const { Option } = Select;

interface IProps {
  user: IUser | null;
  project: IProject;
  showModal: (modal: any) => void;
  getSchedule: () => Promise<any>;
  alerts: IAlert[];
  orders: IPaintedElementsOrder[];
  schedule: ISchedule[];
  scheduleLoading: boolean;
  getProjectAlerts: () => Promise<any>;
  handleRemoveComment: (comment: string) => void;
}

const ProjectDashboard: React.FC<IProps> = ({
  project,
  user,
  showModal,
  alerts,
  orders,
  schedule,
  getSchedule,
  scheduleLoading,
  getProjectAlerts,
  handleRemoveComment,
}) => {
  const dispatch = useDispatch();
  const view = useSelector(
    (state: TAppState) => state.view.projectProductionEditView
  );

  const changeView = (key: ProjectProductionEditView) => {
    dispatch(setProjectProductionEditView(key));
  };

  return (
    <Row gutter={18}>
      <Col md={7} span={24}>
        {!!alerts?.length &&
          isUserHasPermission(user, [
            Positions.DESIGNER,
            Positions.TECHNOLOGIST,
          ]) && (
            <>
              <Row justify="space-between">
                <H4>Powiadomienia</H4>
                <Button onClick={getProjectAlerts}>
                  <RedoOutlined />
                </Button>
              </Row>
              <AlertsList alerts={alerts} />
              <AntDivider marginTop={40} />
            </>
          )}

        {orders?.length ? (
          <>
            <Row justify="space-between">
              <H4>Elementy lakierowane</H4>
            </Row>
            <OrdersList orders={orders} smallTable />
            <AntDivider marginTop={40} />
          </>
        ) : null}

        {!!schedule?.length && (
          <>
            <Row justify="space-between">
              <H4>Terminarz</H4>{' '}
              <Button
                type="primary"
                onClick={() => showModal('newScheduleItem')}
              >
                <CalendarOutlined />
                Dodaj
              </Button>
            </Row>
            <ScheduleList
              schedule={schedule}
              isLoading={scheduleLoading}
              getSchedule={getSchedule}
            />
            <AntDivider marginTop={40} />
          </>
        )}

        <H4>Komentarze</H4>
        <CommentsList
          comments={project.comments}
          showAddCommentModal={() => showModal('addComment')}
          handleRemoveComment={handleRemoveComment}
        />
      </Col>
      <Col md={17} span={24}>
        <Row justify="space-between">
          <H4>Harmonogram produkcji</H4>
          <Select
            key={1}
            placeholder="Widok"
            value={view}
            onChange={(e) => changeView(e)}
            style={{ width: 100 }}
          >
            <Option value={ProjectProductionEditView.DAYS}>Dzień</Option>
            <Option value={ProjectProductionEditView.DAYS_PARTS}>
              Dzień / 4
            </Option>
          </Select>
        </Row>
        {project.isPlaned ? (
          <ProductionSchedule project={project} />
        ) : (
          'Brak harmonogramu'
        )}
      </Col>
    </Row>
  );
};

export default ProjectDashboard;
