import Axios from 'axios';
import { Dispatch } from 'redux';
import setAuthToken from 'services/utils/setAuthToken';
import { IAccessory } from '../types/accessories/Accessories';
import {
  ACCESSORIES_LOAD_SUCCESS,
  ISetAccessories,
} from '../types/accessories/actions';

export const setAccessories = (accessories: IAccessory[]): ISetAccessories => ({
  type: ACCESSORIES_LOAD_SUCCESS,
  accessories,
});

/**--------------------
 * THUNK
 ----------------------*/

export interface ICategoryFilters {
  category?: string;
}

export const getAllAccessories =
  (filters?: ICategoryFilters, onEnd?: () => void) =>
  async (dispatch: Dispatch) => {
    try {
      setAuthToken();
      const res = await Axios.get('/api/accessories/', { params: filters });

      dispatch(setAccessories(res.data));
      if (onEnd) onEnd();
    } catch (error) {
      dispatch(setAccessories([]));
    }
  };
