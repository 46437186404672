/* eslint-disable @typescript-eslint/naming-convention */
import { CategoryType } from 'services/store/enums/categories.enum';
import {
  ProjectEquipmentDestination,
  ProjectImageType,
  ProjectStatus,
} from 'services/store/enums/project.enum';
import { ScheduleType } from 'services/store/enums/schedules.enum';
import { UserStatisticsType } from 'services/store/enums/statistics.enum';
import { Positions } from 'services/store/enums/users.enum';
import { CashRegisterOperationType } from 'services/store/types/cashRegister/CashRegister';

export const domain = 'http://localhost:5000/';

export const _projectStatuses: ProjectStatus[] = [
  ProjectStatus.PENDING,
  ProjectStatus.PROJECT,
  ProjectStatus.TECHNOLOGIST,
  ProjectStatus.CARPENTER,
  ProjectStatus.STORAGE,
  ProjectStatus.MONTAGE,
  ProjectStatus.FINISHED,
  ProjectStatus.CANCELED,
];
export const _equipmentDestinations: ProjectEquipmentDestination[] = [
  ProjectEquipmentDestination.BLOW,
  ProjectEquipmentDestination.LOCAL,
];
export const _projectImagesTypes: ProjectImageType[] = [
  ProjectImageType.MEASURE,
  ProjectImageType.MONTAGE,
  ProjectImageType.REALIZATION,
  ProjectImageType.OTHER,
];
export const _userPositions: Positions[] = [
  Positions.DESIGNER,
  Positions.TECHNOLOGIST,
  Positions.CARPENTER,
  Positions.INSTALLER,
  Positions.STOREKEEPER,
];
export const _categoryTypes: CategoryType[] = [CategoryType.ACCESSORY];
export const _scheduleTypes: ScheduleType[] = [
  ScheduleType.MEASURE,
  ScheduleType.MONTAGE,
  ScheduleType.VISIT,
  ScheduleType.OTHER,
];
export const _cashRegisterOperationTypes: CashRegisterOperationType[] = [
  CashRegisterOperationType.IN,
  CashRegisterOperationType.OUT,
];
export const _UserStatisticsTypes: UserStatisticsType[] = [
  UserStatisticsType.NO_PROJECT,
  UserStatisticsType.WITH_PROJECT,
];
export const _weekDays = [
  'Niedziela',
  'Poniedziałek',
  'Wtorek',
  'Środa',
  'Czwartek',
  'Piątek',
  'Sobota',
];
