import React from 'react';
import { Empty } from 'antd';
import AccessoriesList from 'scenes/Accessories/components/AccessoriesList';
import { IAccessory } from 'services/store/types/accessories/Accessories';
import { IProjectAccessory } from 'services/store/types/projects/Projects';

interface IProps {
  accessories: IProjectAccessory[];
}

const ProjectAccessories: React.FC<IProps> = ({ accessories }) => {
  if (accessories)
    return (
      <div>
        <AccessoriesList
          accessories={accessories.map((acc) => ({
            ...(acc.accessory as IAccessory),
            qty: acc.qty,
          }))}
          isLoading={false}
          qty
        />
      </div>
    );
  return <Empty />;
};

export default ProjectAccessories;
