import React from 'react';
import { H4 } from 'components/Header';
import OrdersList from 'components/ordersList/OrdersList';
import { OrderStatus } from 'services/store/enums/orders.enum';
import { IOrder } from 'services/store/types/orders/Orders';
import { IUser } from 'services/store/types/users/Users';

interface IProps {
  user: IUser | null;
  orders?: IOrder[];
  loading: boolean;
}

const DashboardOrders: React.FC<IProps> = ({ orders, loading, user }) => {
  return (
    <div>
      <H4>W produkcji</H4>
      <OrdersList
        orders={orders?.filter((order) => order.status !== OrderStatus.PENDING)}
        isLoading={loading}
        maxHeight={400}
        user={user}
      />
      <H4>Oczekujące</H4>
      <OrdersList
        orders={orders?.filter((order) => order.status === OrderStatus.PENDING)}
        isLoading={loading}
        maxHeight={200}
        user={user}
      />
    </div>
  );
};

export default DashboardOrders;
