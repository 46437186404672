import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { Form, Input, InputNumber, message, Modal, Select } from 'antd';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { cashRegisterApi } from 'services/api/cashRegister.api';
import {
  CashRegisterOperationType,
  ICashRegister,
  ICashRegisterOperationDescription,
} from 'services/store/types/cashRegister/CashRegister';

const schema = yup.object({
  value: yup
    .number()
    .typeError('Wartość musi być liczbą')
    .required('Wartość jest wymagana')
    .min(1, 'Wartość musi być większa od 0'),
  description: yup.string().required('Opis jest wymagany'),
});

interface IFormValues {
  value: number;
  description: string;
  additionalInfo: string;
}

interface IProps {
  cashRegister: ICashRegister;
  operationDescriptions: ICashRegisterOperationDescription[];
  closeModal: () => void;
  refetchCashRegister: () => Promise<any>;
}

const DepositMoneyModal: React.FC<IProps> = ({
  closeModal,
  refetchCashRegister,
  cashRegister,
  operationDescriptions,
}) => {
  const { mutate: createCashRegisterOperation, isLoading } = useMutation({
    mutationKey: ['create-cash-register-operation'],
    mutationFn: (values: IFormValues) =>
      cashRegisterApi.createCashRegisterOperation({
        ...values,
        operationType: CashRegisterOperationType.IN,
      }),
    onSuccess: async (res) => {
      await refetchCashRegister();
      message.success('Kasa zasilona');
      closeModal();
    },
    onError: () => {
      message.error('Błąd!');
    },
  });

  const formik = useFormik<IFormValues>({
    initialValues: { value: 0, description: '', additionalInfo: '' },
    onSubmit: (values) => createCashRegisterOperation(values),
    validationSchema: schema,
  });

  return (
    <Modal
      title="Zasilenie kasy"
      destroyOnClose
      visible
      onCancel={closeModal}
      maskClosable={false}
      onOk={() => formik.handleSubmit()}
      okButtonProps={{ loading: isLoading, disabled: !formik.isValid }}
      cancelButtonProps={{ disabled: isLoading }}
    >
      <Form labelCol={{ xs: { span: 24 }, sm: { span: 4 } }}>
        <Form.Item
          validateStatus={formik.errors.value && 'error'}
          help={formik.errors.value}
          label="Wartość"
          required
        >
          <InputNumber
            {...formik.getFieldProps('value')}
            onChange={(value) => formik.setFieldValue('value', value)}
            min={0}
          />
        </Form.Item>

        <Form.Item
          validateStatus={formik.errors.description && 'error'}
          help={formik.errors.description}
          label="Opis"
          required
        >
          <Select
            size="middle"
            {...formik.getFieldProps('description')}
            onChange={(value) => formik.setFieldValue('description', value)}
            options={operationDescriptions.map((desc) => ({
              label: desc.value,
              value: desc.value,
            }))}
          />
        </Form.Item>

        <Form.Item
          validateStatus={formik.errors.additionalInfo && 'error'}
          help={formik.errors.additionalInfo}
          label="Info"
        >
          <Input
            {...formik.getFieldProps('additionalInfo')}
            placeholder="Dodatkowe informacje"
          />
        </Form.Item>
        <div>
          <div>Aktualna wartość kasy: {cashRegister.value} zł</div>
          <div>
            Wartość kasy po zasileniu:{' '}
            {cashRegister.value + formik.values.value} zł
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default DepositMoneyModal;
