import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import {
  Button,
  Col,
  Input,
  List,
  message,
  Modal,
  Row,
  Typography,
} from 'antd';
import Spinner from 'components/Spinner';
import {
  cashRegisterApi,
  INewCashRegisterOperationDescription,
} from 'services/api/cashRegister.api';
import {
  CashRegisterOperationType,
  ICashRegisterOperationDescription,
} from 'services/store/types/cashRegister/CashRegister';
const { Title } = Typography;

interface IProps {
  closeModal: () => void;
  descriptions: ICashRegisterOperationDescription[];
  refetchDescriptions: () => Promise<any>;
}

const ChangeCashRegisterOperationDescriptionsModal: React.FC<IProps> = ({
  closeModal,
  descriptions,
  refetchDescriptions,
}) => {
  const [depositDescription, setDepositDescription] = useState<string>('');
  const [payOutDescription, setPayOutDescription] = useState<string>('');

  const { mutate: removeOperationDescription, isLoading: isRemoving } =
    useMutation({
      mutationKey: ['remove-cash-register-operation-description'],
      mutationFn: (_id: string) =>
        cashRegisterApi.removeCashRegisterOperationDescription(_id),
      onSuccess: async () => {
        await refetchDescriptions();
        message.warning('Opis operacji usunięty');
      },
      onError: () => {
        message.error('Błąd!');
      },
    });

  const { mutate: addOperationDescription, isLoading: isUpdating } =
    useMutation({
      mutationKey: ['add-cash-register-operation-description'],
      mutationFn: (
        newOperationDescription: INewCashRegisterOperationDescription
      ) =>
        cashRegisterApi.createCashRegisterOperationDescription(
          newOperationDescription
        ),
      onSuccess: async () => {
        await refetchDescriptions();
        setDepositDescription('');
        setPayOutDescription('');
        message.success('Opis operacji w kasie utworzony');
      },
      onError: () => {
        message.error('Błąd!');
      },
    });

  const createDepositDescription = () => {
    return addOperationDescription({
      forType: CashRegisterOperationType.IN,
      value: depositDescription,
    });
  };
  const createPayOutDescription = () => {
    return addOperationDescription({
      forType: CashRegisterOperationType.OUT,
      value: payOutDescription,
    });
  };

  return (
    <Modal
      title="Opisy operacji"
      destroyOnClose
      open
      onCancel={closeModal}
      maskClosable={false}
      width={1000}
      footer={null}
    >
      {(isUpdating || isRemoving) && <Spinner />}
      <Row justify="space-between">
        <Col sm={11}>
          <List
            style={{ height: 300, overflowY: 'scroll' }}
            header={<Title level={5}>Wpłaty</Title>}
            bordered
            size="small"
            dataSource={descriptions?.filter(
              (desc) => desc.forType === CashRegisterOperationType.IN
            )}
            renderItem={(desc) => (
              <List.Item
                key={desc._id}
                actions={[
                  <Button
                    onClick={() => removeOperationDescription(desc._id)}
                    danger
                    key={0}
                    size="small"
                  >
                    Usuń
                  </Button>,
                ]}
              >
                <Typography.Text>{desc.value}</Typography.Text>
              </List.Item>
            )}
          />

          <Row justify="space-between">
            <Input
              value={depositDescription}
              onChange={(e) => setDepositDescription(e.target.value)}
              placeholder="Opis wpłaty"
              style={{ width: '80%' }}
            />
            <Button
              type="primary"
              onClick={createDepositDescription}
              disabled={depositDescription.length < 3}
            >
              Dodaj
            </Button>
          </Row>
        </Col>
        <Col sm={11}>
          <List
            style={{ height: 300, overflowY: 'scroll' }}
            header={<Title level={5}>Wypłaty</Title>}
            bordered
            size="small"
            dataSource={descriptions?.filter(
              (desc) => desc.forType === CashRegisterOperationType.OUT
            )}
            renderItem={(desc) => (
              <List.Item
                key={desc._id}
                actions={[
                  <Button
                    onClick={() => removeOperationDescription(desc._id)}
                    danger
                    key={0}
                    size="small"
                  >
                    Usuń
                  </Button>,
                ]}
              >
                <Typography.Text>{desc.value}</Typography.Text>
              </List.Item>
            )}
          />
          <Row justify="space-between">
            <Input
              value={payOutDescription}
              onChange={(e) => setPayOutDescription(e.target.value)}
              placeholder="Opis wypłaty"
              style={{ width: '80%' }}
            />
            <Button
              type="primary"
              onClick={createPayOutDescription}
              disabled={payOutDescription.length < 3}
            >
              Dodaj
            </Button>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default ChangeCashRegisterOperationDescriptionsModal;
