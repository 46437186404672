import { IUser } from '../users/Users';

export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_ERROR = 'AUTH_LOGIN_ERROR';
export const AUTH_USER_LOAD_SUCCESS = 'AUTH_USER_LOAD_SUCCESS';
export const AUTH_LOGOUT_USER = 'AUTH_LOGOUT_USER';

export interface IUserLoginSuccess {
  type: typeof AUTH_LOGIN_SUCCESS;
  token: string;
}
export interface IUserLoginError {
  type: typeof AUTH_LOGIN_ERROR;
}

export interface IUserLoadSuccess {
  type: typeof AUTH_USER_LOAD_SUCCESS;
  user: IUser;
  permission: string;
}

export interface IUserLogout {
  type: typeof AUTH_LOGOUT_USER;
}

export type TAuthActions =
  | IUserLoadSuccess
  | IUserLoginSuccess
  | IUserLoginError
  | IUserLogout;
