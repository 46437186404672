import React from 'react';
import {
  EllipsisOutlined,
  HomeOutlined,
  UserDeleteOutlined,
  UserSwitchOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Menu, Popconfirm } from 'antd';
import { isMobile } from 'react-device-detect';
import { ICustomer } from 'services/store/types/customers/Customers';

interface IActions {
  showModal: (key: any) => void;
  addCustomerToBlackList: () => void;
  confirmDeleteCustomer: () => void;
}

interface IProps {
  customer: ICustomer;
  actions: IActions;
}

const CustomerActions: React.FC<IProps> = ({ customer, actions }) => {
  if (!isMobile)
    return (
      <>
        <Button key={1} onClick={() => actions.showModal('changeData')}>
          <UserSwitchOutlined /> Edytuj dane osobowe
        </Button>
        <Button key={2} onClick={() => actions.showModal('changeAddress')}>
          <HomeOutlined /> Edytuj adres zamieszkania
        </Button>
        <Popconfirm
          key={3}
          title={`Chcesz ${
            customer.isOnBlackList ? 'usunąć klienta z' : 'dodać klienta do'
          } czarnej listy?`}
          onConfirm={actions.addCustomerToBlackList}
          okType={customer.isOnBlackList ? 'primary' : 'danger'}
          okText="Tak"
          cancelText="Nie"
        >
          <Button danger>
            <WarningOutlined />
            {`${customer.isOnBlackList ? 'Usuń z' : 'Dodaj do'} czarnej listy`}
          </Button>
        </Popconfirm>
        <Button key={4} danger onClick={actions.confirmDeleteCustomer}>
          <UserDeleteOutlined /> Usuń
        </Button>
      </>
    );
  return (
    <Dropdown
      key="more"
      overlay={dropDownMenu(customer, actions)}
      trigger={['click']}
    >
      <Button type="primary">
        <EllipsisOutlined /> Menu
      </Button>
    </Dropdown>
  );
};

export default CustomerActions;

const dropDownMenu = (customer: ICustomer, actions: IActions) => (
  <Menu>
    <Menu.Item key={1} onClick={() => actions.showModal('changeData')}>
      <UserSwitchOutlined /> Edytuj dane osobowe
    </Menu.Item>
    <Menu.Item key={2} onClick={() => actions.showModal('changeAddress')}>
      <HomeOutlined /> Edytuj adres zamieszkania
    </Menu.Item>
    <Popconfirm
      key={3}
      title={`Chcesz ${
        customer.isOnBlackList ? 'usunąć klienta z' : 'dodać klienta do'
      } czarnej listy?`}
      onConfirm={actions.addCustomerToBlackList}
      okType={customer.isOnBlackList ? 'primary' : 'danger'}
      okText="Tak"
      cancelText="Nie"
    >
      <Menu.Item danger>
        <WarningOutlined />
        {`${customer.isOnBlackList ? 'Usuń z' : 'Dodaj do'} czarnej listy`}
      </Menu.Item>
    </Popconfirm>
    <Menu.Item key={4} danger onClick={actions.confirmDeleteCustomer}>
      <UserDeleteOutlined /> Usuń
    </Menu.Item>
  </Menu>
);
