import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import { Field, Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import {
  AntInput,
  AntInputNumber,
} from 'components/Formik-AntdFields/CreateAntdField';
import { IUser } from 'services/store/types/users/Users';
import { userDataChangeValidation } from './utils/validation';

interface PropsT {
  closeModal: () => void;
  handleSubmit: (userData: IUser, actions: any) => void;
  user: IUser;
}

const ChangeUserDataModal: React.FC<PropsT> = ({
  handleSubmit,
  closeModal,
  user,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  if (user)
    return (
      <Modal
        title="Edycja danych"
        destroyOnClose
        visible
        onCancel={closeModal}
        maskClosable={false}
        footer={[
          <Button key="back" onClick={closeModal}>
            Anuluj
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            htmlType="submit"
            form="ChangeDataForm"
          >
            Zatwierdź
          </Button>,
        ]}
      >
        <div>
          <Formik
            validationSchema={userDataChangeValidation}
            onSubmit={handleSubmit}
            initialValues={{ ...user }}
          >
            {(props) => {
              return (
                <Form
                  noValidate
                  id="ChangeDataForm"
                  onSubmit={props.handleSubmit}
                >
                  <Field
                    component={AntInput}
                    name="firstName"
                    type="text"
                    placeholder="Imię"
                    validate={props.errors.firstName}
                    submitCount={props.submitCount}
                    hasFeedback
                  />
                  <Field
                    component={AntInput}
                    name="lastName"
                    type="text"
                    placeholder="Nazwisko"
                    validate={props.errors.lastName}
                    submitCount={props.submitCount}
                    hasFeedback
                  />
                  <Field
                    component={AntInput}
                    name="email"
                    type="email"
                    placeholder="E-mail"
                    validate={props.errors.email}
                    submitCount={props.submitCount}
                    hasFeedback
                  />
                  <Field
                    component={AntInput}
                    name="phone"
                    type="text"
                    placeholder="Nr telefonu"
                    validate={props.errors.phone}
                    submitCount={props.submitCount}
                    hasFeedback
                  />
                  Stawka/h
                  <Field
                    component={AntInputNumber}
                    name="earnings"
                    validate={props.errors.earnings}
                    submitCount={props.submitCount}
                    hasFeedback
                  />
                </Form>
              );
            }}
          </Formik>
        </div>
      </Modal>
    );
  return null;
};

export default ChangeUserDataModal;
