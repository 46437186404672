import React, { useEffect, useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, message, Popconfirm, Table } from 'antd';
import dayjs from 'dayjs';
import {
  getFileFromPath,
  removeFileById,
} from 'services/requests/files.request';
import { HistoryColor } from 'services/store/enums/historyColor.enum';
import { IFile } from 'services/store/types/files/Files';
import { IOrder, IOrderCost } from 'services/store/types/orders/Orders';

const columns: any[] = [
  {
    dataIndex: 'lp',
    title: 'Lp.',
    align: 'center' as const,
    render: (e: any, r: any, i: number) => i + 1,
  },
  {
    title: 'Nazwa',
    dataIndex: 'name',
    align: 'center' as const,
    defaultSortOrder: 'ascend' as const,
    sorter: (a: any, b: any) => a.name.localeCompare(b.name),
  },
  {
    title: 'Opis',
    dataIndex: 'description',
    align: 'center' as const,
  },
  {
    title: 'Data',
    dataIndex: 'date',
    align: 'center' as const,
    render: (date: Date) => dayjs(date).format('DD.MM.YYYY'),
  },
  {
    title: 'Wartość brutto',
    dataIndex: 'valueGross',
    align: 'center' as const,
  },
  {
    title: 'Wartość netto',
    dataIndex: 'valueNet',
    align: 'center' as const,
  },
  {
    title: 'Potwierdzenie',
    dataIndex: 'getFile',
    align: 'center' as const,
  },
  {
    title: 'Usuń',
    dataIndex: 'remove',
    align: 'center' as const,
  },
];

interface IProps {
  costs?: IOrderCost[];
  handleUpdateOrder: (
    updateData: Partial<IOrder>,
    historyUpdate: string[],
    historyColor: HistoryColor
  ) => Promise<any>;
  maxHeight?: number;
}

const CostsList: React.FC<IProps> = ({
  costs,
  handleUpdateOrder,
  maxHeight,
}) => {
  const [list, setList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (costs) {
      const newCosts = costs.map((cost: IOrderCost, i: number) => ({
        ...cost,
        key: cost._id,
        getFile: (
          <Button
            size="small"
            onClick={() => getCostFile((cost.file as IFile).dir)}
          >
            Pobierz
          </Button>
        ),
        remove: (
          <Popconfirm
            key={1}
            title="Na pewno chcesz usunąć koszt?"
            onConfirm={() => handleRemove(cost)}
            okText="Tak"
            cancelText="Nie"
          >
            <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} />
          </Popconfirm>
        ),
      }));
      setList(newCosts);
    }
  }, [costs]);

  const handleRemove = async (cost: IOrderCost) => {
    setIsLoading(true);

    const removeFileResponse = await removeFileById((cost.file as IFile)._id);
    if (removeFileResponse) {
      message.success('Plik usunięty');

      const newCosts = costs?.filter((cos) => cos._id !== cost._id);
      const historyUpdate = [`Usunięto koszt:`, cost.name];

      await handleUpdateOrder(
        { costs: newCosts },
        historyUpdate,
        HistoryColor.DANGER
      );
    }
    setIsLoading(false);
  };

  const getCostFile = async (path: string) => {
    setIsLoading(true);
    await getFileFromPath(path);
    setIsLoading(false);
  };

  return (
    <Table
      columns={columns}
      size="small"
      pagination={false}
      dataSource={list}
      loading={isLoading}
      scroll={{ x: 1000, y: maxHeight }}
    />
  );
};

export default CostsList;
