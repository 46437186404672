import React, { useEffect, useState } from 'react';
import { Modal, Table, TableColumnsType } from 'antd';
import dayjs from 'dayjs';
import {
  IWorkTime,
  IWorkTimeChange,
  IWorkTimeUpdateHistory,
} from 'services/store/types/statistics/Statistics';
import { IUser } from 'services/store/types/users/Users';
import { getUserName } from 'services/utils/string';

const columns: TableColumnsType<any> = [
  {
    dataIndex: 'lp',
    title: 'Lp.',
    align: 'center' as const,
    render: (e: any, r: any, i: number) => i + 1,
    width: 50,
  },
  {
    title: 'Wprowadzający zmianę',
    dataIndex: 'user',
    align: 'center' as const,
    render: (user: IUser) => getUserName(user),
  },
  {
    title: 'Data',
    dataIndex: 'date',
    align: 'center' as const,
    render: (date: Date) => dayjs(date).format('DD.MM.YYYY, HH:mm'),
  },
  {
    title: 'Zmiany',
    dataIndex: 'changes',
    align: 'center' as const,
    render: (changes: IWorkTimeChange[]) =>
      changes.map((change) => (
        <div key={change.key}>
          pole: <strong>{change.key}</strong> z wartości:
          <strong> {change.from}</strong>, na wartość:
          <strong> {change.to}</strong>
        </div>
      )),
  },
];

interface IProps {
  workTime: IWorkTime;
  closeModal: () => void;
}

const WorkTimeItemUpdateHistoryModal: React.FC<IProps> = ({
  workTime,
  closeModal,
}) => {
  const [list, setList] = useState<any[]>([]);

  useEffect(() => {
    if (workTime.updateHistory) {
      const newList = workTime.updateHistory.map(
        (historyItem: IWorkTimeUpdateHistory) => ({
          ...historyItem,
          key: historyItem._id,
        })
      );
      setList(newList);
    }
  }, [workTime]);

  return (
    <Modal
      title="Historia zmian"
      destroyOnClose
      open
      onCancel={closeModal}
      maskClosable={false}
      footer={null}
      width={1000}
    >
      <Table
        columns={columns}
        dataSource={list}
        size="small"
        pagination={false}
      />
    </Modal>
  );
};

export default WorkTimeItemUpdateHistoryModal;
