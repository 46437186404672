import React, { useState } from 'react';
import { Modal, Input } from 'antd';
import { HistoryColor } from 'services/store/enums/historyColor.enum';
import { IOrder } from 'services/store/types/orders/Orders';

interface IProps {
  order: IOrder;
  closeModal: () => void;
  handleUpdateOrder: (
    updateData: Partial<IOrder>,
    historyUpdate: string[],
    historyColor: HistoryColor
  ) => Promise<any>;
}

const ChangeOrderNumberModal: React.FC<IProps> = ({
  order,
  closeModal,
  handleUpdateOrder,
}) => {
  const [number, setNumber] = useState<string>(order.number);

  const onOk = async () => {
    const historyUpdate = [
      `Zaktualizowano numer zamówienia:`,
      `${order.number} na ${number}`,
    ];
    await handleUpdateOrder({ number }, historyUpdate, HistoryColor.SUCCESS);
    closeModal();
  };

  return (
    <Modal
      title="Zmiana numeru zamówienia"
      destroyOnClose
      visible
      onCancel={closeModal}
      maskClosable={false}
      onOk={onOk}
    >
      <Input
        value={number}
        onChange={(e) => setNumber(e.target.value)}
        placeholder="Numer zamówienia"
        style={{ width: '100%' }}
      />
    </Modal>
  );
};

export default ChangeOrderNumberModal;
