import React, { useEffect, useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Table } from 'antd';
import dayjs from 'dayjs';
import PaintedElementsOrderStatusTag from 'components/Tags/PaintedElementsOrderStatusTag/PaintedElementsOrderStatusTag';
import { PaintedElementsOrderStatus } from 'services/store/enums/paintedElementsOrder.enum';
import {
  IPaintedElementsOrder,
  IPaintedElementsOrderUser,
} from 'services/store/types/projects/paintedElementsOrder.interface';
import { capitalizeFirstLetter, cutString } from 'services/utils/string';

// TODO Dodać autoryzację z użyciem klucza do pobierania zamówień
const columns: any[] = [
  {
    dataIndex: 'lp',
    title: 'Lp.',
    align: 'center' as const,
    render: (e: any, r: any, i: number) => i + 1,
  },
  {
    title: 'Klient',
    dataIndex: 'user',
    align: 'center' as const,
    render: (user: IPaintedElementsOrderUser) =>
      `${user.firstname} ${user.surname}`,
  },
  {
    title: 'Nr',
    dataIndex: 'number',
    align: 'center' as const,
  },
  {
    title: 'Nazwa',
    dataIndex: 'name',
    align: 'center' as const,
    render: (name: string) => cutString(name, 30),
  },
  {
    title: 'Kolor',
    dataIndex: 'color',
    align: 'center' as const,
  },
  {
    title: 'Matowość',
    dataIndex: 'paintType',
    align: 'center' as const,
    render: (paintType: string) => capitalizeFirstLetter(paintType),
  },
  {
    title: 'Elementy',
    dataIndex: 'elements',
    align: 'center' as const,
  },
  {
    title: 'PL',
    dataIndex: 'surfaceRight',
    align: 'center' as const,
    render: (surfaceRight: number) =>
      surfaceRight ? surfaceRight.toFixed(2) : '',
  },
  {
    title: 'PP',
    dataIndex: 'surfaceLeft',
    align: 'center' as const,
    render: (surfaceLeft: number) =>
      surfaceLeft ? surfaceLeft.toFixed(2) : '',
  },
  {
    title: 'Typ',
    dataIndex: 'type',
    align: 'center' as const,
    render: (e: any, order: any) =>
      order.veener ? 'Fornir' : order.millings.length ? 'CNC' : '',
  },
  {
    title: 'Data',
    dataIndex: 'date',
    align: 'center' as const,
    render: (date: Date) => dayjs(date).format('DD.MM.YYYYr.'),
  },
  {
    title: 'Data realizacji',
    dataIndex: 'finishDate',
    align: 'center' as const,
    render: (finishDate: Date) => dayjs(finishDate).format('DD.MM.YYYYr.'),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    align: 'center' as const,
    render: (status: PaintedElementsOrderStatus[]) =>
      status.map((s) => <PaintedElementsOrderStatusTag key={s} status={s} />),
  },
  {
    title: 'Link',
    dataIndex: 'linkTo',
    align: 'center' as const,
  },
  {
    title: 'Usuń',
    dataIndex: 'remove',
    align: 'center' as const,
  },
];

const smallTableColumns: any[] = [
  {
    dataIndex: 'lp',
    title: 'Lp.',
    align: 'center' as const,
    render: (e: any, r: any, i: number) => i + 1,
  },
  {
    title: 'Zamówienie',
    dataIndex: 'user',
    align: 'center' as const,
    render: (user: IPaintedElementsOrderUser) =>
      `${user.firstname} ${user.surname}`,
  },
  {
    title: 'Nazwa',
    dataIndex: 'name',
    align: 'center' as const,
    render: (name: string) => cutString(name, 30),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    align: 'center' as const,
    render: (status: PaintedElementsOrderStatus[]) =>
      status.map((s) => <PaintedElementsOrderStatusTag key={s} status={s} />),
  },
];

interface IProps {
  orders: IPaintedElementsOrder[];
  smallTable?: boolean;
  handleRemove?: (id: string) => void;
  maxHeight?: number;
}

const OrdersList: React.FC<IProps> = ({
  orders,
  smallTable = false,
  handleRemove,
  maxHeight,
}) => {
  const [list, setList] = useState<any[]>([]);

  useEffect(() => {
    if (orders) {
      const newList = orders.map((order: any, i: number) => ({
        ...order,
        key: order._id,
        remove: (
          <Popconfirm
            title="Chcesz usunąć to zamówienie z projektu?"
            onConfirm={() => (handleRemove ? handleRemove(order._id) : null)}
            okText="Tak"
            cancelText="Nie"
          >
            <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} />
          </Popconfirm>
        ),
        linkTo: (
          <Button
            type="primary"
            size="small"
            onClick={() => handleRedirect(order._id)}
          >
            Przejdź do
          </Button>
        ),
      }));
      setList(newList);
    }
  }, [orders]);

  const handleRedirect = (id: string) =>
    window.open(`https://hurt.zamowfronty.pl/order/${id}`, '_blank');

  return (
    <Table
      columns={smallTable ? smallTableColumns : columns}
      size="small"
      pagination={false}
      dataSource={list}
      scroll={{ x: 1000, y: maxHeight }}
    />
  );
};

export default OrdersList;
