import { useState } from 'react';
import { useMutation, useQueries } from '@tanstack/react-query';
import { Button, Input, message, PageHeader, Row, Select } from 'antd';
import { AxiosResponse } from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import { RangeValue } from 'rc-picker/lib/interface';
import AntDatePicker from 'components/DatePicker/DatePicker';
import { H1 } from 'components/Header';
import { useControledDebounce } from 'hook/useControledDebounce';
import { useModal } from 'hook/useModals';
import usePagination from 'hook/usePagination';
import { usersApi } from 'services/api/users.api';
import { workTimesApi } from 'services/api/workTimes.api';
import {
  UserStatisticsType,
  UserStatisticsTypeTranslation,
} from 'services/store/enums/statistics.enum';
import { ISearchResult } from 'services/store/interfaces/search-result.interface';
import { IWorkTime } from 'services/store/types/statistics/Statistics';
import { IUser } from 'services/store/types/users/Users';
import { _UserStatisticsTypes } from 'services/utils/const';
import { sortingFuncion } from 'services/utils/sort.utils';
import { getUserName } from 'services/utils/string';
import PageTemplate from 'templates/PageTemplate';
import CreateWorkTimeModal from './components/CreateWorkTime.modal';
import UpdateWorkTimeModal from './components/UpdateWorkTime.modal';
import UserStatisticsList from './components/UserStatisticsList';
import WorkTimeItemUpdateHistoryModal from './components/WorkTimeItemUpdateHistory.modal';

const defaultDateFrom = dayjs().subtract(1, 'month').startOf('month');
// const defaultDateFrom = dayjs().startOf('month');
const defaultDateTo = dayjs();

const UsersStatisticsPage = () => {
  const [workTimeToUpdate, setWorkTimeToUpdate] = useState<
    IWorkTime | undefined
  >(undefined);
  const [workTimeHistory, setWorkTimeHistory] = useState<IWorkTime | undefined>(
    undefined
  );

  // filters
  const [dateFrom, setDateFrom] = useState<Dayjs>(defaultDateFrom);
  const [dateTo, setDateTo] = useState<Dayjs>(defaultDateTo);
  const [pickedEmployee, setPickedEmployee] = useState<string | undefined>(
    undefined
  );
  const [type, setType] = useState<UserStatisticsType | undefined>(undefined);
  const {
    value,
    debouncedValue: action,
    handleDebouncedValue,
  } = useControledDebounce();

  const { modal, closeModal, showModal } = useModal({ createWorkTime: false });

  const {
    pageNumber,
    itemsPerPage,
    handlePagination,
    totalItems,
    setTotalItems,
  } = usePagination({
    itemsPerPage: 100,
  });

  const [users, workTimes] = useQueries({
    queries: [
      {
        queryKey: ['get-users'],
        queryFn: () => usersApi.getAllUsers(),
        onError(error: any) {
          console.log(error, error.response);
          message.error('Błąd');
        },
      },
      {
        queryKey: [
          'get-user-working-times',
          dateTo,
          type,
          action,
          pickedEmployee,
          pageNumber,
          itemsPerPage,
        ],
        queryFn: () => {
          if (!!pickedEmployee)
            return workTimesApi.getUserWorkTimes(pickedEmployee, {
              dateFrom: dateFrom.startOf('day').toISOString(),
              dateTo: dateTo.endOf('day').toISOString(),
              type,
              action,
              pageNumber,
              itemsPerPage,
            });
        },
        onSuccess(res?: AxiosResponse<ISearchResult<IWorkTime>>) {
          if (res) setTotalItems(res.data.displayParams?.totalItems);
        },

        onError(error: any) {
          console.log(error, error.response);
          message.error('Błąd');
        },
        enabled: !!pickedEmployee && !!dateFrom && !!dateTo,
      },
    ],
  });

  const { mutateAsync: getStatisticsFile, isLoading: isFileDownloading } =
    useMutation({
      mutationKey: ['download-stats-file'],
      mutationFn: () => {
        return workTimesApi.getStatisticsFile({
          dateFrom: dateFrom.startOf('day').toISOString(),
          dateTo: dateTo.endOf('day').toISOString(),
          user: pickedEmployee,
          type,
          action,
        });
      },
      onSuccess: async () => {},
      onError: () => {
        message.error('Błąd!');
      },
    });

  const getFileWithStats = async () => {
    return getStatisticsFile();
  };

  const handleDates = (dates: RangeValue<Dayjs>) => {
    if (dates && dates[0] && !dateFrom.isSame(dates[0], 'hour'))
      setDateFrom(dates[0]);
    if (dates && dates[1] && !dateTo.isSame(dates[1], 'hour'))
      setDateTo(dates[1]);
  };

  const clearFilters = () => {
    setDateFrom(defaultDateFrom);
    setDateTo(defaultDateTo);
    setType(UserStatisticsType.NO_PROJECT);
    setPickedEmployee(undefined);
    handleDebouncedValue('');
  };

  const closeModals = () => {
    setWorkTimeHistory(undefined);
    setWorkTimeToUpdate(undefined);
  };

  return (
    <PageTemplate>
      <PageHeader
        title={<H1>Statystyki pracowników</H1>}
        extra={[
          <Button
            key={1}
            onClick={getFileWithStats}
            disabled={!workTimes.data?.data.content}
            loading={isFileDownloading}
          >
            Pobierz
          </Button>,
          <Button key={0} onClick={() => showModal('createWorkTime')}>
            Dodaj rekord
          </Button>,
        ]}
      />
      <Row justify="space-around" style={{ marginBottom: 20 }}>
        <div title="Data zakończenia procesu">
          <AntDatePicker.RangePicker
            size="middle"
            value={[dateFrom, dateTo]}
            onChange={handleDates}
            allowClear={false}
            disabled={!users.isSuccess}
            format="DD.MM.YYYY"
          />
        </div>
        <div>
          <Select
            size="middle"
            value={pickedEmployee}
            onChange={setPickedEmployee}
            style={{ width: 300 }}
            allowClear={true}
            placeholder="Pracownik"
            disabled={!users.isSuccess}
            loading={users.isLoading}
          >
            {users.data?.data
              .sort(sortingFuncion.users.byLastName)
              .map((employee: IUser) => (
                <Select.Option key={employee._id} value={employee._id}>
                  {getUserName(employee, 'l-f')}
                </Select.Option>
              ))}
          </Select>
        </div>
        <div>
          <Select
            size="middle"
            value={type}
            onChange={setType}
            style={{ width: 300 }}
            placeholder="Typ"
            disabled={!users.isSuccess}
            loading={users.isLoading}
            allowClear
          >
            {_UserStatisticsTypes.map((statisticsType) => (
              <Select.Option key={statisticsType} value={statisticsType}>
                {UserStatisticsTypeTranslation[statisticsType]}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div>
          <Input
            size="middle"
            style={{ width: 300 }}
            placeholder="Akcja"
            value={value}
            onChange={(e) => handleDebouncedValue(e.target.value)}
            allowClear={true}
            disabled={!users.isSuccess}
          />
        </div>
        <div>
          <Button danger onClick={clearFilters} disabled={!users.isSuccess}>
            Wyczyść
          </Button>
        </div>
      </Row>

      <UserStatisticsList
        workTimes={workTimes.data?.data.content}
        loading={workTimes.isLoading && workTimes.isFetching}
        setWorkTimeToUpdate={setWorkTimeToUpdate}
        showWorkTimeUpdateHistory={setWorkTimeHistory}
        pagination={{
          current: pageNumber,
          total: totalItems,
          pageSize: itemsPerPage,
          showSizeChanger: true,
          onChange: handlePagination,
          position: ['topRight', 'bottomRight'],
        }}
      />

      {/* NOTE Modals */}
      {workTimeToUpdate && (
        <UpdateWorkTimeModal
          closeModal={closeModals}
          workTime={workTimeToUpdate}
          refetchStatistics={workTimes.refetch}
        />
      )}
      {workTimeHistory && (
        <WorkTimeItemUpdateHistoryModal
          closeModal={closeModals}
          workTime={workTimeHistory}
        />
      )}
      {modal.createWorkTime && users.data?.data && (
        <CreateWorkTimeModal
          users={users.data.data}
          pickedUser={pickedEmployee}
          closeModal={closeModal}
          refetchStatistics={workTimes.refetch}
        />
      )}
    </PageTemplate>
  );
};

export default UsersStatisticsPage;
