import { useEffect, useState } from 'react';
import { toNumber } from 'lodash';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router';
import { ISearchParams } from 'services/store/interfaces/search-params.interface';

const usePagination = (def?: ISearchParams) => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const query: ISearchParams = queryString.parse(search.replace('?', ''));
  const [totalItems, setTotalItems] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (!query.pageNumber || !query.itemsPerPage) {
      const newQuery = { ...query };
      if (def?.unpaged) {
        newQuery.unpaged = true;
      } else {
        newQuery.pageNumber = def?.pageNumber || 1;
        newQuery.itemsPerPage = def?.itemsPerPage || 10;
      }
      const newSearch = queryString.stringify(newQuery);
      history.replace({ pathname, search: newSearch });
    }
  }, [search]);

  const handlePagination = (pageNumber: number, itemsPerPage: number) => {
    let searchQuery = { ...query, pageNumber, itemsPerPage };
    if (itemsPerPage !== toNumber(query.itemsPerPage))
      searchQuery.pageNumber = 1;
    delete searchQuery.unpaged;
    const newSearch = queryString.stringify(searchQuery);
    history.push({ pathname, search: newSearch });
  };

  const setUnpaged = (unpaged: boolean) => {
    let searchQuery = { ...query };
    if (unpaged) {
      delete searchQuery.pageNumber;
      delete searchQuery.itemsPerPage;
      searchQuery.unpaged = unpaged;
    } else {
      delete searchQuery.unpaged;
      searchQuery.pageNumber = def?.pageNumber || 1;
      searchQuery.itemsPerPage = def?.itemsPerPage || 10;
    }
    const newSearch = queryString.stringify(searchQuery);
    history.push({ pathname, search: newSearch });
  };
  const resetPagination = () => {
    let searchQuery = { ...query, pageNumber: 1, itemsPerPage: 10 };
    delete searchQuery.unpaged;
    const newSearch = queryString.stringify(searchQuery);
    history.push({ pathname, search: newSearch });
  };

  return {
    pageNumber: query.pageNumber ? +query.pageNumber : 1,
    itemsPerPage: query.itemsPerPage ? +query.itemsPerPage : 10,
    unpaged: query.unpaged,
    handlePagination,
    setUnpaged,
    totalItems,
    setTotalItems,
    resetPagination,
  };
};

export default usePagination;
