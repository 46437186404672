import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Empty, message, Row, Statistic } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import { kebabCase } from 'lodash';
import { useDispatch } from 'react-redux';
import AntDivider from 'components/Divider/Divider';
import { H5 } from 'components/Header';
import WorkTimeSummaryModal from 'components/Modals/WorkTimeSummaryModal/WorkTimeSummary.modal';
import { useModal } from 'hook/useModals';
import { setLoading } from 'services/store/actions/view';
import { IOrder } from 'services/store/types/orders/Orders';
import { IOrderStatistics } from 'services/store/types/statistics/Statistics';
import setAuthToken from 'services/utils/setAuthToken';
import OrderUsersWorkTimesList from './OrderUsersWorkTimesList';

interface IProps {
  order: IOrder;
}

const OrderStatistics: React.FC<IProps> = ({ order }) => {
  const dispatch = useDispatch();
  const [orderStatistics, setOrderStatistics] =
    useState<IOrderStatistics | null>(null);
  const { closeModal, showModal, modal } = useModal({
    getStatisticsSummaryFile: false,
  });

  // TODO MOVE TO REQUESTS - wszystkie zapytania wypieprzyć do katalogu requests razem z setAuthToken, loading i błędy już ogarniać w komponencie
  // TODO I wgl ogarnąć react query?
  const getOrderStatistics = useCallback(async () => {
    dispatch(setLoading(true));
    try {
      setAuthToken();
      const res = await axios.get(`/api/statistics/orders/${order._id}`);
      setOrderStatistics(res.data);
    } catch (error: any) {
      console.log(error, error.response);
      message.error('Błąd');
    } finally {
      dispatch(setLoading(false));
    }
  }, [order]);

  useEffect(() => {
    getOrderStatistics();
  }, [getOrderStatistics]);

  if (!orderStatistics) return <Empty />;
  return (
    <div>
      <Row>
        <Col md={{ span: 3 }} span={24}>
          <H5>Płatności</H5>
          <Row justify="space-between">
            <Statistic
              title="Zapłacono"
              value={orderStatistics.alreadyPaid}
              valueStyle={{ color: 'green' }}
              suffix="zł"
            />
            <Statistic
              title="Do zapłaty"
              value={orderStatistics.toPay}
              valueStyle={{ color: 'red' }}
              suffix="zł"
            />
          </Row>
        </Col>
        <Col md={{ span: 3, offset: 8 }} span={24}>
          <H5>Koszty</H5>
          <Row justify="space-between">
            <Statistic
              title="Koszt pracowników"
              value={orderStatistics.employeesCosts}
              valueStyle={{ color: 'red' }}
              suffix="zł"
            />
            <Statistic
              title="Koszty"
              value={orderStatistics.costs}
              valueStyle={{ color: 'red' }}
              suffix="zł"
            />
          </Row>
        </Col>
        <Col md={{ span: 2, offset: 8 }} span={24}>
          <H5>Profit</H5>
          <Statistic
            title="Przewidywany zysk"
            value={orderStatistics.expectedProfit}
            valueStyle={{
              color: orderStatistics.expectedProfit > 0 ? 'green' : 'red',
            }}
            suffix="zł"
          />
        </Col>
      </Row>
      <AntDivider marginTop={30} />
      <Row justify="space-between" style={{ marginBottom: 10 }}>
        <H5>Czas pracy nad projektami</H5>
        <Button
          onClick={() => showModal('getStatisticsSummaryFile')}
          disabled={!orderStatistics.usersWorkTimes.length}
        >
          Pobierz podsumowanie
        </Button>
      </Row>
      <OrderUsersWorkTimesList workTimes={orderStatistics.usersWorkTimes} />

      {modal.getStatisticsSummaryFile && (
        <WorkTimeSummaryModal
          closeModal={closeModal}
          orderId={order._id}
          initDateFrom={order.createdAt}
          initDateTo={order.finishedAt ?? dayjs().toDate()}
          fileName={`zamowienie_${kebabCase(order.number)}`}
        />
      )}
    </div>
  );
};

export default OrderStatistics;
