import React from 'react';
import { Col, Row } from 'antd';
import Address from 'components/Address/Address';
import { IOrder } from 'services/store/types/orders/Orders';
import ProjectUserData from './ProjectUserData';

interface IProps {
  order?: IOrder;
}

const ProjectOrderData: React.FC<IProps> = ({ order }) => {
  if (!order) return null;
  return (
    <Row>
      <Col span={6}>
        <ProjectUserData
          user={order.customer}
          column={1}
          title="Dane klienta"
        />
      </Col>
      <Col span={6}>
        <Address address={order.address} title="Adres montażu" />
      </Col>
    </Row>
  );
};

export default ProjectOrderData;
