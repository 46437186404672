import React from 'react';
import { Comment, Popconfirm, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import styled from 'styled-components';
import { IMessage } from 'services/store/types/messages/Messages';
import { IUser } from 'services/store/types/users/Users';
dayjs.extend(relativeTime);

const { Text } = Typography;

const MessageContentWrapper = styled.div<{ isReaded: boolean }>`
  border-radius: 5px;
  background-color: ${({ isReaded }) => (isReaded ? '#f3f3f3' : '#b3b3b3')};
  cursor: ${({ isReaded }) => (isReaded ? 'default' : 'pointer')};
  padding: 0 3px;
`;

interface IProps {
  message: IMessage;
  markReaded: (id: string) => void;
  removeMessage: (id: string) => void;
  isMessageReaded: (message: IMessage) => boolean;
}

const MessagesListItem: React.FC<IProps> = ({
  message,
  markReaded,
  removeMessage,
  isMessageReaded,
}) => {
  return (
    <li>
      <Comment
        actions={[
          <Popconfirm
            key={1}
            title="Na pewno chcesz usunąć tą wiadomość?"
            onConfirm={() => removeMessage(message._id)}
            okText="Tak"
            cancelText="Nie"
          >
            <Text type="danger">Usuń</Text>
          </Popconfirm>,
        ]}
        author={
          <div>
            <div>
              Od:
              <strong>
                {(message.user as IUser).firstName}{' '}
                {(message.user as IUser).lastName}{' '}
              </strong>
              Do:
              <strong>
                {(message.recipient as IUser).firstName}{' '}
                {(message.recipient as IUser).lastName}
              </strong>
            </div>
          </div>
        }
        content={
          <MessageContent
            isMessageReaded={isMessageReaded(message)}
            message={message}
            markReaded={markReaded}
          />
        }
        datetime={
          <Tooltip title={dayjs(message.date).format('YYYY.MM.DD HH:mm')}>
            <span>{dayjs(message.date).fromNow()}</span>
          </Tooltip>
        }
      />
    </li>
  );
};

export default MessagesListItem;

interface IMessageContentProps {
  message: IMessage;
  markReaded: (id: string) => void;

  isMessageReaded: boolean;
}
const MessageContent: React.FC<IMessageContentProps> = ({
  message,
  markReaded,
  isMessageReaded,
}) => {
  return (
    <Tooltip title={!isMessageReaded && 'Kliknij aby odczytać'}>
      <MessageContentWrapper
        isReaded={isMessageReaded}
        onClick={() => {
          if (!isMessageReaded) markReaded(message._id);
        }}
      >
        <div>
          <strong>{message.subject}</strong>
        </div>
        <div>{message.message}</div>
      </MessageContentWrapper>
    </Tooltip>
  );
};
