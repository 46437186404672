import axios, { AxiosResponse } from 'axios';
import { IOrder } from 'services/store/types/orders/Orders';
import setAuthToken from 'services/utils/setAuthToken';

export const ordersApi = {
  createOrder: async (order: IOrder): Promise<AxiosResponse<string>> => {
    setAuthToken();
    return axios.post(`/api/orders`, order);
  },
};
