import React, { useState } from 'react';
import { Modal } from 'antd';
import dayjs from 'dayjs';
import AntDatePicker from 'components/DatePicker/DatePicker';
import { HistoryColor } from 'services/store/enums/historyColor.enum';
import { IProject } from 'services/store/types/projects/Projects';

interface IProps {
  project: IProject;
  closeModal: () => void;
  handleUpdateProject: (
    updateData: Partial<IProject>,
    historyUpdate: string[],
    historyColor: HistoryColor
  ) => Promise<any>;
}

const ChangeCreationDateModal: React.FC<IProps> = ({
  project,
  closeModal,
  handleUpdateProject,
}) => {
  const [date, setDate] = useState<Date>(project.createdAt);

  const onOk = async () => {
    const historyUpdate = [
      `Zmieniono datę utworzenia projektu.`,
      `Z ${dayjs(project.createdAt).format('DD.MM.YYYY')} na ${dayjs(
        date
      ).format('DD.MM.YYYY')}`,
    ];
    await handleUpdateProject(
      { createdAt: date },
      historyUpdate,
      HistoryColor.WARNING
    );
    closeModal();
  };

  return (
    <Modal
      title="Zmiana daty utworzenia projektu"
      destroyOnClose
      visible
      onCancel={closeModal}
      maskClosable={false}
      onOk={onOk}
    >
      <AntDatePicker
        value={dayjs(date)}
        onChange={(value) => (value ? setDate(value.toDate()) : null)}
        style={{ width: '100%' }}
        format="DD.MM.YYYY"
      />
    </Modal>
  );
};

export default ChangeCreationDateModal;
