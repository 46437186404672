import { useSelector } from 'react-redux';
import { TAppState } from 'services/store';
import { Permissions } from 'services/store/enums/users.enum';

const useUser = () => {
  const user = useSelector((state: TAppState) => state.auth.user);

  const isAuth = !!user;
  const isAdmin = () => {
    if (user?.permission === Permissions.ADMIN) return true;
    return false;
  };
  const isSuperAdmin = () => {
    if (isAdmin() && user?.isSuperAdmin) return true;
    return false;
  };
  const isUserExistsInArray = (arrayOfIds: string[]) => {
    if (user && arrayOfIds.includes(user._id)) return true;
    return false;
  };

  return { user, isAuth, isAdmin, isSuperAdmin, isUserExistsInArray };
};

export default useUser;
