import React, { useEffect, useState } from 'react';
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Descriptions,
  Image,
  message,
  PageHeader,
  Row,
  Modal,
} from 'antd';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { H1 } from 'components/Header';
import AccessoryModal, {
  IUpdateAccessory,
} from 'components/Modals/AccessoryModal/Accessory.modal';
import { useModal } from 'hook/useModals';
import { TAppState } from 'services/store';
import { setLoading } from 'services/store/actions/view';
import { FileDestination } from 'services/store/enums/files.enum';
import { Positions } from 'services/store/enums/users.enum';
import { IAccessory } from 'services/store/types/accessories/Accessories';
import { IFile } from 'services/store/types/files/Files';
import setAuthToken from 'services/utils/setAuthToken';
import { capitalizeFirstLetter, cutString } from 'services/utils/string';
import { isUserHasPermission } from 'services/utils/users';
import PageTemplate from 'templates/PageTemplate';

const { confirm } = Modal;

const AccessoryPage = () => {
  const user = useSelector((state: TAppState) => state.auth.user);
  const history = useHistory();
  const dispatch = useDispatch();
  const { id }: { id: string } = useParams();
  const { modal, showModal, closeModal } = useModal({ editAccessory: false });
  const [accessory, setAccessory] = useState<IAccessory>();

  useEffect(() => {
    dispatch(setLoading(true));
    setAuthToken();
    axios.get(`/api/accessories/${id}`).then((res) => {
      setAccessory(res.data);
    });
    dispatch(setLoading(false));
  }, []);

  // FIXME REFACTOR NEEDED
  // FIXME Ogarnąć uderzenia do api, bo np tworzenie/kasowanie plików się powtarza
  const updateAccessory = async (updatedAccessory: IUpdateAccessory) => {
    try {
      let fileId = (accessory?.image as IFile)?._id;
      if (updatedAccessory.files.length) {
        // remove current file
        if (accessory?.image) {
          await axios.delete(`/api/files/${(accessory?.image as IFile)?._id}`);
        }
        const fileBody = new FormData();
        fileBody.append(
          'file',
          updatedAccessory.files[0],
          updatedAccessory.files[0].fileName
        );
        const fileRes = await axios.post(`/api/files/single`, fileBody, {
          params: {
            destination: FileDestination.ACCESSORIES,
            name: updatedAccessory.name,
          },
        });
        fileId = fileRes.data;
      }

      const res = await axios.patch(`/api/accessories/${id}`, {
        ...updatedAccessory,
        image: fileId,
      });

      setAccessory(res.data);
      message.success('Zaktualizowano akcesorium');
      return;
    } catch (error: any) {
      console.log(error, error.response);
      message.error('Błąd');
      return;
    }
  };

  const deleteAccessory = async () => {
    dispatch(setLoading(true));
    try {
      await axios.delete(`/api/accessories/${id}`);
      message.success('Akcesorium usunięte');
      history.push('/base/accessories');
    } catch (error) {
      message.error('Błąd');
    }
    dispatch(setLoading(false));
  };

  const confirmDeleteAccessory = () =>
    confirm({
      title: 'Czy na pewno chcesz usunąć to akcesorium?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Tak',
      okType: 'danger',
      cancelText: 'Nie',
      onOk() {
        deleteAccessory();
      },
      onCancel() {},
    });

  return (
    <PageTemplate>
      {accessory && (
        <>
          <PageHeader
            title={<H1>{capitalizeFirstLetter(accessory.name)}</H1>}
            subTitle={cutString(accessory.description || '', 20)}
            extra={
              isUserHasPermission(user, [
                Positions.DESIGNER,
                Positions.TECHNOLOGIST,
              ]) && [
                <Button key={1} onClick={() => showModal('editAccessory')}>
                  <EditOutlined />
                  Edytuj
                </Button>,
                <Button key={2} danger onClick={confirmDeleteAccessory}>
                  <DeleteOutlined />
                  Usuń
                </Button>,
              ]
            }
          />
          <Row gutter={8}>
            <Col span={8}>
              <Descriptions title="Dane" size="small" column={1}>
                <Descriptions.Item label="Nazwa">
                  <strong>{accessory.name}</strong>
                </Descriptions.Item>
                <Descriptions.Item label="Symbol">
                  <strong>{accessory.symbol}</strong>
                </Descriptions.Item>
                <Descriptions.Item label="Opis">
                  <strong>{accessory.description}</strong>
                </Descriptions.Item>
                <Descriptions.Item label="Cena">
                  <strong>{accessory.price}</strong>
                </Descriptions.Item>
                <Descriptions.Item label="Sugerowany?">
                  <strong>{accessory.isSuggested ? 'Tak' : 'Nie'}</strong>
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Col span={8}>
              {accessory.image && (
                <Image
                  alt="img"
                  width={200}
                  src={`${(accessory.image as IFile).dir}`}
                  preview={{
                    src: `${(accessory.image as IFile).dir}`,
                  }}
                />
              )}
            </Col>
          </Row>
        </>
      )}

      {/* MODALS */}
      {modal.editAccessory && accessory && (
        <AccessoryModal
          closeModal={closeModal}
          accessory={accessory}
          onSubmit={updateAccessory}
        />
      )}
    </PageTemplate>
  );
};

export default AccessoryPage;
