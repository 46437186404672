import React from 'react';
import { HistoryColor } from 'services/store/enums/historyColor.enum';
import { IPaintedElementsOrder } from 'services/store/types/projects/paintedElementsOrder.interface';
import { IProject } from 'services/store/types/projects/Projects';
import OrdersList from './PaintedElementsOrdersList';

interface IProps {
  project: IProject;
  orders: IPaintedElementsOrder[];
  handleUpdateProject: (
    updateData: Partial<IProject>,
    historyUpdate: string[],
    historyColor: HistoryColor
  ) => Promise<any>;
}

const ProjectPaintedElementsOrders: React.FC<IProps> = ({
  project,
  orders,
  handleUpdateProject,
}) => {
  const handleRemoveOrderFromProject = async (id: string) => {
    const filteredOrders = project.paintedElementsOrders.filter(
      (order) => order !== id
    );

    const historyUpdate = ['Usunięto zamówienie na elementy lakierowane.'];
    await handleUpdateProject(
      { paintedElementsOrders: filteredOrders },
      historyUpdate,
      HistoryColor.DANGER
    );
  };

  return (
    <div>
      <OrdersList orders={orders} handleRemove={handleRemoveOrderFromProject} />
    </div>
  );
};

export default ProjectPaintedElementsOrders;
