import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ProjectStatusTag from 'components/Tags/ProjectStatusTag/ProjectStatusTag';
import { TAppState } from 'services/store';
import { ProjectStatus } from 'services/store/enums/project.enum';
import { ICustomer } from 'services/store/types/customers/Customers';
import { IOrder } from 'services/store/types/orders/Orders';
import { IProject } from 'services/store/types/projects/Projects';
import { IUser } from 'services/store/types/users/Users';
import {
  getPositionFromProjectStatus,
  isSomeoneWorkingOnProjectAtPosition,
} from 'services/utils/project.utils';
import { getUserName } from 'services/utils/string';

const columns: any[] = [
  {
    dataIndex: 'lp',
    title: 'Lp.',
    align: 'center' as const,
    render: (e: any, r: any, i: number) => i + 1,
    width: 50,
  },
  {
    title: 'Klient',
    dataIndex: 'order',
    align: 'center' as const,
    render: (order: IOrder) =>
      order?.customer ? getUserName(order.customer as ICustomer) : '',
    sorter: (a: any, b: any) =>
      a.order?.customer?.lastName.localeCompare(b.order?.customer?.lastName),
    width: 150,
  },
  {
    title: 'Nazwa projektu',
    dataIndex: 'name',
    align: 'center' as const,
    sorter: (a: any, b: any) => a.name.localeCompare(b.name),
  },
  {
    title: 'Utworzony przez',
    dataIndex: 'createdBy',
    align: 'center' as const,
    render: (createdBy: IUser) =>
      `${createdBy.lastName} ${createdBy.firstName}`,
  },
  {
    title: 'Nazwa zamówienia',
    dataIndex: 'order',
    align: 'center' as const,
    render: (order: IOrder) => order?.name || '-',
  },
  {
    title: 'Numer',
    dataIndex: 'number',
    align: 'center' as const,
  },
  {
    title: 'Data utworzenia',
    dataIndex: 'createdAt',
    align: 'center' as const,
    render: (createdAt: string) => dayjs(createdAt).format('DD.MM.YYYY'),
    sorter: (a: IProject, b: IProject) => {
      return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
    },
  },
  {
    title: 'Data zakończenia',
    dataIndex: 'finishedAt',
    align: 'center' as const,
    render: (finishedAt: string) =>
      finishedAt ? dayjs(finishedAt).format('DD.MM.YYYY') : '-',
    sorter: (a: string, b: string) => {
      return new Date(a).getTime() - new Date(b).getTime();
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    align: 'center' as const,
    render: (status: ProjectStatus[], project: IProject) =>
      status.map((st) => (
        <ProjectStatusTag
          key={st}
          status={st}
          inProduction={isSomeoneWorkingOnProjectAtPosition(
            project,
            getPositionFromProjectStatus(st)
          )}
        />
      )),
    fixed: 'right',
    width: 120,
  },
];

interface IProps {
  projects?: IProject[];
  isLoading?: boolean;
  showIsInCurrentSchedule?: boolean;
  maxHeight?: number;
  onRowClick?: (project: IProject) => void;
}

const ProjectsList: React.FC<IProps> = ({
  projects,
  isLoading = false,
  showIsInCurrentSchedule = false,
  maxHeight,
  onRowClick,
}) => {
  const userCurrentNode = useSelector(
    (state: TAppState) => state.productionNodes.userCurrentNode
  );

  const history = useHistory();
  const [list, setList] = useState<any[]>([]);
  useEffect(() => {
    if (projects) {
      const newProjects = projects.map((project: IProject, i: number) => ({
        ...project,
        key: project._id,
        isProjectPlaned: showIsInCurrentSchedule
          ? isProjectInCurrentSchedule(project._id)
          : false,
      }));
      setList(newProjects);
    }
  }, [projects]);

  const isProjectInCurrentSchedule = (projectId?: string) => {
    if (userCurrentNode) {
      const isProjectInActions = userCurrentNode.actions.find(
        (action) => action.project?._id === projectId
      );
      if (isProjectInActions) return true;
    }
    return false;
  };
  const handleRedirect = (id: string) => {
    history.push(`/projects/${id}`);
  };

  return (
    <Table
      columns={columns}
      pagination={false}
      dataSource={list}
      loading={isLoading}
      rowClassName={(r: any) =>
        `table-row-clicable ${r.isProjectPlaned ? 'table-row-highlight' : ''}`
      }
      scroll={{ x: 1000, y: maxHeight }}
      size="small"
      onRow={(record) => {
        return {
          onClick: () => {
            if (onRowClick) {
              return onRowClick(record as IProject);
            }
            return handleRedirect(record._id);
          },
        };
      }}
    />
  );
};

export default ProjectsList;
