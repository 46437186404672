import React, { useEffect, useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Table } from 'antd';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { getFileFromPath } from 'services/requests/files.request';
import { TAppState } from 'services/store';
import { Positions } from 'services/store/enums/users.enum';
import { IFile } from 'services/store/types/files/Files';
import { IOrderDocument } from 'services/store/types/orders/Orders';
import { isUserHasPermission } from 'services/utils/users';

const columns: any[] = [
  {
    dataIndex: 'lp',
    title: 'Lp.',
    align: 'center' as const,
    render: (e: any, r: any, i: number) => i + 1,
  },
  {
    title: 'Nazwa',
    dataIndex: 'name',
    align: 'center' as const,
    defaultSortOrder: 'ascend' as const,
    sorter: (a: any, b: any) => a.name.localeCompare(b.name),
  },
  {
    title: 'Opis',
    dataIndex: 'description',
    align: 'center' as const,
  },
  {
    title: 'Data',
    dataIndex: 'date',
    align: 'center' as const,
    render: (date: Date) => dayjs(date).format('DD.MM.YYYY'),
  },
  {
    title: 'Plik',
    dataIndex: 'getFile',
    align: 'center' as const,
  },
  {
    title: 'Chroniony',
    dataIndex: 'protected',
    align: 'center' as const,
    render: (pr: boolean) => (pr ? 'TAK' : '-'),
  },
  {
    title: 'Usuń',
    dataIndex: 'remove',
    align: 'center' as const,
  },
];

interface IProps {
  documents?: IOrderDocument[];
  handleRemoveDocument: (document: IOrderDocument) => Promise<any>;
  maxHeight?: number;
}

const DocumentsList: React.FC<IProps> = ({
  documents,
  handleRemoveDocument,
  maxHeight,
}) => {
  const user = useSelector((state: TAppState) => state.auth.user);

  const [list, setList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (documents) {
      let newDocuments = [...documents];
      if (
        !isUserHasPermission(user, [Positions.DESIGNER, Positions.TECHNOLOGIST])
      ) {
        newDocuments = newDocuments.filter((doc) => !doc.protected);
      }

      newDocuments = newDocuments.map(
        (document: IOrderDocument, i: number) => ({
          ...document,
          key: document._id,
          getFile: (
            <Button
              size="small"
              disabled={!document.file}
              onClick={() => getDocumentFile((document.file as IFile).dir)}
            >
              Pobierz
            </Button>
          ),
          remove: isUserHasPermission(user, [
            Positions.DESIGNER,
            Positions.TECHNOLOGIST,
          ]) ? (
            <Popconfirm
              key={1}
              title="Na pewno chcesz usunąć ten dokument?"
              onConfirm={() => handleRemoveDocument(document)}
              okText="Tak"
              cancelText="Nie"
            >
              <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} />
            </Popconfirm>
          ) : (
            '-'
          ),
        })
      );
      setList(newDocuments);
    }
  }, [documents]);

  const getDocumentFile = async (path: string) => {
    if (path) {
      setIsLoading(true);
      await getFileFromPath(path);
      setIsLoading(false);
    }
  };

  return (
    <Table
      columns={columns}
      size="small"
      pagination={false}
      dataSource={list}
      loading={isLoading}
      scroll={{ x: 1000, y: maxHeight }}
    />
  );
};

export default DocumentsList;
