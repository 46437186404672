import axios, { AxiosResponse } from 'axios';
import { ProjectStatus } from 'services/store/enums/project.enum';
import { ISearchParams } from 'services/store/interfaces/search-params.interface';
import { ISearchResult } from 'services/store/interfaces/search-result.interface';
import { IProject } from 'services/store/types/projects/Projects';
import setAuthToken from 'services/utils/setAuthToken';

interface IProjectNumber extends Pick<IProject, '_id' | 'number'> {}
export interface IProjectSearchParams extends ISearchParams {
  status?: ProjectStatus[];
  customer?: string;
  name?: string;
  number?: string;
}

export const projectsApi = {
  getProjects: async (
    params: IProjectSearchParams
  ): Promise<AxiosResponse<ISearchResult<IProject>>> => {
    setAuthToken();
    return axios.get(`/api/projects`, {
      params,
    });
  },
  getProjectsNumbers: async (
    projectNumber: string
  ): Promise<AxiosResponse<IProjectNumber[]>> => {
    setAuthToken();
    return axios.get(`/api/projects/get-numbers`, {
      params: { projectNumber },
    });
  },
};
