import React from 'react';
import { Modal } from 'antd';
import { Field, Form, Formik } from 'formik';
import { AntInput } from 'components/Formik-AntdFields/CreateAntdField';
import { companyDataValidation } from 'scenes/Settings/utils/validate';
import {
  ICompanyData,
  ISettings,
} from 'services/store/types/settings/Settings';

const initValues = {
  name: '',
  nip: '',
  regon: '',
  bankAccount: '',
};

interface IProps {
  closeModal: () => void;
  updateSettings: (data: Partial<ISettings>) => Promise<any>;
  companyData: ICompanyData;
}

const ChangeCompanyDataModal: React.FC<IProps> = ({
  closeModal,
  companyData,
  updateSettings,
}) => {
  const handleSubmit = async (data: ICompanyData) => {
    await updateSettings({ companyData: data });
    closeModal();
  };

  return (
    <Modal
      title="Dane firmy"
      destroyOnClose
      visible
      onCancel={closeModal}
      maskClosable={false}
      okButtonProps={{
        form: 'companyDataEditId',
        htmlType: 'submit',
        disabled: false,
      }}
    >
      <Formik
        validationSchema={companyDataValidation}
        onSubmit={handleSubmit}
        initialValues={companyData || initValues}
      >
        {(props) => {
          return (
            <Form
              noValidate
              id="companyDataEditId"
              onSubmit={props.handleSubmit}
            >
              <Field
                component={AntInput}
                name="name"
                type="text"
                placeholder="Nazwa"
                validate={props.errors.name}
                submitCount={props.submitCount}
                hasFeedback
              />
              <Field
                component={AntInput}
                name="nip"
                type="text"
                placeholder="NIP"
                validate={props.errors.nip}
                submitCount={props.submitCount}
                hasFeedback
              />
              <Field
                component={AntInput}
                name="regon"
                type="text"
                placeholder="REGON"
                validate={props.errors.regon}
                submitCount={props.submitCount}
                hasFeedback
              />
              <Field
                component={AntInput}
                name="bankAccount"
                type="text"
                placeholder="Konto bankowe"
                validate={props.errors.bankAccount}
                submitCount={props.submitCount}
                hasFeedback
              />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default ChangeCompanyDataModal;
