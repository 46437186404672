import React, { useState } from 'react';
import { Modal, Input } from 'antd';
import { HistoryColor } from 'services/store/enums/historyColor.enum';
import { IProject } from 'services/store/types/projects/Projects';

interface IProps {
  project: IProject;
  closeModal: () => void;
  handleUpdateProject: (
    updateData: Partial<IProject>,
    historyUpdate: string[],
    historyColor: HistoryColor
  ) => Promise<any>;
}

const ChangeProjectNumberModal: React.FC<IProps> = ({
  project,
  closeModal,
  handleUpdateProject,
}) => {
  const [number, setNumber] = useState<string>(project.number);

  const onOk = async () => {
    const historyUpdate = [
      `Zaktualizowano number projektu:`,
      `${project.number} na ${number}`,
    ];
    await handleUpdateProject({ number }, historyUpdate, HistoryColor.SUCCESS);
    closeModal();
  };

  return (
    <Modal
      title="Zmiana numeru projektu"
      destroyOnClose
      visible
      onCancel={closeModal}
      maskClosable={false}
      onOk={onOk}
    >
      <Input
        value={number}
        onChange={(e) => setNumber(e.target.value)}
        placeholder="Numer zamówienia"
        style={{ width: '100%' }}
      />
    </Modal>
  );
};

export default ChangeProjectNumberModal;
