import React, { useState } from 'react';
import { Checkbox, Modal } from 'antd';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import AntDatePicker from 'components/DatePicker/DatePicker';
import { TAppState } from 'services/store';
import { HistoryColor } from 'services/store/enums/historyColor.enum';
import { IProject } from 'services/store/types/projects/Projects';

interface IProps {
  project: IProject;
  closeModal: () => void;
  handleUpdateProject: (
    updateData: Partial<IProject>,
    historyUpdate: string[],
    historyColor: HistoryColor
  ) => Promise<any>;
}

// FIXME REFACTOR NEEDED (wynieść funkcję do aktualizacji terminarza gdzieś do requestów, tak samo update projektu zabrać z kontenera projektu)
const ChangeProjectMontageDateModal: React.FC<IProps> = ({
  project,
  closeModal,
  handleUpdateProject,
}) => {
  const user = useSelector((state: TAppState) => state.auth.user);
  const [date, setDate] = useState<Date>(project.montageAt || new Date());
  const [createScheduleItem, setCreateScheduleItem] = useState<boolean>(false);

  const onOk = async () => {
    const historyUpdate = [
      `Zmieniono datę montażu projektu.`,
      `Z ${
        project.montageAt ? dayjs(project.montageAt).format('DD.MM.YYYY') : '-'
      } na ${dayjs(date).format('DD.MM.YYYY')}`,
    ];
    await handleUpdateProject(
      { montageAt: date },
      historyUpdate,
      HistoryColor.WARNING
    );

    // TODO zmiana daty montazu - dodawanie do terminarza
    // if (createScheduleItem && user) {
    //   const scheduleItemData: Partial<ISchedule> = {
    //     createdAt: new Date(),
    //     createdBy: user._id,
    //     date,
    //     createdFor: [],
    //     project: project._id,
    //     name: 'Montaż zamówienia',
    //     description: '',
    //     type: ScheduleType.MONTAGE,
    //   };
    //   await axios.post(`/api/schedule`, {
    //     scheduleItemData,
    //     userAction: `Utworzył wydarzenie ${scheduleItemData.name}`,
    //   });
    // }

    closeModal();
  };

  return (
    <Modal
      title="Zmiana daty montażu projektu"
      destroyOnClose
      visible
      onCancel={closeModal}
      maskClosable={false}
      onOk={onOk}
    >
      <AntDatePicker
        value={dayjs(date)}
        onChange={(value) => (value ? setDate(value.toDate()) : null)}
        style={{ width: '100%', marginBottom: 10 }}
        format="DD.MM.YYYY"
      />
      <Checkbox
        checked={createScheduleItem}
        onChange={(e) => setCreateScheduleItem(e.target.checked)}
      >
        Utwórz wydarzenie w terminarzu
      </Checkbox>
    </Modal>
  );
};

export default ChangeProjectMontageDateModal;
