import React, { useEffect, useState } from 'react';
import { UserAddOutlined } from '@ant-design/icons';
import { Button, PageHeader } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { H1 } from 'components/Header';
import { useModal } from 'hook/useModals';
import { TAppState } from 'services/store';
import { getAllUsers } from 'services/store/actions/users';
import PageTemplate from 'templates/PageTemplate';
import CreateUserModal from './components/CreateUserModal';
import UsersList from './components/UsersList';

const UsersPage = () => {
  const dispatch = useDispatch();
  const { modal, showModal, closeModal } = useModal({ createUser: false });
  const users = useSelector((state: TAppState) => state.users.users);
  const isAuth = useSelector((state: TAppState) => state.auth.isAuth);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isAuth) {
      setIsLoading(true);
      dispatch(getAllUsers(() => setIsLoading(false)));
    }
  }, [isAuth]);

  return (
    <PageTemplate>
      <PageHeader
        title={<H1>Użytkownicy</H1>}
        extra={[
          <Button key="1" onClick={() => showModal('createUser')}>
            <UserAddOutlined />
            Dodaj
          </Button>,
        ]}
      />
      <UsersList users={users} isLoading={isLoading} />

      {/* MDOALS */}
      {modal.createUser && <CreateUserModal closeModal={closeModal} />}
    </PageTemplate>
  );
};

export default UsersPage;
