import dayjs from 'dayjs';
import { IPositionNode } from 'services/store/types/productionNodes/ProductionNodes';

export const getEditDayPartViewData = (nodes: IPositionNode[]) => {
  const newList = nodes.map((node, nodeIndex) => {
    const item: any = {
      key: node.nodeId,
      node: { position: node.position, user: node.user },
    };
    node.schedule.forEach((scheduleItem, scheduleIndex) => {
      scheduleItem.actions.forEach((action, actionIndex) => {
        const date = dayjs(scheduleItem.date).format('DD.MM.YYYY');
        const dataIndex = `${date}[${actionIndex}]`;
        const data = {
          action: action,
          indexes: { nodeIndex, scheduleIndex, actionIndex },
        };
        item[dataIndex] = data;
      });
    });
    return item;
  });
  return newList;
};
