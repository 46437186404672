import dayjs from 'dayjs';
import { IPositionNode } from 'services/store/types/productionNodes/ProductionNodes';

export const getEditDayViewData = (nodes: IPositionNode[]) => {
  const newList = nodes.map((node, nodeIndex) => {
    const item: any = {
      key: node.nodeId,
      node: { position: node.position, user: node.user },
    };
    node.schedule.forEach((scheduleItem, scheduleIndex) => {
      const dataIndex = dayjs(scheduleItem.date).format('DD.MM.YYYY');
      const data = {
        actions: scheduleItem.actions,
        indexes: { nodeIndex, scheduleIndex },
      };
      item[dataIndex] = data;
    });
    return item;
  });
  return newList;
};
