import React, { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Modal, Typography, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { uploadFile } from 'services/requests/files.request';
import { FileDestination } from 'services/store/enums/files.enum';
import { HistoryColor } from 'services/store/enums/historyColor.enum';
import { OderPaymentType } from 'services/store/enums/orders.enum';
import { IOrder, IOrderPayment } from 'services/store/types/orders/Orders';

const { Text } = Typography;

interface IProps {
  order: IOrder;
  payment: IOrderPayment;
  closeModal: () => void;
  handleUpdateOrder: (
    updateData: Partial<IOrder>,
    historyUpdate: string[],
    historyColor: HistoryColor
  ) => Promise<any>;
}

const OrderApprovePaymentModal: React.FC<IProps> = ({
  order,
  payment,
  closeModal,
  handleUpdateOrder,
}) => {
  const [files, setFiles] = useState<RcFile[]>([]);

  const onOk = async () => {
    let fileId: string | undefined = undefined;
    if (files.length) {
      fileId = await uploadFile(files[0], FileDestination.ORDERS, payment.name);
    }

    const historyUpdate = [`Zatwierdzono przyjęcie płatności`];
    const newPayments = order.payments.map((pay) => {
      if (pay._id === payment._id)
        return { ...pay, isPaid: true, file: fileId };
      return pay;
    });
    await handleUpdateOrder(
      { payments: newPayments },
      historyUpdate,
      HistoryColor.SUCCESS
    );

    closeModal();
  };

  return (
    <Modal
      title={`Zatwierdzenie transzy: ${payment.name}. (${payment.type})`}
      destroyOnClose
      visible
      onCancel={closeModal}
      maskClosable={false}
      onOk={onOk}
      okButtonProps={{
        disabled: payment.type === OderPaymentType.CONFIRMED && !files.length,
      }}
    >
      <Text>
        Zatwierdzasz płatność wysokości <Text strong>{payment.value}</Text>zł.
        {payment.type === OderPaymentType.CONFIRMED && ' Dołącz dowód wpłaty:'}
      </Text>
      {payment.type === OderPaymentType.CONFIRMED && (
        <Upload
          onRemove={() => setFiles([])}
          beforeUpload={(file: RcFile) => {
            setFiles([file]);
            return false;
          }}
          fileList={files}
          multiple={false}
          accept="image/*"
        >
          <Button icon={<UploadOutlined />}>Dodaj plik</Button>
        </Upload>
      )}
    </Modal>
  );
};

export default OrderApprovePaymentModal;
