import * as yup from 'yup';

export const accessoryValidation = yup.object({
  name: yup
    .string()
    .required('Nazwa jest wymagana')
    .min(1, 'Min. 1 znak')
    .max(50, 'Max. 50 znaków'),
  symbol: yup
    .string()
    .required('Symbol jest wymagany')
    .min(1, 'Min. 1 znak')
    .max(50, 'Max. 50 znaków'),
  price: yup
    .number()
    .moreThan(0, 'Cena jest wymagana')
    .required('Cena jest wymagana'),
});
