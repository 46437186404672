import React from 'react';
import { RedoOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import AlertsList from 'components/AlertsList/AlertsList';
import CommentsList from 'components/Coments/CommentsList/CommentsList';
import AntDivider from 'components/Divider/Divider';
import { H4 } from 'components/Header';
import ProjectsList from 'components/ProjectsList/ProjectsList';
import { Positions } from 'services/store/enums/users.enum';
import { IAlert } from 'services/store/interfaces/alert.interface';
import { IComment } from 'services/store/interfaces/comment.interface';
import { IOrder } from 'services/store/types/orders/Orders';
import { IProject } from 'services/store/types/projects/Projects';
import { IUser } from 'services/store/types/users/Users';
import { isUserHasPermission } from 'services/utils/users';

interface IProps {
  user: IUser | null;
  order: IOrder;
  showModal: (modal: any) => void;
  projects: IProject[];
  alerts: IAlert[];
  getOrderAlerts: () => Promise<any>;
  handleRemoveComment: (comment: string) => void;
}

const OrderDashboard: React.FC<IProps> = ({
  user,
  order,
  showModal,
  projects,
  alerts,
  getOrderAlerts,
  handleRemoveComment,
}) => {
  return (
    <Row gutter={18}>
      <Col md={7} span={24}>
        {isUserHasPermission(user, [
          Positions.DESIGNER,
          Positions.TECHNOLOGIST,
        ]) && (
          <>
            <Row justify="space-between">
              <H4>Alerty</H4>
              <Button onClick={getOrderAlerts}>
                <RedoOutlined />
              </Button>
            </Row>
            <AlertsList alerts={alerts} />
            <AntDivider marginTop={40} />
          </>
        )}

        <H4>Komentarze</H4>
        <CommentsList
          comments={order.comments}
          showAddCommentModal={() => showModal('addComment')}
          handleRemoveComment={handleRemoveComment}
        />
      </Col>
      <Col md={17} span={24}>
        <ProjectsList projects={projects} />
      </Col>
    </Row>
  );
};

export default OrderDashboard;
