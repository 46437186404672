import React, { useState } from 'react';
import { MenuOutlined } from '@ant-design/icons';
import { Button, Dropdown, List, Menu, Tabs } from 'antd';
import dayjs from 'dayjs';
import styled from 'styled-components';
import InnerSpinner from 'components/Spinner/InnerSpinner';
import UserPositionTag from 'components/Tags/UserPositionTag/UserPositionTag';
import useUser from 'hook/useUser';
import { IProject } from 'services/store/types/projects/Projects';
import { IWorkTime } from 'services/store/types/statistics/Statistics';
import { IUser } from 'services/store/types/users/Users';
import { getDurationTextFromDate } from 'services/utils/dates.utils';
import { getUserName } from 'services/utils/string';
import StopUserProcessModal from '../Modals/StopUserProcess.modal';

const AlertsWrapper = styled.div`
  position: relative;
  max-height: 300px;
  overflow-y: scroll;
`;

interface IProps {
  users: IUser[];
  isLoading: boolean;
  refetchActivity: () => Promise<any>;
}

const DashboardCurrentUsersActivity: React.FC<IProps> = ({
  users,
  isLoading,
  refetchActivity,
}) => {
  const { isAdmin } = useUser();
  const [userToCloseAction, setUserToCloseAction] = useState<
    IUser | undefined
  >();

  return (
    <AlertsWrapper>
      {isLoading && <InnerSpinner />}
      <List
        itemLayout="horizontal"
        dataSource={users.filter((user) => user?.workingOn)}
        renderItem={(user) => (
          <List.Item
            extra={[
              isAdmin() && (
                <Dropdown
                  key={1}
                  placement="bottomLeft"
                  overlay={
                    <Menu>
                      <Menu.Item onClick={() => setUserToCloseAction(user)}>
                        Zakończ
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={['click']}
                >
                  <Button size="small" title="Akcje">
                    <MenuOutlined />
                  </Button>
                </Dropdown>
              ),
            ]}
          >
            <List.Item.Meta
              title={getUserName(user)}
              description={
                <div>
                  <div>
                    <strong>Projekt: </strong>
                    {((user?.workingOn as IWorkTime)?.project as IProject)
                      ?.number || '-'}
                  </div>
                  <div>
                    <strong>Czas trwania: </strong>
                    {getDurationTextFromDate(
                      (user?.workingOn as IWorkTime)?.start
                    )}
                  </div>
                  {(user?.workingOn as IWorkTime)?.action && (
                    <div>
                      <strong>Akcja: </strong>
                      {(user?.workingOn as IWorkTime)?.action}
                    </div>
                  )}
                </div>
              }
            />
            <UserPositionTag
              position={(user?.workingOn as IWorkTime)?.position}
            />
          </List.Item>
        )}
      />

      {/* NOTE MODALS */}
      {!!userToCloseAction && (
        <StopUserProcessModal
          closeModal={() => setUserToCloseAction(undefined)}
          user={userToCloseAction}
          refetchActivity={refetchActivity}
        />
      )}
    </AlertsWrapper>
  );
};

export default DashboardCurrentUsersActivity;
