import { ICategory, ISettings, IWorkAction } from './Settings';

export const SETTINGS_LOAD_SUCCESS = 'SETTINGS_LOAD_SUCCESS';
export const CATEGORIES_LOAD_SUCCESS = 'CATEGORIES_LOAD_SUCCESS';
export const WORK_ACTIONS_LOAD_SUCCESS = 'WORK_ACTIONS_LOAD_SUCCESS';

export interface ISetSettings {
  type: typeof SETTINGS_LOAD_SUCCESS;
  settings: ISettings;
}
export interface ISetCategories {
  type: typeof CATEGORIES_LOAD_SUCCESS;
  categories: ICategory[];
}
export interface ISetWorkActions {
  type: typeof WORK_ACTIONS_LOAD_SUCCESS;
  workActions: IWorkAction[];
}

export const SETTINGS_CLEAR = 'SETTINGS_CLEAR';
export interface IClearSettings {
  type: typeof SETTINGS_CLEAR;
}

export type TSettingsActions =
  | ISetWorkActions
  | ISetSettings
  | IClearSettings
  | ISetCategories;
