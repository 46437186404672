import React, { useState } from 'react';
import { message } from 'antd';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { getAllWorkActions } from 'services/store/actions/settings';
import { setLoading } from 'services/store/actions/view';
import { IWorkAction } from 'services/store/types/settings/Settings';
import AddWorkActionModal from './Modals/AddWorkAction.modal';
import WorkActionsList from './WorkActionsList';

interface IProps {
  workActions: IWorkAction[];
}

const WorkActions: React.FC<IProps> = ({ workActions }) => {
  const [workActionToEdit, setWorkActionToEdit] = useState<IWorkAction | null>(
    null
  );
  const dispatch = useDispatch();

  const handleRemoveWorkAction = async (id: string) => {
    dispatch(setLoading(true));
    try {
      await axios.delete(`/api/settings/work-actions/${id}`);
      dispatch(getAllWorkActions());
      message.success('Akcja usunięta');
    } catch (error) {
      message.error('Błąd');
    }
    dispatch(setLoading(false));
  };
  return (
    <div>
      <WorkActionsList
        workActions={workActions}
        removeWorkAction={handleRemoveWorkAction}
        setToEdit={setWorkActionToEdit}
      />
      {/* NOTE MODALS */}
      {workActionToEdit && (
        <AddWorkActionModal
          closeModal={() => setWorkActionToEdit(null)}
          workAction={workActionToEdit}
        />
      )}
    </div>
  );
};

export default WorkActions;
