import { UserAddOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Button, Input, message, PageHeader, Row } from 'antd';
import { H1 } from 'components/Header';
import { useControledDebounce } from 'hook/useControledDebounce';
import { useModal } from 'hook/useModals';
import usePagination from 'hook/usePagination';
import useUser from 'hook/useUser';
import { customersApi } from 'services/api/customers.api';
import PageTemplate from 'templates/PageTemplate';
import CustomersList from './components/CustomersList';
import CreateCustomerModal from '../../components/Modals/CustomerCreateModal/CreateCustomerModal';

const CustomersPage = () => {
  const { isAuth } = useUser();
  const { modal, showModal, closeModal } = useModal({ createCustomer: false });
  const { value, debouncedValue, handleDebouncedValue } =
    useControledDebounce();

  const {
    pageNumber,
    itemsPerPage,
    handlePagination,
    totalItems,
    setTotalItems,
  } = usePagination({
    itemsPerPage: 50,
  });

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['get-customers', pageNumber, itemsPerPage, debouncedValue],
    queryFn: () =>
      customersApi.getCustomers({
        pageNumber,
        itemsPerPage,
        name: debouncedValue,
      }),
    onSuccess: (res) => {
      setTotalItems(res.data.displayParams?.totalItems);
    },
    onError: (error: any) => {
      console.log(error, error.response);
      message.error('Błąd!');
    },
    enabled: isAuth,
  });

  return (
    <PageTemplate>
      <PageHeader
        title={<H1>Klienci</H1>}
        extra={[
          <Button key="1" onClick={() => showModal('createCustomer')}>
            <UserAddOutlined />
            Dodaj
          </Button>,
        ]}
      />
      <Row justify="end" style={{ marginBottom: 20 }}>
        <div>
          <Input
            size="middle"
            style={{ width: 300 }}
            placeholder="Wyszukaj..."
            value={value}
            onChange={(e) => handleDebouncedValue(e.target.value)}
            allowClear={true}
            title="Imię, nazwisko lub nazwa firmy"
          />
        </div>
      </Row>
      <CustomersList
        customers={data?.data.content}
        loading={isLoading}
        pagination={{
          current: pageNumber,
          total: totalItems,
          pageSize: itemsPerPage,
          showSizeChanger: true,
          onChange: handlePagination,
          position: ['topRight', 'bottomRight'],
        }}
      />

      {/* MDOALS */}
      {modal.createCustomer && (
        <CreateCustomerModal closeModal={closeModal} refetch={refetch} />
      )}
    </PageTemplate>
  );
};

export default CustomersPage;
