import { Permissions, Positions } from 'services/store/enums/users.enum';
import { IUser } from 'services/store/types/users/Users';

const isUserHasPermission = (
  user: null | IUser,
  positionsWithPermission: Positions[]
) => {
  if (!user) return false;
  else if (user.permission === Permissions.ADMIN) return true;
  else if (
    user.activePosition &&
    positionsWithPermission.includes(user.activePosition)
  ) {
    return true;
  }
  return false;
};

export { isUserHasPermission };
