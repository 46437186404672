import React from 'react';
import { UserSwitchOutlined, LockOutlined } from '@ant-design/icons';
import { Button, Descriptions, message, PageHeader, Typography } from 'antd';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { H1 } from 'components/Header';
import ChangeUserDataModal from 'components/Modals/ChangeUserDataModal/ChangeUserData.modal';
import { useModal } from 'hook/useModals';
import { TAppState } from 'services/store';
import { userLoadSuccess } from 'services/store/actions/auth';
import { setLoading } from 'services/store/actions/view';
import { Permissions } from 'services/store/enums/users.enum';
import { IUser } from 'services/store/types/users/Users';
import { getUserName } from 'services/utils/string';
import PageTemplate from 'templates/PageTemplate';
import ChangePasswordModal from './components/ChangePasswordModal';

const { Text } = Typography;

interface Props {
  permission: string;
}

const AccountSettingsPage: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: TAppState) => state.auth.user);
  const { modal, closeModal, showModal } = useModal({
    dataChange: false,
    passwordChange: false,
  });

  const handleChangeUserData = async (values: IUser, actions: any) => {
    if (user) {
      dispatch(setLoading(true));
      try {
        const res = await axios.patch(`/api/users/${user._id}`, {
          ...values,
          userAction: `Zaktualizował swoje dane`,
        });
        dispatch(userLoadSuccess(res.data, res.data.permission));
        message.success('Dane zmienione');
        closeModal();
      } catch (error: any) {
        if (error.response?.data?.error)
          actions.setErrors(error.response.data.error);
        else message.error('Błąd');
        console.log(error, error.response);
      }
      dispatch(setLoading(false));
    }
  };

  if (user)
    return (
      <PageTemplate>
        <PageHeader
          title={<H1>{getUserName(user)}</H1>}
          subTitle={user.permission === Permissions.ADMIN && 'Administrator'}
          extra={[
            <Button key={1} onClick={() => showModal('dataChange')}>
              <UserSwitchOutlined /> Edytuj dane
            </Button>,
            <Button key={2} onClick={() => showModal('passwordChange')}>
              <LockOutlined /> Zmień hasło
            </Button>,
          ]}
        />
        <Descriptions title="Dane osobowe" size="small" column={1}>
          <Descriptions.Item label="Imię">
            <strong>{user.firstName}</strong>
          </Descriptions.Item>
          <Descriptions.Item label="Nazwisko">
            <strong>{user.lastName}</strong>
          </Descriptions.Item>
          <Descriptions.Item label="E-mail">
            <strong>
              <Text copyable>{user.email}</Text>
            </strong>
          </Descriptions.Item>
          <Descriptions.Item label="Telefon">
            <strong>
              <Text copyable>{user.phone}</Text>
            </strong>
          </Descriptions.Item>
        </Descriptions>

        {/* MODALS */}
        {modal.passwordChange && (
          <ChangePasswordModal user={user} closeModal={closeModal} />
        )}
        {modal.dataChange && (
          <ChangeUserDataModal
            user={user}
            closeModal={closeModal}
            handleSubmit={handleChangeUserData}
          />
        )}
      </PageTemplate>
    );
  return null;
};

export default AccountSettingsPage;
