import axios, { AxiosResponse } from 'axios';
import { IUser } from 'services/store/types/users/Users';
import setAuthToken from 'services/utils/setAuthToken';

export const usersApi = {
  getAllUsers: async (): Promise<AxiosResponse<IUser[]>> => {
    setAuthToken();
    return axios.get('/api/users');
  },
};
