import React, { useState } from 'react';
import { Input, Select, Modal, Typography } from 'antd';
import { HistoryColor } from 'services/store/enums/historyColor.enum';
import { ProjectEquipmentDestination } from 'services/store/enums/project.enum';
import { IHistory } from 'services/store/interfaces/history.interface';
import {
  IProject,
  IProjectEquipment,
} from 'services/store/types/projects/Projects';
import { _equipmentDestinations } from 'services/utils/const';
import { capitalizeFirstLetter } from 'services/utils/string';

const { Title } = Typography;
const { Option } = Select;

const initValues: IProjectEquipment = {
  name: '',
  description: '',
  destination: ProjectEquipmentDestination.BLOW,
};

interface IProps {
  project: IProject;
  closeModal: () => void;
  handleUpdateProject: (
    updateData: Partial<IProject>,
    historyUpdate: string[],
    historyColor: HistoryColor
  ) => Promise<any>;
}

const AddProjectEquipmentModal: React.FC<IProps> = ({
  project,
  closeModal,
  handleUpdateProject,
}) => {
  const [equipment, setEquipment] = useState<IProjectEquipment>(initValues);

  const onOk = async () => {
    const historyUpdate = [
      `Zaktualizowano listę sprzętu AGD.`,
      `Dodano: ${equipment.name}, dostarczany do: ${capitalizeFirstLetter(
        equipment.destination
      )}`,
    ];
    const newEquipment = [...project.equipment, equipment];

    await handleUpdateProject(
      { equipment: newEquipment },
      historyUpdate,
      HistoryColor.SUCCESS
    );
    closeModal();
  };

  const handleChange = (field: keyof IProjectEquipment, value: any) => {
    setEquipment({ ...equipment, [field]: value });
  };

  return (
    <Modal
      title="Dodawanie sprzętu AGD"
      destroyOnClose
      visible
      onCancel={closeModal}
      maskClosable={false}
      onOk={onOk}
      okButtonProps={{ disabled: !equipment.name }}
    >
      <Title level={5}>Nazwa:</Title>
      <Input
        value={equipment.name}
        onChange={(e) => handleChange('name', e.target.value)}
        placeholder="Nazwa"
      />
      <Title level={5}>Opis:</Title>
      <Input
        value={equipment.description}
        onChange={(e) => handleChange('description', e.target.value)}
        placeholder="Opis"
      />
      <Title level={5}>Dostarczyć do:</Title>
      <Select
        style={{ width: '100%' }}
        value={equipment.destination}
        onChange={(value) => handleChange('destination', value)}
      >
        {_equipmentDestinations.map((dest) => (
          <Option key={dest} value={dest}>
            {dest.toUpperCase()}
          </Option>
        ))}
      </Select>
    </Modal>
  );
};

export default AddProjectEquipmentModal;
