import React, { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { message, Modal, Select } from 'antd';
import useUser from 'hook/useUser';
import { cashRegisterApi } from 'services/api/cashRegister.api';
import { usersApi } from 'services/api/users.api';
import { getUserName } from 'services/utils/string';

const { Option } = Select;

interface IProps {
  closeModal: () => void;
  authorizedUsers: string[];
  refetchCashRegister: () => Promise<any>;
}

const ChangeCashRegisterAuthorizedUsers: React.FC<IProps> = ({
  closeModal,
  authorizedUsers,
  refetchCashRegister,
}) => {
  const { user: currentUser } = useUser();
  const [pickedUsers, setPickedUsers] = useState(authorizedUsers);

  const { data: users, isLoading } = useQuery({
    queryKey: ['get-all-users'],
    queryFn: () => usersApi.getAllUsers(),
    onError: () => {
      message.error('Błąd!');
    },
  });

  const { mutate: updateAuthorizedUsers, isLoading: isUpdating } = useMutation({
    mutationKey: ['change-cash-register-authorized-users'],
    mutationFn: () => cashRegisterApi.updateAuthorizedUsers(pickedUsers),
    onSuccess: async () => {
      await refetchCashRegister();
      message.success('Lista uprawnionych użytkowników zaktualizowana');
      closeModal();
    },
    onError: () => {
      message.error('Błąd!');
    },
  });

  return (
    <Modal
      title="Dostęp do kasy"
      destroyOnClose
      visible
      onCancel={closeModal}
      maskClosable={false}
      onOk={() => updateAuthorizedUsers()}
      okButtonProps={{ loading: isLoading || isUpdating }}
    >
      Dodatkowi użytkownicy uprawnieni do dostępu do kasy:
      <Select
        value={pickedUsers}
        onChange={setPickedUsers}
        placeholder="Uprawnieni użytkownicy"
        disabled={!users?.data}
        mode="multiple"
        style={{ width: '100%', marginTop: 10 }}
      >
        {users?.data.map((user) => (
          <Option
            key={user._id}
            value={user._id}
            disabled={user._id === currentUser?._id}
          >
            {getUserName(user)}
          </Option>
        ))}
      </Select>
    </Modal>
  );
};

export default ChangeCashRegisterAuthorizedUsers;
