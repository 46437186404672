import axios, { AxiosResponse } from 'axios';
import { UserStatisticsType } from 'services/store/enums/statistics.enum';
import { ISearchByDateParams } from 'services/store/interfaces/search-params.interface';
import { ISearchResult } from 'services/store/interfaces/search-result.interface';
import { IWorkTime } from 'services/store/types/statistics/Statistics';
import { saveFileAs } from 'services/utils/files.utils';
import setAuthToken from 'services/utils/setAuthToken';

export interface IWorkTimeCreate
  extends Omit<IWorkTime, '_id' | 'updateHistory' | 'cost' | 'user'> {
  user: string;
}
export interface IWorkTimeUpdate
  extends Partial<Pick<IWorkTime, 'start' | 'finish' | 'action' | 'cost'>> {
  project?: string;
}

interface IWorkTimeSearchParams extends ISearchByDateParams {
  type?: UserStatisticsType;
  project?: string;
  action?: string;
}

interface IWorkTimeGenerateFileParams extends IWorkTimeSearchParams {
  user?: string;
}

export interface IWorkTimeSummaryGenerateFileParams {
  dateFrom: string;
  dateTo: string;
  project?: string;
  order?: string;
}

export const workTimesApi = {
  createWorkTime: async (
    body: IWorkTimeCreate
  ): Promise<AxiosResponse<IWorkTime>> => {
    setAuthToken();
    return axios.post(`/api/work-time/`, body);
  },

  getUserWorkTimes: async (
    userId: string,
    params: IWorkTimeSearchParams
  ): Promise<AxiosResponse<ISearchResult<IWorkTime>>> => {
    setAuthToken();
    return axios.get(`/api/work-time/user/${userId}`, { params });
  },

  getStatisticsFile: async (
    params: IWorkTimeGenerateFileParams
  ): Promise<void> => {
    setAuthToken();
    const res = await axios.get(`/api/work-time/get-file`, {
      params,
      responseType: 'blob',
    });

    const fileName = `statystyki.xlsx`;

    return saveFileAs(res.data, fileName);
  },

  getStatisticsSummaryFile: async (
    params: IWorkTimeSummaryGenerateFileParams,
    initFileName?: string
  ): Promise<void> => {
    setAuthToken();
    const res = await axios.get(`/api/work-time/get-summary-file`, {
      params,
      responseType: 'blob',
    });

    const fileName = `${initFileName}.xlsx` ?? `statystyki-podsumowanie.xlsx`;

    return saveFileAs(res.data, fileName);
  },

  updateWorkTime: async (
    workTimeId: string,
    body: IWorkTimeUpdate
  ): Promise<AxiosResponse<IWorkTime>> => {
    setAuthToken();
    return axios.patch(`/api/work-time/${workTimeId}`, body);
  },
};
