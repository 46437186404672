import {
  AppstoreAddOutlined,
  BarsOutlined,
  CalendarFilled,
  CalendarOutlined,
  DashOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  FieldNumberOutlined,
  FileAddFilled,
  FileTextOutlined,
  SmallDashOutlined,
  StepForwardOutlined,
  UserSwitchOutlined,
  CaretRightOutlined,
  StopOutlined,
  PauseOutlined,
  FileDoneOutlined,
} from '@ant-design/icons';
import { Button, Menu, Dropdown } from 'antd';
import { isMobile } from 'react-device-detect';
import { ProjectStatus } from 'services/store/enums/project.enum';
import { Permissions, Positions } from 'services/store/enums/users.enum';
import { IProject } from 'services/store/types/projects/Projects';
import { IWorkAction } from 'services/store/types/settings/Settings';
import { IUser } from 'services/store/types/users/Users';
import { includesOneOf } from 'services/utils/array';
import {
  getProjectStatusFromActivePosition,
  isProjectOnActivePosition,
  isUserWorkingOnProjectAtPosition,
} from 'services/utils/project.utils';
import { isUserHasPermission } from 'services/utils/users';

const { SubMenu } = Menu;

interface IActions {
  showModal: (key: any) => void;
  goToScheduleEdit: () => void;
  handleRemoveProject: () => void;
  goToNextStatus: () => void;
  finishProject: () => void;
  startProduction: (position: Positions) => void;
  stopProduction: (position: Positions) => void;
}
interface IProps {
  project: IProject;
  user: IUser;
  actions: IActions;
  workActions: IWorkAction[];
}

const ProjectActions: React.FC<IProps> = ({
  project,
  user,
  actions,
  workActions,
}) => {
  return (
    <>
      {user.activePosition &&
        (isUserWorkingOnProjectAtPosition(
          project,
          user.activePosition,
          user._id
        ) ? (
          <Button
            key={0}
            disabled={
              !user.activePosition ||
              !isUserWorkingOnProjectAtPosition(
                project,
                user.activePosition,
                user._id
              )
            }
            onClick={() =>
              user.activePosition && actions.stopProduction(user.activePosition)
            }
          >
            <StopOutlined />
            Zatrzymaj pracę
          </Button>
        ) : (
          <Button
            key={0}
            disabled={
              !user.activePosition ||
              !project.status.includes(
                getProjectStatusFromActivePosition(
                  user.activePosition
                ) as ProjectStatus
              )
            }
            onClick={() => {
              if (user.activePosition) {
                return actions.showModal('startProduction');
              }
            }}
          >
            <CaretRightOutlined />
            Rozpocznij pracę
          </Button>
        ))}

      {!isMobile && (
        <>
          <Button
            key={2}
            onClick={actions.goToNextStatus}
            disabled={
              (project.status.length === 1 &&
                project.status[0] === ProjectStatus.MONTAGE) ||
              includesOneOf(project.status, [
                ProjectStatus.CANCELED,
                ProjectStatus.FINISHED,
              ]) ||
              !isProjectOnActivePosition(project.status, user.activePosition)
            }
          >
            <StepForwardOutlined />
            Przekaż dalej
          </Button>

          {isUserHasPermission(user, [
            Positions.DESIGNER,
            Positions.TECHNOLOGIST,
          ]) && (
            <Button
              key={3}
              onClick={actions.finishProject}
              disabled={includesOneOf(project.status, [
                ProjectStatus.CANCELED,
                ProjectStatus.FINISHED,
              ])}
            >
              <FileDoneOutlined />
              Zakończ projekt
            </Button>
          )}
        </>
      )}
      <Dropdown
        key="more"
        overlay={dropDownMenu(project, user, actions)}
        trigger={['click']}
      >
        <Button type="primary">
          <EllipsisOutlined /> Menu
        </Button>
      </Dropdown>
    </>
  );
};

export default ProjectActions;

const dropDownMenu = (project: IProject, user: IUser, actions: IActions) => (
  <Menu>
    {isMobile && (
      <>
        <Menu.Item
          key={1}
          onClick={actions.goToNextStatus}
          disabled={
            (project.status.length === 1 &&
              project.status[0] === ProjectStatus.MONTAGE) ||
            includesOneOf(project.status, [
              ProjectStatus.CANCELED,
              ProjectStatus.FINISHED,
            ]) ||
            !isProjectOnActivePosition(project.status, user.activePosition)
          }
        >
          <StepForwardOutlined />
          Przekaż dalej
        </Menu.Item>
        {isUserHasPermission(user, [
          Positions.DESIGNER,
          Positions.TECHNOLOGIST,
        ]) && (
          <Menu.Item
            key={2}
            onClick={actions.finishProject}
            disabled={includesOneOf(project.status, [
              ProjectStatus.CANCELED,
              ProjectStatus.FINISHED,
            ])}
          >
            <FileDoneOutlined />
            Zakończ projekt
          </Menu.Item>
        )}
        <Menu.Divider />
      </>
    )}
    {isUserHasPermission(user, [
      Positions.DESIGNER,
      Positions.TECHNOLOGIST,
    ]) && (
      <>
        <SubMenu title="Projekt" key={2}>
          <Menu.Item
            key={3}
            onClick={() => actions.showModal('setProductionDays')}
          >
            <DashOutlined />
            Ustal dni produkcyjne
          </Menu.Item>

          <Menu.Item
            key={4}
            onClick={actions.goToScheduleEdit}
            disabled={includesOneOf(project.status, [
              ProjectStatus.CANCELED,
              ProjectStatus.FINISHED,
            ])}
          >
            <BarsOutlined />
            Zaplanuj produkcję
          </Menu.Item>
          <Menu.Item
            key={5}
            onClick={() => actions.showModal('changeStatus')}
            disabled={includesOneOf(project.status, [ProjectStatus.CANCELED])}
          >
            <SmallDashOutlined />
            Zmień status
          </Menu.Item>
          <Menu.Divider />

          <Menu.Item
            key={6}
            onClick={() => actions.showModal('changeNumber')}
            disabled={includesOneOf(project.status, [
              ProjectStatus.CANCELED,
              ProjectStatus.FINISHED,
            ])}
          >
            <FieldNumberOutlined />
            Zmień numer
          </Menu.Item>

          <Menu.Item
            key={7}
            onClick={() => actions.showModal('changeName')}
            disabled={includesOneOf(project.status, [
              ProjectStatus.CANCELED,
              ProjectStatus.FINISHED,
            ])}
          >
            <FileTextOutlined />
            Zmień nazwę
          </Menu.Item>
          <Menu.Divider />

          <Menu.Item
            key={8}
            onClick={() => actions.showModal('changeCreationDate')}
            disabled={includesOneOf(project.status, [
              ProjectStatus.CANCELED,
              ProjectStatus.FINISHED,
            ])}
          >
            <CalendarOutlined />
            Zmień datę utworzenia
          </Menu.Item>

          <Menu.Item
            key={9}
            onClick={() => actions.showModal('changeMontageDate')}
            disabled={includesOneOf(project.status, [
              ProjectStatus.CANCELED,
              ProjectStatus.FINISHED,
            ])}
          >
            <CalendarFilled />
            Zmień datę montażu
          </Menu.Item>
          <Menu.Divider />

          <Menu.Item
            key={10}
            onClick={() => actions.showModal('changeDesigner')}
            disabled={includesOneOf(project.status, [
              ProjectStatus.CANCELED,
              ProjectStatus.FINISHED,
            ])}
          >
            <UserSwitchOutlined />
            Zmień projektana
          </Menu.Item>

          <Menu.Item
            key={11}
            onClick={() => actions.showModal('changeTechnologist')}
            disabled={includesOneOf(project.status, [
              ProjectStatus.CANCELED,
              ProjectStatus.FINISHED,
            ])}
          >
            <UserSwitchOutlined />
            Zmień technologa
          </Menu.Item>
          <Menu.Divider />

          <Menu.Item
            key={12}
            danger
            disabled={user.permission !== Permissions.ADMIN}
            onClick={actions.handleRemoveProject}
          >
            <DeleteOutlined />
            Usuń
          </Menu.Item>
        </SubMenu>
        <Menu.Divider />
      </>
    )}

    {isUserHasPermission(user, [
      Positions.DESIGNER,
      Positions.TECHNOLOGIST,
    ]) && (
      <SubMenu title="Lakiernia" key={13}>
        <Menu.Item
          key={14}
          onClick={() => actions.showModal('addPaintedElementsOrder')}
          disabled={includesOneOf(project.status, [
            ProjectStatus.CANCELED,
            ProjectStatus.FINISHED,
          ])}
        >
          <AppstoreAddOutlined />
          Dodaj zamówienie
        </Menu.Item>
      </SubMenu>
    )}

    {isUserHasPermission(user, [
      Positions.DESIGNER,
      Positions.TECHNOLOGIST,
    ]) && (
      <SubMenu title="Akcesoria" key={15}>
        <Menu.Item
          key={16}
          onClick={() => actions.showModal('editAccessories')}
          disabled={includesOneOf(project.status, [
            ProjectStatus.CANCELED,
            ProjectStatus.FINISHED,
          ])}
        >
          <AppstoreAddOutlined />
          Edytuj akcesoria
        </Menu.Item>
      </SubMenu>
    )}

    {isUserHasPermission(user, [
      Positions.DESIGNER,
      Positions.TECHNOLOGIST,
    ]) && (
      <SubMenu title="AGD" key={17}>
        <Menu.Item
          key={18}
          onClick={() => actions.showModal('addEquipment')}
          disabled={includesOneOf(project.status, [
            ProjectStatus.CANCELED,
            ProjectStatus.FINISHED,
          ])}
        >
          <AppstoreAddOutlined />
          Dodaj AGD
        </Menu.Item>
      </SubMenu>
    )}

    {isUserHasPermission(user, [
      Positions.DESIGNER,
      Positions.TECHNOLOGIST,
      Positions.INSTALLER,
    ]) && (
      <SubMenu title="Pliki" key={19}>
        <Menu.Item
          key={20}
          disabled={includesOneOf(project.status, [
            ProjectStatus.CANCELED,
            ProjectStatus.FINISHED,
          ])}
          onClick={() => actions.showModal('addFile')}
        >
          <FileAddFilled />
          Dodaj plik
        </Menu.Item>
      </SubMenu>
    )}

    <SubMenu title="Zdjęcia" key={21}>
      <Menu.Item key={22} onClick={() => actions.showModal('addImage')}>
        <FileAddFilled />
        Dodaj zdjęcie
      </Menu.Item>
    </SubMenu>
  </Menu>
);
