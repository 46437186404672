import dayjs from 'dayjs';
import { renderDateTitle } from 'components/ProductionSchedule/components/renderDateTitle';
import { renderProductionNodeCell } from 'components/ProductionSchedule/components/renderProductionNodeCell';
import { IHandleProjectInAction } from 'components/ProductionSchedule/interfaces/productionSchedule.interface';
import { Positions } from 'services/store/enums/users.enum';
import { IAction } from 'services/store/types/productionNodes/ProductionNodes';
import { IProject } from 'services/store/types/projects/Projects';
import { IUser } from 'services/store/types/users/Users';
import { renderPartDayCell } from './renderEditCells';

export const getEditDayPartViewTableHeaders = (
  dates: Date[],
  addProjectToSchedule: IHandleProjectInAction,
  removeProjectFromSchedule: IHandleProjectInAction,
  cellWidth: number,
  project?: IProject
) => {
  const nodesItem = {
    title: 'Gniazda produkcyjne',
    width: 150,
    dataIndex: 'node',
    render: (data: { position: Positions; user: IUser }) => {
      return renderProductionNodeCell(data);
    },
    key: 'node',
    fixed: 'left',
    align: 'center' as const,
  };
  const datesColumns = dates.reduce((acc: any, date: Date) => {
    const result = [];

    for (let i = 0; i < 4; i++) {
      result.push({
        colSpan: i === 0 ? 4 : 0,
        title: renderDateTitle(date),
        // NOTE Otypować record
        render: (
          data: {
            action: IAction;
            indexes: {
              nodeIndex: number;
              scheduleIndex: number;
              actionIndex: number;
            };
          },
          record: any
        ) => {
          return renderPartDayCell(
            data?.action,
            date,
            record.node.position,
            addProjectToSchedule,
            removeProjectFromSchedule,
            data?.indexes,
            cellWidth,
            project?._id
          );
        },
        dataIndex: `${dayjs(date).format('DD.MM.YYYY')}[${i}]`,
        key: date,
        width: cellWidth,
        align: 'center' as const,
      });
    }
    return [...acc, ...result];
  }, []);

  return [nodesItem, ...datesColumns];
};
