export interface IAlert {
  type: AlertType;
  message: string;
  title?: string;
  name: string;
  id: string;
  source: AlertSource;
}

export enum AlertSource {
  ORDERS = 'orders',
  PROJECTS = 'projects',
}
export enum AlertType {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}
