import { useCallback, useEffect, useState } from 'react';
import { Button, message, PageHeader, Row, Select, Slider } from 'antd';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { H1, H2, H5 } from 'components/Header';
import ProductionScheduleEdit from 'components/ProductionSchedule/Edit/ProductionScheduleEdit';
import { TAppState } from 'services/store';
import { getAllProductionNodes } from 'services/store/actions/productionNodes';
import {
  setProjectProductionEditView,
  setLoading,
  setProjectProductionEditCellWidth,
} from 'services/store/actions/view';
import { HistoryColor } from 'services/store/enums/historyColor.enum';
import { ProjectStatus } from 'services/store/enums/project.enum';
import { ProjectProductionEditView } from 'services/store/enums/view.enum';
import { IProject } from 'services/store/types/projects/Projects';
import {
  getChangedNodesFromSchedule,
  isProjectProductionPlaned,
} from 'services/utils/projectProduction.utils';
import setAuthToken from 'services/utils/setAuthToken';
import PageTemplate from 'templates/PageTemplate';
import ProjectCard from './components/ProjectCard';
import { ProjectsCartsWrapper, SliderWrapper } from './components/styles';

const { Option } = Select;

const ProductionScheduleEditPage = () => {
  const location: { state: { projectId?: string } } = useLocation();
  const dispatch = useDispatch();
  const nodes = useSelector((state: TAppState) => state.productionNodes.nodes);
  const view = useSelector(
    (state: TAppState) => state.view.projectProductionEditView
  );
  const cellWitdh = useSelector(
    (state: TAppState) => state.view.projectProductionEditCellWidth
  );

  const [projects, setProjects] = useState<IProject[]>([]);
  const [activeProject, setActiveProject] = useState<IProject | undefined>(
    undefined
  );
  const [changedProjects, setChangedProjects] = useState<string[]>([]);
  const [isChanges, setIsChanges] = useState<boolean>(false);

  // --------------------------------------------------------------------------
  // NOTE Get data
  // --------------------------------------------------------------------------
  const getProjects = useCallback(async () => {
    try {
      dispatch(setLoading(true));
      setAuthToken();
      const res = await axios.get(`/api/projects`, {
        params: {
          status: [
            ProjectStatus.PENDING,
            ProjectStatus.PROJECT,
            ProjectStatus.TECHNOLOGIST,
            ProjectStatus.CARPENTER,
            ProjectStatus.STORAGE,
            ProjectStatus.MONTAGE,
          ],
          unpaged: true,
        },
      });
      setProjects(res.data.content);
      dispatch(setLoading(false));
    } catch (error) {
      console.log(error);
      message.error('Błąd');
      dispatch(setLoading(false));
    }
  }, []);

  // --------------------------------------------------------------------------
  // NOTE LIFECYCLE
  // --------------------------------------------------------------------------
  useEffect(() => {
    getProjects();
    dispatch(getAllProductionNodes());
  }, []);

  useEffect(() => {
    if (projects.length && location.state?.projectId) {
      const project = projects.find(
        (pr) => pr._id === location.state.projectId
      );
      if (project) setActiveProject(project);
    }
  }, [projects, location]);

  // --------------------------------------------------------------------------
  // NOTE HANDLERS
  // --------------------------------------------------------------------------

  const handleSetActiveProject = (project: IProject) =>
    setActiveProject(project);

  const addProjectToChanged = (id: string) => {
    if (!changedProjects.includes(id)) {
      setChangedProjects((prev) => [...prev, id]);
    }
  };

  const changeView = (key: ProjectProductionEditView) => {
    dispatch(setProjectProductionEditView(key));
  };
  const changeZoom = (z: number) =>
    dispatch(setProjectProductionEditCellWidth(z));

  const handleSave = async () => {
    dispatch(setLoading(true));
    try {
      setAuthToken();
      const changedNodes = getChangedNodesFromSchedule(nodes);
      await axios.patch(
        `/api/production-nodes/production/actions`,
        changedNodes
      );

      for (const id of changedProjects) {
        const project = projects.find((pr) => pr._id === id);

        if (project && project._id) {
          const isProjectPlaned = isProjectProductionPlaned(nodes, project._id);

          if (isProjectPlaned && !project?.isPlaned) {
            await axios.patch(`/api/projects/${project._id}`, {
              updateData: { isPlaned: true },
              historyUpdate: ['Zaplanowano produkcję'],
              historyColor: HistoryColor.SUCCESS,
            });
          } else if (!isProjectPlaned && project?.isPlaned) {
            await axios.patch(`/api/projects/${project._id}`, {
              updateData: { isPlaned: false },
              historyUpdate: ['Usunięto plan produkcji'],
              historyColor: HistoryColor.DANGER,
            });
          } else {
            await axios.patch(`/api/projects/${project._id}`, {
              updateData: {},
              historyUpdate: ['Zmieniono plan produkcji'],
              historyColor: HistoryColor.WARNING,
            });
          }
        }
      }
      await getProjects();
      setIsChanges(false);
      setActiveProject(undefined);
      dispatch(setLoading(false));
    } catch (error: any) {
      console.log(error, error.response);
      dispatch(setLoading(false));
    }
  };

  return (
    <PageTemplate>
      <PageHeader
        title={<H1>Harmonogram produkcji</H1>}
        extra={[
          <Button
            type="primary"
            key={0}
            style={{ marginRight: 5 }}
            onClick={handleSave}
            disabled={!isChanges}
          >
            Zapisz
          </Button>,
          <Select
            key={1}
            placeholder="Widok"
            value={view}
            onChange={(e) => changeView(e)}
            style={{ width: 100 }}
          >
            <Option value={ProjectProductionEditView.DAYS}>Dzień</Option>
            <Option value={ProjectProductionEditView.DAYS_PARTS}>
              Dzień / 4
            </Option>
          </Select>,
        ]}
      />
      <H2>Projekty</H2>
      <ProjectsCartsWrapper>
        {projects.map((project) => (
          <ProjectCard
            setActiveProject={handleSetActiveProject}
            key={project._id}
            project={project}
            isActive={project._id === activeProject?._id}
          />
        ))}
      </ProjectsCartsWrapper>
      <Row justify="end">
        <SliderWrapper>
          <H5>Szerokość kolumn</H5>
          <Slider
            value={cellWitdh}
            min={60}
            max={200}
            step={20}
            onChange={changeZoom}
          />
        </SliderWrapper>
      </Row>
      <ProductionScheduleEdit
        project={activeProject}
        isChanges={isChanges}
        setIsChanges={setIsChanges}
        addProjectToChanged={addProjectToChanged}
      />
    </PageTemplate>
  );
};

export default ProductionScheduleEditPage;
