import React, { useState } from 'react';
import { Modal, Button, message } from 'antd';
import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import {
  AntInput,
  AntInputNumber,
  AntPassword,
  AntSelect,
} from 'components/Formik-AntdFields/CreateAntdField';
import { getAllUsers } from 'services/store/actions/users';
import { Permissions } from 'services/store/enums/users.enum';
import { IUser } from 'services/store/types/users/Users';
import { _userPositions } from 'services/utils/const';
import { userCreateValidation } from '../utils/validation';

interface IInitUser extends IUser {
  password2: string;
}
const initData: IInitUser = {
  _id: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  password: '',
  password2: '',
  earnings: 0,
  activePosition: undefined,
  positions: [],
  permission: Permissions.USER,
  isSuperAdmin: false,
};

interface PropsT {
  closeModal: () => void;
}

const CreateUserModal: React.FC<PropsT> = ({ closeModal }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values: IUser, actions: any) => {
    setLoading(true);
    try {
      await axios.post(`/api/users`, {
        ...values,
        _id: undefined,
        activePosition: values.positions[0] || undefined,
      });
      dispatch(getAllUsers());
      message.success('Użytkownik utworzony');
      closeModal();
    } catch (error: any) {
      if (error.response?.data?.error)
        actions.setErrors(error.response.data.error);
      else message.error('Błąd');
      console.log(error.response);
    }
    setLoading(false);
  };

  return (
    <Modal
      title="Nowy użytkownik"
      destroyOnClose
      visible
      onCancel={closeModal}
      maskClosable={false}
      footer={[
        <Button key="back" onClick={closeModal}>
          Anuluj
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          htmlType="submit"
          form="ChangeDataForm"
        >
          Zatwierdź
        </Button>,
      ]}
    >
      <div>
        <Formik
          validationSchema={userCreateValidation}
          onSubmit={handleSubmit}
          initialValues={initData}
          validate={(values) => {
            const errors: any = {};
            if (values.password2 !== values.password)
              errors.password2 = 'Hasła muszą być identyczne';
            return errors;
          }}
        >
          {(props) => {
            return (
              <Form
                noValidate
                id="ChangeDataForm"
                onSubmit={props.handleSubmit}
              >
                <Field
                  component={AntInput}
                  name="firstName"
                  type="text"
                  placeholder="Imię"
                  validate={props.errors.firstName}
                  submitCount={props.submitCount}
                  hasFeedback
                />
                <Field
                  component={AntInput}
                  name="lastName"
                  type="text"
                  placeholder="Nazwisko"
                  validate={props.errors.lastName}
                  submitCount={props.submitCount}
                  hasFeedback
                />
                <Field
                  component={AntInput}
                  name="email"
                  type="email"
                  placeholder="E-mail"
                  validate={props.errors.email}
                  submitCount={props.submitCount}
                  hasFeedback
                />
                <Field
                  component={AntSelect}
                  name="positions"
                  placeholder="Stanowisko"
                  validate={props.errors.positions}
                  submitCount={props.submitCount}
                  hasFeedback
                  selectOptions={_userPositions}
                  mode="multiple"
                  allowClear
                />
                <Field
                  component={AntInput}
                  name="phone"
                  type="text"
                  placeholder="Nr telefonu"
                  validate={props.errors.phone}
                  submitCount={props.submitCount}
                  hasFeedback
                />
                Stawka/h
                <Field
                  component={AntInputNumber}
                  name="earnings"
                  validate={props.errors.earnings}
                  submitCount={props.submitCount}
                  hasFeedback
                />
                <Field
                  component={AntPassword}
                  name="password"
                  type="password"
                  placeholder="Hasło"
                  validate={props.errors.password}
                  submitCount={props.submitCount}
                  hasFeedback
                />
                <Field
                  component={AntPassword}
                  name="password2"
                  type="password2"
                  placeholder="Powtórz hasło"
                  validate={props.errors.password2}
                  submitCount={props.submitCount}
                  hasFeedback
                />
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default CreateUserModal;
