import { message } from 'antd';
import axios from 'axios';
import { HistoryColor } from 'services/store/enums/historyColor.enum';
import setAuthToken from 'services/utils/setAuthToken';

export const addItemToProjectHistory = async (
  projectId: string,
  historyUpdate: string[],
  historyColor: HistoryColor
) => {
  try {
    setAuthToken();
    const res = await axios.patch(`/api/projects/${projectId}/history`, {
      historyUpdate,
      historyColor,
    });

    return res.data;
  } catch (error: any) {
    console.log(error, error.response);
    message.error('Błąd');
  }
};
