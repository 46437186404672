import * as yup from 'yup';
import { IAddress } from 'services/store/types/address/Address';

export const categoryDataValidation = yup.object({
  type: yup.string().required('Typ jest wymagany'),
  name: yup
    .string()
    .min(1, 'Min. liczba znaków to 1')
    .max(50, 'Max. liczba znaków to 50')
    .required('Nazwa jest wymagana'),
  description: yup.string().max(250, 'Max. liczba znaków to 250'),
});
