import React, { useEffect } from 'react';
import { Button, message, Modal, Row } from 'antd';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import TextButton from 'components/Buttons/TextButton/TextButton';
import UserPositionTag from 'components/Tags/UserPositionTag/UserPositionTag';
import { loadUserData } from 'services/store/actions/auth';
import { getAllProductionNodes } from 'services/store/actions/productionNodes';
import { setLoading } from 'services/store/actions/view';
import { Positions } from 'services/store/enums/users.enum';
import { IProject } from 'services/store/types/projects/Projects';
import { IWorkTime } from 'services/store/types/statistics/Statistics';
import { IUser } from 'services/store/types/users/Users';
import setAuthToken from 'services/utils/setAuthToken';

interface IProps {
  user: IUser;
  closeModal: () => void;
}

const WorkingOnModal: React.FC<IProps> = ({ user, closeModal }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const project: IProject | undefined = user?.workingOn?.project as IProject;
  const workingOnPosition: Positions | undefined = user?.workingOn?.position;

  useEffect(() => {
    closeIfUserDisplayCurrentlyWorkingOnProject();
  }, [location]);

  const closeIfUserDisplayCurrentlyWorkingOnProject = () => {
    if (project?._id && location.pathname.includes(project._id)) {
      message.info('Wyświetlasz projekt nad którym pracujesz');
      return closeModal();
    }
  };
  const goToWorkingOnProject = () => {
    if (!project?._id) return;
    closeModal();
    return history.push(`/projects/${project._id}`);
  };
  const goToWorkingOnOrder = () => {
    if (!project?._id) return;
    closeModal();
    return history.push(`/orders/${project.order}`);
  };

  const stopWorkingOn = async () => {
    dispatch(setLoading(true));
    try {
      setAuthToken();

      await axios.patch(`/api/users/${user._id}/stop-working-on`);

      dispatch(loadUserData());
      message.success('Proces zakończony');
    } catch (error: any) {
      console.log(error, error.response);
      message.error('Błąd');
    }
    dispatch(setLoading(false));
    closeModal();
  };
  const stopProcess = async () => {
    if (!user.workingOn) return;
    await stopWorkingOn();
    if (user.workingOn.project) {
      return dispatch(getAllProductionNodes());
    }
  };

  return (
    <Modal
      title={'Info'}
      destroyOnClose
      visible
      onCancel={closeModal}
      maskClosable={false}
      width={620}
      footer={
        <Row justify="space-between">
          <Button
            disabled={!project || !workingOnPosition}
            onClick={goToWorkingOnOrder}
          >
            Przejdź do zamówienia
          </Button>
          <Button
            disabled={!project || !workingOnPosition}
            onClick={goToWorkingOnProject}
          >
            Przejdź do projektu
          </Button>
          <Button disabled={!user.workingOn} onClick={stopProcess}>
            Zakończ paracę nad procesem
          </Button>
        </Row>
      }
    >
      <div style={{ textAlign: 'center' }}>
        {user.workingOn?.project ? (
          <div>
            Aktualnie pracujesz nad projektem:{' '}
            <TextButton onClick={goToWorkingOnProject} text={project.number} />,
            na stanowisku:{' '}
            <UserPositionTag
              position={(user?.workingOn as IWorkTime)?.position}
            />
            .
          </div>
        ) : !user.workingOn ? (
          <div>Nie wykonujesz aktualnie żadnej akcji</div>
        ) : (
          <div>
            <div>Wykonujesz akcję nie związaną z żadnym projektem</div>
            <div>
              <small>Akcja: {user.workingOn.action}</small>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default WorkingOnModal;
