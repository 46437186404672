export const renderDayCell = (projectsNumbers: string[]) => {
  return (
    <div>
      {projectsNumbers?.map((el, i) => (
        <div style={{ wordWrap: 'break-word' }} key={`${i}${el}`}>
          {el}
        </div>
      ))}
    </div>
  );
};

export const renderPartDayCell = (projectsNumber: string) => {
  return projectsNumber;
};
