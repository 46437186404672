import React, { useCallback, useEffect, useState } from 'react';
import { message, Row } from 'antd';
import axios from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import { useDispatch } from 'react-redux';
import AntDatePicker from 'components/DatePicker/DatePicker';
import { setLoading } from 'services/store/actions/view';
import { OrderStatus } from 'services/store/enums/orders.enum';
import { IOrderStatistics } from 'services/store/types/statistics/Statistics';
import setAuthToken from 'services/utils/setAuthToken';
import OrdersStatisticsList from './OrdersStatisticsList';

interface IProps {
  status: OrderStatus;
}

const OrdersStatistics: React.FC<IProps> = ({ status }) => {
  const dispatch = useDispatch();
  const [dates, setDates] = useState<[Dayjs, Dayjs]>([
    dayjs().startOf('month'),
    dayjs(),
  ]);
  const [statistics, setStatistics] = useState<IOrderStatistics[]>([]);

  // --------------------------------------------------------------------------
  // NOTE GET DATA
  // --------------------------------------------------------------------------
  const getStatistics = useCallback(async () => {
    try {
      dispatch(setLoading(true));
      setAuthToken();
      const params: any = { status };

      if (status === OrderStatus.FINISHED) {
        params.finishedDateFrom = dates[0].toDate();
        params.finishedDateTo = dates[1].toDate();
      }

      const res = await axios.get(`/api/statistics/orders`, {
        params,
      });
      setStatistics(res.data);
    } catch (error) {
      console.log(error);
      message.error('Błąd');
    } finally {
      dispatch(setLoading(false));
    }
  }, [status, dates]);

  // --------------------------------------------------------------------------
  // NOTE LIFECYCLE
  // --------------------------------------------------------------------------
  useEffect(() => {
    getStatistics();
  }, [getStatistics]);

  return (
    <div>
      {status === OrderStatus.FINISHED && (
        <Row justify="end">
          <div>
            <div>Data zakończenia</div>
            <AntDatePicker.RangePicker
              size="large"
              allowClear={false}
              value={dates}
              onChange={(d: any) => setDates(d)}
              format="DD.MM.YYYY"
            />
          </div>
        </Row>
      )}
      <OrdersStatisticsList ordersStatistics={statistics} />
    </div>
  );
};

export default OrdersStatistics;
