import { IOrderStatistics } from 'services/store/types/statistics/Statistics';

export const getOrdersStatisticsSummary = (stats: IOrderStatistics[]) => {
  const summary = stats.reduce(
    (acc, statistics) => {
      acc.price += statistics.price;
      acc.alreadyPaid += statistics.alreadyPaid;
      acc.toPay += statistics.toPay;
      acc.costs += statistics.costs;
      acc.expectedProfit += statistics.expectedProfit;
      acc.employeesCosts += statistics.employeesCosts;
      acc.projectsCount += statistics.projectsCount;
      return acc;
    },
    {
      price: 0,
      alreadyPaid: 0,
      toPay: 0,
      costs: 0,
      expectedProfit: 0,
      employeesCosts: 0,
      projectsCount: 0,
    }
  );
  return summary;
};
