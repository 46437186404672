import React, { useCallback, useEffect, useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Modal, Button, Upload, message } from 'antd';
import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import {
  AntCheckbox,
  AntInput,
  AntInputNumber,
  AntSelect,
  AntTextArea,
} from 'components/Formik-AntdFields/CreateAntdField';
import { uploadFile } from 'services/requests/files.request';
import { getCategoriesByType } from 'services/requests/settings.request';
import { getAllAccessories } from 'services/store/actions/accessories';
import { CategoryType } from 'services/store/enums/categories.enum';
import { FileDestination } from 'services/store/enums/files.enum';
import { IAccessory } from 'services/store/types/accessories/Accessories';
import { ICategory } from 'services/store/types/settings/Settings';
import { accessoryValidation } from './utils/validation';

const initValues = {
  category: undefined,
  name: '',
  symbol: '',
  description: '',
  price: 0,
  isSuggested: false,
  image: '',
  files: [],
};

export interface IUpdateAccessory extends IAccessory {
  files: any[];
}
interface IProps {
  closeModal: () => void;
  onSubmit?: (accessory: IUpdateAccessory) => void;
  accessory?: IAccessory;
}

const AccessoryModal: React.FC<IProps> = ({
  closeModal,
  accessory,
  onSubmit,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState<ICategory[]>([]);

  const getCategories = useCallback(async () => {
    const data = await getCategoriesByType(CategoryType.ACCESSORY);
    setCategories(data);
  }, []);

  useEffect(() => {
    if (!categories.length) {
      getCategories();
    }
  }, [getCategories]);

  const createAccessory = async (newAccessory: IUpdateAccessory) => {
    try {
      let fileId = undefined;
      if (newAccessory.files.length) {
        fileId = await uploadFile(
          newAccessory.files[0],
          FileDestination.ACCESSORIES,
          newAccessory.name
        );
      }
      console.log(newAccessory);
      await axios.post(`/api/accessories/`, {
        ...newAccessory,
        image: fileId,
      });

      // TODO Dodać kasowanie pliku w przypadku błędu zapisu pliku
      dispatch(getAllAccessories());
      message.success('Utworzono akcesorium');
    } catch (error: any) {
      console.log(error, error.response);
      message.error('Błąd');
    }
  };

  const handleSubmit = async (values: IUpdateAccessory) => {
    setLoading(true);
    if (onSubmit) onSubmit(values);
    else await createAccessory(values);
    setLoading(false);
    closeModal();
  };

  return (
    <Modal
      afterClose={() => {
        setLoading(false);
      }}
      title={'Nowe akcesorium'}
      destroyOnClose
      visible
      onCancel={closeModal}
      maskClosable={false}
      footer={[
        <Button key="back" onClick={closeModal}>
          Anuluj
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          htmlType="submit"
          form="addAccessoryForm"
        >
          Zatwierdź
        </Button>,
      ]}
    >
      <Formik
        validationSchema={accessoryValidation}
        onSubmit={handleSubmit}
        initialValues={accessory ? { ...accessory, files: [] } : initValues}
      >
        {(props) => {
          return (
            <Form
              noValidate
              id="addAccessoryForm"
              onSubmit={props.handleSubmit}
            >
              <Field
                component={AntSelect}
                name="category"
                placeholder="Kategoria"
                validate={props.errors.category}
                submitCount={props.submitCount}
                hasFeedback
                selectOptions={categories}
              />
              <Field
                component={AntInput}
                name="symbol"
                type="text"
                placeholder="Symbol"
                validate={props.errors.symbol}
                submitCount={props.submitCount}
                hasFeedback
              />
              <Field
                component={AntInput}
                name="name"
                type="text"
                placeholder="Nazwa"
                validate={props.errors.name}
                submitCount={props.submitCount}
                hasFeedback
              />
              <Field
                component={AntTextArea}
                name="description"
                type="text"
                placeholder="Opis"
                validate={props.errors.description}
                submitCount={props.submitCount}
                hasFeedback
              />
              <Field
                component={AntInputNumber}
                name="price"
                placeholder="Cena"
                validate={props.errors.price}
                submitCount={props.submitCount}
                hasFeedback
              />
              <Field
                component={AntCheckbox}
                checkbox
                name="isSuggested"
                label="Sugerowany"
                validate={props.errors.isSuggested}
                submitCount={props.submitCount}
                hasFeedback
              />

              <Upload
                onRemove={() => props.setFieldValue('files', [])}
                beforeUpload={(file: any) => {
                  props.setFieldValue('files', [file]);
                  return false;
                }}
                fileList={[...props.values.files]}
                multiple={false}
                accept="image/*"
              >
                <Button icon={<UploadOutlined />}>Dodaj zdjęcie</Button>
              </Upload>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AccessoryModal;
