import { Button, Typography } from 'antd';
import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import {
  AntInput,
  AntPassword,
} from 'components/Formik-AntdFields/CreateAntdField';
import { H1 } from 'components/Header';
import { TAppState } from 'services/store';
import {
  loginSuccess,
  loadUserData,
  loginError,
} from 'services/store/actions/auth';
import { setLoading } from 'services/store/actions/view';
import { loginValidation } from './utils/validation';

const { Link } = Typography;

const Wrapper = styled.div`
  height: 100%;
  padding: 100px 30px;
  display: flex;
  justify-content: center;
`;
const StyledButton = styled(Button)`
  width: 100%;
`;
const LinkWrapper = styled.div`
  text-align: right;
  margin-top: 10px;
`;

interface ICredentials {
  email: string;
  password: string;
}

const LoginPage = () => {
  const dispatch = useDispatch();
  const isAuth = useSelector<TAppState>((state) => state.auth.isAuth);
  const { token } = localStorage;
  if (token && isAuth) return <Redirect to="/" />;

  const handleSubmit = async (credentials: ICredentials, actions: any) => {
    dispatch(setLoading(true));
    try {
      const res = await axios.post('/api/auth/login', credentials);
      const accessToken = res.data;
      if (accessToken) {
        dispatch(loginSuccess(accessToken));
        dispatch(loadUserData());
      }
    } catch (error) {
      dispatch(loginError());
      actions.setErrors({
        email: 'Nieprawidłowe dane logowania',
        password: 'Nieprawidłowe dane logowania',
      });
    }

    dispatch(setLoading(false));
  };
  return (
    <Wrapper>
      <div>
        <H1>Panel logowania</H1>
        <Formik
          validationSchema={loginValidation}
          onSubmit={handleSubmit}
          initialValues={{
            email: '',
            password: '',
          }}
          render={(props) => (
            <Form noValidate onSubmit={props.handleSubmit}>
              <Field
                component={AntInput}
                name="email"
                type="email"
                placeholder="Email"
                validate={props.errors.email}
                submitCount={props.submitCount}
                hasFeedback
              />
              <Field
                component={AntPassword}
                name="password"
                type="password"
                placeholder="Hasło"
                validate={props.errors.password}
                submitCount={props.submitCount}
                hasFeedback
              />

              <StyledButton htmlType="submit" type="primary" size="large">
                Zaloguj
              </StyledButton>
              <LinkWrapper>
                <Link href="/passwordremind">Nie pamiętam hasła</Link>
              </LinkWrapper>
            </Form>
          )}
        />
      </div>
    </Wrapper>
  );
};

export default LoginPage;
