import { Positions } from 'services/store/enums/users.enum';
import { IPositionNode } from './ProductionNodes';
import { IProject } from '../projects/Projects';

export const PRODUCTION_NODES_LOAD_SUCCESS = 'PRODUCTION_NODES_LOAD_SUCCESS';
export const PRODUCTION_NODES_CLEAR_DATA = 'PRODUCTION_NODES_CLEAR_DATA';

export const PRODUCTION_NODES_SET_USER_CURRENT_NODE =
  'PRODUCTION_NODES_SET_USER_CURRENT_NODE';

export const PRODUCTION_NODES_ADD_PROJECT_TO_ACTION =
  'PRODUCTION_NODES_ADD_PROJECT_TO_ACTION';
export const PRODUCTION_NODES_ADD_PROJECT_TO_DAY =
  'PRODUCTION_NODES_ADD_PROJECT_TO_DAY';
export const PRODUCTION_NODES_REMOVE_PROJECT_FROM_ACTION =
  'PRODUCTION_NODES_REMOVE_PROJECT_FROM_ACTION';
export const PRODUCTION_NODES_REMOVE_PROJECT_FROM_DAY =
  'PRODUCTION_NODES_REMOVE_PROJECT_FROM_DAY';

export interface ISetProductionNodes {
  type: typeof PRODUCTION_NODES_LOAD_SUCCESS;
  positionNodes: IPositionNode[];
  dates: Date[];
}
export interface ISetUserCurrentNode {
  type: typeof PRODUCTION_NODES_SET_USER_CURRENT_NODE;
  userId: string;
  position: Positions;
}
export interface IProductionNodesClear {
  type: typeof PRODUCTION_NODES_CLEAR_DATA;
}

export interface IAddProjectToNodeAction {
  type: typeof PRODUCTION_NODES_ADD_PROJECT_TO_ACTION;
  nodeIndex: number;
  scheduleIndex: number;
  actionIndex: number;
  project: IProject;
}
export interface IAddProjectToNodeDay {
  type: typeof PRODUCTION_NODES_ADD_PROJECT_TO_DAY;
  nodeIndex: number;
  scheduleIndex: number;
  project: IProject;
  isFriday: boolean;
}
export interface IRemoveProjectFromNodeAction {
  type: typeof PRODUCTION_NODES_REMOVE_PROJECT_FROM_ACTION;
  nodeIndex: number;
  scheduleIndex: number;
  actionIndex: number;
}
export interface IRemoveProjectFromNodeDay {
  type: typeof PRODUCTION_NODES_REMOVE_PROJECT_FROM_DAY;
  nodeIndex: number;
  scheduleIndex: number;
  isFriday: boolean;
}

export type TProductionNodesActions =
  | ISetUserCurrentNode
  | IAddProjectToNodeAction
  | IAddProjectToNodeDay
  | IRemoveProjectFromNodeAction
  | IRemoveProjectFromNodeDay
  | ISetProductionNodes
  | IProductionNodesClear;
