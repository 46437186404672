import React, { useState, useEffect } from 'react';
import {
  Modal,
  Input,
  Space,
  Select,
  message as messageModal,
  Checkbox,
} from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import AntDatePicker from 'components/DatePicker/DatePicker';
import { TAppState } from 'services/store';
import { getAllUsers } from 'services/store/actions/users';
import { ScheduleType } from 'services/store/enums/schedules.enum';
import { IProject } from 'services/store/types/projects/Projects';
import { ISchedule } from 'services/store/types/schedule/Schedule';
import { IUser } from 'services/store/types/users/Users';
import { _scheduleTypes } from 'services/utils/const';
import setAuthToken from 'services/utils/setAuthToken';
import {
  capitalizeFirstLetter,
  cutString,
  getUserName,
} from 'services/utils/string';

const { Option } = Select;

interface IProps {
  closeModal: () => void;
  getSchedule: () => Promise<any>;
  projects: IProject[];
}

// FIXME REFACTOR NEEDED (wynieść funkcję do aktualizacji terminarza gdzieś do requestów, tak samo update projektu zabrać z kontenera projektu)

const AddScheduleItemModal: React.FC<IProps> = ({
  closeModal,
  getSchedule,
  projects,
}) => {
  const dispatch = useDispatch();
  const users = useSelector((state: TAppState) => state.users.users);
  const user = useSelector((state: TAppState) => state.auth.user);

  const [loading, setLoading] = useState(false);
  const [createdFor, setCreatedFor] = useState<string[]>([]);
  const [date, setDate] = useState(new Date());
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [project, setProject] = useState<string | undefined>(undefined);
  const [type, setType] = useState<ScheduleType>(ScheduleType.OTHER);
  const [changeMontageInProject, setChangeMontageInProject] =
    useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    dispatch(getAllUsers(() => setLoading(false)));
  }, []);

  const createScheduleItem = async () => {
    if (user)
      try {
        setLoading(true);
        setAuthToken();
        const scheduleItemData: Partial<ISchedule> = {
          createdAt: new Date(),
          createdBy: user._id,
          date,
          createdFor,
          project,
          name,
          description,
          type,
        };
        await axios.post(`/api/schedule`, {
          ...scheduleItemData,
        });

        // TODO History item
        if (
          type === ScheduleType.MONTAGE &&
          project &&
          changeMontageInProject
        ) {
          await axios.put(`/api/projects/${project}`, {
            updateData: { montageAt: date },
          });
        }

        await getSchedule();
        messageModal.success('Utworzono wydarzenie');
        closeModal();
      } catch (error) {
        console.log(error);
        messageModal.error('Błąd');
      } finally {
        setLoading(false);
      }
  };

  const handleType = (value: ScheduleType) => {
    setType(value);
    if (value === ScheduleType.OTHER) setProject('');
  };

  return (
    <Modal
      title="Nowe wydarzenie"
      destroyOnClose
      maskClosable={false}
      visible
      onCancel={closeModal}
      onOk={createScheduleItem}
      okButtonProps={{
        loading,
        disabled: loading || !name || !date || !type,
      }}
    >
      <AntDatePicker
        style={{ width: '100%', marginBottom: 10 }}
        value={dayjs(date)}
        onChange={(d: any) => setDate(new Date(d))}
        allowClear={false}
        format="DD.MM.YYYY"
      />

      <Space direction="vertical" style={{ width: '100%' }}>
        <Select
          placeholder="Typ wydarzenia"
          value={type}
          style={{ width: '100%' }}
          onChange={handleType}
        >
          {_scheduleTypes.map((t) => (
            <Option key={t} value={t}>
              {capitalizeFirstLetter(t)}
            </Option>
          ))}
        </Select>

        <Select
          placeholder="Projekt"
          value={project}
          style={{ width: '100%' }}
          onChange={(value) => setProject(value)}
        >
          {projects.map(
            (p) =>
              p._id &&
              p.order?.customer && (
                <Option key={p._id} value={p._id}>
                  {getUserName(p.order?.customer, 'l-f')} -{' '}
                  {cutString(p.name, 20)}
                </Option>
              )
          )}
        </Select>

        {type === ScheduleType.MONTAGE && project && (
          <Checkbox
            checked={changeMontageInProject}
            onChange={(e) => setChangeMontageInProject(e.target.checked)}
          >
            Zmień datę montażu w projekcie
          </Checkbox>
        )}

        <Select
          allowClear
          mode="multiple"
          placeholder="Odbiorcy wydarzenia"
          disabled={loading}
          loading={loading}
          value={createdFor}
          style={{ width: '100%' }}
          optionFilterProp="children"
          filterOption={(input, option: any) =>
            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          filterSort={(optionA, optionB) =>
            optionA.children
              .toLowerCase()
              .localeCompare(optionB.children.toLowerCase())
          }
          onClear={() => setCreatedFor([])}
          onChange={(value) => setCreatedFor(value)}
        >
          {users
            .filter((item: any) => item._id !== user?._id)
            .sort((a: any, b: any) => `${a.lastName}`.localeCompare(b.lastName))
            .map((item: IUser) => {
              if (item._id)
                return (
                  <Option key={item._id} value={item._id}>
                    {`${item.firstName} ${item.lastName}`}
                  </Option>
                );
            })}
        </Select>

        <Input
          value={name}
          onChange={(e: any) => setName(e.target.value)}
          placeholder="Nazwa"
        />

        <Input.TextArea
          value={description}
          onChange={(e: any) => setDescription(e.target.value)}
          placeholder="Opis..."
          rows={4}
        />
      </Space>
    </Modal>
  );
};

export default AddScheduleItemModal;
