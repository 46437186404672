import { IAccessories } from '../types/accessories/Accessories';
import {
  ACCESSORIES_LOAD_SUCCESS,
  TAccessoriesActions,
} from '../types/accessories/actions';

const initialState: IAccessories = {
  accessories: [],
};

const accessoriesReducer = (
  state = initialState,
  action: TAccessoriesActions
): IAccessories => {
  switch (action.type) {
    case ACCESSORIES_LOAD_SUCCESS: {
      return { ...state, accessories: action.accessories };
    }
    default:
      return state;
  }
};

export { accessoriesReducer };
