import React, { useState } from 'react';
import { InputNumber, Modal } from 'antd';
import { HistoryColor } from 'services/store/enums/historyColor.enum';
import { IOrder } from 'services/store/types/orders/Orders';

interface IProps {
  order: IOrder;
  closeModal: () => void;
  handleUpdateOrder: (
    updateData: Partial<IOrder>,
    historyUpdate: string[],
    historyColor: HistoryColor
  ) => Promise<any>;
}

const ChangeOrderPriceModal: React.FC<IProps> = ({
  order,
  closeModal,
  handleUpdateOrder,
}) => {
  const [newPrice, setNewPrice] = useState<any>(order.price);

  const onOk = async () => {
    const historyUpdate = [`Zmienono cenę:`, `${order.price} na ${newPrice}`];

    await handleUpdateOrder(
      { price: newPrice },
      historyUpdate,
      HistoryColor.WARNING
    );
    closeModal();
  };

  return (
    <Modal
      title="Zmiana ceny"
      destroyOnClose
      visible
      onCancel={closeModal}
      onOk={onOk}
    >
      Aktualna cena projektu wynosi {order.price}zł. chcesz ją zmienić na:
      <div>
        <InputNumber
          value={newPrice}
          onChange={(value) => setNewPrice(value)}
          parser={(value) => value?.replace(',', '.')}
          min={0}
          style={{ width: '100%' }}
          size="large"
        />
      </div>
    </Modal>
  );
};

export default ChangeOrderPriceModal;
