import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Modal, Row, message } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { RangeValue } from 'rc-picker/lib/interface';
import AntDatePicker from 'components/DatePicker/DatePicker';
import { workTimesApi } from 'services/api/workTimes.api';

interface IProps {
  closeModal: () => void;
  initDateFrom: Date;
  initDateTo: Date;
  fileName: string;
  projectId?: string;
  orderId?: string;
}

const WorkTimeSummaryModal = ({
  closeModal,
  projectId,
  orderId,
  initDateFrom,
  initDateTo,
  fileName,
}: IProps) => {
  const [dateFrom, setDateFrom] = useState<Dayjs>(dayjs(initDateFrom));
  const [dateTo, setDateTo] = useState<Dayjs>(dayjs(initDateTo));

  const { mutateAsync: getStatisticsSummaryFile, isLoading } = useMutation({
    mutationKey: ['download-stats-summary-file'],
    mutationFn: () => {
      return workTimesApi.getStatisticsSummaryFile(
        {
          dateFrom: dateFrom.startOf('day').toISOString(),
          dateTo: dateTo.endOf('day').toISOString(),
          project: projectId,
          order: orderId,
        },
        fileName
      );
    },
    onSuccess: async () => {
      message.success('Wygenerowano plik .xlsx');
      closeModal();
    },
    onError: () => {
      message.error('Błąd!');
    },
  });

  const isButtonDisabled = () => {
    if (!dateFrom || !dateTo) return true;
    if (dateFrom.isAfter(dateTo)) return true;
    if (!projectId && !orderId) return true;
    return false;
  };

  const handleDates = (dates: RangeValue<Dayjs>) => {
    if (dates && dates[0] && !dateFrom.isSame(dates[0], 'hour'))
      setDateFrom(dates[0]);
    if (dates && dates[1] && !dateTo.isSame(dates[1], 'hour'))
      setDateTo(dates[1]);
  };

  return (
    <Modal
      title={`Podsumowanie statystyk pracowników dla ${
        projectId ? 'projektu' : orderId ? 'zamówienia' : 'ERR!'
      }`}
      destroyOnClose
      visible
      onCancel={closeModal}
      maskClosable={false}
      onOk={() => getStatisticsSummaryFile()}
      okButtonProps={{
        disabled: isButtonDisabled(),
        loading: isLoading,
      }}
      okText="Pobierz"
    >
      Wybierz zakres dat:
      <Row justify="start">
        <AntDatePicker.RangePicker
          size="middle"
          value={[dateFrom, dateTo]}
          onChange={handleDates}
          allowClear={false}
          format="DD.MM.YYYY"
        />
      </Row>
    </Modal>
  );
};

export default WorkTimeSummaryModal;
