import React, { useEffect, useState } from 'react';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Card, message, Typography, Modal } from 'antd';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { TAppState } from 'services/store';
import { getAllProductionNodes } from 'services/store/actions/productionNodes';
import { setLoading } from 'services/store/actions/view';
import {
  IPositionNode,
  IProductionNode,
} from 'services/store/types/productionNodes/ProductionNodes';
import { capitalizeFirstLetter } from 'services/utils/string';

const { Text } = Typography;
const { confirm } = Modal;

interface IProps {
  setNodeToEdit: (node: IProductionNode) => void;
}

const ProductionNodes: React.FC<IProps> = ({ setNodeToEdit }) => {
  const dispatch = useDispatch();
  const nodes = useSelector((state: TAppState) => state.productionNodes.nodes);
  const [singleNodes, setSingleNodes] = useState<IProductionNode[]>([]);

  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(getAllProductionNodes());
    dispatch(setLoading(false));
  }, []);
  console.log(singleNodes);
  useEffect(() => {
    if (nodes) {
      const single = nodes.reduce(
        (acc: IProductionNode[], node: IPositionNode) => {
          const exist = acc.find((n) => n.nodeId === node.nodeId);
          if (exist) return [...acc];
          return [...acc, node.schedule[0]];
        },
        []
      );
      setSingleNodes(single);
    }
  }, [nodes]);

  const removeProductionNode = async (nodeId: string) => {
    dispatch(setLoading(true));
    try {
      await axios.delete(`/api/production-nodes/node/${nodeId}`);
      dispatch(getAllProductionNodes());
      message.success('Gniazdo produkcyjne usunięte');
    } catch (error) {
      message.error('Błąd');
    }
    dispatch(setLoading(false));
  };

  const confirmDeleteProductionNode = (nodeId: string) =>
    confirm({
      title: 'Czy na pewno chcesz usunąć to gniazdo produkcyjne?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Tak',
      okType: 'danger',
      cancelText: 'Nie',
      onOk() {
        removeProductionNode(nodeId);
      },
      onCancel() {},
    });

  return (
    <div>
      {singleNodes.map((node) => (
        <ProductionNode
          key={node._id}
          node={node}
          setNodeToEdit={setNodeToEdit}
          handleRemove={confirmDeleteProductionNode}
        />
      ))}
    </div>
  );
};

export default ProductionNodes;

interface IProductionNodeProps {
  node: IProductionNode;
  setNodeToEdit: (node: IProductionNode) => void;
  handleRemove: (nodeId: string) => void;
}

const ProductionNode: React.FC<IProductionNodeProps> = ({
  node,
  setNodeToEdit,
  handleRemove,
}) => {
  return (
    <Card
      style={{ width: 300, marginBottom: 10 }}
      bodyStyle={{ padding: '10px 5px' }}
      title={capitalizeFirstLetter(node.position)}
      extra={[
        <Button
          key={1}
          size="small"
          style={{ marginRight: 3 }}
          onClick={() => setNodeToEdit(node)}
        >
          Edytuj
        </Button>,
        <Button
          key={2}
          size="small"
          danger
          onClick={() => handleRemove(node.nodeId)}
        >
          <DeleteOutlined />
        </Button>,
      ]}
    >
      <ul style={{ marginTop: 10 }}>
        {/* {node.users.map((user) => ( */}
        <li key={node.user?._id}>
          {node.user?.firstName} {node.user?.lastName}
        </li>
        {/* ))} */}
      </ul>
    </Card>
  );
};
