import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import dayjs from 'dayjs';
import { useHistory } from 'react-router';
import OrderStatusTag from 'components/Tags/OrderStatusTag/OrderStatusTag';
import useUser from 'hook/useUser';
import { OrderStatus } from 'services/store/enums/orders.enum';
import { Positions } from 'services/store/enums/users.enum';
import { ICustomer } from 'services/store/types/customers/Customers';
import { IOrder } from 'services/store/types/orders/Orders';
import { IUser } from 'services/store/types/users/Users';
import { getUserName } from 'services/utils/string';
import { isUserHasPermission } from 'services/utils/users';

const columns: any[] = [
  {
    dataIndex: 'lp',
    title: 'Lp.',
    align: 'center' as const,
    render: (e: any, r: any, i: number) => i + 1,
    width: 50,
  },
  {
    title: 'Klient',
    dataIndex: 'customer',
    align: 'center' as const,
    render: (customer: ICustomer) => getUserName(customer),
    sorter: (a: IOrder, b: IOrder) => {
      if (a.customer && b.customer)
        return a.customer.lastName.localeCompare(b.customer.lastName);
    },
    width: 150,
  },
  {
    title: 'Firma',
    dataIndex: 'customer',
    align: 'center' as const,
    render: (customer: ICustomer) => customer.company || '-',
    sorter: (a: IOrder, b: IOrder) =>
      `${a.customer?.company}`.localeCompare(`${b.customer?.company}`),
  },
  {
    title: 'Utworzony przez',
    dataIndex: 'createdBy',
    align: 'center' as const,
    render: (createdBy: IUser) => getUserName(createdBy),
    sorter: (a: IOrder, b: IOrder) =>
      `${a.createdBy?.firstName}`.localeCompare(`${b.createdBy?.firstName}`),
  },
  {
    title: 'Nazwa',
    dataIndex: 'name',
    align: 'center' as const,
    sorter: (a: any, b: any) => a.name.localeCompare(b.name),
  },
  {
    title: 'Numer',
    dataIndex: 'number',
    align: 'center' as const,
  },
  {
    title: 'Data utworzenia',
    dataIndex: 'createdAt',
    align: 'center' as const,
    render: (createdAt: string) => dayjs(createdAt).format('DD.MM.YYYY'),
    defaultSortOrder: 'ascend' as const,
    sorter: (a: IOrder, b: IOrder) => {
      return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
    },
  },
  {
    title: 'Cena',
    dataIndex: 'price',
    align: 'center' as const,
    sorter: (a: IOrder, b: IOrder) => a.price - b.price,
  },
  {
    title: 'Data zakończenia',
    dataIndex: 'finishedAt',
    align: 'center' as const,
    render: (finishedAt: string) =>
      finishedAt ? dayjs(finishedAt).format('DD.MM.YYYY') : '-',
    sorter: (a: IOrder, b: IOrder) => {
      if (a.finishedAt && b.finishedAt)
        return (
          new Date(a.finishedAt).getTime() - new Date(b.finishedAt).getTime()
        );
      if (a.finishedAt && !b.finishedAt) return 1;
      if (!a.finishedAt && b.finishedAt) return -1;
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    align: 'center' as const,
    render: (status: OrderStatus) => <OrderStatusTag status={status} />,
    fixed: 'right',
    width: 120,
  },
];

interface IProps {
  user: IUser | null;
  orders?: IOrder[];
  isLoading?: boolean;
  maxHeight?: number;
}

const OrdersList: React.FC<IProps> = ({
  user,
  orders,
  isLoading = false,
  maxHeight,
}) => {
  const { isAdmin } = useUser();
  const history = useHistory();
  const [list, setList] = useState<any[]>([]);
  useEffect(() => {
    if (orders) {
      const newList = orders.map((order: IOrder, i: number) => ({
        ...order,
        price: isAdmin() ? order.price : '-',
        key: order._id,
      }));
      setList(newList);
    }
  }, [orders]);

  const handleRedirect = (id: string) => {
    history.push(`/orders/${id}`);
  };

  return (
    <Table
      columns={columns}
      pagination={false}
      dataSource={list}
      loading={isLoading}
      rowClassName={(r: any) => `table-row-clicable`}
      scroll={{ x: 1000, y: maxHeight }}
      size="small"
      onRow={(record) => {
        return {
          onClick: () => {
            handleRedirect(record._id);
          },
        };
      }}
    />
  );
};

export default OrdersList;
