import React, { useState } from 'react';
import { Select, Modal, Input } from 'antd';
import axios from 'axios';
import { checkIsOrdersStatusUpdateIsNeededAndUpdate } from 'services/requests/orders.request';
import { HistoryColor } from 'services/store/enums/historyColor.enum';
import { ProjectStatus } from 'services/store/enums/project.enum';
import { IProject } from 'services/store/types/projects/Projects';
import { includesOneOf } from 'services/utils/array';
import { _projectStatuses } from 'services/utils/const';
import {
  getProjectStatusFromActivePosition,
  sortProjectStatusByProductionProcess,
} from 'services/utils/project.utils';
import { capitalizeFirstLetter } from 'services/utils/string';

const { Option } = Select;

interface IProps {
  project: IProject;
  closeModal: () => void;
  handleUpdateProject: (
    updateData: Partial<IProject>,
    historyUpdate: string[],
    historyColor: HistoryColor
  ) => Promise<any>;
}

const ChangeProjectStatus: React.FC<IProps> = ({
  project,
  closeModal,
  handleUpdateProject,
}) => {
  const [status, setStatus] = useState<ProjectStatus[]>(project.status);
  const [cancelationReason, setCancelationReason] = useState<string>('');

  const handleChangeStatus = (value: ProjectStatus[]) => {
    const workingOnStatus = project.workingOn
      .filter((workingOn) => workingOn.position)
      .map((workingOn) =>
        getProjectStatusFromActivePosition(workingOn.position)
      );

    workingOnStatus.forEach((st) => {
      if (st && !value.includes(st)) value.push(st);
    });
    // NOTE FINISHED/CANCELED STATUS
    if (
      status.length === 1 &&
      [ProjectStatus.CANCELED, ProjectStatus.FINISHED].includes(status[0]) &&
      value.length > 1
    ) {
      const filteredValue = value.filter(
        (s) => s !== ProjectStatus.CANCELED && s !== ProjectStatus.FINISHED
      );
      return setStatus(filteredValue);
    }
    if (value.includes(ProjectStatus.CANCELED)) {
      return setStatus([ProjectStatus.CANCELED]);
    }
    if (value.includes(ProjectStatus.FINISHED)) {
      return setStatus([ProjectStatus.FINISHED]);
    }
    // NOTE PENDING STATUS
    if (
      status.length === 1 &&
      [ProjectStatus.PENDING].includes(status[0]) &&
      value.length > 1
    ) {
      const filteredValue = value.filter((s) => s !== ProjectStatus.PENDING);
      return setStatus(filteredValue);
    }
    if (value.includes(ProjectStatus.PENDING)) {
      return setStatus([ProjectStatus.PENDING]);
    }
    return setStatus(value);
  };

  const onOk = async () => {
    let historyColor = HistoryColor.SUCCESS;
    let historyUpdate = [
      `Zmieniono status projektu.`,
      `Z ${project.status.join(', ')} na ${status.join(', ')}`,
    ];

    if (status[0] === ProjectStatus.CANCELED) {
      historyColor = HistoryColor.DANGER;
      historyUpdate = [
        'Projekt został anulowany.',
        `Przyczyna: ${cancelationReason}`,
      ];
    }
    const sortedStatus = sortProjectStatusByProductionProcess(status);
    const updateData: Partial<IProject> = { status: sortedStatus };

    if (
      !includesOneOf(status, [
        ProjectStatus.CANCELED,
        ProjectStatus.FINISHED,
        ProjectStatus.PENDING,
      ]) &&
      !project.productionStartedAt
    ) {
      updateData.productionStartedAt = new Date();
    }

    await handleUpdateProject(updateData, historyUpdate, historyColor);

    if (
      includesOneOf(status, [ProjectStatus.CANCELED, ProjectStatus.FINISHED]) &&
      project.isPlaned
    ) {
      await axios.patch(
        `/api/production-nodes/remove-project-from-schedule/${project._id}`
      );
    }

    if (project.order?._id) {
      await checkIsOrdersStatusUpdateIsNeededAndUpdate(project.order._id);
    }

    closeModal();
  };

  return (
    <Modal
      title="Zmiana statusu projektu"
      destroyOnClose
      visible
      onCancel={closeModal}
      maskClosable={false}
      onOk={onOk}
      okButtonProps={{
        disabled:
          status[0] === ProjectStatus.CANCELED
            ? !cancelationReason
            : !status.length,
      }}
    >
      <Select
        value={status}
        onChange={handleChangeStatus}
        placeholder="Status"
        style={{ width: '100%' }}
        mode="multiple"
        allowClear
      >
        {_projectStatuses.map((s) => (
          <Option key={s} value={s}>
            {capitalizeFirstLetter(s)}
          </Option>
        ))}
      </Select>

      {status[0] === ProjectStatus.CANCELED && (
        <Input
          value={cancelationReason}
          onChange={(e) => setCancelationReason(e.target.value)}
          placeholder={'Przyczyna anulowania'}
          style={{ marginTop: 10 }}
        />
      )}
    </Modal>
  );
};

export default ChangeProjectStatus;
