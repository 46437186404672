import React from 'react';
import { H4 } from 'components/Header';
import ProjectsList from 'components/ProjectsList/ProjectsList';
import { ProjectStatus } from 'services/store/enums/project.enum';
import { Positions } from 'services/store/enums/users.enum';
import { IProject } from 'services/store/types/projects/Projects';
import { IUser } from 'services/store/types/users/Users';
import { isUserHasPermission } from 'services/utils/users';

interface IProps {
  projects?: IProject[];
  loading: boolean;
  user: IUser | null;
}

const DashboardProjects: React.FC<IProps> = ({ projects, loading, user }) => {
  return (
    <div>
      <H4>W produkcji</H4>
      <ProjectsList
        projects={projects?.filter(
          (project) => !project.status.includes(ProjectStatus.PENDING)
        )}
        isLoading={loading}
        showIsInCurrentSchedule
        maxHeight={400}
      />

      {isUserHasPermission(user, [
        Positions.TECHNOLOGIST,
        Positions.DESIGNER,
      ]) && (
        <>
          <H4>Oczekujące</H4>
          <ProjectsList
            projects={projects?.filter(
              (project) => project.status[0] === ProjectStatus.PENDING
            )}
            isLoading={loading}
            maxHeight={200}
          />
        </>
      )}
    </div>
  );
};

export default DashboardProjects;
