import Axios from 'axios';
import { Dispatch } from 'redux';
import setAuthToken from 'services/utils/setAuthToken';
import {
  ISetSettings,
  SETTINGS_LOAD_SUCCESS,
  IClearSettings,
  SETTINGS_CLEAR,
  ISetCategories,
  CATEGORIES_LOAD_SUCCESS,
  ISetWorkActions,
  WORK_ACTIONS_LOAD_SUCCESS,
} from '../types/settings/actions';
import { ICategory, ISettings, IWorkAction } from '../types/settings/Settings';

export const setSettings = (settings: ISettings): ISetSettings => ({
  type: SETTINGS_LOAD_SUCCESS,
  settings,
});

export const setCategories = (categories: ICategory[]): ISetCategories => ({
  type: CATEGORIES_LOAD_SUCCESS,
  categories,
});

export const setWorkActions = (
  workActions: IWorkAction[]
): ISetWorkActions => ({
  type: WORK_ACTIONS_LOAD_SUCCESS,
  workActions,
});

export const clearSettings = (): IClearSettings => ({
  type: SETTINGS_CLEAR,
});

/**--------------------
 * NOTE THUNK
 ----------------------*/
export const getGlobalSettings =
  (onEnd?: () => void) => async (dispatch: Dispatch) => {
    try {
      setAuthToken();
      const res = await Axios.get('/api/settings/');

      dispatch(setSettings(res.data));
      if (onEnd) onEnd();
    } catch (error) {
      dispatch(clearSettings());
    }
  };

export const getAllCategories =
  (onEnd?: () => void) => async (dispatch: Dispatch) => {
    try {
      setAuthToken();
      const res = await Axios.get('/api/settings/categories');

      dispatch(setCategories(res.data));
      if (onEnd) onEnd();
    } catch (error) {
      dispatch(clearSettings());
    }
  };

export const getAllWorkActions =
  (onEnd?: () => void) => async (dispatch: Dispatch) => {
    try {
      setAuthToken();
      const res = await Axios.get('/api/settings/work-actions');

      dispatch(setWorkActions(res.data));
      if (onEnd) onEnd();
    } catch (error) {
      dispatch(clearSettings());
    }
  };
