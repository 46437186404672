import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import InnerSpinner from 'components/Spinner/InnerSpinner';
import { TAppState } from 'services/store';
import { IMessage } from 'services/store/types/messages/Messages';
import { IUser } from 'services/store/types/users/Users';
import MessagesList from './MessagesList';
import ImportantMessageModal from '../Modals/ImportantMessage.modal';

const { TabPane } = Tabs;

const MessagesWrapper = styled.div`
  position: relative;
`;

interface IProps {
  messages: IMessage[];
  isLoading: boolean;
  markReaded: (id: string) => void;
  removeMessage: (id: string) => void;
  refetchMessages: () => Promise<any>;
}

const Messages: React.FC<IProps> = ({
  messages,
  isLoading,
  markReaded,
  removeMessage,
  refetchMessages,
}) => {
  const user = useSelector((state: TAppState) => state.auth.user);
  const [key, setKey] = useState<string>('received');
  const [importantMessage, setImportantMessage] = useState<
    IMessage | undefined
  >();

  useEffect(() => {
    if (messages.length) {
      const important = messages.find(
        (msg) =>
          msg.isImportant &&
          !msg.isReaded &&
          msg.user &&
          (msg.recipient as IUser)._id === user?._id
      );
      if (important) {
        setImportantMessage(important);
      }
    }
  }, [messages]);

  return (
    <MessagesWrapper>
      {isLoading && <InnerSpinner />}
      <Tabs defaultActiveKey={key} onChange={setKey} size="small">
        <TabPane tab="Odebrane" key="received">
          <MessagesList
            messages={messages?.filter(
              (message) =>
                message.user && (message.recipient as IUser)._id === user?._id
            )}
            markReaded={markReaded}
            removeMessage={removeMessage}
            user={user}
          />
        </TabPane>
        <TabPane tab="Wysłane" key="sended">
          <MessagesList
            messages={messages?.filter(
              (message) =>
                message.user && (message.user as IUser)._id === user?._id
            )}
            markReaded={markReaded}
            removeMessage={removeMessage}
            user={user}
          />
        </TabPane>
      </Tabs>

      {!!importantMessage && (
        <ImportantMessageModal
          closeModal={() => setImportantMessage(undefined)}
          message={importantMessage}
          refetchMessages={refetchMessages}
        />
      )}
    </MessagesWrapper>
  );
};

export default Messages;
