import React, { useEffect, useState } from 'react';
import { Col, Row, Statistic } from 'antd';
import { HistoryColor } from 'services/store/enums/historyColor.enum';
import { IOrder } from 'services/store/types/orders/Orders';
import CostsList from './CostsList';

interface IProps {
  order: IOrder;
  handleUpdateOrder: (
    updateData: Partial<IOrder>,
    historyUpdate: string[],
    historyColor: HistoryColor
  ) => Promise<any>;
}

const OrderCosts: React.FC<IProps> = ({ order, ...props }) => {
  const [costs, setCosts] = useState({ gross: 0, net: 0 });

  useEffect(() => {
    const gross = order.costs?.reduce((acc, cost) => {
      const sum = acc + cost.valueGross;
      return sum;
    }, 0);
    const net = order.costs?.reduce((acc, cost) => {
      const sum = acc + cost.valueNet;
      return sum;
    }, 0);
    setCosts({ gross, net });
  }, [order]);

  return (
    <div>
      <Row>
        <Col md={{ span: 2, offset: 20 }} span={24}>
          <Statistic title="Koszty brutto" value={costs.gross} suffix="zł" />
        </Col>
        <Col md={{ span: 2 }} span={24}>
          <Statistic title="Koszty netto" value={costs.net} suffix="zł" />
        </Col>
      </Row>
      <CostsList costs={order.costs} {...props} />
    </div>
  );
};

export default OrderCosts;
