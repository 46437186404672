import React from 'react';
import { Tag } from 'antd';
import { PaintedElementsOrderStatus } from 'services/store/enums/paintedElementsOrder.enum';

interface IProps {
  status: PaintedElementsOrderStatus;
}

const PaintedElementsOrderStatusTag: React.FC<IProps> = ({ status }) => {
  if (status === PaintedElementsOrderStatus.SENDED)
    return <Tag>{status.toUpperCase()}</Tag>;
  if (status === PaintedElementsOrderStatus.SAW)
    return <Tag>{status.toUpperCase()}</Tag>;
  if (status === PaintedElementsOrderStatus.MACHINING)
    return <Tag color="gold">{status.toUpperCase()}</Tag>;
  if (status === PaintedElementsOrderStatus.RAW)
    return <Tag color="orange">{status.toUpperCase()}</Tag>;
  if (status === PaintedElementsOrderStatus.GRINGING)
    return <Tag color="cyan">{status.toUpperCase()}</Tag>;
  if (status === PaintedElementsOrderStatus.BASE)
    return <Tag>{status.toUpperCase()}</Tag>;
  if (status === PaintedElementsOrderStatus.PAINT)
    return <Tag color="blue">{status.toUpperCase()}</Tag>;
  if (status === PaintedElementsOrderStatus.POLISH)
    return <Tag color="red">{status.toUpperCase()}</Tag>;
  if (status === PaintedElementsOrderStatus.PACKING)
    return <Tag color="green">{status.toUpperCase()}</Tag>;
  if (status === PaintedElementsOrderStatus.FINISHED)
    return <Tag color="purple">{status.toUpperCase()}</Tag>;
  if (status === PaintedElementsOrderStatus.PICKED)
    return <Tag>{status.toUpperCase()}</Tag>;
  return null;
};

export default PaintedElementsOrderStatusTag;
