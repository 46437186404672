import update from 'immutability-helper';
import { getUserCurrentNode } from 'services/utils/projectProduction.utils';
import {
  PRODUCTION_NODES_CLEAR_DATA,
  PRODUCTION_NODES_LOAD_SUCCESS,
  TProductionNodesActions,
  PRODUCTION_NODES_ADD_PROJECT_TO_ACTION,
  PRODUCTION_NODES_ADD_PROJECT_TO_DAY,
  PRODUCTION_NODES_REMOVE_PROJECT_FROM_ACTION,
  PRODUCTION_NODES_REMOVE_PROJECT_FROM_DAY,
  PRODUCTION_NODES_SET_USER_CURRENT_NODE,
} from '../types/productionNodes/actions';
import { IProductionNodes } from '../types/productionNodes/ProductionNodes';

const initialState: IProductionNodes = {
  dates: [],
  nodes: [],
  userCurrentNode: null,
};

const productionNodesReducer = (
  state = initialState,
  action: TProductionNodesActions
): IProductionNodes => {
  switch (action.type) {
    case PRODUCTION_NODES_LOAD_SUCCESS: {
      return { ...state, nodes: action.positionNodes, dates: action.dates };
    }
    case PRODUCTION_NODES_SET_USER_CURRENT_NODE: {
      const userCurrentNode = getUserCurrentNode(
        action.userId,
        action.position,
        state.nodes
      );
      return { ...state, userCurrentNode };
    }
    case PRODUCTION_NODES_CLEAR_DATA: {
      return { ...state, nodes: [], dates: [] };
    }
    // NOTE HANDLE DAY/4
    case PRODUCTION_NODES_ADD_PROJECT_TO_ACTION: {
      return update(state, {
        nodes: {
          [action.nodeIndex]: {
            schedule: {
              [action.scheduleIndex]: {
                isChanged: { $set: true },
                actions: {
                  [action.actionIndex]: {
                    planedAt: { $set: new Date() },
                    project: { $set: action.project },
                  },
                },
              },
            },
          },
        },
      });
    }
    case PRODUCTION_NODES_REMOVE_PROJECT_FROM_ACTION: {
      return update(state, {
        nodes: {
          [action.nodeIndex]: {
            schedule: {
              [action.scheduleIndex]: {
                isChanged: { $set: true },
                actions: {
                  [action.actionIndex]: {
                    planedAt: { $set: null },
                    project: { $set: null },
                  },
                },
              },
            },
          },
        },
      });
    }
    // NOTE HANDLE WHOLE DAY
    case PRODUCTION_NODES_ADD_PROJECT_TO_DAY: {
      return update(state, {
        nodes: {
          [action.nodeIndex]: {
            schedule: {
              [action.scheduleIndex]: {
                isChanged: { $set: true },
                actions: {
                  0: {
                    planedAt: { $set: new Date() },
                    project: { $set: action.project },
                  },
                  1: {
                    planedAt: { $set: new Date() },
                    project: { $set: action.project },
                  },
                  2: {
                    planedAt: {
                      $apply: (current) =>
                        action.isFriday ? current : new Date(),
                    },
                    project: {
                      $apply: (current) =>
                        action.isFriday ? current : action.project,
                    },
                  },
                  3: {
                    planedAt: {
                      $apply: (current) =>
                        action.isFriday ? current : new Date(),
                    },
                    project: {
                      $apply: (current) =>
                        action.isFriday ? current : action.project,
                    },
                  },
                },
              },
            },
          },
        },
      });
    }
    case PRODUCTION_NODES_REMOVE_PROJECT_FROM_DAY: {
      return update(state, {
        nodes: {
          [action.nodeIndex]: {
            schedule: {
              [action.scheduleIndex]: {
                isChanged: { $set: true },
                actions: {
                  0: {
                    planedAt: { $set: null },
                    project: { $set: null },
                  },
                  1: {
                    planedAt: { $set: null },
                    project: { $set: null },
                  },
                  2: {
                    planedAt: {
                      $apply: (current) => (action.isFriday ? current : null),
                    },
                    project: {
                      $apply: (current) => (action.isFriday ? current : null),
                    },
                  },
                  3: {
                    planedAt: {
                      $apply: (current) => (action.isFriday ? current : null),
                    },
                    project: {
                      $apply: (current) => (action.isFriday ? current : null),
                    },
                  },
                },
              },
            },
          },
        },
      });
    }
    default:
      return state;
  }
};

export { productionNodesReducer };
