import React, { useEffect, useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Popconfirm, Table } from 'antd';
import { useSelector } from 'react-redux';
import { TAppState } from 'services/store';
import { HistoryColor } from 'services/store/enums/historyColor.enum';
import { ProjectEquipmentDestination } from 'services/store/enums/project.enum';
import { Positions } from 'services/store/enums/users.enum';
import { IHistory } from 'services/store/interfaces/history.interface';
import {
  IProject,
  IProjectEquipment,
} from 'services/store/types/projects/Projects';
import { isUserHasPermission } from 'services/utils/users';

const columns: any[] = [
  {
    dataIndex: 'lp',
    title: 'Lp.',
    align: 'center' as const,
    render: (e: any, r: any, i: number) => i + 1,
  },
  {
    title: 'Nazwa',
    dataIndex: 'name',
    align: 'center' as const,
    defaultSortOrder: 'ascend' as const,
    sorter: (a: any, b: any) => a.name.localeCompare(b.name),
  },
  {
    title: 'Opis',
    dataIndex: 'description',
    align: 'center' as const,
  },
  {
    title: 'Dostarczyć do',
    dataIndex: 'destination',
    align: 'center' as const,
    render: (destination: ProjectEquipmentDestination) =>
      destination.toUpperCase(),
  },
  {
    title: 'Usuń',
    dataIndex: 'remove',
    align: 'center' as const,
  },
];

interface IProps {
  equipment?: IProjectEquipment[];
  handleUpdateProject: (
    updateData: Partial<IProject>,
    historyUpdate: string[],
    historyColor: HistoryColor
  ) => Promise<any>;
  maxHeight?: number;
}

const EquipmentList: React.FC<IProps> = ({
  equipment,
  handleUpdateProject,
  maxHeight,
}) => {
  const user = useSelector((state: TAppState) => state.auth.user);

  const [list, setList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (equipment) {
      const newEquipment = equipment.map(
        (equip: IProjectEquipment, i: number) => ({
          ...equip,
          key: equip._id,
          remove: isUserHasPermission(user, [
            Positions.DESIGNER,
            Positions.TECHNOLOGIST,
          ]) ? (
            <Popconfirm
              key={1}
              title="Na pewno chcesz usunąć ten dokument?"
              onConfirm={() => handleRemove(equip)}
              okText="Tak"
              cancelText="Nie"
            >
              <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} />
            </Popconfirm>
          ) : (
            '-'
          ),
        })
      );
      setList(newEquipment);
    }
  }, [equipment]);

  const handleRemove = async (equip: IProjectEquipment) => {
    setIsLoading(true);
    const newEquipment = equipment?.filter((eq) => eq._id !== equip._id);

    const historyUpdate = [
      `Zaktualizowano listę sprzętu AGD.`,
      `Usunięto: ${equip.name}`,
    ];
    await handleUpdateProject(
      { equipment: newEquipment },
      historyUpdate,
      HistoryColor.DANGER
    );
    setIsLoading(false);
  };

  return (
    <Table
      columns={columns}
      size="small"
      pagination={false}
      dataSource={list}
      loading={isLoading}
      scroll={{ x: 1000, y: maxHeight }}
    />
  );
};

export default EquipmentList;
